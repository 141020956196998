import { React, useState, useEffect, toast, _, Modal, Link, BarLoader } from 'commons';
import axios from 'axios';
import { useToggler, useSecurity } from "shared/hooks";
import { LoadButton, ToggableBlocComponent, FieldComponent } from "shared/components";
import { mediumModalCustomStyles } from 'shared/services';
import { SheetAffectedApi, AccountApi } from 'shared/api';
import PushApi from 'api/Push';
import PerimeterSelectionModal from 'views/push/Perimeter/PerimeterSelectionModal';
import ConfigurationAccount from 'views/push/List/Assignements/AffectationForm/ConfigurationAccount';
import UpdateComments from 'views/push/List/Assignements/AffectationForm/UpdateComments';
import HtmlComponent from "shared/components/HtmlComponent";

export default function AffectationForm(props) {

    const [user] = useSecurity();
    const [loading, setLoading] = useState(false);
    const [selecting, setSelecting] = useState(false);
    const [updatingComment, setUpdatingComment] = useState(false);
    const [perimeter, setPerimeter] = useState(props.perimeter);
    const [tree, setTree] = useState([]);
    const [alreadyAffected, setAlreadyAffected] = useState([]);
    const [togglers, toggle] = useToggler({'configuration-global': true, 'configuration-specific': true});
    const [consultantComment, setConsultantComment] = useState("");
    const [excludeAfterAffectation, setExcludeAfterAffectation] = useState(true);
    const [includeInAlert, setIncludeInAlert] = useState(true);
    const [affecting, setAffecting] = useState(false);
    const [accountsSelection, setAccountsSelection] = useState([]);
    const [forcedPerimeters, setForcedPerimeters] = useState([]);
    const [batchCommentUpdatePerimeters, setBatchCommentUpdatePerimeters] = useState([]);
    const [includedPerimeters, setIncludedPerimeters] = useState(tree.map(a => "account" + a.id));
    const [specificComments, setSpecificComments] = useState([]);

    const watchsiteMapper = (w, a, as, parentSelected = false) => {
        const isSelected = parentSelected || perimeter.includes("watchsite:" + w.id) || perimeter.includes("account:" + a.id);
        if (isSelected) {
            as.push(a);
        }
        return {
            "is_node": false,
            "is_watchsite": true,
            "label": w.label,
            "id": w.id,
            "consultants": w.consultants,
            "affectation_mode": w.affectation_mode,
            "account": a,
            "is_selected": isSelected,
            "subscription_begin": w.subscription_begin,
            "subscription_end": w.subscription_end,
            "active": w.active,
        };
    };
    const nodeMapper = (n, a, as, parentSelected = false) => {
        const isSelected = parentSelected || perimeter.includes("node:" + n.id) || perimeter.includes("account:" + a.id);
        if (isSelected) {
            as.push(a);
        }
        let children = _.sortBy(n.childs, n => n.label.toLowerCase()).map(item => nodeMapper(item, a, as, isSelected));
        children = children.concat(_.sortBy(n.watchsites, w => w.label.toLowerCase()).map(item => watchsiteMapper(item, a, as, isSelected)));
        return {
            "is_node": true,
            "is_watchsite": false,
            "label": n.label,
            "id": n.id,
            "children": children,
            "account": a,
            "is_selected": isSelected,
        };
    };
    const cleanTree = (t) => {
        const cleanedTree = [];
        t.forEach(item => {
            if (item.is_node) {
                item.children = cleanTree(item.children);
                if (item.children.length > 0) {
                    cleanedTree.push(item);
                }
            } else if (item.is_watchsite && item.consultants.includes(user.id)) {
                cleanedTree.push(item);
            }
        });
        return cleanedTree;
    }

    function submit(e) {
        e.preventDefault();
        setAffecting(true);
        SheetAffectedApi
            .bulkAffect({
                sheet: props.sheet.id,
                comment_consultant: consultantComment,
                alert: includeInAlert,
                perimeter: determineAffectationPerimeter(),
                forced_watchsites: findForcedWatchsitesFromPerimeter(),
                specific_comments: specificComments,
            })
            .then(() => {
                toast.success("Fiche affectée.");
                if (excludeAfterAffectation) {
                    PushApi
                        .excludeSheets([props.sheet.id])
                        .then(() => {
                            toast.success("Fiche exclue");
                            props.refresh();
                            props.onClose();
                        });
                } else {
                    props.refresh();
                    props.onClose();
                }
            })
            .catch(error => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.forEach(object => toast.error(object.message));
                }
            })
            .finally(() => setAffecting(false));
    }

    function load() {
        setLoading(true);
        const batchRequests = [];
        if (!_.isEmpty(perimeter)) {
            batchRequests.push(
                PushApi
                    .affectations(props.sheet.id, perimeter)
                    .then(results => setAlreadyAffected(results.map(w => w.id)))
            );
        }

        const query = {"bool":{"should":[
            {"match":{"pilot_consultants": user.id}},
            {"match":{"associate_consultants": user.id}}
        ]}};
        AccountApi
            .search(query, 0, 99999, "name", "ASC")
            .then(([resultsAccounts]) => {
                const newTree = [];
                let newAccountsSelection = [];
                    
                resultsAccounts.forEach(account => {
                    if (props.restrictedAccount === null || props.restrictedAccount === account.id) {
                        batchRequests.push(
                            AccountApi
                                .getConsultantTree(account.id)
                                .then(accountTree => {
                                    if (accountTree) {
                                        const a = {"id": account.id, "name": account.name};
                                        newTree.push({
                                            "account": a,
                                            "tree": cleanTree(
                                                    _.sortBy(accountTree.nodes, n => n.label.toLowerCase()).map(item => nodeMapper(item, a, newAccountsSelection))
                                                ).concat(
                                                    _.sortBy(accountTree.watchsites, w => w.label.toLowerCase()).map(item => watchsiteMapper(item, a, newAccountsSelection))
                                                )
                                        });
                                    }
                                })
                        );
                    }                    
                });
                
                axios.all(batchRequests)
                    .then(() => {
                        setTree(_.sortBy(newTree, a => a.account.name.toLowerCase()));
                        newAccountsSelection = _.uniqBy(newAccountsSelection, a => a.id).map(a => a.id);
                        setAccountsSelection(newAccountsSelection);
                        setIncludedPerimeters(newAccountsSelection.map(a => "account:" + a));
                        setAccountsSelection(newAccountsSelection);
                        setLoading(false);
                    })
                    .catch((err) => {
                        toast.error("Impossible de récupérer vos comptes.")
                    });
            })
            .catch(() => toast.error("Impossible de récupérer vos comptes."));
    }

    function updateParameters(action, perimeter, checked) {
        if (action === "include-perimeter") {
            let newIncludedPerimeters = [...includedPerimeters];
            if (checked) {
                newIncludedPerimeters.push(perimeter);
            } else {
                newIncludedPerimeters = newIncludedPerimeters.filter(p => p !== perimeter);
            }
            setIncludedPerimeters(newIncludedPerimeters);
        }
        if (action === "force-perimeter") {
            let newForcedPerimeters = [...forcedPerimeters];
            if (checked) {
                newForcedPerimeters.push(perimeter);
            } else {
                newForcedPerimeters = newForcedPerimeters.filter(p => p !== perimeter);
            }
            setForcedPerimeters(newForcedPerimeters);
        }
        if (action === "addto-batch-update-comment") {
            let newBatchCommentUpdatePerimeters = [...batchCommentUpdatePerimeters];
            if (checked) {
                newBatchCommentUpdatePerimeters.push(perimeter);
            } else {
                newBatchCommentUpdatePerimeters = newBatchCommentUpdatePerimeters.filter(p => p !== perimeter);
            }
            setBatchCommentUpdatePerimeters(newBatchCommentUpdatePerimeters);
        }
        if (action === "use-specific-comment") {
            let newSpecificComments = [...specificComments];
            if (checked) {
                newSpecificComments.push({
                    comment: consultantComment,
                    watchsite: perimeter
                });
            } else {
                newSpecificComments = newSpecificComments.filter(o => o.watchsite !== perimeter);
            }
            setSpecificComments(newSpecificComments);
        }
    }

    function updateComment(watchsite, comment) {
        let newSpecificComments = [...specificComments];
        newSpecificComments.forEach(specificComment => {
            if (specificComment.watchsite === watchsite) {
                specificComment.comment = comment;
            }
        })
        setSpecificComments(newSpecificComments);
    }

    function updateMassComment(comment) {
        let newSpecificComments = [...specificComments];

        const updateCommentForItem = (item, parentIsInPerimeter) => {
            if (item.is_node) {
                item.children.forEach(itemTree => updateCommentForItem(
                    itemTree,
                    parentIsInPerimeter || batchCommentUpdatePerimeters.includes("node:" + item.id)
                ));
            }

            if (item.is_watchsite
                && (parentIsInPerimeter || batchCommentUpdatePerimeters.includes("watchsite:" + item.id))
            ) {
                const specificComment = _.find(newSpecificComments, {"watchsite": item.id});
                if (specificComment) {
                    specificComment.comment = comment;
                } else {
                    newSpecificComments.push({
                        comment: comment,
                        watchsite: item.id
                    });
                }
            }
        };

        tree.forEach(item => {
            if (batchCommentUpdatePerimeters.includes("account:" + item.account.id)) {
                item.tree.forEach(treeItem => updateCommentForItem(treeItem, true));
            } else {
                item.tree.forEach(treeItem => updateCommentForItem(treeItem, false));
            }
        });

        setSpecificComments(newSpecificComments);
        setBatchCommentUpdatePerimeters([]);
    }

    function determineAffectationPerimeter() {
        const affectationPerimeter = [];
        const addItem = (item, accountIsAdded = false) => {
            if (!props.sheet.is_private || props.sheet.confidential_account.id === item.account.id) {
                if (item.is_selected) {
                    if (item.is_node) {
                        if (accountIsAdded || includedPerimeters.includes("node:" + item.id)) {
                            affectationPerimeter.push("node:" + item.id);
                        } else {
                            item.children.forEach(itemTree => addItem(itemTree));
                        }
                        
                    }

                    if (item.is_watchsite && (accountIsAdded || includedPerimeters.includes("watchsite:" + item.id))) {
                        affectationPerimeter.push("watchsite:" + item.id);
                    }
                } else {
                    if (item.is_node) {
                        item.children.forEach(itemTree => addItem(itemTree, accountIsAdded));
                    }
                }
            }
        };
        tree.forEach(item => {
            if (includedPerimeters.includes("account:" + item.account.id)) {
                item.tree.forEach(treeItem => addItem(treeItem, true));
            } else {
                item.tree.forEach(treeItem => addItem(treeItem, false));
            }
        });
        return affectationPerimeter;
    }

    function findForcedWatchsitesFromPerimeter() {
        const forcedWatchsites = [];
        const addForcedWatchsite = (item, parentIsInPerimeter) => {
            if (item.is_node) {
                item.children.forEach(itemTree => addForcedWatchsite(
                    itemTree,
                    parentIsInPerimeter || forcedPerimeters.includes("node:" + item.id)
                ));
            }

            if (item.is_watchsite && (parentIsInPerimeter || forcedPerimeters.includes("watchsite:" + item.id))) {
                forcedWatchsites.push(item.id);
            }
        };
        tree.forEach(item => {
            if (forcedPerimeters.includes("account:" + item.account.id)) {
                item.tree.forEach(treeItem => addForcedWatchsite(treeItem, true));
            } else {
                item.tree.forEach(treeItem => addForcedWatchsite(treeItem, false));
            }
        });
        return forcedWatchsites;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, [user, props.restrictedAccount, perimeter]);

    return <section className="bloc form">
        <h1><Link className="color-gris-texte" to={`/sheets/${props.sheet.id}/preview`} target="_blank">Affectation de la fiche {props.sheet.id}</Link></h1>
        <HtmlComponent>{props.sheet.title}</HtmlComponent>
        <form className="form" id="affectation-form-form" onSubmit={submit}> 
            <ToggableBlocComponent label="Paramètre globaux de l'affectation" id="configuration-global" toggled={togglers["configuration-global"]} toggle={toggle}>
                {props.sheet.consultant_comment && <div className="bg-gris-10 border-gris-25 border-lrb">
                    <div className="flex-label">
                        <label className="at-top no-marge">Commentaire consultant type : </label>
                        <div dangerouslySetInnerHTML={{__html: props.sheet.consultant_comment}} />
                        <div className="col text-right at-top">
                            <button type="button" id="copy" onClick={() => setConsultantComment(props.sheet.consultant_comment)} className="btn h25 btn-na">
                                Copier dans le commentaire
                            </button>
                        </div>
                    </div>
                </div>}
                <FieldComponent
                    label="Commentaire global consultant retenu"
                    type="textarea"
                    rte={true}
                    name="consultant_comment"
                    value={consultantComment}
                    onChange={value => setConsultantComment(value)}
                />
                <FieldComponent
                    label="Exclure la fiche du push après affectation"
                    type="checksingle"
                    name="exclude_after_affectation"
                    checksingleLabel="Oui"
                    checked={excludeAfterAffectation}
                    onChange={value => setExcludeAfterAffectation(value ? true : false)}
                />
                <FieldComponent
                    type="checksingle"
                    name="include_in_alerte"
                    label="Inclure dans l'alerte"
                    checksingleLabel="Oui"
                    onChange={value => setIncludeInAlert(value ? true : false)}
                    checked={includeInAlert}
                />
            </ToggableBlocComponent>
            <ToggableBlocComponent label="Paramètre d'affectation et personnalisation du commentaire" id="configuration-specific" toggled={togglers["configuration-specific"]} toggle={toggle}>
                <div className="flex-label">
                    {props.sheet.is_private && <div className="col text-left">
                        <div class="alert alert-warning at-top">
                            Fiche privée limitée au compte {props.sheet.confidential_account ? props.sheet.confidential_account.name : "N/A"}
                        </div>
                    </div>}
                    <div className="col text-right at-top">
                        <button type="button" id="push-change-perimeter" className="btn btn-bleu-4" onClick={() => setSelecting(true)}>Ajouter des points de veille</button>
                    </div>                
                </div>
                <header className="bg-gris-45">
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <div className="d-flex">
                                <div className="w-120px text-center">Affecter</div>
                                <div className="w-120px text-center">Modifier le commentaire par lot</div>
                                <div className="w-120px text-center">Forcer la réaffectation</div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="bg-blanc">
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            {loading && <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />}
                            {!loading && <ul className="arborescence pushtree no-arrow offset-400px">
                                {tree.map(item => <React.Fragment key={item.account.id}>                                    
                                    {(!props.sheet.is_private || props.sheet.confidential_account.id === item.account.id) && <ConfigurationAccount
                                        item={item}
                                        includedPerimeters={includedPerimeters}
                                        forcedPerimeters={forcedPerimeters}
                                        batchCommentUpdatePerimeters={batchCommentUpdatePerimeters}
                                        specificComments={specificComments}
                                        updateParameters={updateParameters}
                                        updateComment={updateComment}
                                        accountsSelection={accountsSelection}
                                        alreadyAffected={alreadyAffected}
                                    />}
                                </React.Fragment>)}
                            </ul>}
                        </div>
                    </div>
                </div>
                <div className="bg-blanc">
                    <div className="row">
                        <div className="col">
                            <button type="button" id="back-push" onClick={() => setUpdatingComment(true)} className="btn btn-primary">
                                Modifier le commentaire pour la sélection
                            </button>
                        </div>
                    </div>
                </div>
            </ToggableBlocComponent>
            <section className="row">
                <div className="col">
                    <button type="button" id="back-push" onClick={() => props.onClose()} className="btn btn-bleu-4">
                        Retour à la liste
                    </button>
                </div>
                {!_.isEmpty(perimeter) && <div className="col text-right">
                    <LoadButton loading={affecting} label="Affecter" name="affect-sheet" id="affect-sheet" />
                </div>}
            </section>
        </form>
        <Modal isOpen={selecting} onRequestClose={() => setSelecting(false)} style={mediumModalCustomStyles}>
            <PerimeterSelectionModal
                perimeter={perimeter}
                setPerimeter={setPerimeter}
                restrictedAccount={props.restrictedAccount}
                onClose={() => setSelecting(false)}
            />
        </Modal>
        <Modal isOpen={updatingComment} onRequestClose={() => setUpdatingComment(false)} style={mediumModalCustomStyles}>
            <UpdateComments
                globalComment={consultantComment}
                onClose={() => setUpdatingComment(false)}
                onSubmit={updateMassComment}
            />
        </Modal>
    </section>;
}

AffectationForm.defaultProps = {
    restrictedAccount: null,
};
