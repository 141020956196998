import React, { useEffect } from 'react';
import _ from 'lodash';

import Header from "partials/Header";
import Menu from "partials/Menu";
import Footer from "partials/Footer";
import Breadcrumbs from "partials/Breadcrumbs";

import { useSecurity } from 'shared/hooks/Security';
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';

import routes from "routes";

export default function DefaultLayout(props) {

    const [user] = useSecurity();
    const [updateLastVisit] = useAutomaticRedirect();

    useEffect(() => {
        if (props.title) {
            document.title = "Apave Pilot Veille - Administration - " + props.title;
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props.updateLastVisit) {
        updateLastVisit();
    }

    function scrolling(event) {
        if (props.onScroll) {
            props.onScroll(event.target.scrollTop);
        }
    }

    return (
        <>
            <Header isLogged={!_.isEmpty(user)} />
            <section className="main" id="main">
                <Menu isLogged={!_.isEmpty(user)} />
                <main className="content" id="main-content" onScroll={_.debounce(scrolling, 300)}>
                    <Breadcrumbs routes={routes} />
                    <div className={props.contentWrapperClassName}>
                        {props.children}
                    </div>
                    <Footer screen={props.screen} />
                </main>
            </section>
        </>
    );
}

DefaultLayout.defaultProps = {
    updateLastVisit: true,
    contentWrapperClassName: "content-wrapper",
    screen: ""
};