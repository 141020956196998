import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SheetApi from 'shared/api/Sheet';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';

export default function RequirementImportForm(props) {

    const {sheet, onSuccess} = props;
    const [file, setFile] = useState();
    const [importing, setImporting] = useState(false);
    const [hasRequirements, setHasRequirements] = useState(true);

    useEffect(() => {
        SheetApi
            .hasRequirements(sheet.id)
            .then(data => setHasRequirements(data))
            .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function importRequirements() {
        setImporting(true);
        SheetApi
            .importRequirements(sheet.id, file)
            .then(response => {                
                setHasRequirements(true);
                onSuccess();
                toast.success("Import effectué");
            })
            .catch(error => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setFile(null);
                setImporting(false);
            });
    }

    if (hasRequirements) {
        return <></>
    } else {
        return (
            <section className="bloc">
                <header>
                    <h2 className="uppercase">Import des exigences</h2>
                </header>
                <div className="border-rlb border-gris-40">
                    <div className="row">
                        <div className="col-md-8">
                            <FieldComponent
                                type="file"
                                label="Fichiers à importer"
                                name="requirements"
                                accept=".xlsx"
                                onChange={value => setFile(value)}
                            />
                        </div>
                        <div className="col-md-4">
                            <LoadButton
                                loading={importing}
                                onClick={importRequirements}
                                label="Importer"
                                name="import-requirements"
                                id="import-requirements"
                            />
                        </div>
                    </div>
                </div>
            </section>
        );
    }   
}