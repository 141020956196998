import React, { useState } from 'react';
import { Link} from 'react-router-dom';

import confirm from 'shared/services/Confirmation';
import { prefixLinkTo } from 'shared/services';
import TreeWatchsiteComponent from 'shared/components/tree/TreeWatchsiteComponent';

import NodeApi from 'shared/api/Node';

import Can from 'shared/services/Can';

export default function TreeNodeComponent(props) {

    const [active, setActive] = useState(true);

    function deleteNode() {
        confirm("Confirmez vous la suppression du noeud ?", function() {
            NodeApi
                .delete(props.item.id)
                .then(response => {
                    props.onNodeRemove();
                })
                .catch(error => console.log(error));
        });
    }

    return (
        <li id={`node-${props.item.id}`} data-type="node" data-id={props.item.id} draggable="true" onDragStart={props.onDragStart} className={active === true ? "draggable actif" : "draggable"}>
            {(props.item.childs.length > 0 || props.item.watchsites.length > 0) && 
                <button type="button" className="btn btn-primary icon icon-only" onClick={() => active === true ? setActive(false) : setActive(true)}>
                    <i className={active ? "icon-boutons-moins" : "icon-boutons-plus"}></i>
                </button>
            }
            <Link 
                onClick={e => props.onNodeClick(e, props.item)}
                id={`node-${props.item.id}-link`}
                to={prefixLinkTo() + `/accounts/${props.account.id}/nodes/${props.item.id}`}
            >
                {props.item.label}
            </Link>
            {props.item.childs.length === 0
                && props.item.watchsites.length === 0
                && <Can
                perform="account:write"
                data={props.account}
                yes={() => (<button type="button" onClick={deleteNode} className="no-style">&nbsp;<i className="icon-boutons-poubelle" title="Supprimer"></i></button>)}
            />}
            <ul id={`node-${props.item.id}-childs`} className={active === true ? "" : "d-none"}>
                {props.item.watchsites.map((watchsite) => (
                    <TreeWatchsiteComponent
                        onDragStart={props.onDragStart}
                        key={watchsite.id}
                        item={watchsite}
                        onWatchsiteClick={props.onWatchsiteClick}
                        onSelect={props.onSelect}
                        selected={props.selected}
                        disabled={props.disabled}
                        account={props.account}
                    />
                ))}
                {props.item.childs.map((child) => (
                    <TreeNodeComponent
                        onDrop={props.onDrop}
                        onDragOver={props.onDragOver}
                        onDragStart={props.onDragStart}
                        key={child.id}
                        item={child}
                        onWatchsiteClick={props.onWatchsiteClick}
                        onNodeClick={props.onNodeClick}
                        onNodeRemove={props.onNodeRemove}
                        onSelect={props.onSelect}
                        selected={props.selected}
                        account={props.account}
                        disabled={props.disabled}
                    />
                ))}
            </ul>            
        </li>
    )
}