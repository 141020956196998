export const REQUIREMENT_STATUS_DRAFT = 0;
export const REQUIREMENT_STATUS_VALID = 1;
export const REQUIREMENT_STATUS_ARCHIVED = 2;

export const REQUIREMENT_STATUS = {
    [REQUIREMENT_STATUS_DRAFT]: "Brouillon",
    [REQUIREMENT_STATUS_VALID]: "Validé",
    [REQUIREMENT_STATUS_ARCHIVED]: "Archivé",
}

export const REQUIREMENT_STATUS_COLOR = {
    [REQUIREMENT_STATUS_DRAFT]: "new",
    [REQUIREMENT_STATUS_VALID]: "valid",
    [REQUIREMENT_STATUS_ARCHIVED]: "na"
}

export function getStatus() {    
    let status = [];
    for (const [key, value] of Object.entries(REQUIREMENT_STATUS)) {
        status.push({
            value: parseInt(key), 
            label: value,
        });
    }
    return status;
}

export function getStatusColor(status) {
    return REQUIREMENT_STATUS_COLOR[status];
}

export function getStatusLabel(status) {
    return REQUIREMENT_STATUS[status];
}