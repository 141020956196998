import { React, DefaultLayout, useEffect, useContext, useState, Link, _, BarLoader, toast } from 'commons';
import { useLocation } from "react-router-dom";
import { useList, usePosition, useSecurity, useQuery } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { InternalUserApi, SheetApi } from 'shared/api'
import { getSheetStatus, getSheetStatusColor, getSheetStatusLabel, SHEET_TO_RULE, SHEET_DONE, SHEET_TO_DO, SHEET_ARCHIVED } from 'data/SheetStatus';
import { getSheetStateColor, getSheetStateLabel, getSheetState, getSheetTodo, SHEET_STATE_TODECIDE, SHEET_STATE_TODO, SHEET_STATE_TYPE_TECHNICAL } from 'shared/data';
import { TreeReferentialComponent, HtmlComponent, ListComponent, StatusComponent, FilterComponent, LoadButton } from 'shared/components';
import { Can, denyIfCantPerform, preventDefault, compileDataToSelectOptions, FileUtils, formatDate, convertCriteriesToSearchQuery } from 'shared/services';
import SheetDashboardBatchAction from 'views/sheet/dashboard/BatchAction';

export default function SheetDashboard(props) {

    denyIfCantPerform(props, "sheet:dashboard");

    const query = useQuery();
    const location = useLocation();
    const [initializePosition, setPosition] = usePosition("sheet-dashboard");
    const [user] = useSecurity();
    const [experts, setExperts] = useState([]);
    const [librarians, setLibrarians] = useState([]);
    const [updatingAddToDispatch, setUpdatingAddToDispatch] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [referentialContext] = useContext(ReferentialContext);
    const [batchSelection, setBatchSelection] = useState([]);
    const tree = _.cloneDeep(referentialContext["tree"]);

    localStorage.setItem("sheetFormReferer", document.location.pathname);

    const columns = [
        {id: 'id', title: 'Numéro'},
        {id: 'title', title: 'Titre', render: (row) => <HtmlComponent>{row.title}</HtmlComponent>},
        {id: 'categories', title: 'Domaines', sortable: false, render: (row) => 
            <TreeReferentialComponent className="arborescence" items={tree} value={row.categories.map(e => e.id)} />
        },
        {id: 'nor', title: 'Numéro NOR'},
        {id: 'text_number', title: 'Numéro du texte'},
        {id: 'text_date', title: 'Date du texte', format: 'date'},
        {id: 'publication_date', title: 'Date de publication', format: 'date'},
        {id: 'date_end_expert_process', title: 'Date prévue de fin de traitement expert', render: (row) => 
            <span className={row.date_end_expert_process && new Date(row.date_end_expert_process) < new Date() ? "color-rouge" : ""}>{row.date_end_expert_process ? formatDate(row.date_end_expert_process) : '-'}</span>
        },
        {id: 'date_end_librarian_process', title: 'Date prévue de fin de traitement documentaliste', render: (row) => 
            <span className={row.date_end_librarian_process && new Date(row.date_end_librarian_process) < new Date() ? "color-rouge" : ""}>{row.date_end_librarian_process ? formatDate(row.date_end_librarian_process) : '-'}</span>
        },
        {id: 'add_to_dispatch', title: 'Dépêche', format: 'checksingle'},
        {id: 'todos', title: 'Action(s) à réaliser'},
        {id: 'status', title: 'Statut', render: (row) => 
            <StatusComponent 
                value={getSheetStatusLabel(row.status)} 
                color={getSheetStatusColor(row.status)} 
            />
        },
        {id: 'state_synthesis', title: 'Niveau synthèse', render: (row) => 
            <StatusComponent 
                value={getSheetStateLabel(row.state_synthesis)} 
                color={getSheetStateColor(row.state_synthesis)} 
            />
        },
        {id: 'state_technical', title: 'Niveau technique', render: (row) => 
            <StatusComponent 
                value={getSheetStateLabel(row.state_technical)} 
                color={getSheetStateColor(row.state_technical)} 
            />
        },
        {id: 'librarian', title: 'Documentaliste affecté', sortable: false, render: (row) => <>{row.librarian ? row.librarian.firstname + " " + row.librarian.lastname : ""}</>},
        {id: 'expert', title: 'Expert affecté', sortable: false, render: (row) => <>{row.expert ? row.expert.firstname + " " + row.expert.lastname : ""}</>},        
        {id: 'action', title: '', sortable: false, render: (row) => {
            let output;
            switch (row.status) {
                case SHEET_TO_RULE:
                    output = <ul className="actions">
                        <li>
                            <Link title="Modifier la fiche" to={"/sheets/" + (row.is_private ? "private/" : "") + row.id} id={`edit-sheet-${row.id}`}>
                                <i className="icon-actions-modifier" aria-hidden="true"></i>
                            </Link>
                            {(
                                user.roles.includes("ROLE_LIBRARIAN")
                                || user.roles.includes("ROLE_ADMIN")
                                || user.roles.includes("ROLE_SUPER_ADMIN")
                            ) && <Link onClick={(e) => archiveSheet(e, row)} title="Archiver la fiche" to={"/sheets/" + row.id}id={`archive-sheet-${row.id}`}>
                                <i className="icon-actions-archiver-fiche" aria-hidden="true"></i>
                            </Link>}
                        </li>
                    </ul>;
                    break;
                case SHEET_TO_DO:
                    output = <ul className="actions">
                        <li>
                            {!row.is_private &&<Link title="Modifier la fiche" to={"/sheets/" + row.id} id={`edit-sheet-${row.id}`}>
                                <i className="icon-actions-modifier" aria-hidden="true"></i>
                            </Link>}
                            {row.is_private && <Link title="Modifier la fiche" to={"/sheets/private/" + row.id} id={`edit-sheet-${row.id}`}>
                                <i className="icon-actions-modifier" aria-hidden="true"></i>
                            </Link>}
                            <Link onClick={(e) => downloadText(e, row)} title="Consulter le texte reglementaire" to={"/sheets/" + row.id} id={`download-text-${row.id}`}>
                                <i className="icon-actions-consulter-pdf" aria-hidden="true"></i>
                            </Link>
                        </li>
                    </ul>;
                    break;
                case SHEET_DONE:
                    output = <ul className="actions">
                        <li>
                            {!row.is_private && <Link title="Modifier la fiche" to={"/sheets/" + row.id} id={`edit-sheet-${row.id}`}>
                                <i className="icon-actions-modifier" aria-hidden="true"></i>
                            </Link>}
                            {row.is_private && <Link title="Modifier la fiche" to={"/sheets/private/" + row.id} id={`edit-sheet-${row.id}`}>
                                <i className="icon-actions-modifier" aria-hidden="true"></i>
                            </Link>}
                            {row.reglementary_text && <Link onClick={(e) => downloadText(e, row)} title="Consulter le texte reglementaire" to={"/sheets/" + row.id} id={`download-text-${row.id}`}>
                                <i className="icon-actions-consulter-pdf" aria-hidden="true"></i>
                            </Link>}
                            {updatingAddToDispatch !== row.id && (
                                user.roles.includes("ROLE_LIBRARIAN")
                                || user.roles.includes("ROLE_ADMIN")
                                || user.roles.includes("ROLE_SUPER_ADMIN")
                            ) && <Link onClick={(e) => toggleDispatch(e, row)} title="Ajouter/Retirer de la dépêche" to={"/sheets/" + row.id}id={`toggle-dispatch-${row.id}`}>
                                <i className="icon-actions-ajouter-retirer-depeche" aria-hidden="true"></i>
                            </Link>}
                            {updatingAddToDispatch === row.id && <BarLoader loading={true} width={20} color="#5bad27" css="display:inline-block;margin-left: 10px;" />}
                        </li>
                    </ul>;
                    break;
                case SHEET_ARCHIVED:
                    output = <ul className="actions">
                        <li>                            
                            {(
                                user.roles.includes("ROLE_LIBRARIAN")
                                || user.roles.includes("ROLE_ADMIN")
                                || user.roles.includes("ROLE_SUPER_ADMIN")
                            ) && <>
                                {!row.is_private &&<Link title="Modifier la fiche" to={"/sheets/" + row.id} id={`edit-sheet-${row.id}`}>
                                    <i className="icon-actions-modifier" aria-hidden="true"></i>
                                </Link>}
                                {row.is_private && <Link title="Modifier la fiche" to={"/sheets/private/" + row.id} id={`edit-sheet-${row.id}`}>
                                    <i className="icon-actions-modifier" aria-hidden="true"></i>
                                </Link>}
                            </>}
                            <Link onClick={(e) => downloadText(e, row)} title="Consulter le texte reglementaire" to={"/sheets/" + row.id} id={`download-text-${row.id}`}>
                                <i className="icon-actions-consulter-pdf" aria-hidden="true"></i>
                            </Link>
                        </li>
                    </ul>;
                    break;
                default:
                    output = <></>;
                    break;
            }
            return output;
        }}
    ];

    const filterType = {
        id: {type: "match", fields: ["id"]},
        title: {type: "wildcard", fields: ["title"]},
        status: {type: "terms", fields: ["status"]},
        state_synthesis: {type: "terms", fields: ["state_synthesis"]},
        state_technical: {type: "terms", fields: ["state_technical"]},
        expert: {type: "match", fields: ["expert"]},
        librarian: {type: "match", fields: ["librarian"]},
        add_to_dispatch: {type: "match", fields: ["add_to_dispatch"]},
        publication_date: {type: "range", fields: ["publication_date"]},
        text_date: {type: "range", fields: ["text_date"]},
        todos: {type: "terms", fields: ["todos"]},
        nor: {type: "match", fields: ["nor"]},
        text_number: {type: "match", fields: ["text_number"]},
        is_private: {type: "match", fields: ["is_private"], cast: "int"},
    };

    const initialCriterias = {
        status: [SHEET_TO_RULE, SHEET_DONE, SHEET_TO_DO],
        is_private: '0',
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh,,
        loadCriterias
    ] = useList("sheet-dashboard", SheetApi, null, "date_end_librarian_process", initialCriterias, "asc", 20, modifyQuery);

    function modifyQuery(query) {
        const searchWithoutExpert = criterias.expert && criterias.expert === -1;
        const searchWithoutLibrarian = criterias.librarian && criterias.librarian === -1;

        if (searchWithoutExpert || searchWithoutLibrarian) {
            let newCriterias = {...criterias};

            if (searchWithoutExpert) {
                delete newCriterias.expert;
            }

            if (searchWithoutLibrarian) {
                delete newCriterias.librarian;
            }
            
            let newQuery = convertCriteriesToSearchQuery(newCriterias, filterType);

            if (searchWithoutExpert) {
                newQuery["bool"]["must"].push({
                    "bool": {
                        "must_not": [
                            {"defined": {"expert": "number"}}
                        ]
                    }
                });
            }

            if (searchWithoutLibrarian) {
                newQuery["bool"]["must"].push({
                    "bool": {
                        "must_not": [
                            {"defined": {"librarian": "number"}}
                        ]
                    }
                });
            }

            return newQuery;
        }

        return query;
    }

    function load() {
        if (!query.has("trigger")) {
            submitSearch(filterType, () => initializePosition(), false);
        } else {
            triggerSearchFromQuery();
        }
        
        InternalUserApi
            .search({"bool":{"must":[{"match":{"active":1}},{"terms":{"roles":["ROLE_EXPERT"]}}]}})
            .then(([u]) => {
                let values = compileDataToSelectOptions(u, 'id', ['lastname', 'firstname']);
                values.unshift({
                    value: -1,
                    label: "<Non affecté uniquement>"
                });
                setExperts(values);
            });

        InternalUserApi
            .search({"bool":{"must":[{"match":{"active":1}},{"terms":{"roles":["ROLE_LIBRARIAN"]}}]}})
            .then(([u]) => {
                let values = compileDataToSelectOptions(u, 'id', ['lastname', 'firstname']);
                values.unshift({
                    value: -1,
                    label: "<Non affecté uniquement>"
                });
                setLibrarians(values);
            });            
    }

    function triggerNeedDecisionSearch() {
        loadCriterias({status: [SHEET_TO_RULE]}, filterType);
    }

    function triggerNeedSynthesis() {
        loadCriterias({
            status: [SHEET_TO_RULE, SHEET_TO_DO, SHEET_DONE],
            state_synthesis: [SHEET_STATE_TODO],
            librarian: user.id
        }, filterType);
    }

    function triggerNeedTechnicalAffectation() {
        loadCriterias({
            status: [SHEET_TO_RULE, SHEET_TO_DO, SHEET_DONE],
            state_technical: [SHEET_STATE_TODECIDE]
        }, filterType);
    }

    function triggerNeedTechnical() {
        loadCriterias({
            status: [SHEET_TO_RULE, SHEET_TO_DO, SHEET_DONE],
            state_technical: [SHEET_STATE_TODO],
            expert: user.id
        }, filterType);
    }

    function triggerNeedConsolidation() {
        loadCriterias({
            status: [SHEET_TO_RULE, SHEET_TO_DO, SHEET_DONE],
            todos: [1]
        }, filterType);
    }

    function archiveSheet(event, row) {
        event.preventDefault();
        SheetApi
            .bulkUpdate([row.id], {"status": SHEET_ARCHIVED})
            .then(() => {
                toast.success("Mise à jour effectuée.")
                refresh();
            });
    }

    function downloadText(event, row) {
        event.preventDefault();
        FileUtils.download(row.reglementary_text);
    }

    function toggleDispatch(event, row) {
        event.preventDefault();

        setUpdatingAddToDispatch(row.id);
        row.add_to_dispatch = !row.add_to_dispatch;
        const successMessage = row.add_to_dispatch ? "Fiche ajoutée de la dépêche" : "Fiche supprimée à la dépêche";
        SheetApi
            .bulkUpdate([row.id], {"add_to_dispatch": row.add_to_dispatch ? 1 : 0})
            .then(() => {
                _.delay(
                    () => {
                        refresh();
                        toast.success(successMessage);
                        setUpdatingAddToDispatch(false);
                    },
                    2000
                ); // solr indexation
            });
    }

    function triggerSearchFromQuery() {
        switch (query.get("trigger")) {
            case "need-decision":
                triggerNeedDecisionSearch();
                break;
            case "need-synthesis":
                triggerNeedSynthesis();
                break;
            case "need-technical":
                triggerNeedTechnical();
                break;
            case "need-technical-affectation":
                triggerNeedTechnicalAffectation();
                break;
            case "need-consolidation":
                triggerNeedConsolidation();
                break;
            default:
                submitSearch(filterType, () => initializePosition(), false);
                break;
        }
        props.history.push("/dashboard");
    }

    function runExport(mode) {
        setExporting(true);

        const exportQuery = convertCriteriesToSearchQuery(criterias, filterType);
        const fileName = FileUtils.getFilePrefix() + "-dashboard.xlsx";
        return SheetApi
            .exportDashboard(exportQuery, fileName)
            .then(data => {
                toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible.");
            })
            .catch((error) => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Une erreur est survenue");
                }
            })
            .finally(() => setExporting(false));
    }

    // eslint-disable-next-line
    useEffect(load, []);
    // eslint-disable-next-line
    useEffect(triggerSearchFromQuery, [location.search]);

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E14" title="Tableau de bord des fiches">
            <h1>Tableau de bord des fiches</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2>Recherches prédéfinies</h2>
                        </header>
                            <div className="bg-gris-25">                           
                                <Can perform="dashboard:search-need-decision" yes={() => (<button onClick={triggerNeedDecisionSearch} type="button" className="btn btn-primary h25" id="predefined-search-need-decision">Fiche à statuer</button>)} />
                                <Can perform="dashboard:search-need-synthesis" yes={() => (<button onClick={triggerNeedSynthesis} type="button" className="btn btn-primary h25" id="predefined-search-need-synthesis">Fiche à synthétiser</button>)} />
                                <Can perform="dashboard:search-need-technical-affectation" yes={() => (<button onClick={triggerNeedTechnicalAffectation} type="button" className="btn btn-primary h25" id="predefined-search-need-technical-affectation">Fiche à affecter niveau technique</button>)} />
                                <Can perform="dashboard:search-need-technical" yes={() => (<button onClick={triggerNeedTechnical} type="button" className="btn btn-primary h25" id="predefined-search-need-technical">Fiche à commenter niveau technique</button>)} />
                                <Can perform="dashboard:search-need-consolidation" yes={() => (<button onClick={triggerNeedConsolidation} type="button" className="btn btn-primary h25" id="predefined-search-need-consolidation">Fiche à consolider</button>)} />
                            </div>
                    </section>
                    <section className="filters">
                        <header>
                            <h2>Recherche</h2>
                        </header>
                        <div className="bg-gris-25">                            
                            <div className="row">
                                <FilterComponent
                                    label="Numéro de la fiche"
                                    name="id"
                                    onChange={value => addCriteria("id", value)}
                                    value={criterias.id}
                                />
                                <FilterComponent
                                    label="Mot dans le titre du texte"
                                    name="title"
                                    onChange={value => addCriteria("title", value)}
                                    value={criterias.title}
                                />
                                <FilterComponent
                                    type="select"
                                    label="Statut"
                                    name="status"
                                    multiple
                                    onChange={value => addCriteria("status", value)}
                                    value={criterias.status || []}
                                    options={getSheetStatus()}
                                />
                                <FilterComponent
                                    type="select"
                                    label="Statut niveau synthèse"
                                    name="state_synthesis"
                                    multiple
                                    clearable
                                    onChange={value => addCriteria("state_synthesis", value)}
                                    value={criterias.state_synthesis || []}
                                    options={getSheetState()}
                                />
                                <FilterComponent
                                    type="select"
                                    label="Statut niveau technique"
                                    name="state_technical"
                                    multiple
                                    clearable
                                    onChange={value => addCriteria("state_technical", value)}
                                    value={criterias.state_technical || []}
                                    options={getSheetState(SHEET_STATE_TYPE_TECHNICAL)}
                                />
                                <FilterComponent
                                    label="Expert affecté"
                                    type="select"
                                    name="expert"
                                    value={criterias.expert}
                                    options={experts}
                                    clearable
                                    onChange={value => addCriteria("expert", value)}
                                />
                                <FilterComponent
                                    label="Documentaliste affecté"
                                    type="select"
                                    name="librarian"
                                    value={criterias.librarian}
                                    options={librarians}
                                    clearable
                                    onChange={value => addCriteria("librarian", value)}
                                />
                                <FilterComponent
                                    label="Ajouter à la dépêche"
                                    type="radio"
                                    name="add_to_dispatch"
                                    value={criterias.add_to_dispatch}
                                    blankLabel="Tous"
                                    options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]}
                                    onChange={value => addCriteria("add_to_dispatch", value)}
                                />
                                <FilterComponent
                                    label="Date de parution"
                                    type="daterange"
                                    name="publication_date"
                                    value={criterias.publication_date}
                                    onChange={value => addCriteria("publication_date", value)}
                                />
                                <FilterComponent
                                    label="Date du texte"
                                    type="daterange"
                                    name="text_date"
                                    value={criterias.text_date}
                                    onChange={value => addCriteria("text_date", value)}
                                />
                                <FilterComponent
                                    label="Action(s) à réaliser"
                                    type="select"
                                    name="todos"
                                    options={getSheetTodo()}
                                    value={criterias.todos || []}
                                    multiple
                                    clearable
                                    onChange={value => addCriteria("todos", value)}
                                />
                                <FilterComponent
                                    label="Numéro NOR"
                                    name="nor"
                                    value={criterias.nor}
                                    onChange={value => addCriteria("nor", value)}
                                />
                                <FilterComponent
                                    label="Numéro de texte"
                                    name="text_number"
                                    value={criterias.text_number}
                                    onChange={value => addCriteria("text_number", value)}
                                />
                                <FilterComponent
                                    type="radio"
                                    name="is_private"
                                    onChange={value => addCriteria("is_private", value)}
                                    value={criterias.is_private}
                                    label="Fiche privée"
                                    blankLabel="Tous"
                                    options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-dashboard" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-dashboard" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="sheet-dashboard"
                        loading={loading}
                        selectable={true}
                        selection={batchSelection}
                        onSelect={(s) => setBatchSelection(s)}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        sortOptions={[
                            {value: 'librarian_name', label: 'Documentaliste affecté'},
                            {value: 'expert_name', label: 'Expert affecté'}
                        ]}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        batchActions={<SheetDashboardBatchAction cleanSelection={() => setBatchSelection([])} selection={batchSelection} setLoading={setLoading} refresh={refresh} />}
                        globalActions={(
                            <>
                                <LoadButton
                                    loading={exporting}
                                    onClick={runExport}
                                    label="Exporter"
                                    id="export-dashboard"
                                    className="btn btn-primary h25"
                                    iconClass="icon-file-excel"
                                />
                                <Link id="new-sheet" to="/sheets/new" className="btn btn-primary h25 icon">
                                    <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>&nbsp;Créer une fiche
                                </Link>
                            </>
                        )}
                    />
                </form>
            </div>
        </DefaultLayout>
    )
}