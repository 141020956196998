import React, { useEffect, useState, useContext } from 'react';
import BarLoader from "react-spinners/BarLoader";
import { toast } from 'react-toastify';
import axios from 'axios';
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';
import { useSecurity } from 'shared/hooks/Security';
import ReferentialApi from 'shared/api/Referential';
import SecurityApi from 'shared/api/Security';
import { ReferentialContext } from 'shared/stores/Referential';
import { REFERENTIALS } from 'shared/data/ReferentialType';

export default function Loader(props) {

    const [referentialLoaded, setReferentialLoaded] = useState(false);
    const [, referentialDispatcher] = useContext(ReferentialContext);
    const [,, setUser] = useSecurity();
    const [, getLastVisitPath, shouldRedirect] = useAutomaticRedirect();

    useEffect(() => {
        SecurityApi
            .getCurrentUser()
            .then(user => {
                setUser(user.data);

                referentialDispatcher({action: "status", data: "loading"});
                axios
                    .all([
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_TYPE),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_IMPACT),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_SOURCE),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_TRANSMITTER),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_DESCRIPTORS),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_CODIFICATION),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_DOMAIN),
                        ReferentialApi.list(REFERENTIALS.REFERENTIAL_DISPATCH_GROUP),
                        ReferentialApi.tree(),
                    ])
                    .then(axios.spread((
                        [type],
                        [impact],
                        [source],
                        [transmitter],
                        [involvedActor],
                        [involvedFunction],
                        [descriptor],
                        [codification],
                        [domains],
                        [dispatchGroups],
                        tree
                    ) => {
                        referentialDispatcher({action: "populate", data: type, type: REFERENTIALS.REFERENTIAL_TEXT_TYPE});
                        referentialDispatcher({action: "populate", data: impact, type: REFERENTIALS.REFERENTIAL_TEXT_IMPACT});
                        referentialDispatcher({action: "populate", data: source, type: REFERENTIALS.REFERENTIAL_SOURCE});
                        referentialDispatcher({action: "populate", data: transmitter, type: REFERENTIALS.REFERENTIAL_TRANSMITTER});
                        referentialDispatcher({action: "populate", data: involvedActor, type: REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR});
                        referentialDispatcher({action: "populate", data: involvedFunction, type: REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION});
                        referentialDispatcher({action: "populate", data: descriptor, type: REFERENTIALS.REFERENTIAL_DESCRIPTORS});
                        referentialDispatcher({action: "populate", data: codification, type: REFERENTIALS.REFERENTIAL_TEXT_CODIFICATION});
                        referentialDispatcher({action: "populate", data: domains, type: REFERENTIALS.REFERENTIAL_DOMAIN});
                        referentialDispatcher({action: "populate", data: dispatchGroups, type: REFERENTIALS.REFERENTIAL_DISPATCH_GROUP});
                        referentialDispatcher({action: "populate", data: tree, type: "tree"});
                        
                        referentialDispatcher({action: "status", data: "load"});
                        setReferentialLoaded(true);
                    }))
                    .catch(() => toast.error("Impossible de charger les données"));
            })
            .catch(() => toast.error("Impossible de charger les accès"));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (referentialLoaded) {
            if (shouldRedirect()) {
                props.history.push(getLastVisitPath());
            } else {
                props.history.push("/");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referentialLoaded]);

    return (
        <section className="login">
            <form className="form">
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Chargement des données</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            {!referentialLoaded && <>
                                <p className="text-center" id="load-data">Chargement</p>
                                <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                            </>}
                        </div>
                    </section>
                </div>
            </form>
        </section>
    );
}