import React, { useState, useEffect } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import _ from 'lodash';
import DefaultLayout from "layouts/DefaultLayout";
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import RequirementApi from 'shared/api/Requirement';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import { compileErrorsFromResponse } from 'shared/services/Utils';
import { smallModalCustomStyles } from 'shared/services/ModalStyle';
import SectionForm from 'views/requirement/section/Form';
import { REQUIREMENT_STATUS_DRAFT, getStatus } from 'data/RequirementStatus';
import { REQUIREMENT_CONFORMITY_NEW, getConformities } from 'data/RequirementConformity';
import Can, { denyIfCantPerform } from 'shared/services/Can';

export default function RequirementForm(props) {

    const [opening, setOpening] = useState(false);
    const [currentSection, setCurrentSection] = useState(false);
    const [loading, setLoading] = useState(parseInt(props.match.params.id) > 0);
    const [sections, setSections] = useState([]);
    const [saving, setSaving] = useState("");
    const [togglers, toggle] = useToggler({'configuration': true});
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        section: null,
        article: "",
        description: "",
        comment: "",
        applicability_start_at: null,
        applicability_end_at: null,
        sheet: parseInt(props.match.params.sheet),
        default_conformity: REQUIREMENT_CONFORMITY_NEW,
        status: REQUIREMENT_STATUS_DRAFT,
        position: null
    });

    useEffect(() => {
        if (props.match.params.id) {
            RequirementApi
                .get(props.match.params.id)
                .then(d => {
                    setData({
                        id: d.id,
                        section: d.section ? d.section.id : null,
                        article: d.article,
                        description: d.description,
                        comment: d.comment,
                        applicability_start_at: _.isNil(d.applicability_start_at) ? null : new Date(d.applicability_start_at),
                        applicability_end_at: _.isNil(d.applicability_end_at) ? null : new Date(d.applicability_end_at),
                        sheet: d.sheet.id,
                        default_conformity: d.default_conformity,
                        status: d.status,
                        position: d.position ? d.position : 0
                    });
                    setLoading(false);
                })
                .catch(error => console.log(error));
        }
        
        loadSections();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function loadSections() {
        RequirementApi
            .getSections(parseInt(props.match.params.sheet))
            .then(newSections => setSections(newSections));
    }

    function getSections() {
        const mapper = (section) => {
            return {
                "label": section.title,
                "value": section.id,
                "children": section.children ? section.children.map(mapper) : [],
                "checked": (data.section === section.id)
            }
        };
        return sections.map(mapper);
    }

    function addSection() {
        setCurrentSection({"sheet": data.sheet});
        setOpening(true);
    }

    function editSection() {
        setCurrentSection({"sheet": data.sheet});
        const mapper = (section) => {
            if (data.section === section.id) {
                setCurrentSection(section);
            } else if (section.children) {
                section.children.map(mapper);
            }
            return section;
        };
        sections.map(mapper);
        setOpening(true);
    }

    function afterSectionUpdated(section) {
        setValue("section", section);
        loadSections();
    }

    function submit(event) {
        event.preventDefault();
        setSaving(event.nativeEvent.submitter.name);

        RequirementApi
            .save(data)
            .then(newdata => {
                setData(Object.assign({}, data, {"id": newdata.id, "position": newdata.position}));
                setSaving("");
                setErrors({});
                toast.success("Enregistrement effectué.");
                _.delay(() => reloadOrClose(event, props, "sheets/" + data.sheet + "/requirements", newdata.id), 500);
            })
            .catch(error => {
                setSaving("");
                if (error.response.data.message) {
                    toast.error(error.response.data.message);                    
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    if (loading) {
        return <PageLoadingComponent label="Edition d'une exigence" />

    } else {
        denyIfCantPerform(props, "requirement:read", data);
        return (
            <DefaultLayout screen="E17" title="Configuration de l'exigence">
                <Prompt
                    when={formHasModifications()}
                    message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
                />
                <h1>Configuration de l'exigence</h1>
                <section>
                    <form onSubmit={submit} id="form-requirement" className="form">
                        <ToggableBlocComponent label="Configuration" id="configuration" toggled={togglers["configuration"]} toggle={toggle} wrapChildren={false}>
                            <div className="border-rlb border-gris-40">
                                <FieldComponent
                                    className="field"
                                    type="dropdown-tree-select"
                                    name="section"
                                    label="Section"
                                    error={errors.section}
                                    onChange={value => setValue("section", value)}
                                    data={getSections()}
                                    mode="radioSelect"
                                    value={data.section}
                                >
                                    <Can 
                                        perform="requirement:write"
                                        data={data}
                                        yes={() => (
                                            <>
                                            <button onClick={addSection} type="button" className="btn btn-primary">Ajouter une nouvelle section</button>
                                            {data.section && <button onClick={editSection} type="button" className="btn btn-primary">Modifier</button>}
                                            </>
                                        )}
                                    />
                                </FieldComponent>
                                <FieldComponent
                                    className="field medium"
                                    name="article"
                                    label="Référence d'article"
                                    value={data.article}
                                    onChange={value => setValue("article", value)}
                                />
                            </div>
                            <div className="border-rlb border-gris-40 bg-gris-20">
                                <FieldComponent 
                                    type="textarea"
                                    rte
                                    name="description"
                                    label="Description *"
                                    error={errors.description}
                                    value={data.description}
                                    onChange={value => setValue("description", value)}
                                />
                                <FieldComponent 
                                    type="textarea"
                                    rte
                                    name="comment"
                                    label="Commentaire Apave"
                                    error={errors.comment}
                                    value={data.comment}
                                    onChange={value => setValue("comment", value)}
                                />
                            </div>
                            <div className="border-rlb border-gris-40">
                                <FieldComponent
                                    type="date"
                                    className="field small"
                                    name="applicability_start_at"
                                    label="Date de début d'applicabilité"
                                    error={errors.applicability_start_at}
                                    value={data.applicability_start_at}
                                    onChange={value => setValue("applicability_start_at", value)}
                                    selectsStart
                                    startDate={data.applicability_start_at}
                                    endDate={data.applicability_end_at}
                                />
                                <FieldComponent
                                    type="date"
                                    className="field small"
                                    name="applicability_end_at"
                                    label="Date de fin d'applicabilité"
                                    error={errors.applicability_end_at}
                                    value={data.applicability_end_at}
                                    onChange={value => setValue("applicability_end_at", value)}
                                    selectsEnd
                                    startDate={data.applicability_start_at}
                                    endDate={data.applicability_end_at}
                                />
                                <FieldComponent 
                                    type="radio"
                                    name="default_conformity"
                                    label="Conformité par défaut *"
                                    error={errors.default_conformity}
                                    value={data.default_conformity}
                                    onChange={value => setValue("default_conformity", parseInt(value))}
                                    options={getConformities()}
                                />
                                <FieldComponent
                                    className="field small"
                                    type="select"
                                    name="status"
                                    label="Statut *"
                                    error={errors.status}
                                    value={data.status}
                                    onChange={value => setValue("status", value)}
                                    options={getStatus()}
                                />
                                {data.id && 
                                    <FieldComponent
                                        className="field small"
                                        type="numeric"
                                        name="position"
                                        label="Position *"
                                        error={errors.position}
                                        value={data.position !== null ? data.position.toString() : ""}
                                        onChange={value => setValue("position", parseInt(value))}
                                    />
                                }
                                {_.isNil(data.id) && 
                                    <FieldComponent
                                        className="field small"
                                        type="number"
                                        name="position"
                                        label="Position"
                                        error={errors.position}
                                        value={data.position || ""}
                                        onChange={value => setValue("position", value ? value : null)}
                                    >
                                        <div><small>Si vide, l'exigence sera placée en dernière position</small></div>
                                    </FieldComponent>
                                }
                            </div>
                        </ToggableBlocComponent>
                        <section className="row">
                            <div className="col">
                                <Link id="back-requirement-list" to={`/sheets/${data.sheet}/requirements`} className="btn btn-bleu-4">Retour à la liste</Link>
                            </div>
                            <div className="col text-right">
                                {data.id && <Link
                                    id="link-to-history"
                                    to={`/history/requirements/${data.id}`}
                                    className="btn btn-primary"
                                    target="_blank"
                                >
                                    <i className="icon-boutons-voir-historique" aria-hidden="true"></i>
                                    Voir l'historique
                                </Link>}
                                <Can 
                                    perform="requirement:write"
                                    data={data}
                                    yes={() => (<>
                                        <LoadButton 
                                            loading={saving !== ""} 
                                            label="Enregistrer et fermer"
                                            name="save-and-close"
                                            id="save-and-close-requirement"
                                        />
                                        <LoadButton 
                                            loading={saving !== ""}
                                            label="Enregistrer" 
                                            name="save" 
                                            id="save-requirement"
                                        />
                                    </>)}
                                />
                            </div>                           
                        </section>
                    </form>
                </section>
                <Can 
                    perform="requirement:write"
                    data={data}
                    yes={() => (
                        <Modal isOpen={opening} onRequestClose={() => setOpening(false)} style={smallModalCustomStyles}>
                            <SectionForm 
                                section={currentSection}
                                sections={sections}
                                onUpdated={afterSectionUpdated}
                                onRequestClose={() => setOpening(false)}
                            />
                        </Modal>
                    )}
                />
            </DefaultLayout>
        );
    }
}