import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import BarLoader from "react-spinners/BarLoader";
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";
import SecurityApi from 'shared/api/Security';
import { useSecurity } from 'shared/hooks/Security';
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';

export default function AdslLogin(props) {

    const location = useLocation();
    const [, getLastVisitPath] = useAutomaticRedirect();
    const [,, setUser] = useSecurity();

    useEffect(() => {
        const qs = queryString.parse(location.search);
        SecurityApi
            .adslLogin({
                "email": qs.SSO_UTI,                
                "date": qs.SSO_DATE,
                "hash": qs.SSO_CERT,
                "account": qs.SOCIETY,
                "user": qs.u,
            })
            .then(response => {
                SecurityApi
                    .getCurrentUser()
                    .then(currentUserResponse => {
                        toast.success("Authentification réussi.");
                        setUser(currentUserResponse.data);
                        document.location.href = getLastVisitPath();
                    });
            })
            .catch(error => {
                toast.error(error.response.data.message);
            });        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className="login">
            <form className="form">
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Connexion en cours</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                        </div>
                    </section>
                </div>
            </form>
        </section>    
    )
}