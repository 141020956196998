import React, { useEffect, useState } from 'react';
import BarLoader from "react-spinners/BarLoader";
import { toast } from 'react-toastify';
import { useToggler } from "shared/hooks/Toggler";
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PushApi from 'api/Push';
import _ from 'lodash';

export default function Absences(props) {

    const [loading, setLoading] = useState(true);
    const [list, setList] = useState({});
    const [togglers, toggle] = useToggler({'watchsites-absences': true});
    
    function load() {        
        PushApi
            .absences(props.sheet.id)
            .then(results => {
                const freshList = {};
                results.forEach(watchsite => {                    
                    if (!_.find(freshList, (item) => item.account.id === watchsite.account.id)) {
                        freshList[watchsite.account.id] = {
                            account: watchsite.account,
                            watchsites: []
                        };
                    }
                    freshList[watchsite.account.id].watchsites.push(watchsite);
                });

                _.forEach(freshList, (item, key) => {
                    freshList[key].watchsites = _.sortBy(item.watchsites, w => w.label.toLowerCase());
                });

                setList(_.sortBy(freshList, i => i.account.name.toLowerCase()));
                setLoading(false);
            })
            .catch(error => toast.error(error.response.data.message));
    }

    useEffect(load, [props]);

    return <section className="bloc form">
        <h1>Point(s) de veille non affecté(s) à la fiche {props.sheet.id}</h1>
        <BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
        {!loading && <ToggableBlocComponent label="Points de veille" id="watchsites-absences" toggled={togglers["watchsites-absences"]} toggle={toggle}>                        
            <div className="flex-label">
                <label className="at-top">Point de veille non affectée(s) : </label>
                {_.isEmpty(list) && <span className="at-top">Aucun point de veille non affectée.</span>}
                {!_.isEmpty(list) && <ul className="push-selection">
                    {_.map(list, item => <li>
                        <button type="button" className="btn h25 btn-gris-50">{item.account.name}</button>
                        <ul>
                            {item.watchsites.map(watchsite => <li><button type="button" className="btn h25 btn-gris-25">{watchsite.label}</button></li>)}
                        </ul>
                    </li>)}
                </ul>}
            </div>
        </ToggableBlocComponent>}
        <section className="row">
            <div className="col">
                <button type="button" id="back-push" onClick={() => props.onClose()} className="btn btn-bleu-4">
                    Retour à la liste
                </button>
            </div>
        </section>
    </section>;
}