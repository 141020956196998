import React, { useEffect } from 'react';
import _ from 'lodash';
import BarLoader from "react-spinners/BarLoader";

import { useSecurity } from 'shared/hooks/Security';
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';
import SecurityApi from 'shared/api/Security';

export default function Logout() {
    
    const [,,, removeLastVisit] = useAutomaticRedirect();
    const [user,, setUser] = useSecurity();

    useEffect(() => {
        SecurityApi
            .logout()
            .then(() => {
                if (!_.isEmpty(user)) {
                    setUser({});
                }
                removeLastVisit();
                document.location.href = "/login";
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (<div><BarLoader width={100} color="#5bad27" css="display:block;margin:10px auto;" /></div>)
}