import { getSheetStateLabel } from 'shared/data/SheetState';
import { getSheetStatusLabel } from 'data/SheetStatus';
import { getSheetTodoLabel } from 'shared/data/SheetTodo';
import { getStatusLabel } from 'data/RequirementStatus';
import { getConformityLabel } from 'data/RequirementConformity';
import { getApplicabilityLabel } from 'data/RequirementApplicability';

export const actionLabel = {
    "update": "Mise à jour",
    "create": "Création",
};

export const typeLabel = {
    "sheets": "fiche",
    "requirements": "exigence",
    "App\\Entity\\Sheet": "fiche",
    "App\\Entity\\Requirement": "exigence",
};

export const fieldRenderer = {
    "App\\Entity\\Sheet": {
        "updated_at": "date",
        "created_at": "date",
        "synthesis_updated_at": "date",
        "technical_updated_at": "date",
        "publication_date": "date",
        "text_date": "date",
        "text_update_date": "date",
        "status": getSheetStatusLabel,
        "state_synthesis": getSheetStateLabel,
        "state_technical": getSheetStateLabel,
        "todos": getSheetTodoLabel,
        "major_text": "bool",
        "cut_into_requirements": "bool",
        "consolidated_text": "bool",
        "repealed_text": "bool",
        "add_to_dispatch": "bool",
    },
    "App\\Entity\\Requirement": {
        "updated_at": "date",
        "created_at": "date",
        "applicability_state": getApplicabilityLabel,
        "applicability_start_at": "date",
        "applicability_end_at": "date",
        "status": getStatusLabel,
        "default_conformity": getConformityLabel,
    }
};

export const fieldLabel = {
    "App\\Entity\\Sheet": {
        "synthesis_updated_at": "Date de mise à jour de la synthèse",
        "synthesis_updated_by": "Auteur de la mise à jour de la synthèse",
        "updated_at": "Date de mise à jour",
        "created_at": "Date de création",
        "updated_by": "Auteur de la mise à jour",
        "created_by": "Auteur de la création",
        "technical_updated_at": "Date de mise à jour du niveau technique",
        "technical_updated_by": "Auteur de la mise à jour du niveau technique",
        "status": "Statut de la fiche",
        "state_technical": "Etat niveau technique",
        "state_synthesis": "Etat niveau synthèse",
        "private": "Fiche privative|is",
        "internal_comment": "Communication interne",
        "todos": "Action(s) à réaliser",
        "title": "Titre",
        "reglementary_text": "Texte réglementaire (PDF)",
        "legifrance_source": "Lien vers la source officiel",
        "nor": "Numéro NOR",
        "source_number": "N° dans la source",
        "transmitter": "Emetteur",
        "text_types": "Type(s) de texte",
        "text_number": "Numéro de texte",
        "source": "Source du texte",
        "repealed_text": "Texte abrogé",
        "consolidated_text": "Texte consolidé",
        "publication_date": "Date de publication",
        "text_date": "Date du texte",
        "text_update_date": "Date de mise à jour du texte",
        "text_codification": "Codification du texte",
        "librarian": "Documentaliste affecté",
        "synthesis": "Synthèse",
        "descriptors": "Descripteurs",
        "expert": "Expert affecté",
        "technical_comment": "Commentaire technique",
        "sanction": "Sanction",
        "text_impacts": "Impact(s) du texte",
        "corporal_impact_degree": "Degrés d'impact corporel",
        "media_impact_degree": "Degrés d'impact médiatique",
        "legal_impact_degree": "Degrés d'impact juridique",
        "financial_impact_degree": "Degrés d'impact financier",
        "involved_actors": "Acteur(s) concerné(s)",
        "involved_functions": "Fonction(s) impactée(s)",
        "consultant_comment": "Commentaire consultant type",
        "main_domain": "Domaine principal",
        "main_subdomain": "Sous-domaine principal",
        "main_theme": "Thème principal",
        "weblinks": "Liens internet",
        "confidential_account": "Confidentiel Client",
        "dispatch": "Dépêche associée",
        "add_to_dispatch": "Ajouté à la dépêche",
        "dispatch_image": "Image pour dépêche",
        "control_todo": "Contrôle à effectuer",
        "document_todo": "Document à faire",
        "periodicity": "Périodicité",
        "cut_into_requirements": "Découpée en exigence",
        "major_text": "Texte majeur",        
    },
    "App\\Entity\\Requirement": {
        "updated_at": "Date de mise à jour",
        "created_at": "Date de création",
        "updated_by": "Auteur de la mise à jour",
        "created_by": "Auteur de la création",
        "description": "Description",
        "article": "Référence d'article",
        "comment": "Commentaire Apave",
        "applicability_state": "Statut d'applicabilité",
        "applicability_start_at": "Date de début d'applicabilité",
        "applicability_end_at": "Date de fin d'applicabilité",
        "section": "Section",
        "sheet": "Fiche",
        "default_conformity": "Conformité par défaut",
        "status": "Statut",
        "position": "Position",
    }
};