import { React, useState, toast } from 'commons';
import { useToggler } from "shared/hooks";
import { SheetAffectedApi } from 'shared/api';
import { LoadButton, ToggableBlocComponent, HtmlComponent } from "shared/components";
import { STATUS_SENT_BY_APAVE } from 'data/AffectationStatus';

export default function SheetAffectedListFormSheetAffectation(props) {

    const [togglers, toggle] = useToggler({'list': true});
    const [loading, setLoading] = useState(false);

    function execute(event) {
        event.preventDefault();
        setLoading(true);

        SheetAffectedApi
            .bulkUpdate(
                props.perimeter.map(sheetaffected => sheetaffected.id),
                {
                    status: STATUS_SENT_BY_APAVE,
                    include_in_alert: event.nativeEvent.submitter.name === "confirm-affectation"
                }
            )
            .then(() => {
                setLoading(false);
                toast.success("Mise à jour du statut effectué");
                props.onCloseSuccess();
            })
            .catch(() => {
                setLoading(false);
                toast.error("Une erreur est survenue")
            });
    }

    return <section>
        <h1>Validation des affectations</h1>
        <form className="form" id="sheet-affectation-form" onSubmit={execute}>
            <ToggableBlocComponent label="Listes des fiches" id="list" toggled={togglers["list"]} toggle={toggle}>
                <ul>
                    {props.perimeter.map(sheetaffected => <li key={sheetaffected.id}>
                        <strong>{sheetaffected.sheet.id}</strong> pour <strong>{sheetaffected.watchsite.label}</strong>
                        <div>
                            <HtmlComponent>{sheetaffected.sheet.title}</HtmlComponent>
                        </div>
                    </li>)}
                </ul>
            </ToggableBlocComponent>
            <section className="row">
                <div className="col">
                    <button type="button" id="back-list" onClick={() => props.onCloseCancel()} className="btn btn-bleu-4">
                        Retour à la liste
                    </button>
                </div>
                <div className="col text-right">
                    <LoadButton loading={loading} name="confirm-affectation" id="confirm-affectation" className="btn btn-primary" label="Valider" />
                    <LoadButton loading={loading} name="confirm-affectation-without-alert" id="confirm-affectation-without-alert" className="btn btn-primary" label="Valider sans envoi de mail" />
                </div>
            </section>
        </form>
    </section>;
}