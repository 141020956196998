import { React, DefaultLayout, useState, Modal } from 'commons';
import { usePosition } from 'shared/hooks';
import { mediumModalCustomStyles, denyIfCantPerform } from 'shared/services';
import SheetAffectedListWatchsiteSelection from 'views/sheetaffected/list/WatchsiteSelection';
import SheetAffectedListSheetList from 'views/sheetaffected/list/SheetList';
import SheetAffectedListFormSheetAffectation from 'views/sheetaffected/list/form/SheetAffectation';
import SheetAffectedListFormBaseDuplication from 'views/sheetaffected/list/form/BaseDuplication';

export default function SheetAffectedList(props) {

    denyIfCantPerform(props, "sheetaffected:list");

    const [treeOptions, setTreeOptions] = useState([]);
    const [watchsite, setWatchsite] = useState(null);
    const [account, setAccount] = useState(null);
    const [, setPosition] = usePosition("sheetaffected-list");
    const [reload, setReload] = useState(1);
    const [isSheetAffectationOpen, openSheetAffectation] = useState(false);
    const [isBaseDuplication, openBaseDuplication] = useState(false);
    const [perimeter, setPerimeter] = useState([]);

    function updateWatchsite(value) {
        setWatchsite(value);
    }

    function updateAccount(value) {
        setWatchsite(null);
        setAccount(value);
    }

    function updateTreeOptions(value) {
        setTreeOptions(value);
    }

    function openSheetAffecationForm(newPerimeter) {
        setPerimeter(newPerimeter);
        openSheetAffectation(true);
    }

    function closeSheetAffecationFormSuccess(newPerimeter) {
        setReload(reload + 1)
        openSheetAffectation(false);
    }

    function closeSheetAffectationFormCancel() {
        openSheetAffectation(false);
    }

    function openBaseDuplicationForm(watchsiteData) {
        setPerimeter(watchsiteData);
        openBaseDuplication(true);
    }

    function closeBaseDuplicationForm() {
        openBaseDuplication(false);
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E22" title="Fond documentaire">            
            <h1>Fonds documentaire</h1>
            <SheetAffectedListWatchsiteSelection 
                watchsite={watchsite}
                account={account}
                treeOptions={treeOptions}
                updateWatchsite={updateWatchsite}
                updateAccount={updateAccount}
                updateTreeOptions={updateTreeOptions}
            />
            {watchsite && <SheetAffectedListSheetList
                reload={reload}
                watchsite={watchsite}
                account={account}
                treeOptions={treeOptions}
                openSheetAffecationForm={openSheetAffecationForm}
                openBaseDuplicationForm={openBaseDuplicationForm}
            />}
            <Modal isOpen={isSheetAffectationOpen} onRequestClose={closeSheetAffecationFormSuccess} style={mediumModalCustomStyles}>
                <SheetAffectedListFormSheetAffectation
                    perimeter={perimeter}
                    onCloseSuccess={closeSheetAffecationFormSuccess}
                    onCloseCancel={closeSheetAffectationFormCancel}
                />
            </Modal>
            <Modal isOpen={isBaseDuplication} onRequestClose={closeBaseDuplicationForm} style={mediumModalCustomStyles}>
                <SheetAffectedListFormBaseDuplication perimeter={perimeter} onClose={closeBaseDuplicationForm} />
            </Modal>
        </DefaultLayout>
    );
}