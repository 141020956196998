


import { React,  DefaultLayout } from 'commons';
import { NavLink } from "react-router-dom";

export default function ObligationGeneratorMenu() {

    return (
        <DefaultLayout title="Accueil générateur d'obligations" contentWrapperClassName="content-wrapper bg-gris-10">
            <h1 className="main-title">Générateur d'obligations</h1>
            <div className="row grid">
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-obligationgenerator-form" to="/obligationgeneratorform" title="Générateur">
                            <i className="icon-documentaire-actualites-documentaires" aria-hidden="true"></i>
                            Générateur
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="contenu">
                        <NavLink className="padding" id="home-obligationgenerator-import" to="/obligationgeneratorimport" title="Formulaires d'import">
                            <i className="icon-documentaire-formulatires-declaration" aria-hidden="true"></i>
                            Formulaires d'import
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
}