import { React, useState, toast } from 'commons';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent, FieldComponent, LoadButton } from "shared/components";
import { SheetAffectedApi } from 'shared/api';

export default function SheetAffectedListFormUpdateComment(props) {

    const [togglers, toggle] = useToggler({'configuration': true});
    const [comment, setComment] = useState("");

    function execute(event) {
        event.preventDefault();
        props.setLoading(true);

        SheetAffectedApi
            .bulkUpdate([props.sheetaffected.id], {comment_consultant: comment})
            .then(() => toast.success("Mise à jour du commentaire effectué"))
            .catch(() => toast.error("Une erreur est survenue"))
            .finally(() => { props.setLoading(false); props.onClose(); });
    }

    return <section>
        <h1>Modification du commentaire pour la fiche {props.sheetaffected.sheet.id}</h1>
        <form className="form" id="watchsite-selection-form" onSubmit={execute}>
            <ToggableBlocComponent label="Modification du commentaire" id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                <FieldComponent
                    type="textarea"
                    rte
                    prefixId="update-comment-form-"
                    name="comment"
                    label="Commentaire"
                    onChange={value => setComment(value)}
                    value={comment}
                />
            </ToggableBlocComponent>
            <section className="row">
                <div className="col">
                    <button type="button" id="back-list" onClick={() => props.onClose()} className="btn btn-bleu-4">
                        Retour à la liste
                    </button>
                </div>
                <div className="col text-right">
                    <LoadButton loading={props.loading} id="confirm-comment" className="btn btn-primary" label="Confirmer" />
                </div>
            </section>
        </form>
    </section>;
}