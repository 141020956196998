import FieldComponent from "shared/components/FieldComponent";
import { useForm } from "shared/hooks/Form";
import { validateUrl } from "shared/services/Utils";

export default function WeblinkPicker(props) {

    const { values, label, onChange } = props;
    const [data, errors, setErrors, setValue] = useForm({
        weblink: '',
    });

    function removeItem(event, itemRemoved) {
        onChange(values.filter(item => item !== itemRemoved));
    }
    
    function addItem() {
        if(!validateUrl(data.weblink)) {
            setErrors({
                weblink: "Le lien saisi n'est pas valide."
            });
        } else if(values.map(value => value.toLowerCase()).includes(data.weblink.toLowerCase()) === true) {
            setErrors({
                weblink: "Le lien saisi existe déjà dans la liste."
            });
        } else {
            setErrors({});
            let newValues = [...values];
            newValues.push(data.weblink);
            onChange(newValues);
            setValue("weblink", "");
        }
    }

    return (
        <FieldComponent
            label={label}
            name="weblink"
            className="field medium"
            wrapper="text-add"
            value={data.weblink}
            onChange={value => setValue("weblink", value)}
            onAdd={addItem}
            error={errors.weblink}
        >
            <ul className="filelist arborescence no-arrow squared">
                {values.map(item => {
                    return(
                        <li key={item}>
                            <span><a href={item} target="_blank" rel="noreferrer">{item}</a></span>
                            <button data-info="Supprimer le lien de la liste" className="infobulle" type="button" onClick={e => removeItem(e, item)}>
                                <i className="icon-filtres-poubelle" aria-hidden="true"></i>
                            </button>
                        </li>
                    );
                })}
        </ul>
        </FieldComponent>
    );
}