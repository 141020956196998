import React, { useEffect, useState } from "react";
import _ from "lodash";
import SheetAffectedApi from 'api/SheetAffected';
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import HtmlComponent from 'shared/components/HtmlComponent';

export default function Affectations(props) {

    const [loading, setLoading] = useState(true);
    const [affectations, setAffectations] = useState([]);

    useEffect(() => {
        SheetAffectedApi
            .getAffectations(props.sheet.id)
            .then(data => {
                let freshAffectations = {};
                data.forEach(item => {
                    if (_.isNil(freshAffectations[item.watchsite.account.id])) {
                        freshAffectations[item.watchsite.account.id] = {
                            account: item.watchsite.account,
                            affectations: []
                        };
                    }
                    freshAffectations[item.watchsite.account.id].affectations.push({
                        watchsite: item.watchsite,
                        id: item.id,
                        status: item.status,
                        hidden: item.hidden,
                    });
                });

                setAffectations(freshAffectations);
                setLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <PageLoadingComponent label={`Affectations de la fiche ${props.sheet.id}`} layout={false} />
    } else {
        return (
            <form className="form">
                <section>
                    <h1>Fiche {props.sheet.id}</h1>
                    <HtmlComponent>{props.sheet.title}</HtmlComponent>
                    <section className="bloc">
                        <header>
                            <h2 className="uppercase">Affectations de la fiche {props.sheet.id}</h2>
                        </header>
                        <section className="border-rlb border-gris-40">
                            {!_.isEmpty(affectations) && (
                                <ul>
                                    {_.map(affectations, (data) => <li key={data.account.id}>
                                        {data.account.name}
                                        <ul>
                                            {data.affectations.map((affectation) => <li key={affectation.id}>
                                                {affectation.watchsite.label}
                                                {affectation.hidden && <> - Fiche masquée</>}
                                            </li>)}
                                        </ul>
                                    </li>
                                    )}
                                </ul>
                            )}
                            {_.isEmpty(affectations) && (
                                <div className="col-md-12">
                                    <p>Aucune affectation pour cette fiche</p>
                                </div>
                            )}
                        </section>
                    </section>
                </section>
            </form>
        );
    }
}