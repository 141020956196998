import { React, _ } from 'commons';
import { FieldComponent } from "shared/components";
import WatchsiteUtils from 'shared/services/WatchsiteUtils';

export default function ConfigurationWatchsite(props) {

    const isIncluded = props.parentIncluded || props.includedPerimeters.includes("watchsite:" + props.watchsite.id);
    const isForced = props.parentForced || props.forcedPerimeters.includes("watchsite:" + props.watchsite.id);
    const isIncluedInUpdateBatch = props.parentIncluedInUpdateBatch || props.batchCommentUpdatePerimeters.includes("watchsite:" + props.watchsite.id);
    const specificComment = _.find(props.specificComments, {"watchsite": props.watchsite.id});
    const alreadyAffected = Array.isArray(props.alreadyAffected) && props.alreadyAffected.includes(props.watchsite.id);
    const isActive = WatchsiteUtils.isActive(props.watchsite);

    if (props.watchsite.is_selected) {
        return <li className="watchsite">
            <div className="highlight">
                <fieldset className="at-left">
                    <legend className="sr-only">Paramétrage du point de veille&nbsp;:</legend>
                    <div>
                        <input
                            checked={isIncluded}
                            type="checkbox"
                            id={`watchsite-${props.watchsite.id}-affect`}
                            disabled={props.parentIncluded === true}
                            onChange={e => props.updateParameters("include-perimeter", "watchsite:" + props.watchsite.id, e.target.checked)}
                        />
                        <label id={`watchsite-${props.watchsite.id}-affect-label`} htmlFor={`watchsite-${props.watchsite.id}-affect`} className="w-120px text-center">
                            <span className="sr-only">Affecter</span>
                        </label>
                        {isIncluded && <>
                            <input
                                checked={isIncluedInUpdateBatch}
                                type="checkbox"
                                id={`watchsite-${props.watchsite.id}-updatecomment`}
                                disabled={props.parentIncluedInUpdateBatch === true}
                                onChange={e => props.updateParameters("addto-batch-update-comment", "watchsite:" + props.watchsite.id, e.target.checked)}
                            />
                            <label id={`watchsite-${props.watchsite.id}-updatecomment-label`} htmlFor={`watchsite-${props.watchsite.id}-updatecomment`} className="w-120px text-center">
                                <span className="sr-only">Modifier le commentaire</span>
                            </label>
                            {alreadyAffected && <>
                                <input
                                    checked={isForced}
                                    type="checkbox"
                                    id={`watchsite-${props.watchsite.id}-forceaffectation`}
                                    disabled={props.parentForced === true}
                                    onChange={e => props.updateParameters("force-perimeter", "watchsite:" + props.watchsite.id, e.target.checked)}
                                />
                                <label id={`watchsite-${props.watchsite.id}-forceaffectation-label`} htmlFor={`watchsite-${props.watchsite.id}-forceaffectation`} className="w-120px text-center">
                                    <span className="sr-only">Forcer l'affectation</span>
                                </label>
                            </>}
                        </>}
                    </div>
                </fieldset>
                {props.watchsite.label}
                {!isActive && (<i className="icon-autres-temps-depasse color-rouge" title="Point de veille expiré ou désactivé"></i>)}
                {parseInt(props.watchsite.affectation_mode) === 1 ? " (Envoi différé)" : ""}
                {isIncluded && <fieldset className="at-right">
                    <legend className="sr-only">Paramétrage du commentaire point de veille&nbsp;:</legend>
                    <label>Utiliser le commentaire consultant retenu</label>
                    <input
                        type="radio"
                        id={`watchsite-${props.watchsite.id}-hasspecificcomment-1`}
                        name={`watchsite-${props.watchsite.id}-hasspecificcomment`}
                        checked={_.isNil(specificComment)}
                        onChange={() => props.updateParameters("use-specific-comment", props.watchsite.id, false)}
                    />
                    <label htmlFor={`watchsite-${props.watchsite.id}-hasspecificcomment-1`}>Oui</label>
                    <input
                        type="radio"
                        id={`watchsite-${props.watchsite.id}-hasspecificcomment-0`}
                        name={`watchsite-${props.watchsite.id}-hasspecificcomment`}
                        checked={!_.isNil(specificComment)}
                        onChange={() => props.updateParameters("use-specific-comment", props.watchsite.id, true)}
                    />
                    <label htmlFor={`watchsite-${props.watchsite.id}-hasspecificcomment-0`}>Non</label>
                </fieldset>}
            </div>
            {isIncluded && !_.isNil(specificComment) && <div>
                <FieldComponent
                    wrapper={([, input]) => <>{input}</>}
                    type="textarea"
                    rte={true}
                    name={`watchsite-${props.watchsite.id}-specificcomment`}
                    value={specificComment.comment}
                    onChange={value => props.updateComment(props.watchsite.id, value)}
                />
            </div>}
        </li>;
    } else {
        return <></>;
    }    
}