import React, { useEffect, useState } from 'react';
import BarLoader from "react-spinners/BarLoader";
import StatusApi from "api/Status";
import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";

export default function Status() {

    const [status, setStatus] = useState(null);
    const [beReachabled, setBeReachabled] = useState(null);
    const statusClassNames = {
        'OK': 'alert-primary',
        'WARNING': 'alert-warning',
        'ERROR': 'alert-danger',
    };

    useEffect(() => {
        StatusApi
            .status()
            .then(s =>  {setStatus(s); setBeReachabled(true);})
            .catch(r => setBeReachabled(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    if (status === null && beReachabled === null) {
        return (
            <>
                <section className="login">
                    <div className="form">
                        <h1>
                            <div className="main-logo">
                                <img src={logoapave} width="96" alt="Logo Apave" />
                            </div>
                            <div className="main-name">
                                <img src={logopilotveille} alt="Logo Pilot Veille" />&nbsp;&nbsp;Disponibilités
                            </div>
                        </h1>
                        <div className="bloc">
                            <section>
                                <BarLoader width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                            </section>
                        </div>
                    </div>
                </section>
            </>);
    } else if(beReachabled === false) {
        return (
            <>
                <section className="login">
                    <div className="form">
                        <h1>
                            <div className="main-logo">
                                <img src={logoapave} width="96" alt="Logo Apave" />
                            </div>
                            <div className="main-name">
                                <img src={logopilotveille} alt="Logo Pilot Veille" />&nbsp;&nbsp;Disponibilités
                            </div>
                        </h1>
                        <div className="bloc">
                            <section>
                                <div className="bg-blanc border-t border-rlb border-gris-40">
                                    <div>
                                        <h3>Backend Pilot Veille</h3>
                                        <div className={`alert ${statusClassNames.ERROR}`} role="alert">
                                            ERROR Backend unreachable
                                        </div>
                                    </div>
                                    <div>
                                        <h3>Base de données</h3>
                                        <div className={`alert ${statusClassNames.ERROR}`} role="alert">
                                            ERROR Backend unreachable
                                        </div>
                                    </div>
                                    <div>
                                        <h3>Solr</h3>
                                        <div className={`alert ${statusClassNames.ERROR}`} role="alert">
                                            ERROR Backend unreachable
                                        </div>
                                    </div>
                                    <div>
                                        <h3>API ADSL Compte</h3>
                                        <div className={`alert ${statusClassNames.ERROR}`} role="alert">
                                            ERROR Backend unreachable
                                        </div>
                                    </div>
                                    <div>
                                        <h3>API ADSL Utilisateur</h3>
                                        <div className={`alert ${statusClassNames.ERROR}`} role="alert">
                                            ERROR Backend unreachable
                                        </div>
                                    </div>
                                    <div>
                                        <h3>API ADSL Connexion</h3>
                                        <div className={`alert ${statusClassNames.ERROR}`} role="alert">
                                            ERROR Backend unreachable
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </>
        );
    } else {
        return (
            <>
                <section className="login">
                    <div className="form">
                        <h1>
                            <div className="main-logo">
                                <img src={logoapave} width="96" alt="" />
                            </div>
                            <div className="main-name">
                                <img src={logopilotveille} alt="Logo Pilot Veille" />&nbsp;&nbsp;Disponibilités
                            </div>
                        </h1>
                        <div className="bloc">
                            <section>
                                <div className="bg-blanc border-t border-rlb border-gris-40">
                                    <div>
                                        <h3>Backend Pilot Veille</h3>
                                        <div className={`alert ${statusClassNames.OK}`} role="alert">
                                            OK
                                        </div>
                                    </div>
                                    <div>
                                        <h3>Base de données</h3>
                                        <div className={`alert ${statusClassNames[status.database.status]}`} role="alert">
                                            {status.database.status} {status.database.message}
                                        </div>
                                    </div>
                                    <div>
                                        <h3>Solr</h3>
                                        <div className={`alert ${statusClassNames[status.solr.status]}`} role="alert">
                                            {status.solr.status} {status.solr.message}
                                        </div>
                                    </div>
                                    <div>
                                        <h3>API ADSL Compte</h3>
                                        <div className={`alert ${statusClassNames[status.adsl_account.status]}`} role="alert">
                                            {status.adsl_account.status} {status.adsl_account.message}
                                        </div>
                                    </div>
                                    <div>
                                        <h3>API ADSL Utilisateur</h3>
                                        <div className={`alert ${statusClassNames[status.adsl_user.status]}`} role="alert">
                                            {status.adsl_user.status} {status.adsl_user.message}
                                        </div>
                                    </div>
                                    <div>
                                        <h3>API ADSL Connexion</h3>
                                        <div className={`alert ${statusClassNames[status.adsl_profile.status]}`} role="alert">
                                            {status.adsl_profile.status} {status.adsl_profile.message}
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}