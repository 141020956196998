import { React, useState } from 'commons';
import PerimeterSelectionWatchsite from 'views/push/Perimeter/Form/PerimeterSelectionWatchsite';
import PerimeterSelectionNode from 'views/push/Perimeter/Form//PerimeterSelectionNode';

export default function PerimeterSelectionAccount(props) {

    const [folded, setFold] = useState(false);

    return <li>
        <button type="button" className="btn btn-primary icon icon-only" onClick={() => setFold(!folded)}>
            <i className={folded ? "icon-boutons-plus" : "icon-boutons-moins"}></i>
        </button>
        <input
            type="checkbox"
            checked={props.perimeter.includes("account:" + props.item.account.id)}
            id={`account-${props.item.account.id}`}
            onChange={(e) => props.updatePerimeter("account:" + props.item.account.id, e)}
        />
        <label htmlFor={`account-${props.item.account.id}`}>{props.item.account.name}</label>
        <ul className={folded ? "d-none" : ""}>
            {props.item.tree.map(treeItem => {
                if (treeItem.is_watchsite) {
                    return <PerimeterSelectionWatchsite
                        key={treeItem.id}
                        parentChecked={props.perimeter.includes("account:" + props.item.account.id)}
                        perimeter={props.perimeter}
                        updatePerimeter={props.updatePerimeter}
                        watchsite={treeItem}
                    />;
                }
                if (treeItem.is_node) {
                    return <PerimeterSelectionNode
                        key={treeItem.id}
                        parentChecked={props.perimeter.includes("account:" + props.item.account.id)}
                        perimeter={props.perimeter}
                        updatePerimeter={props.updatePerimeter}
                        node={treeItem}
                    />;
                }
                return <React.Fragment key={treeItem.id}></React.Fragment>;
            })}
        </ul>
    </li>;
}