import { React, useEffect, useState, toast } from 'commons';
import { useSecurity, useToggler } from 'shared/hooks';
import { compileDataToSelectOptions } from 'shared/services';
import { AccountApi } from 'shared/api';
import { ToggableBlocComponent, FieldComponent } from 'shared/components';

export default function SheetAffectedListWatchsiteSelection(props) {

    const [user] = useSecurity();
    const [togglers, toggle] = useToggler({'watchsite-selection': true});
    const [accounts, setAccounts] = useState([]);
    const isAdmin = user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN");

    function load() {
        if (isAdmin) {
            AccountApi
                .search({}, 0, 99999, "name", "ASC")
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        } else {
            AccountApi
                .search(
                    {"bool": {"should": [{"match":{"pilot_consultants": user.id}}, {"match":{"associate_consultants": user.id}}]}},
                    0,
                    99999,
                    "name",
                    "ASC"
                )
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        }
    }

    function loadTree() {
        if (props.account) {
            const watchsiteMapper = (watchsite) => {
                return {
                    "label": watchsite.label,
                    "value": watchsite.id,
                    "disabled": !watchsite.consultants.includes(user.id) && !isAdmin,
                } 
            };
            const nodeMapper = (node) => {
                let children = node.childs.map(nodeMapper);
                children = children.concat(node.watchsites.map(watchsiteMapper));
                return {
                    "label": node.label,
                    "value": "node:" + node.id,
                    "children": children,
                    "disabled": true
                }
            };
            const cleanTree = (t) => {
                const cleanedTree = [];
                t.forEach(item => {
                    if (item.children) {
                        // node
                        item.children = cleanTree(item.children);
                        if (item.children.length > 0) {
                            cleanedTree.push(item);
                        }    
                    } else {
                        // watchsite
                        cleanedTree.push(item);
                    }
                    
                });
                return cleanedTree;
            }

            AccountApi
                .getConsultantTree(props.account)
                .then(response => {
                    const cleanedOptions = cleanTree(response.nodes.map(nodeMapper))
                        .concat(response.watchsites.map(watchsiteMapper));

                    props.updateTreeOptions(cleanedOptions);
                })
                .catch(() => toast.error("Impossible de récupérer l'arborescence."));
        } else {
            props.updateTreeOptions([]);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadTree, [props.account]);

    return <div className="bloc">
        <form className="form">
            <ToggableBlocComponent label="Périmètre de selection" id="watchsite-selection" toggled={togglers["watchsite-selection"]} toggle={toggle}>
                <div className="row">
                    <div class="col-md-6">
                        <FieldComponent
                            type="select"
                            label="Compte"
                            value={props.account}
                            name="account"
                            onChange={(value) => props.updateAccount(value)}
                            options={accounts}
                            clearable
                        />
                    </div>  
                    <div class="col-md-6">          
                        {props.account && <>
                            <FieldComponent
                                type="dropdown-tree-select"
                                name="watchsite"
                                label="Point de veille"
                                data={props.treeOptions}
                                value={props.watchsite}
                                onChange={(value) => props.updateWatchsite(value)}
                                mode="radioSelect"
                            />
                        </>}
                    </div>
                </div>
            </ToggableBlocComponent>
        </form>
    </div>;
}