export const CONFORMITY_NEW = 0;
export const CONFORMITY_INFO = 1;
export const CONFORMITY_NA = 2;
export const CONFORMITY_TOSPECIFIED = 3;
export const CONFORMITY_NOK = 4;
export const CONFORMITY_OK = 5;

export const SHEET_AFFECTED_CONFORMITY = {
    [CONFORMITY_NEW]: "Nouveau",
    [CONFORMITY_INFO]: "Information",
    [CONFORMITY_NA]: "Non applicable",
    [CONFORMITY_TOSPECIFIED]: "A préciser",
    [CONFORMITY_NOK]: "En écart",
    [CONFORMITY_OK]: "Conforme",
}


export function getConformities() {
    let status = [];

    let options = [
        CONFORMITY_NEW,
        CONFORMITY_TOSPECIFIED,
        CONFORMITY_NOK,
        CONFORMITY_OK,
        CONFORMITY_INFO,
        CONFORMITY_NA,
    ];

    for (const value of options) {
        status.push({
            value: value, 
            label: SHEET_AFFECTED_CONFORMITY[value],
        });
    }
    return status;
};

export function getConformityLabel(conformity) {
    return SHEET_AFFECTED_CONFORMITY[conformity];
}