import { React, useState, useEffect, _ } from 'commons';
import { useToggler } from "shared/hooks";
import { FieldComponent, ToggableBlocComponent } from "shared/components";
import { SheetAutocomplete } from 'shared/services';
import { SHEET_STATE_VALIDATED } from 'shared/data';

export default function DispatchFormAddSheets(props) {

    const [togglers, toggle] = useToggler({'add-sheet': true});
    const [sheets, setSheets] = useState([]);
    const currentSheets = [];

    function loadCurrentSheets() {
        _.forEach(props.currentSheets, ss => {
            _.forEach(ss, s => {
                currentSheets.push(s)
            })
        });
    }

    function submit(event) {
        event.preventDefault();

        props.attachSheets(sheets.map(s => s.value));
        setSheets([]);
    }

    function add(_value, sheet) {
        if (!_.find(sheets, sheet)) {
            const newSheets = [...sheets];
            newSheets.push(sheet)
            setSheets(newSheets);
        }
    }

    function remove(sheet) {
        setSheets(sheets.filter(s => s.value !== sheet.value));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadCurrentSheets, [props.currentSheets]);

    return <section>
        <div id="form-add-sheet-to-dispatch" className="form">
            <ToggableBlocComponent label="Ajouter des fiches à la dépêche" id="add-sheet" toggled={togglers["add-sheet"]} toggle={toggle}>
                <FieldComponent
                    label="Fiche"
                    type="async-select"
                    cacheOptions={false}
                    name="sheet-to-added"
                    loadOptions={(inputValue, callback) => SheetAutocomplete(
                        {
                            keyword: inputValue,
                            dispatch: 0,
                            add_to_dispatch: 1,
                            excludeIds: currentSheets.map(s => s.id),
                            state_synthesis: [SHEET_STATE_VALIDATED]
                        },
                        callback
                    )}
                    onChange={add}
                    value={null}
                />
                <div className="flex-label">
                    <ul className="filelist arborescence no-arrow squared">
                        {sheets.map(sheet => <li key={sheet.value}>
                            {sheet.label}
                            <button data-info="Enlever la fiche" type="button" onClick={() => remove(sheet)} id="remove-sheet-from-dispatch-queue">
                                <i className="icon-filtres-poubelle" aria-hidden="true"></i>
                            </button>
                        </li>)}
                    </ul>
                </div>
                <section>
                    <div className="text-right" style={{marginBottom: "15px"}}>
                        <button className="btn btn-primary" onClick={submit} type="button" name="add" id="add-to-dispatch">Ajouter à la dépêche</button>
                    </div>
                </section>
            </ToggableBlocComponent>
        </div>
    </section>;
}