import { React, Modal, useState } from 'commons';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent } from "shared/components";
import { mediumModalCustomStyles } from 'shared/services';
import PerimeterSelectionModal from 'views/push/Perimeter/PerimeterSelectionModal';

export default function PushPerimeter(props) {

    const [selecting, setSelecting] = useState(false);
    const [togglers, toggle] = useToggler({'perimeter-selection': true});

    return <section>
        <ToggableBlocComponent 
            label="Pré-sélectionner le périmètre"
            id="perimeter-selection"
            toggled={togglers["perimeter-selection"]}
            toggle={toggle}
            className="toggle-group"
        >
            <section>
			    <header className='bg-gris-25'>
                    <button className="btn h25 btn-primary" type="button" onClick={() => setSelecting(true)}>Sélectionner</button>
                </header>
			    <div className="border-gris-25 border-lbr bg-blanc">
                    <ul>
                        {props.accountsSelection.map(account => <li key={account.id}>{account.name}</li>)}
                    </ul>
                </div>
            </section>
        </ToggableBlocComponent>
        <Modal isOpen={selecting} onRequestClose={() => setSelecting(false)} style={mediumModalCustomStyles}>
            <PerimeterSelectionModal
                perimeter={props.perimeter}
                setPerimeter={props.setPerimeter}
                setAccountsSelection={props.setAccountsSelection}
                onClose={() => setSelecting(false)}
            />
        </Modal>
    </section>;
}