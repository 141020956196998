import { React, useState, toast } from 'commons';
import { STATUS_TOSENT, STATUS_ARCHIVED } from 'data/AffectationStatus';

export default function SheetAffectedListBatchActions(props) {

    const [batch, setBatch] = useState(null);

    function execute(event) {
        if (props.selection.length === 0) {
            toast.warning("Selection vide");
        } else {
            if (batch === "update_status_sent") {
                props.openSheetAffecationForm(
                    props.rows.filter(row => props.selection.includes(row.id))
                );
            } else {
                props.setLoading(true);
                switch (batch) {
                    case "update_status_archived": 
                        props.updateStatus(event, props.selection, STATUS_ARCHIVED, true);
                        break;
                    case "update_status_tosent": 
                        props.updateStatus(event, props.selection, STATUS_TOSENT, true);
                        break;
                    default:
                        toast.error("Veuillez indiquer une tâche");
                        break;
                }
            }
        }
    }

    return (
        <>
            <select id="batch-sheet-affected" value={batch} onChange={(e) => setBatch(e.target.value)}>
                <option></option>
                <option value="update_status_archived">Exclure les fiches</option>
                <option value="update_status_sent">Envoyer les fiches</option>
                <option value="update_status_tosent">Retenir pour affectation</option>
            </select>
            {batch && <button onClick={execute} className="btn btn-primary h25" type="button">Executer</button>}
        </>
    )
}