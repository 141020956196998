export const REQUIREMENT_CONFORMITY_NEW = 0;
export const REQUIREMENT_CONFORMITY_INFO = 1;

export const REQUIREMENT_CONFORMITIES = {
    [REQUIREMENT_CONFORMITY_NEW]: "Nouveau",
    [REQUIREMENT_CONFORMITY_INFO]: "Information",
}

export const REQUIREMENT_CONFORMITIES_COLOR = {
    [REQUIREMENT_CONFORMITY_NEW]: "new",
    [REQUIREMENT_CONFORMITY_INFO]: "na",
}

export function getConformities() {    
    let conformities = [];
    for (const [key, value] of Object.entries(REQUIREMENT_CONFORMITIES)) {
        conformities.push({
            value: parseInt(key), 
            label: value,
        });
    }
    return conformities;
}

export function getConformityColor(conformity) {
    return REQUIREMENT_CONFORMITIES_COLOR[conformity];
}

export function getConformityLabel(conformity) {
    return REQUIREMENT_CONFORMITIES[conformity];
}