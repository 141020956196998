import { React, DefaultLayout, useState, useEffect, toast } from 'commons';
import { usePosition, useSecurity } from 'shared/hooks';
import { denyIfCantPerform, compileDataToSelectOptions } from 'shared/services';
import { AccountApi } from 'shared/api';
import { FieldComponent } from 'shared/components';
import SheetListStandard from 'views/sheet/list/Standard';
import SheetListAffected from 'views/sheet/list/Affected';

export default function SheetList(props) {

    denyIfCantPerform(props, "sheet:list");

    const [, setPosition] = usePosition("sheet-list");
    const [user] = useSecurity();
    const [account, setAccount] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const mode = (user.roles.includes("ROLE_CONSULTANT") || user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) && account ? "affected" : "standard";

    localStorage.setItem("sheetFormReferer", document.location.pathname);

    function load() {
        if (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) {
            AccountApi
                .search({}, 0, 99999, "name", "ASC")
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        } else if (user.roles.includes("ROLE_CONSULTANT")) {
            AccountApi
                .search(
                    {"bool": {"should": [{"match":{"pilot_consultants": user.id}}, {"match":{"associate_consultants": user.id}}]}},
                    0,
                    99999,
                    "name",
                    "ASC"
                )
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E25" title="Recherche avancée">
            <h1>Recherche avancée</h1>
            {(user.roles.includes("ROLE_CONSULTANT") || user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) && <>
                <div className="bloc">
                    <form className="form">
                        <section>
                            <header>
                                <h2>Limiter au compte</h2>
                            </header>
                            <div className="bg-gris-25">                            
                                <FieldComponent type="select" label="Compte" value={account} name="account" onChange={a => setAccount(a)} options={accounts} clearable />                                
                            </div>
                        </section>
                    </form>
                </div>
            </>}
            {mode === "affected" && <SheetListAffected account={account} />}
            {mode === "standard" && <SheetListStandard />}
        </DefaultLayout>
    )
}