import React from "react";
import { Link } from "react-router-dom";
import { useSecurity } from 'shared/hooks/Security';

import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";

export default function Header(props) {

    const [user] = useSecurity();

    function toggleMenu() {
        document.body.classList.toggle("main-nav-opened");
    }

    return (
        <header className="page-header">
            <button id="main-menu-toggler" className="main-menu-toggler" onClick={toggleMenu}><i className="icon-nav-burger" aria-hidden="true"></i></button>
            <div className="bandeau">
                <div className="main-logo">
                    <Link to="/" title="Retour à l'accueil"><img src={logoapave} width="96" alt="Retour à l'accueil" /></Link>
                </div>
                <div className="main-name">
                    <img src={logopilotveille} alt="Logo Pilot Veille" />
                </div>
                <div className="tools">
                    <a target="_blank" href="/documentation.pdf" className="help" title="Aide">
                        <i className="icon-help-circled" aria-hidden="true"></i>
                    </a>
                    {props.isLogged && <div className="user-menu">
                        <h3>{user.firstname} <span>{user.lastname}</span></h3>
                        <ul>
                            {process.env.NODE_ENV === "development" && <li>{user.roles.map(role => (<span key={role} className="color-rouge">{role}<br/></span>))}</li>}
                            <li><a href="/">Configuration</a></li>
                            <li><a id="menu-oneapave" href={process.env.REACT_APP_ADSL_URL} rel="noreferrer" title="OneApave" target="_blank">One Apave</a></li>
                            <li><Link to="/logout">Se déconnecter</Link></li>
                        </ul>
                    </div>}
                </div>
            </div>
        </header>
    )
}
