import React from 'react';

import TreeNodeComponent from 'shared/components/tree/TreeNodeComponent';
import TreeWatchsiteComponent from 'shared/components/tree/TreeWatchsiteComponent';

export default function TreeComponent(props) {

    function dragOver(event) {
        event.preventDefault();
        const target = event.target.tagName === "LI" ? event.target : event.target.closest("li");
        if (target && target.getAttribute("data-type") === "node") {
            target.classList.add("droppable");
        }
    }

    function dragLeave(event) {
        event.preventDefault();
        const target = event.target.tagName === "LI" ? event.target : event.target.closest("li");
        if (target && target.getAttribute("data-type") === "node") {
            target.classList.remove("droppable");
        }
    }
      
    function dragStart(event) {
        event.dataTransfer.setData("text", event.target.id.replace("-link", ""));
    }
      
    function drop(event) {        
        event.preventDefault();
        props.onDragEnd(event);
    }

    return (
        <>
            <ul className="arborescence accounttree no-arrow offset-200px" onDrop={drop} onDragOver={dragOver} onDragLeave={dragLeave}>
                {props.tree.watchsites && props.tree.watchsites.map((treeItem) => (
                    <TreeWatchsiteComponent
                        onDragStart={dragStart}
                        key={treeItem.id}
                        item={treeItem} 
                        onWatchsiteClick={props.onWatchsiteClick}
                        onSelect={props.onSelect}
                        selected={props.selected}
                        account={props.account}
                        disabled={props.disabled}
                    />
                ))}
                {props.tree.nodes && props.tree.nodes.map((treeItem) => (
                    <TreeNodeComponent
                        onDrop={drop}
                        onDragOver={dragOver}
                        onDragStart={dragStart}
                        key={treeItem.id}
                        item={treeItem}
                        onWatchsiteClick={props.onWatchsiteClick}
                        onNodeClick={props.onNodeClick}
                        onNodeRemove={props.onNodeRemove}
                        onSelect={props.onSelect}
                        selected={props.selected}
                        account={props.account}
                        disabled={props.disabled}
                    />
                ))}
            </ul>
        </>
    )

}