import ApiRequest from 'shared/services/ApiRequest';

const PREFIX = "sheetsaffected";

const SheetAffectedApi = {

    getAffectations: function(sheet) {
        return ApiRequest.get(PREFIX + "/" + sheet + "/affectations").then(response => response.data);
    }
}
export default SheetAffectedApi;