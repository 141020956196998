import { React, useState, toast } from 'commons';
import DefaultLayout from "layouts/DefaultLayout";
import { denyIfCantPerform } from 'shared/services';
import PushPerimeter from 'views/push/PushPerimeter';
import PushList from 'views/push/PushList';

export default function Push(props) {

    denyIfCantPerform(props, "push:list");

    const [perimeter, setPerimeter] = useState(localStorage.getItem("current-push") ? JSON.parse(localStorage.getItem("current-push")) : []);
    const [accountsSelection, setAccountsSelection] = useState(localStorage.getItem("current-acount-selection") ? JSON.parse(localStorage.getItem("current-acount-selection")) : []);

    function updatePerimeter(newPerimeter) {
        setPerimeter(newPerimeter);
        localStorage.setItem("current-push", JSON.stringify(newPerimeter));
        toast.success("Selection mise à jour");
    }

    function updateAccountsSelection(newAccountsSelection) {
        setAccountsSelection(newAccountsSelection);
        localStorage.setItem("current-acount-selection", JSON.stringify(newAccountsSelection));
    }

    return <DefaultLayout screen="E20" title="Push consultant">
        <h1>Push consultant</h1>
        <PushPerimeter perimeter={perimeter} accountsSelection={accountsSelection} setPerimeter={updatePerimeter} setAccountsSelection={updateAccountsSelection} />
        <PushList perimeter={perimeter} />
    </DefaultLayout>
}