import { React, useState, toast } from "commons";
import { useToggler } from "shared/hooks";
import { SHEET_DONE, SHEET_TO_DO } from "data/SheetStatus";
import { ToggableBlocComponent, FieldComponent, LoadButton } from "shared/components";
import { SheetAutocomplete } from "shared/services";
import { SheetAffectedApi } from "shared/api";

export default function SheetAffectedListFormAddSheet(props) {

    const [adding, setAdding] = useState(false);
    const [togglers, toggle] = useToggler({'add-sheet': true});
    const [sheet, setSheet] = useState(null);

    function submit(event) {
        event.preventDefault();

        setAdding(true);

        SheetAffectedApi
            .addToDatabase(sheet.value, props.watchsite)
            .then(result => {
                setSheet(null);
                if (result.level === "notice") {
                    toast.info(result.message);
                } else if (result.level === "info") {
                    toast.success(result.message);
                } else if (result.level === "warning") {
                    toast.warning(result.message);
                }
                props.onSuccess();
            })
            .catch(error => {
                setSheet(null);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Une erreur est survenue.");
                }
            })
            .finally(() => setAdding(false));
    }

    function add(value, newSheet) {
        setSheet(newSheet);
    }

    return <section>
        <form onSubmit={submit} id="form-add-sheet-to-push" className="form">
            <ToggableBlocComponent label="Ajouter de fiches" id="add-sheet" toggled={togglers["add-sheet"]} toggle={toggle}>
                <FieldComponent
                    label="Fiche"
                    type="async-select"
                    cacheOptions={false}
                    name="sheet-to-add"
                    loadOptions={(inputValue, callback) => SheetAutocomplete({keyword: inputValue, status: [SHEET_DONE, SHEET_TO_DO]}, callback)}
                    onChange={add}
                />
                {sheet && <div className="flex-label"><label>Fiche à ajouter : </label>{sheet.label}</div>}
                <section>
                    <div className="text-right" style={{marginBottom: "15px"}}>
                        <LoadButton loading={adding} label="Ajouter" name="add" id="add" />
                    </div>
                </section>
            </ToggableBlocComponent>
        </form>
    </section>;
}