import { React, useEffect, useState, useContext, Link, DefaultLayout, toast, _ } from 'commons';
import { useList } from 'shared/hooks/List';
import { usePosition } from 'shared/hooks/Position';
import { ReferentialContext } from 'shared/stores/Referential';
import { AccountApi, DocumentarySpaceApi } from 'shared/api';
import { ListComponent, FilterComponent, TreeReferentialComponent } from 'shared/components';
import { denyIfCantPerform, preventDefault, toggleArrayValue } from 'shared/services';
import { typeLabels } from 'shared/data';

export default function DocumentarySpaceList(props) {

    denyIfCantPerform(props, "documentaryspace:list");

    const validEntityTypes = [
        'caselaw',
        'clientspecificmodule',
        'drafttext',
        'form',
        'news',
        'newsflash',
        'newsletter',
        'regulatorycontrol',
        'regulatoryflowchart',
        'sanction',
        'thematicfile',
        'documentaryspace',
    ];
    const type = props.match.params.type ? props.match.params.type : document.location.href.substring(document.location.href.lastIndexOf('/') + 1);

    if (!validEntityTypes.includes(type)) {
        props.history.push('/denied');
    }

    const listId = "documentaryspace-list-" + type;
    const [accounts, setAccounts] = useState([]);
    const [initializePosition, setPosition] = usePosition(listId);
    const [referentialContext] = useContext(ReferentialContext);
    const domainTree = _.cloneDeep(referentialContext["tree"]);
    const columns = [
        {id: 'date', title: 'Date', format: "date"},
        {id: 'title', title: 'Titre'},
        {
            id: 'theme',
            title: 'Domaines / sous domaines / thèmes',
            sortable: false,
            render: (row) => <TreeReferentialComponent className="arborescence" items={domainTree} value={row.theme ? [row.domain.id, row.subdomain.id, row.theme.id] : [row.domain.id, row.subdomain.id]} />
        },
        {
            id: 'action',
            sortable: false,
            title: '',
            render: (row) => <ul className="actions">
                <li><Link title="Modifier" to={`/documentaryspace/${type}/${row.id}`}>
                    <i className="icon-actions-modifier" aria-hidden="true"></i>
                </Link></li>
                <li><Link title="Supprimer" onClick={(e) => deleteDocument(e, row.id)} to={`/documentaryspace/${type}/${row.id}`}>
                        <i className="icon-actions-supprimer" aria-hidden="true"></i>
                </Link></li>
            </ul>
        }
    ];

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList(listId, DocumentarySpaceApi, preFetch, "date", {
        keyword_fields: ["title", "description"]
    });

    let availableKeywordFields = [];
    let filterType = {};
    if (type === "documentnews") {
        availableKeywordFields = [
            {value: 'title', label: 'Titre'},        
            {value: 'description', label: 'Description'},
            {value: 'attachments', label: 'PDF joints'},
        ];
        filterType = {
            date: {type: "range", fields: ["date"]},
            domains: {type: "terms", fields: ["domain", "subdomain", "theme"]},
            keyword: {type: "wildcard", fields: criterias.keyword_fields},
            account: {type: "match", fields: ["account.id"]},
        };
    } else {
        availableKeywordFields = [
            {value: 'title', label: 'Titre'},        
            {value: 'attachments', label: 'PDF joints'},
        ];
        filterType = {
            date: {type: "range", fields: ["date"]},
            domains: {type: "terms", fields: ["domain", "subdomain", "theme"]},
            keyword: {type: "wildcard", fields: criterias.keyword_fields.filter(field => field !== "description")},
            account: {type: "match", fields: ["account.id"]},
        };
    }

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        return [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters, {type: type}];
    }

    function load() {
        AccountApi
            .search(
                {"bool": {
                    "must": [
                        {"match": {"access_private_documentary_space": 1}},
                        {"match": {"access_documentary_space": 1}}
                    ]
                }},
                0,
                10000,
                "name",
                "asc"
            )
            .then(([results]) => {
                setAccounts(results.map(r => {return {value: r.id, label: r.name};}));
            });

        submitSearch(filterType, () => initializePosition());
    }

    function deleteDocument(event, documentId) {
        event.preventDefault();
        DocumentarySpaceApi
            .delete(type, documentId)
            .then(() => {
                toast.success("Document supprimé");
                refresh();
            });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => load(), []);

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E51" title="Espace documentaire">
            <h1>Espace documentaire « {typeLabels[type]} »</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    label="Date"
                                    name="date"
                                    type="daterange"
                                    value={criterias.date}
                                    onChange={value => addCriteria("date", value)}
                                />
                                <FilterComponent
                                    label="Domaines / Sous domaines / Thèmes"
                                    name="domains"
                                    type="dropdown-tree-select"
                                    onChange={value => addCriteria("domains", value)}
                                    value={criterias.domains}
                                    data={domainTree}
                                    mode="hierarchical"
                                />
                                <FilterComponent 
                                    type="keyword"
                                    name="keyword"
                                    label="Recherche libre" 
                                    fields={availableKeywordFields}
                                    selectedFields={criterias.keyword_fields}
                                    onChange={value => addCriteria("keyword", value)}
                                    onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                    value={criterias.keyword || ""} 
                                />
                                {type === "clientspecificmodule" && <FilterComponent
                                    type="select"
                                    label="Compte"
                                    name="account"
                                    options={accounts}
                                    clearable={true}
                                    onChange={value => addCriteria("account", value)}
                                    value={criterias.account}
                                />}
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-documentaryspace" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-documentaryspace" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    <ListComponent 
                        id={listId}
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        globalActions={(
                            <>
                                <Link id={`new-${listId}`} to={`/documentaryspace/${type}/new`} className="btn btn-primary h25 icon">
                                    <i className="icon-boutons-creer-acces" aria-hidden="true"></i>&nbsp;Créer un document
                                </Link>
                            </>
                        )}
                    />
                </form>
            </div>
        </DefaultLayout>
    );
}
