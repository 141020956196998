import { React, useState } from 'commons';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent } from "shared/components";
import PerimeterSelectionForm from 'views/push/Perimeter/PerimeterSelectionForm';

export default function PerimeterSelectionModal(props) {

    const [perimeter, setPerimeter] = useState(props.perimeter);
    const [accountsSelection, setAccountsSelection] = useState([]);
    const [togglers, toggle] = useToggler({'perimeter-selection': true});

    return <section>
        <form className="form">
            <ToggableBlocComponent 
                label="Pré-sélectionner le périmètre"
                id="perimeter-selection"
                toggled={togglers["perimeter-selection"]}
                toggle={toggle}
                className="toggle-group"
            >
                <PerimeterSelectionForm 
                    perimeter={perimeter}
                    setPerimeter={setPerimeter}
                    setAccountsSelection={setAccountsSelection}
                    restrictedAccount={props.restrictedAccount}
                />
            </ToggableBlocComponent>
            <section className="row">
                <div className="col">
                    <button type="button" id="back-push" onClick={() => props.onClose()} className="btn btn-bleu-4">
                        Retour au push
                    </button>
                </div>
                <div className="col text-right">
                    <button
                        type="button"
                        id="valid-perimeter"
                        onClick={() => {
                            props.setPerimeter(perimeter);
                            if (props.setAccountsSelection) {
                                props.setAccountsSelection(accountsSelection)
                            }
                            props.onClose();
                        }}
                        className="btn btn-primary"
                    >
                        Valider la sélection
                    </button>
                </div>
            </section>
        </form>
    </section>;
}