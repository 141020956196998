export function getCodeNaf() {
    return [
        {code: "0111Z", label: "0111Z - Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses"},
        {code: "0112Z", label: "0112Z - Culture du riz"},
        {code: "0113Z", label: "0113Z - Culture de légumes, de melons, de racines et de tubercules"},
        {code: "0114Z", label: "0114Z - Culture de la canne à sucre"},
        {code: "0115Z", label: "0115Z - Culture du tabac"},
        {code: "0116Z", label: "0116Z - Culture de plantes à fibres"},
        {code: "0119Z", label: "0119Z - Autres cultures non permanentes"},
        {code: "0121Z", label: "0121Z - Culture de la vigne"},
        {code: "0122Z", label: "0122Z - Culture de fruits tropicaux et subtropicaux"},
        {code: "0123Z", label: "0123Z - Culture d'agrumes"},
        {code: "0124Z", label: "0124Z - Culture de fruits à pépins et à noyau"},
        {code: "0125Z", label: "0125Z - Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque"},
        {code: "0126Z", label: "0126Z - Culture de fruits oléagineux"},
        {code: "0127Z", label: "0127Z - Culture de plantes à boissons"},
        {code: "0128Z", label: "0128Z - Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques"},
        {code: "0129Z", label: "0129Z - Autres cultures permanentes"},
        {code: "0130Z", label: "0130Z - Reproduction de plantes"},
        {code: "0141Z", label: "0141Z - Élevage de vaches laitières"},
        {code: "0142Z", label: "0142Z - Élevage d'autres bovins et de buffles"},
        {code: "0143Z", label: "0143Z - Élevage de chevaux et d'autres équidés"},
        {code: "0144Z", label: "0144Z - Élevage de chameaux et d'autres camélidés"},
        {code: "0145Z", label: "0145Z - Élevage d'ovins et de caprins"},
        {code: "0146Z", label: "0146Z - Élevage de porcins"},
        {code: "0147Z", label: "0147Z - Élevage de volailles"},
        {code: "0149Z", label: "0149Z - Élevage d'autres animaux"},
        {code: "0150Z", label: "0150Z - Culture et élevage associés"},
        {code: "0161Z", label: "0161Z - Activités de soutien aux cultures"},
        {code: "0162Z", label: "0162Z - Activités de soutien à la production animale"},
        {code: "0163Z", label: "0163Z - Traitement primaire des récoltes"},
        {code: "0164Z", label: "0164Z - Traitement des semences"},
        {code: "0170Z", label: "0170Z - Chasse, piégeage et services annexes"},
        {code: "0210Z", label: "0210Z - Sylviculture et autres activités forestières"},
        {code: "0220Z", label: "0220Z - Exploitation forestière"},
        {code: "0230Z", label: "0230Z - Récolte de produits forestiers non ligneux poussant à l'état sauvage"},
        {code: "0240Z", label: "0240Z - Services de soutien à l'exploitation forestière"},
        {code: "0311Z", label: "0311Z - Pêche en mer"},
        {code: "0312Z", label: "0312Z - Pêche en eau douce"},
        {code: "0321Z", label: "0321Z - Aquaculture en mer"},
        {code: "0322Z", label: "0322Z - Aquaculture en eau douce"},
        {code: "0510Z", label: "0510Z - Extraction de houille"},
        {code: "0520Z", label: "0520Z - Extraction de lignite"},
        {code: "0610Z", label: "0610Z - Extraction de pétrole brut"},
        {code: "0620Z", label: "0620Z - Extraction de gaz naturel"},
        {code: "0710Z", label: "0710Z - Extraction de minerais de fer"},
        {code: "0721Z", label: "0721Z - Extraction de minerais d'uranium et de thorium"},
        {code: "0729Z", label: "0729Z - Extraction d'autres minerais de métaux non ferreux"},
        {code: "0811Z", label: "0811Z - Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise"},
        {code: "0812Z", label: "0812Z - Exploitation de gravières et sablières, extraction d’argiles et de kaolin"},
        {code: "0891Z", label: "0891Z - Extraction des minéraux chimiques et d'engrais minéraux"},
        {code: "0892Z", label: "0892Z - Extraction de tourbe"},
        {code: "0893Z", label: "0893Z - Production de sel"},
        {code: "0899Z", label: "0899Z - Autres activités extractives nca"},
        {code: "0910Z", label: "0910Z - Activités de soutien à l'extraction d'hydrocarbures"},
        {code: "0990Z", label: "0990Z - Activités de soutien aux autres industries extractives"},
        {code: "1011Z", label: "1011Z - Transformation et conservation de la viande de boucherie"},
        {code: "1012Z", label: "1012Z - Transformation et conservation de la viande de volaille"},
        {code: "1013A", label: "1013A - Préparation industrielle de produits à base de viande"},
        {code: "1013B", label: "1013B - Charcuterie"},
        {code: "1020Z", label: "1020Z - Transformation et conservation de poisson, de crustacés et de mollusques"},
        {code: "1031Z", label: "1031Z - Transformation et conservation de pommes de terre"},
        {code: "1032Z", label: "1032Z - Préparation de jus de fruits et légumes"},
        {code: "1039A", label: "1039A - Autre transformation et conservation de légumes"},
        {code: "1039B", label: "1039B - Transformation et conservation de fruits"},
        {code: "1041A", label: "1041A - Fabrication d'huiles et graisses brutes"},
        {code: "1041B", label: "1041B - Fabrication d'huiles et graisses raffinées"},
        {code: "1042Z", label: "1042Z - Fabrication de margarine et graisses comestibles similaires"},
        {code: "1051A", label: "1051A - Fabrication de lait liquide et de produits frais"},
        {code: "1051B", label: "1051B - Fabrication de beurre"},
        {code: "1051C", label: "1051C - Fabrication de fromage"},
        {code: "1051D", label: "1051D - Fabrication d'autres produits laitiers"},
        {code: "1052Z", label: "1052Z - Fabrication de glaces et sorbets"},
        {code: "1061A", label: "1061A - Meunerie"},
        {code: "1061B", label: "1061B - Autres activités du travail des grains"},
        {code: "1062Z", label: "1062Z - Fabrication de produits amylacés"},
        {code: "1071A", label: "1071A - Fabrication industrielle de pain et de pâtisserie fraîche"},
        {code: "1071B", label: "1071B - Cuisson de produits de boulangerie"},
        {code: "1071C", label: "1071C - Boulangerie et boulangerie-pâtisserie"},
        {code: "1071D", label: "1071D - Pâtisserie"},
        {code: "1072Z", label: "1072Z - Fabrication de biscuits, biscottes et pâtisseries de conservation"},
        {code: "1073Z", label: "1073Z - Fabrication de pâtes alimentaires"},
        {code: "1081Z", label: "1081Z - Fabrication de sucre"},
        {code: "1082Z", label: "1082Z - Fabrication de cacao, chocolat et de produits de confiserie"},
        {code: "1083Z", label: "1083Z - Transformation du thé et du café"},
        {code: "1084Z", label: "1084Z - Fabrication de condiments et assaisonnements"},
        {code: "1085Z", label: "1085Z - Fabrication de plats préparés"},
        {code: "1086Z", label: "1086Z - Fabrication d'aliments homogénéisés et diététiques"},
        {code: "1089Z", label: "1089Z - Fabrication d'autres produits alimentaires nca"},
        {code: "1091Z", label: "1091Z - Fabrication d'aliments pour animaux de ferme"},
        {code: "1092Z", label: "1092Z - Fabrication d'aliments pour animaux de compagnie"},
        {code: "1101Z", label: "1101Z - Production de boissons alcooliques distillées"},
        {code: "1102A", label: "1102A - Fabrication de vins effervescents"},
        {code: "1102B", label: "1102B - Vinification"},
        {code: "1103Z", label: "1103Z - Fabrication de cidre et de vins de fruits"},
        {code: "1104Z", label: "1104Z - Production d'autres boissons fermentées non distillées"},
        {code: "1105Z", label: "1105Z - Fabrication de bière"},
        {code: "1106Z", label: "1106Z - Fabrication de malt"},
        {code: "1107A", label: "1107A - Industrie des eaux de table"},
        {code: "1107B", label: "1107B - Production de boissons rafraîchissantes"},
        {code: "1200Z", label: "1200Z - Fabrication de produits à base de tabac"},
        {code: "1310Z", label: "1310Z - Préparation de fibres textiles et filature"},
        {code: "1320Z", label: "1320Z - Tissage"},
        {code: "1330Z", label: "1330Z - Ennoblissement textile"},
        {code: "1391Z", label: "1391Z - Fabrication d'étoffes à mailles"},
        {code: "1392Z", label: "1392Z - Fabrication d'articles textiles, sauf habillement"},
        {code: "1393Z", label: "1393Z - Fabrication de tapis et moquettes"},
        {code: "1394Z", label: "1394Z - Fabrication de ficelles, cordes et filets"},
        {code: "1395Z", label: "1395Z - Fabrication de non-tissés, sauf habillement"},
        {code: "1396Z", label: "1396Z - Fabrication d'autres textiles techniques et industriels"},
        {code: "1399Z", label: "1399Z - Fabrication d'autres textiles nca"},
        {code: "1411Z", label: "1411Z - Fabrication de vêtements en cuir"},
        {code: "1412Z", label: "1412Z - Fabrication de vêtements de travail"},
        {code: "1413Z", label: "1413Z - Fabrication de vêtements de dessus"},
        {code: "1414Z", label: "1414Z - Fabrication de vêtements de dessous"},
        {code: "1419Z", label: "1419Z - Fabrication d'autres vêtements et accessoires"},
        {code: "1420Z", label: "1420Z - Fabrication d'articles en fourrure"},
        {code: "1431Z", label: "1431Z - Fabrication d'articles chaussants à mailles"},
        {code: "1439Z", label: "1439Z - Fabrication d'autres articles à mailles"},
        {code: "1511Z", label: "1511Z - Apprêt et tannage des cuirs ; préparation et teinture des fourrures"},
        {code: "1512Z", label: "1512Z - Fabrication d'articles de voyage, de maroquinerie et de sellerie"},
        {code: "1520Z", label: "1520Z - Fabrication de chaussures"},
        {code: "1610A", label: "1610A - Sciage et rabotage du bois, hors imprégnation"},
        {code: "1610B", label: "1610B - Imprégnation du bois"},
        {code: "1621Z", label: "1621Z - Fabrication de placage et de panneaux de bois"},
        {code: "1622Z", label: "1622Z - Fabrication de parquets assemblés"},
        {code: "1623Z", label: "1623Z - Fabrication de charpentes et d'autres menuiseries"},
        {code: "1624Z", label: "1624Z - Fabrication d'emballages en bois"},
        {code: "1629Z", label: "1629Z - Fabrication d'objets divers en bois; fabrication d'objets en liège, vannerie et sparterie"},
        {code: "1711Z", label: "1711Z - Fabrication de pâte à papier"},
        {code: "1712Z", label: "1712Z - Fabrication de papier et de carton"},
        {code: "1721A", label: "1721A - Fabrication de carton ondulé"},
        {code: "1721B", label: "1721B - Fabrication de cartonnages"},
        {code: "1721C", label: "1721C - Fabrication d'emballages en papier"},
        {code: "1722Z", label: "1722Z - Fabrication d'articles en papier à usage sanitaire ou domestique"},
        {code: "1723Z", label: "1723Z - Fabrication d'articles de papeterie"},
        {code: "1724Z", label: "1724Z - Fabrication de papiers peints"},
        {code: "1729Z", label: "1729Z - Fabrication d'autres articles en papier ou en carton"},
        {code: "1811Z", label: "1811Z - Imprimerie de journaux"},
        {code: "1812Z", label: "1812Z - Autre imprimerie (labeur)"},
        {code: "1813Z", label: "1813Z - Activités de pré-presse"},
        {code: "1814Z", label: "1814Z - Reliure et activités connexes"},
        {code: "1820Z", label: "1820Z - Reproduction d'enregistrements"},
        {code: "1910Z", label: "1910Z - Cokéfaction"},
        {code: "1920Z", label: "1920Z - Raffinage du pétrole"},
        {code: "2011Z", label: "2011Z - Fabrication de gaz industriels"},
        {code: "2012Z", label: "2012Z - Fabrication de colorants et de pigments"},
        {code: "2013A", label: "2013A - Enrichissement et retraitement de matières nucléaires"},
        {code: "2013B", label: "2013B - Fabrication d'autres produits chimiques inorganiques de base nca"},
        {code: "2014Z", label: "2014Z - Fabrication d'autres produits chimiques organiques de base"},
        {code: "2015Z", label: "2015Z - Fabrication de produits azotés et d'engrais"},
        {code: "2016Z", label: "2016Z - Fabrication de matières plastiques de base"},
        {code: "2017Z", label: "2017Z - Fabrication de caoutchouc synthétique"},
        {code: "2020Z", label: "2020Z - Fabrication de pesticides et d’autres produits agrochimiques"},
        {code: "2030Z", label: "2030Z - Fabrication de peintures, vernis, encres et mastics"},
        {code: "2041Z", label: "2041Z - Fabrication de savons, détergents et produits d'entretien"},
        {code: "2042Z", label: "2042Z - Fabrication de parfums et de produits pour la toilette"},
        {code: "2051Z", label: "2051Z - Fabrication de produits explosifs"},
        {code: "2052Z", label: "2052Z - Fabrication de colles"},
        {code: "2053Z", label: "2053Z - Fabrication d'huiles essentielles"},
        {code: "2059Z", label: "2059Z - Fabrication d'autres produits chimiques nca"},
        {code: "2060Z", label: "2060Z - Fabrication de fibres artificielles ou synthétiques"},
        {code: "2110Z", label: "2110Z - Fabrication de produits pharmaceutiques de base"},
        {code: "2120Z", label: "2120Z - Fabrication de préparations pharmaceutiques"},
        {code: "2211Z", label: "2211Z - Fabrication et rechapage de pneumatiques"},
        {code: "2219Z", label: "2219Z - Fabrication d'autres articles en caoutchouc"},
        {code: "2221Z", label: "2221Z - Fabrication de plaques, feuilles, tubes et profilés en matières plastiques"},
        {code: "2222Z", label: "2222Z - Fabrication d'emballages en matières plastiques"},
        {code: "2223Z", label: "2223Z - Fabrication d'éléments en matières plastiques pour la construction"},
        {code: "2229A", label: "2229A - Fabrication de pièces techniques à base de matières plastiques"},
        {code: "2229B", label: "2229B - Fabrication de produits de consommation courante en matières plastiques"},
        {code: "2311Z", label: "2311Z - Fabrication de verre plat"},
        {code: "2312Z", label: "2312Z - Façonnage et transformation du verre plat"},
        {code: "2313Z", label: "2313Z - Fabrication de verre creux"},
        {code: "2314Z", label: "2314Z - Fabrication de fibres de verre"},
        {code: "2319Z", label: "2319Z - Fabrication et façonnage d'autres articles en verre, y compris verre technique"},
        {code: "2320Z", label: "2320Z - Fabrication de produits réfractaires"},
        {code: "2331Z", label: "2331Z - Fabrication de carreaux en céramique"},
        {code: "2332Z", label: "2332Z - Fabrication de briques, tuiles et produits de construction, en terre cuite"},
        {code: "2341Z", label: "2341Z - Fabrication d'articles céramiques à usage domestique ou ornemental"},
        {code: "2342Z", label: "2342Z - Fabrication d'appareils sanitaires en céramique"},
        {code: "2343Z", label: "2343Z - Fabrication d'isolateurs et pièces isolantes en céramique"},
        {code: "2344Z", label: "2344Z - Fabrication d'autres produits céramiques à usage technique"},
        {code: "2349Z", label: "2349Z - Fabrication d'autres produits céramiques"},
        {code: "2351Z", label: "2351Z - Fabrication de ciment"},
        {code: "2352Z", label: "2352Z - Fabrication de chaux et plâtre"},
        {code: "2361Z", label: "2361Z - Fabrication d'éléments en béton pour la construction"},
        {code: "2362Z", label: "2362Z - Fabrication d'éléments en plâtre pour la construction"},
        {code: "2363Z", label: "2363Z - Fabrication de béton prêt à l'emploi"},
        {code: "2364Z", label: "2364Z - Fabrication de mortiers et bétons secs"},
        {code: "2365Z", label: "2365Z - Fabrication d'ouvrages en fibre-ciment"},
        {code: "2369Z", label: "2369Z - Fabrication d'autres ouvrages en béton, en ciment ou en plâtre"},
        {code: "2370Z", label: "2370Z - Taille, façonnage et finissage de pierres"},
        {code: "2391Z", label: "2391Z - Fabrication de produits abrasifs"},
        {code: "2399Z", label: "2399Z - Fabrication d'autres produits minéraux non métalliques nca"},
        {code: "2410Z", label: "2410Z - Sidérurgie"},
        {code: "2420Z", label: "2420Z - Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier"},
        {code: "2431Z", label: "2431Z - Étirage à froid de barres"},
        {code: "2432Z", label: "2432Z - Laminage à froid de feuillards"},
        {code: "2433Z", label: "2433Z - Profilage à froid par formage ou pliage"},
        {code: "2434Z", label: "2434Z - Tréfilage à froid"},
        {code: "2441Z", label: "2441Z - Production de métaux précieux"},
        {code: "2442Z", label: "2442Z - Métallurgie de l'aluminium"},
        {code: "2443Z", label: "2443Z - Métallurgie du plomb, du zinc ou de l'étain"},
        {code: "2444Z", label: "2444Z - Métallurgie du cuivre"},
        {code: "2445Z", label: "2445Z - Métallurgie des autres métaux non ferreux"},
        {code: "2446Z", label: "2446Z - Élaboration et transformation de matières nucléaires"},
        {code: "2451Z", label: "2451Z - Fonderie de fonte"},
        {code: "2452Z", label: "2452Z - Fonderie d'acier"},
        {code: "2453Z", label: "2453Z - Fonderie de métaux légers"},
        {code: "2454Z", label: "2454Z - Fonderie d'autres métaux non ferreux"},
        {code: "2511Z", label: "2511Z - Fabrication de structures métalliques et de parties de structures"},
        {code: "2512Z", label: "2512Z - Fabrication de portes et fenêtres en métal"},
        {code: "2521Z", label: "2521Z - Fabrication de radiateurs et de chaudières pour le chauffage central"},
        {code: "2529Z", label: "2529Z - Fabrication d'autres réservoirs, citernes et conteneurs métalliques"},
        {code: "2530Z", label: "2530Z - Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central"},
        {code: "2540Z", label: "2540Z - Fabrication d'armes et de munitions"},
        {code: "2550A", label: "2550A - Forge, estampage, matriçage ; métallurgie des poudres"},
        {code: "2550B", label: "2550B - Découpage, emboutissage"},
        {code: "2561Z", label: "2561Z - Traitement et revêtement des métaux"},
        {code: "2562A", label: "2562A - Décolletage"},
        {code: "2562B", label: "2562B - Mécanique industrielle"},
        {code: "2571Z", label: "2571Z - Fabrication de coutellerie"},
        {code: "2572Z", label: "2572Z - Fabrication de serrures et de ferrures"},
        {code: "2573A", label: "2573A - Fabrication de moules et modèles"},
        {code: "2573B", label: "2573B - Fabrication d'autres outillages"},
        {code: "2591Z", label: "2591Z - Fabrication de fûts et emballages métalliques similaires"},
        {code: "2592Z", label: "2592Z - Fabrication d'emballages métalliques légers"},
        {code: "2593Z", label: "2593Z - Fabrication d'articles en fils métalliques, de chaînes et de ressorts"},
        {code: "2594Z", label: "2594Z - Fabrication de vis et de boulons"},
        {code: "2599A", label: "2599A - Fabrication d'articles métalliques ménagers"},
        {code: "2599B", label: "2599B - Fabrication d'autres articles métalliques"},
        {code: "2611Z", label: "2611Z - Fabrication de composants électroniques"},
        {code: "2612Z", label: "2612Z - Fabrication de cartes électroniques assemblées"},
        {code: "2620Z", label: "2620Z - Fabrication d'ordinateurs et d'équipements périphériques"},
        {code: "2630Z", label: "2630Z - Fabrication d'équipements de communication"},
        {code: "2640Z", label: "2640Z - Fabrication de produits électroniques grand public"},
        {code: "2651A", label: "2651A - Fabrication d'équipements d'aide à la navigation"},
        {code: "2651B", label: "2651B - Fabrication d'instrumentation scientifique et technique"},
        {code: "2652Z", label: "2652Z - Horlogerie"},
        {code: "2660Z", label: "2660Z - Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques"},
        {code: "2670Z", label: "2670Z - Fabrication de matériels optique et photographique"},
        {code: "2680Z", label: "2680Z - Fabrication de supports magnétiques et optiques"},
        {code: "2711Z", label: "2711Z - Fabrication de moteurs, génératrices et transformateurs électriques"},
        {code: "2712Z", label: "2712Z - Fabrication de matériel de distribution et de commande électrique"},
        {code: "2720Z", label: "2720Z - Fabrication de piles et d'accumulateurs électriques"},
        {code: "2731Z", label: "2731Z - Fabrication de câbles de fibres optiques"},
        {code: "2732Z", label: "2732Z - Fabrication d'autres fils et câbles électroniques ou électriques"},
        {code: "2733Z", label: "2733Z - Fabrication de matériel d'installation électrique"},
        {code: "2740Z", label: "2740Z - Fabrication d'appareils d'éclairage électrique"},
        {code: "2751Z", label: "2751Z - Fabrication d'appareils électroménagers"},
        {code: "2752Z", label: "2752Z - Fabrication d'appareils ménagers non électriques"},
        {code: "2790Z", label: "2790Z - Fabrication d'autres matériels électriques"},
        {code: "2811Z", label: "2811Z - Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules"},
        {code: "2812Z", label: "2812Z - Fabrication d'équipements hydrauliques et pneumatiques"},
        {code: "2813Z", label: "2813Z - Fabrication d'autres pompes et compresseurs"},
        {code: "2814Z", label: "2814Z - Fabrication d'autres articles de robinetterie"},
        {code: "2815Z", label: "2815Z - Fabrication d'engrenages et d'organes mécaniques de transmission"},
        {code: "2821Z", label: "2821Z - Fabrication de fours et brûleurs"},
        {code: "2822Z", label: "2822Z - Fabrication de matériel de levage et de manutention"},
        {code: "2823Z", label: "2823Z - Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)"},
        {code: "2824Z", label: "2824Z - Fabrication d'outillage portatif à moteur incorporé"},
        {code: "2825Z", label: "2825Z - Fabrication d'équipements aérauliques et frigorifiques industriels"},
        {code: "2829A", label: "2829A - Fabrication d'équipements d'emballage, de conditionnement et de pesage"},
        {code: "2829B", label: "2829B - Fabrication d'autres machines d'usage général"},
        {code: "2830Z", label: "2830Z - Fabrication de machines agricoles et forestières"},
        {code: "2841Z", label: "2841Z - Fabrication de machines-outils pour le travail des métaux"},
        {code: "2849Z", label: "2849Z - Fabrication d'autres machines-outils"},
        {code: "2891Z", label: "2891Z - Fabrication de machines pour la métallurgie"},
        {code: "2892Z", label: "2892Z - Fabrication de machines pour l'extraction ou la construction"},
        {code: "2893Z", label: "2893Z - Fabrication de machines pour l'industrie agro-alimentaire"},
        {code: "2894Z", label: "2894Z - Fabrication de machines pour les industries textiles"},
        {code: "2895Z", label: "2895Z - Fabrication de machines pour les industries du papier et du carton"},
        {code: "2896Z", label: "2896Z - Fabrication de machines pour le travail du caoutchouc ou des plastiques"},
        {code: "2899A", label: "2899A - Fabrication de machines d'imprimerie"},
        {code: "2899B", label: "2899B - Fabrication d'autres machines spécialisées"},
        {code: "2910Z", label: "2910Z - Construction de véhicules automobiles"},
        {code: "2920Z", label: "2920Z - Fabrication de carrosseries et remorques"},
        {code: "2931Z", label: "2931Z - Fabrication d'équipements électriques et électroniques automobiles"},
        {code: "2932Z", label: "2932Z - Fabrication d'autres équipements automobiles"},
        {code: "3011Z", label: "3011Z - Construction de navires et de structures flottantes"},
        {code: "3012Z", label: "3012Z - Construction de bateaux de plaisance"},
        {code: "3020Z", label: "3020Z - Construction de locomotives et d'autre matériel ferroviaire roulant"},
        {code: "3030Z", label: "3030Z - Construction aéronautique et spatiale"},
        {code: "3040Z", label: "3040Z - Construction de véhicules militaires de combat"},
        {code: "3091Z", label: "3091Z - Fabrication de motocycles"},
        {code: "3092Z", label: "3092Z - Fabrication de bicyclettes et de véhicules pour invalides"},
        {code: "3099Z", label: "3099Z - Fabrication d’autres équipements de transport nca"},
        {code: "3101Z", label: "3101Z - Fabrication de meubles de bureau et de magasin"},
        {code: "3102Z", label: "3102Z - Fabrication de meubles de cuisine"},
        {code: "3103Z", label: "3103Z - Fabrication de matelas"},
        {code: "3109A", label: "3109A - Fabrication de sièges d'ameublement d'intérieur"},
        {code: "3109B", label: "3109B - Fabrication d'autres meubles et industries connexes de l'ameublement"},
        {code: "3211Z", label: "3211Z - Frappe de monnaie"},
        {code: "3212Z", label: "3212Z - Fabrication d’articles de joaillerie et bijouterie"},
        {code: "3213Z", label: "3213Z - Fabrication d’articles de bijouterie fantaisie et articles similaires"},
        {code: "3220Z", label: "3220Z - Fabrication d'instruments de musique"},
        {code: "3230Z", label: "3230Z - Fabrication d'articles de sport"},
        {code: "3240Z", label: "3240Z - Fabrication de jeux et jouets"},
        {code: "3250A", label: "3250A - Fabrication de matériel chirurgical et dentaire"},
        {code: "3250B", label: "3250B - Fabrication de lunettes"},
        {code: "3291Z", label: "3291Z - Fabrication d’articles de brosserie"},
        {code: "3299Z", label: "3299Z - Autres activités manufacturières nca"},
        {code: "3311Z", label: "3311Z - Réparation d'ouvrages en métaux"},
        {code: "3312Z", label: "3312Z - Réparation de machines et équipements mécaniques"},
        {code: "3313Z", label: "3313Z - Réparation de matériels électroniques et optiques"},
        {code: "3314Z", label: "3314Z - Réparation d'équipements électriques"},
        {code: "3315Z", label: "3315Z - Réparation et maintenance navale"},
        {code: "3316Z", label: "3316Z - Réparation et maintenance d'aéronefs et d'engins spatiaux"},
        {code: "3317Z", label: "3317Z - Réparation et maintenance d'autres équipements de transport"},
        {code: "3319Z", label: "3319Z - Réparation d'autres équipements"},
        {code: "3320A", label: "3320A - Installation de structures métalliques, chaudronnées et de tuyauterie"},
        {code: "3320B", label: "3320B - Installation de machines et équipements mécaniques"},
        {code: "3320C", label: "3320C - Conception d'ensemble et assemblage sur site industriel d'équipements de contrôle des processus industriels"},
        {code: "3320D", label: "3320D - Installation d'équipements électriques, de matériels électroniques et optiques ou d'autres matériels"},
        {code: "3511Z", label: "3511Z - Production d'électricité"},
        {code: "3512Z", label: "3512Z - Transport d'électricité"},
        {code: "3513Z", label: "3513Z - Distribution d'électricité"},
        {code: "3514Z", label: "3514Z - Commerce d'électricité"},
        {code: "3521Z", label: "3521Z - Production de combustibles gazeux"},
        {code: "3522Z", label: "3522Z - Distribution de combustibles gazeux par conduites"},
        {code: "3523Z", label: "3523Z - Commerce de combustibles gazeux par conduites"},
        {code: "3530Z", label: "3530Z - Production et distribution de vapeur et d'air conditionné"},
        {code: "3600Z", label: "3600Z - Captage, traitement et distribution d'eau"},
        {code: "3700Z", label: "3700Z - Collecte et traitement des eaux usées"},
        {code: "3811Z", label: "3811Z - Collecte des déchets non dangereux"},
        {code: "3812Z", label: "3812Z - Collecte des déchets dangereux"},
        {code: "3821Z", label: "3821Z - Traitement et élimination des déchets non dangereux"},
        {code: "3822Z", label: "3822Z - Traitement et élimination des déchets dangereux"},
        {code: "3831Z", label: "3831Z - Démantèlement d'épaves"},
        {code: "3832Z", label: "3832Z - Récupération de déchets triés"},
        {code: "3900Z", label: "3900Z - Dépollution et autres services de gestion des déchets"},
        {code: "4110A", label: "4110A - Promotion immobilière de logements"},
        {code: "4110B", label: "4110B - Promotion immobilière de bureaux"},
        {code: "4110C", label: "4110C - Promotion immobilière d'autres bâtiments"},
        {code: "4110D", label: "4110D - Supports juridiques de programmes"},
        {code: "4120A", label: "4120A - Construction de maisons individuelles"},
        {code: "4120B", label: "4120B - Construction d'autres bâtiments"},
        {code: "4211Z", label: "4211Z - Construction de routes et autoroutes"},
        {code: "4212Z", label: "4212Z - Construction de voies ferrées de surface et souterraines"},
        {code: "4213A", label: "4213A - Construction d'ouvrages d'art"},
        {code: "4213B", label: "4213B - Construction et entretien de tunnels"},
        {code: "4221Z", label: "4221Z - Construction de réseaux pour fluides"},
        {code: "4222Z", label: "4222Z - Construction de réseaux électriques et de télécommunications"},
        {code: "4291Z", label: "4291Z - Construction d'ouvrages maritimes et fluviaux"},
        {code: "4299Z", label: "4299Z - Construction d'autres ouvrages de génie civil nca"},
        {code: "4311Z", label: "4311Z - Travaux de démolition"},
        {code: "4312A", label: "4312A - Travaux de terrassement courants et travaux préparatoires"},
        {code: "4312B", label: "4312B - Travaux de terrassement spécialisés ou de grande masse"},
        {code: "4313Z", label: "4313Z - Forages et sondages"},
        {code: "4321A", label: "4321A - Travaux d'installation électrique dans tous locaux"},
        {code: "4321B", label: "4321B - Travaux d'installation électrique sur la voie publique"},
        {code: "4322A", label: "4322A - Travaux d'installation d'eau et de gaz en tous locaux"},
        {code: "4322B", label: "4322B - Travaux d' installation d'équipements thermiques et de climatisation"},
        {code: "4329A", label: "4329A - Travaux d'isolation"},
        {code: "4329B", label: "4329B - Autres travaux d'installation nca"},
        {code: "4331Z", label: "4331Z - Travaux de plâtrerie"},
        {code: "4332A", label: "4332A - Travaux de menuiserie bois et PVC"},
        {code: "4332B", label: "4332B - Travaux de menuiserie métallique et serrurerie"},
        {code: "4332C", label: "4332C - Agencement de lieux de vente"},
        {code: "4333Z", label: "4333Z - Travaux de revêtement des sols et des murs"},
        {code: "4334Z", label: "4334Z - Travaux de peinture et vitrerie"},
        {code: "4339Z", label: "4339Z - Autres travaux de finition"},
        {code: "4391A", label: "4391A - Travaux de charpente"},
        {code: "4391B", label: "4391B - Travaux de couverture par éléments"},
        {code: "4399A", label: "4399A - Travaux d'étanchéification"},
        {code: "4399B", label: "4399B - Travaux de montage de structures métalliques"},
        {code: "4399C", label: "4399C - Travaux de maçonnerie générale et gros œuvre de bâtiment"},
        {code: "4399D", label: "4399D - Autres travaux spécialisés de construction"},
        {code: "4399E", label: "4399E - Location avec opérateur de matériel de construction"},
        {code: "4511Z", label: "4511Z - Commerce de voitures et de véhicules automobiles légers"},
        {code: "4519Z", label: "4519Z - Commerce d'autres véhicules automobiles"},
        {code: "4520A", label: "4520A - Entretien et réparation de véhicules automobiles légers"},
        {code: "4520B", label: "4520B - Entretien et réparation d'autres véhicules automobiles"},
        {code: "4531Z", label: "4531Z - Commerce de gros d'équipements automobiles"},
        {code: "4532Z", label: "4532Z - Commerce de détail d'équipements automobiles"},
        {code: "4540Z", label: "4540Z - Commerce et réparation de motocycles"},
        {code: "4611Z", label: "4611Z - Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis"},
        {code: "4612A", label: "4612A - Centrales d'achat de carburant"},
        {code: "4612B", label: "4612B - Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques"},
        {code: "4613Z", label: "4613Z - Intermédiaires du commerce en bois et matériaux de construction"},
        {code: "4614Z", label: "4614Z - Intermédiaires du commerce en machines, équipements industriels, navires et avions"},
        {code: "4615Z", label: "4615Z - Intermédiaires du commerce en meubles, articles de ménage et quincaillerie"},
        {code: "4616Z", label: "4616Z - Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"},
        {code: "4617A", label: "4617A - Centrales d'achat alimentaires"},
        {code: "4617B", label: "4617B - Autres intermédiaires du commerce en denrées, boissons et tabac"},
        {code: "4618Z", label: "4618Z - Intermédiaires spécialisés dans le commerce d'autres produits spécifiques"},
        {code: "4619A", label: "4619A - Centrales d'achat non alimentaires"},
        {code: "4619B", label: "4619B - Autres intermédiaires du commerce en produits divers"},
        {code: "4621Z", label: "4621Z - Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail"},
        {code: "4622Z", label: "4622Z - Commerce de gros (commerce interentreprises) de fleurs et plantes"},
        {code: "4623Z", label: "4623Z - Commerce de gros (commerce interentreprises) d'animaux vivants"},
        {code: "4624Z", label: "4624Z - Commerce de gros (commerce interentreprises) de cuirs et peaux"},
        {code: "4631Z", label: "4631Z - Commerce de gros (commerce interentreprises) de fruits et légumes"},
        {code: "4632A", label: "4632A - Commerce de gros (commerce interentreprises) de viandes de boucherie"},
        {code: "4632B", label: "4632B - Commerce de gros (commerce interentreprises) de produits à base de viande"},
        {code: "4632C", label: "4632C - Commerce de gros (commerce interentreprises) de volailles et gibier"},
        {code: "4633Z", label: "4633Z - Commerce de gros (commerce interentreprises) de produits laitiers, œufs, huiles et matières grasses comestibles"},
        {code: "4634Z", label: "4634Z - Commerce de gros (commerce interentreprises) de boissons"},
        {code: "4635Z", label: "4635Z - Commerce de gros (commerce interentreprises) de produits à base de tabac"},
        {code: "4636Z", label: "4636Z - Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie"},
        {code: "4637Z", label: "4637Z - Commerce de gros (commerce interentreprises) de café, thé, cacao et épices"},
        {code: "4638A", label: "4638A - Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques"},
        {code: "4638B", label: "4638B - Commerce de gros (commerce interentreprises) alimentaire spécialisé divers"},
        {code: "4639A", label: "4639A - Commerce de gros (commerce interentreprises) de produits surgelés"},
        {code: "4639B", label: "4639B - Commerce de gros (commerce interentreprises) alimentaire non spécialisé"},
        {code: "4641Z", label: "4641Z - Commerce de gros (commerce interentreprises) de textiles"},
        {code: "4642Z", label: "4642Z - Commerce de gros (commerce interentreprises) d'habillement et de chaussures"},
        {code: "4643Z", label: "4643Z - Commerce de gros (commerce interentreprises) d'appareils électroménagers"},
        {code: "4644Z", label: "4644Z - Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d'entretien"},
        {code: "4645Z", label: "4645Z - Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté"},
        {code: "4646Z", label: "4646Z - Commerce de gros (commerce interentreprises) de produits pharmaceutiques"},
        {code: "4647Z", label: "4647Z - Commerce de gros (commerce interentreprises) de meubles, de tapis et d'appareils d'éclairage"},
        {code: "4648Z", label: "4648Z - Commerce de gros (commerce interentreprises) d'articles d'horlogerie et de bijouterie"},
        {code: "4649Z", label: "4649Z - Commerce de gros (commerce interentreprises) d'autres biens domestiques"},
        {code: "4651Z", label: "4651Z - Commerce de gros (commerce interentreprises) d'ordinateurs, d'équipements informatiques périphériques et de logiciels"},
        {code: "4652Z", label: "4652Z - Commerce de gros (commerce interentreprises) de composants et d'équipements électroniques et de télécommunication"},
        {code: "4661Z", label: "4661Z - Commerce de gros (commerce interentreprises) de matériel agricole"},
        {code: "4662Z", label: "4662Z - Commerce de gros (commerce interentreprises) de machines-outils"},
        {code: "4663Z", label: "4663Z - Commerce de gros (commerce interentreprises) de machines pour l'extraction, la construction et le génie civil"},
        {code: "4664Z", label: "4664Z - Commerce de gros (commerce interentreprises) de machines pour l'industrie textile et l'habillement"},
        {code: "4665Z", label: "4665Z - Commerce de gros (commerce interentreprises) de mobilier de bureau"},
        {code: "4666Z", label: "4666Z - Commerce de gros (commerce interentreprises) d'autres machines et équipements de bureau"},
        {code: "4669A", label: "4669A - Commerce de gros (commerce interentreprises) de matériel électrique"},
        {code: "4669B", label: "4669B - Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers"},
        {code: "4669C", label: "4669C - Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services"},
        {code: "4671Z", label: "4671Z - Commerce de gros (commerce interentreprises) de combustibles et de produits annexes"},
        {code: "4672Z", label: "4672Z - Commerce de gros (commerce interentreprises) de minerais et métaux"},
        {code: "4673A", label: "4673A - Commerce de gros (commerce interentreprises) de bois et de matériaux de construction"},
        {code: "4673B", label: "4673B - Commerce de gros (commerce interentreprises) d'appareils sanitaires et de produits de décoration"},
        {code: "4674A", label: "4674A - Commerce de gros (commerce interentreprises) de quincaillerie"},
        {code: "4674B", label: "4674B - Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage"},
        {code: "4675Z", label: "4675Z - Commerce de gros (commerce interentreprises) de produits chimiques"},
        {code: "4676Z", label: "4676Z - Commerce de gros (commerce interentreprises) d'autres produits intermédiaires"},
        {code: "4677Z", label: "4677Z - Commerce de gros (commerce interentreprises) de déchets et débris"},
        {code: "4690Z", label: "4690Z - Commerce de gros (commerce interentreprises) non spécialisé"},
        {code: "4711A", label: "4711A - Commerce de détail de produits surgelés"},
        {code: "4711B", label: "4711B - Commerce d'alimentation générale"},
        {code: "4711C", label: "4711C - Supérettes"},
        {code: "4711D", label: "4711D - Supermarchés"},
        {code: "4711E", label: "4711E - Magasins multi-commerces"},
        {code: "4711F", label: "4711F - Hypermarchés"},
        {code: "4719A", label: "4719A - Grands magasins"},
        {code: "4719B", label: "4719B - Autres commerces de détail en magasin non spécialisé"},
        {code: "4721Z", label: "4721Z - Commerce de détail de fruits et légumes en magasin spécialisé"},
        {code: "4722Z", label: "4722Z - Commerce de détail de viandes et de produits à base de viande en magasin spécialisé"},
        {code: "4723Z", label: "4723Z - Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé"},
        {code: "4724Z", label: "4724Z - Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé"},
        {code: "4725Z", label: "4725Z - Commerce de détail de boissons en magasin spécialisé"},
        {code: "4726Z", label: "4726Z - Commerce de détail de produits à base de tabac en magasin spécialisé"},
        {code: "4729Z", label: "4729Z - Autres commerces de détail alimentaires en magasin spécialisé"},
        {code: "4730Z", label: "4730Z - Commerce de détail de carburants en magasin spécialisé"},
        {code: "4741Z", label: "4741Z - Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé"},
        {code: "4742Z", label: "4742Z - Commerce de détail de matériels de télécommunication en magasin spécialisé"},
        {code: "4743Z", label: "4743Z - Commerce de détail de matériels audio et vidéo en magasin spécialisé"},
        {code: "4751Z", label: "4751Z - Commerce de détail de textiles en magasin spécialisé"},
        {code: "4752A", label: "4752A - Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m2)"},
        {code: "4752B", label: "4752B - Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m2 et plus)"},
        {code: "4753Z", label: "4753Z - Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé"},
        {code: "4754Z", label: "4754Z - Commerce de détail d'appareils électroménagers en magasin spécialisé"},
        {code: "4759A", label: "4759A - Commerce de détail de meubles"},
        {code: "4759B", label: "4759B - Commerce de détail d'autres équipements du foyer"},
        {code: "4761Z", label: "4761Z - Commerce de détail de livres en magasin spécialisé"},
        {code: "4762Z", label: "4762Z - Commerce de détail de journaux et papeterie en magasin spécialisé"},
        {code: "4763Z", label: "4763Z - Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé"},
        {code: "4764Z", label: "4764Z - Commerce de détail d'articles de sport en magasin spécialisé"},
        {code: "4765Z", label: "4765Z - Commerce de détail de jeux et jouets en magasin spécialisé"},
        {code: "4771Z", label: "4771Z - Commerce de détail d'habillement en magasin spécialisé"},
        {code: "4772A", label: "4772A - Commerce de détail de la chaussure"},
        {code: "4772B", label: "4772B - Commerce de détail de maroquinerie et d'articles de voyage"},
        {code: "4773Z", label: "4773Z - Commerce de détail de produits pharmaceutiques en magasin spécialisé"},
        {code: "4774Z", label: "4774Z - Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé"},
        {code: "4775Z", label: "4775Z - Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé"},
        {code: "4776Z", label: "4776Z - Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé"},
        {code: "4777Z", label: "4777Z - Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé"},
        {code: "4778A", label: "4778A - Commerces de détail d'optique"},
        {code: "4778B", label: "4778B - Commerces de détail de charbons et combustibles"},
        {code: "4778C", label: "4778C - Autres commerces de détail spécialisés divers"},
        {code: "4779Z", label: "4779Z - Commerce de détail de biens d'occasion en magasin"},
        {code: "4781Z", label: "4781Z - Commerce de détail alimentaire sur éventaires et marchés"},
        {code: "4782Z", label: "4782Z - Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés"},
        {code: "4789Z", label: "4789Z - Autres commerces de détail sur éventaires et marchés"},
        {code: "4791A", label: "4791A - Vente à distance sur catalogue général"},
        {code: "4791B", label: "4791B - Vente à distance sur catalogue spécialisé"},
        {code: "4799A", label: "4799A - Vente à domicile"},
        {code: "4799B", label: "4799B - Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés nca"},
        {code: "4910Z", label: "4910Z - Transport ferroviaire interurbain de voyageurs"},
        {code: "4920Z", label: "4920Z - Transports ferroviaires de fret"},
        {code: "4931Z", label: "4931Z - Transports urbains et suburbains de voyageurs"},
        {code: "4932Z", label: "4932Z - Transports de voyageurs par taxis"},
        {code: "4939A", label: "4939A - Transports routiers réguliers de voyageurs"},
        {code: "4939B", label: "4939B - Autres transports routiers de voyageurs"},
        {code: "4939C", label: "4939C - Téléphériques et remontées mécaniques"},
        {code: "4941A", label: "4941A - Transports routiers de fret interurbains"},
        {code: "4941B", label: "4941B - Transports routiers de fret de proximité"},
        {code: "4941C", label: "4941C - Location de camions avec chauffeur"},
        {code: "4942Z", label: "4942Z - Services de déménagement"},
        {code: "4950Z", label: "4950Z - Transports par conduites"},
        {code: "5010Z", label: "5010Z - Transports maritimes et côtiers de passagers"},
        {code: "5020Z", label: "5020Z - Transports maritimes et côtiers de fret"},
        {code: "5030Z", label: "5030Z - Transports fluviaux de passagers"},
        {code: "5040Z", label: "5040Z - Transports fluviaux de fret"},
        {code: "5110Z", label: "5110Z - Transports aériens de passagers"},
        {code: "5121Z", label: "5121Z - Transports aériens de fret"},
        {code: "5122Z", label: "5122Z - Transports spatiaux"},
        {code: "5210A", label: "5210A - Entreposage et stockage frigorifique"},
        {code: "5210B", label: "5210B - Entreposage et stockage non frigorifique"},
        {code: "5221Z", label: "5221Z - Services auxiliaires des transports terrestres"},
        {code: "5222Z", label: "5222Z - Services auxiliaires des transports par eau"},
        {code: "5223Z", label: "5223Z - Services auxiliaires des transports aériens"},
        {code: "5224A", label: "5224A - Manutention portuaire"},
        {code: "5224B", label: "5224B - Manutention non portuaire"},
        {code: "5229A", label: "5229A - Messagerie, fret express"},
        {code: "5229B", label: "5229B - Affrètement et organisation des transports"},
        {code: "5310Z", label: "5310Z - Activités de poste dans le cadre d'une obligation de service universel"},
        {code: "5320Z", label: "5320Z - Autres activités de poste et de courrier"},
        {code: "5510Z", label: "5510Z - Hôtels et hébergement similaire"},
        {code: "5520Z", label: "5520Z - Hébergement touristique et autre hébergement de courte durée"},
        {code: "5530Z", label: "5530Z - Terrains de camping et parcs pour caravanes ou véhicules de loisirs"},
        {code: "5590Z", label: "5590Z - Autres hébergements"},
        {code: "5610A", label: "5610A - Restauration traditionnelle"},
        {code: "5610B", label: "5610B - Cafétérias et autres libres-services"},
        {code: "5610C", label: "5610C - Restauration de type rapide"},
        {code: "5621Z", label: "5621Z - Services des traiteurs"},
        {code: "5629A", label: "5629A - Restauration collective sous contrat"},
        {code: "5629B", label: "5629B - Autres services de restauration nca"},
        {code: "5630Z", label: "5630Z - Débits de boissons"},
        {code: "5811Z", label: "5811Z - Édition de livres"},
        {code: "5812Z", label: "5812Z - Édition de répertoires et de fichiers d'adresses"},
        {code: "5813Z", label: "5813Z - Édition de journaux"},
        {code: "5814Z", label: "5814Z - Édition de revues et périodiques"},
        {code: "5819Z", label: "5819Z - Autres activités d'édition"},
        {code: "5821Z", label: "5821Z - Édition de jeux électroniques"},
        {code: "5829A", label: "5829A - Édition de logiciels système et de réseau"},
        {code: "5829B", label: "5829B - Edition de logiciels outils de développement et de langages"},
        {code: "5829C", label: "5829C - Edition de logiciels applicatifs"},
        {code: "5911A", label: "5911A - Production de films et de programmes pour la télévision"},
        {code: "5911B", label: "5911B - Production de films institutionnels et publicitaires"},
        {code: "5911C", label: "5911C - Production de films pour le cinéma"},
        {code: "5912Z", label: "5912Z - Post-production de films cinématographiques, de vidéo et de programmes de télévision"},
        {code: "5913A", label: "5913A - Distribution de films cinématographiques"},
        {code: "5913B", label: "5913B - Edition et distribution vidéo"},
        {code: "5914Z", label: "5914Z - Projection de films cinématographiques"},
        {code: "5920Z", label: "5920Z - Enregistrement sonore et édition musicale"},
        {code: "6010Z", label: "6010Z - Édition et diffusion de programmes radio"},
        {code: "6020A", label: "6020A - Edition de chaînes généralistes"},
        {code: "6020B", label: "6020B - Edition de chaînes thématiques"},
        {code: "6110Z", label: "6110Z - Télécommunications filaires"},
        {code: "6120Z", label: "6120Z - Télécommunications sans fil"},
        {code: "6130Z", label: "6130Z - Télécommunications par satellite"},
        {code: "6190Z", label: "6190Z - Autres activités de télécommunication"},
        {code: "6201Z", label: "6201Z - Programmation informatique"},
        {code: "6202A", label: "6202A - Conseil en systèmes et logiciels informatiques"},
        {code: "6202B", label: "6202B - Tierce maintenance de systèmes et d’applications informatiques"},
        {code: "6203Z", label: "6203Z - Gestion d'installations informatiques"},
        {code: "6209Z", label: "6209Z - Autres activités informatiques"},
        {code: "6311Z", label: "6311Z - Traitement de données, hébergement et activités connexes"},
        {code: "6312Z", label: "6312Z - Portails Internet"},
        {code: "6391Z", label: "6391Z - Activités des agences de presse"},
        {code: "6399Z", label: "6399Z - Autres services d'information nca"},
        {code: "6411Z", label: "6411Z - Activités de banque centrale"},
        {code: "6419Z", label: "6419Z - Autres intermédiations monétaires"},
        {code: "6420Z", label: "6420Z - Activités des sociétés holding"},
        {code: "6430Z", label: "6430Z - Fonds de placement et entités financières similaires"},
        {code: "6491Z", label: "6491Z - Crédit-bail"},
        {code: "6492Z", label: "6492Z - Autre distribution de crédit"},
        {code: "6499Z", label: "6499Z - Autres activités des services financiers, hors assurance et caisses de retraite, nca"},
        {code: "6511Z", label: "6511Z - Assurance vie"},
        {code: "6512Z", label: "6512Z - Autres assurances"},
        {code: "6520Z", label: "6520Z - Réassurance"},
        {code: "6530Z", label: "6530Z - Caisses de retraite"},
        {code: "6611Z", label: "6611Z - Administration de marchés financiers"},
        {code: "6612Z", label: "6612Z - Courtage de valeurs mobilières et de marchandises"},
        {code: "6619A", label: "6619A - Supports juridiques de gestion de patrimoine mobilier"},
        {code: "6619B", label: "6619B - Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, nca"},
        {code: "6621Z", label: "6621Z - Évaluation des risques et dommages"},
        {code: "6622Z", label: "6622Z - Activités des agents et courtiers d'assurances"},
        {code: "6629Z", label: "6629Z - Autres activités auxiliaires d'assurance et de caisses de retraite"},
        {code: "6630Z", label: "6630Z - Gestion de fonds"},
        {code: "6810Z", label: "6810Z - Activités des marchands de biens immobiliers"},
        {code: "6820A", label: "6820A - Location de logements"},
        {code: "6820B", label: "6820B - Location de terrains et d'autres biens immobiliers"},
        {code: "6831Z", label: "6831Z - Agences immobilières"},
        {code: "6832A", label: "6832A - Administration d'immeubles et autres biens immobiliers"},
        {code: "6832B", label: "6832B - Supports juridiques de gestion de patrimoine immobilier"},
        {code: "6910Z", label: "6910Z - Activités juridiques"},
        {code: "6920Z", label: "6920Z - Activités comptables"},
        {code: "7010Z", label: "7010Z - Activités des sièges sociaux"},
        {code: "7021Z", label: "7021Z - Conseil en relations publiques et communication"},
        {code: "7022Z", label: "7022Z - Conseil pour les affaires et autres conseils de gestion"},
        {code: "7111Z", label: "7111Z - Activités d'architecture"},
        {code: "7112A", label: "7112A - Activité des géomètres"},
        {code: "7112B", label: "7112B - Ingénierie, études techniques"},
        {code: "7120A", label: "7120A - Contrôle technique automobile"},
        {code: "7120B", label: "7120B - Analyses, essais et inspections techniques"},
        {code: "7211Z", label: "7211Z - Recherche-développement en biotechnologie"},
        {code: "7219Z", label: "7219Z - Recherche-développement en autres sciences physiques et naturelles"},
        {code: "7220Z", label: "7220Z - Recherche-développement en sciences humaines et sociales"},
        {code: "7311Z", label: "7311Z - Activités des agences de publicité"},
        {code: "7312Z", label: "7312Z - Régie publicitaire de médias"},
        {code: "7320Z", label: "7320Z - Études de marché et sondages"},
        {code: "7410Z", label: "7410Z - Activités spécialisées de design"},
        {code: "7420Z", label: "7420Z - Activités photographiques"},
        {code: "7430Z", label: "7430Z - Traduction et interprétation"},
        {code: "7490A", label: "7490A - Activité des économistes de la construction"},
        {code: "7490B", label: "7490B - Activités spécialisées, scientifiques et techniques diverses"},
        {code: "7500Z", label: "7500Z - Activités vétérinaires"},
        {code: "7711A", label: "7711A - Location de courte durée de voitures et de véhicules automobiles légers"},
        {code: "7711B", label: "7711B - Location de longue durée de voitures et de véhicules automobiles légers"},
        {code: "7712Z", label: "7712Z - Location et location-bail de camions"},
        {code: "7721Z", label: "7721Z - Location et location-bail d'articles de loisirs et de sport"},
        {code: "7722Z", label: "7722Z - Location de vidéocassettes et disques vidéo"},
        {code: "7729Z", label: "7729Z - Location et location-bail d'autres biens personnels et domestiques"},
        {code: "7731Z", label: "7731Z - Location et location-bail de machines et équipements agricoles"},
        {code: "7732Z", label: "7732Z - Location et location-bail de machines et équipements pour la construction"},
        {code: "7733Z", label: "7733Z - Location et location-bail de machines de bureau et de matériel informatique"},
        {code: "7734Z", label: "7734Z - Location et location-bail de matériels de transport par eau"},
        {code: "7735Z", label: "7735Z - Location et location-bail de matériels de transport aérien"},
        {code: "7739Z", label: "7739Z - Location et location-bail d'autres machines, équipements et biens matériels nca"},
        {code: "7740Z", label: "7740Z - Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright"},
        {code: "7810Z", label: "7810Z - Activités des agences de placement de main-d'œuvre"},
        {code: "7820Z", label: "7820Z - Activités des agences de travail temporaire"},
        {code: "7830Z", label: "7830Z - Autre mise à disposition de ressources humaines"},
        {code: "7911Z", label: "7911Z - Activités des agences de voyage"},
        {code: "7912Z", label: "7912Z - Activités des voyagistes"},
        {code: "7990Z", label: "7990Z - Autres services de réservation et activités connexes"},
        {code: "8010Z", label: "8010Z - Activités de sécurité privée"},
        {code: "8020Z", label: "8020Z - Activités liées aux systèmes de sécurité"},
        {code: "8030Z", label: "8030Z - Activités d'enquête"},
        {code: "8110Z", label: "8110Z - Activités combinées de soutien lié aux bâtiments"},
        {code: "8121Z", label: "8121Z - Nettoyage courant des bâtiments"},
        {code: "8122Z", label: "8122Z - Autres activités de nettoyage des bâtiments et nettoyage industriel"},
        {code: "8129A", label: "8129A - Désinfection, désinsectisation, dératisation"},
        {code: "8129B", label: "8129B - Autres activités de nettoyage nca"},
        {code: "8130Z", label: "8130Z - Services d'aménagement paysager"},
        {code: "8211Z", label: "8211Z - Services administratifs combinés de bureau"},
        {code: "8219Z", label: "8219Z - Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau"},
        {code: "8220Z", label: "8220Z - Activités de centres d'appels"},
        {code: "8230Z", label: "8230Z - Organisation de foires, salons professionnels et congrès"},
        {code: "8291Z", label: "8291Z - Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle"},
        {code: "8292Z", label: "8292Z - Activités de conditionnement"},
        {code: "8299Z", label: "8299Z - Autres activités de soutien aux entreprises nca"},
        {code: "8411Z", label: "8411Z - Administration publique générale"},
        {code: "8412Z", label: "8412Z - Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale"},
        {code: "8413Z", label: "8413Z - Administration publique (tutelle) des activités économiques"},
        {code: "8421Z", label: "8421Z - Affaires étrangères"},
        {code: "8422Z", label: "8422Z - Défense"},
        {code: "8423Z", label: "8423Z - Justice"},
        {code: "8424Z", label: "8424Z - Activités d’ordre public et de sécurité"},
        {code: "8425Z", label: "8425Z - Services du feu et de secours"},
        {code: "8430A", label: "8430A - Activités générales de sécurité sociale"},
        {code: "8430B", label: "8430B - Gestion des retraites complémentaires"},
        {code: "8430C", label: "8430C - Distribution sociale de revenus"},
        {code: "8510Z", label: "8510Z - Enseignement pré-primaire"},
        {code: "8520Z", label: "8520Z - Enseignement primaire"},
        {code: "8531Z", label: "8531Z - Enseignement secondaire général"},
        {code: "8532Z", label: "8532Z - Enseignement secondaire technique ou professionnel"},
        {code: "8541Z", label: "8541Z - Enseignement post-secondaire non supérieur"},
        {code: "8542Z", label: "8542Z - Enseignement supérieur"},
        {code: "8551Z", label: "8551Z - Enseignement de disciplines sportives et d'activités de loisirs"},
        {code: "8552Z", label: "8552Z - Enseignement culturel"},
        {code: "8553Z", label: "8553Z - Enseignement de la conduite"},
        {code: "8559A", label: "8559A - Formation continue d'adultes"},
        {code: "8559B", label: "8559B - Autres enseignements"},
        {code: "8560Z", label: "8560Z - Activités de soutien à l'enseignement"},
        {code: "8610Z", label: "8610Z - Activités hospitalières"},
        {code: "8621Z", label: "8621Z - Activité des médecins généralistes"},
        {code: "8622A", label: "8622A - Activités de radiodiagnostic et de radiothérapie"},
        {code: "8622B", label: "8622B - Activités chirurgicales"},
        {code: "8622C", label: "8622C - Autres activités des médecins spécialistes"},
        {code: "8623Z", label: "8623Z - Pratique dentaire"},
        {code: "8690A", label: "8690A - Ambulances"},
        {code: "8690B", label: "8690B - Laboratoires d'analyses médicales"},
        {code: "8690C", label: "8690C - Centres de collecte et banques d'organes"},
        {code: "8690D", label: "8690D - Activités des infirmiers et des sages-femmes"},
        {code: "8690E", label: "8690E - Activités des professionnels de la rééducation, de l’appareillage et des pédicures-podologues"},
        {code: "8690F", label: "8690F - Activités de santé humaine non classées ailleurs"},
        {code: "8710A", label: "8710A - Hébergement médicalisé pour personnes âgées"},
        {code: "8710B", label: "8710B - Hébergement médicalisés pour enfants handicapés"},
        {code: "8710C", label: "8710C - Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé "},
        {code: "8720A", label: "8720A - Hébergement social pour handicapés mentaux et malades mentaux"},
        {code: "8720B", label: "8720B - Hébergement social pour toxicomanes"},
        {code: "8730A", label: "8730A - Hébergement social pour personnes âgées"},
        {code: "8730B", label: "8730B - Hébergement social pour handicapés physiques"},
        {code: "8790A", label: "8790A - Hébergement social pour enfants en difficultés"},
        {code: "8790B", label: "8790B - Hébergement social pour adultes et familles en difficultés et autre hébergement social"},
        {code: "8810A", label: "8810A - Aide à domicile"},
        {code: "8810B", label: "8810B - Accueil ou accompagnement sans hébergement d’adultes handicapés ou de personnes âgées"},
        {code: "8810C", label: "8810C - Aide par le travail"},
        {code: "8891A", label: "8891A - Accueil de jeunes enfants"},
        {code: "8891B", label: "8891B - Accueil ou accompagnement sans hébergement d’enfants handicapés"},
        {code: "8899A", label: "8899A - Autre accueil ou accompagnement sans hébergement ?_x0019_enfants et d’adolescents "},
        {code: "8899B", label: "8899B - Action sociale sans hébergement nca"},
        {code: "9001Z", label: "9001Z - Arts du spectacle vivant"},
        {code: "9002Z", label: "9002Z - Activités de soutien au spectacle vivant"},
        {code: "9003A", label: "9003A - Création artistique relevant des arts plastiques"},
        {code: "9003B", label: "9003B - Autre création artistique"},
        {code: "9004Z", label: "9004Z - Gestion de salles de spectacles"},
        {code: "9101Z", label: "9101Z - Gestion des bibliothèques et des archives"},
        {code: "9102Z", label: "9102Z - Gestion des musées"},
        {code: "9103Z", label: "9103Z - Gestion des sites et monuments historiques et des attractions touristiques similaires"},
        {code: "9104Z", label: "9104Z - Gestion des jardins botaniques et zoologiques et des réserves naturelles"},
        {code: "9200Z", label: "9200Z - Organisation de jeux de hasard et d'argent"},
        {code: "9311Z", label: "9311Z - Gestion d'installations sportives"},
        {code: "9312Z", label: "9312Z - Activités de clubs de sports"},
        {code: "9313Z", label: "9313Z - Activités des centres de culture physique"},
        {code: "9319Z", label: "9319Z - Autres activités liées au sport"},
        {code: "9321Z", label: "9321Z - Activités des parcs d'attractions et parcs à thèmes"},
        {code: "9329Z", label: "9329Z - Autres activités récréatives et de loisirs"},
        {code: "9411Z", label: "9411Z - Activités des organisations patronales et consulaires"},
        {code: "9412Z", label: "9412Z - Activités des organisations professionnelles"},
        {code: "9420Z", label: "9420Z - Activités des syndicats de salariés"},
        {code: "9491Z", label: "9491Z - Activités des organisations religieuses"},
        {code: "9492Z", label: "9492Z - Activités des organisations politiques"},
        {code: "9499Z", label: "9499Z - Autres organisations fonctionnant par adhésion volontaire"},
        {code: "9511Z", label: "9511Z - Réparation d'ordinateurs et d'équipements périphériques"},
        {code: "9512Z", label: "9512Z - Réparation d'équipements de communication"},
        {code: "9521Z", label: "9521Z - Réparation de produits électroniques grand public"},
        {code: "9522Z", label: "9522Z - Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin"},
        {code: "9523Z", label: "9523Z - Réparation de chaussures et d'articles en cuir"},
        {code: "9524Z", label: "9524Z - Réparation de meubles et d'équipements du foyer"},
        {code: "9525Z", label: "9525Z - Réparation d'articles d'horlogerie et de bijouterie"},
        {code: "9529Z", label: "9529Z - Réparation d'autres biens personnels et domestiques"},
        {code: "9601A", label: "9601A - Blanchisserie-teinturerie de gros"},
        {code: "9601B", label: "9601B - Blanchisserie-teinturerie de détail"},
        {code: "9602A", label: "9602A - Coiffure"},
        {code: "9602B", label: "9602B - Soins de beauté"},
        {code: "9603Z", label: "9603Z - Services funéraires"},
        {code: "9604Z", label: "9604Z - Entretien corporel"},
        {code: "9609Z", label: "9609Z - Autres services personnels nca"},
        {code: "9700Z", label: "9700Z - Activités des ménages en tant qu'employeurs de personnel domestique"},
        {code: "9810Z", label: "9810Z - Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre"},
        {code: "9820Z", label: "9820Z - Activités indifférenciées des ménages en tant que producteurs de services pour usage propre"},
        {code: "9900Z", label: "9900Z - Activités des organisations et organismes extraterritoriaux"}
    ];
}
