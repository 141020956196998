import { React, useEffect, useState, fileDownload, DefaultLayout } from 'commons';
import BarLoader from "react-spinners/ClipLoader";
import { useList, usePosition } from 'shared/hooks';
import LegifranceApi from 'api/Legifrance';
import { denyIfCantPerform, preventDefault } from 'shared/services';
import { ListComponent, FilterComponent, LoadButton } from 'shared/components';
import LegifranceManual from 'views/legifrance/Manual';

export default function LegifranceList(props) {

    denyIfCantPerform(props, "legifrance:list");

    const [exporting, setExporting] = useState(false);
    const [initializePosition, setPosition] = usePosition("legifrance-list");
    
    const columns = [
        {id: 'start_at', title: 'Début de l\'import', format: 'datetime'},
        {id: 'finish_at', title: 'Fin de l\'import', format: 'datetime'},
        {id: 'files', title: 'Fichiers', render: (row) => <span>{row.files.join(", ")}</span>, sortable: false},
        {id: 'mode', title: 'Type de lancement', render: (row) => <span>{row.mode === 0 ? "Automatique" : "Manuel"}</span>},
        {id: 'status', title: 'Statut', render: (row) => <span>
            {row.status === 0 && "En attente"}
            {row.status === 1 && "Succès"}
            {row.status === 2 && "Erreur"}
            {row.status === 4 && "En cours"}
        </span>},
        {id: 'action', title: '', sortable: false, render: (row) => <ul className="actions">
            <li>
                {exporting !== row.id && <a title="Voir le journal" href={`/legifrance/${row.id}`} onClick={(e) => showLog(e, row.id)}>
                    <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                </a>}
                {exporting === row.id && <BarLoader color="#5bad27" loading={true} size={15} css="display:block;margin:0px auto;" />}
            </li>
        </ul>}
    ];

    const filterType = {start_at: {type: "range", fields: ["start_at"]}};

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("import-list", LegifranceApi, null, "start_at");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    function load() {
        submitSearch(filterType, () => initializePosition());
    }

    function showLog(event, id) {
        event.preventDefault();
        setExporting(id);

        LegifranceApi
            .log(id)
            .then(log => {
                setExporting(false);
                fileDownload(log, "journal-import-" + id + ".log")                
            }
        );
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E13" title="Historique des imports Légifrance">
            <h1>Historique des imports Légifrance</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    type="daterange"
                                    label="Date"
                                    name="start_at"
                                    value={criterias.start_at}
                                    onChange={value => addCriteria("start_at", value)}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-import" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <LoadButton id="search-import" type="submit" label="Rechercher" loading={loading} />
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent
                        id="imports"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                    />
                </form>
            </div>
            <LegifranceManual onSuccess={refresh} />
        </DefaultLayout>
    );
}
