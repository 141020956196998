import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

import DefaultLayout from "layouts/DefaultLayout";
import Can from "shared/services/Can";
import ConfigurationApi from "shared/api/Configuration";
import HtmlComponent from "shared/components/HtmlComponent";

export default function Home() {

    const [message, setMessage] = useState("");

    useEffect(() => {
        ConfigurationApi
            .get("message_home_bo")
            .then(c => setMessage(c.value));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DefaultLayout title="Page d'accueil" contentWrapperClassName="content-wrapper bg-gris-10">
            <h1 className="main-title">Bienvenue sur l'administration de Pilot Veille</h1>
            {message && <div className="row"><div className="col-md-12"><HtmlComponent>{message}</HtmlComponent></div></div>}
            <div className="row grid">
                <Can perform="sheet:dashboard" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-dashboard" to="/dashboard" className="padding">
                            <i className="icon-tasks" aria-hidden="true"></i>
                            Tableau de bord des fiches
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="sheet:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-sheet-list" to="/sheets" className="padding">
                            <i className="icon-nav-recherche-texte" aria-hidden="true"></i>
                            Recherche avancée
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="referential:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-referentials" to="/referentials" className="padding">
                            <i className="icon-nav-gestion-categories" aria-hidden="true"></i>
                            Gestion des référentiels
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="push:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-push" to="/push" className="padding">
                            <i className="icon-accueil-dernieres-fiches" aria-hidden="true"></i>
                            Push consultant
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="sheetaffected:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-sheet-affected-list" to="/sheetsaffected" className="padding">
                            <i className="icon-th-list" aria-hidden="true"></i>
                            Fonds documentaire
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="sheet:private:write" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-sheet-affected-list" to="/sheets/private/new" className="padding">
                            <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>
                            Créer une fiche privée
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="account:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-accounts" to="/accounts" className="padding">
                            <i className="icon-nav-arbo-point-veille" aria-hidden="true"></i>
                            Gestion des comptes
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="externaluser:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-externalusers" to="/externalusers" className="padding">
                            <i className="icon-nav-utilisateur-externe" aria-hidden="true"></i>
                            Gestion des utilisateurs externes
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="internaluser:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-internalusers" to="/internalusers" className="padding">
                            <i className="icon-nav-utilisateur-interne" aria-hidden="true"></i>
                            Gestion des utilisateurs internes
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="dispatch:write" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-dispatch" to="/dispatchs/new" className="padding">
                            <i className="icon-nav-ma-veille" aria-hidden="true"></i>
                            Générer une dépêche
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="configuration:write" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-configurations" to="/configurations" className="padding">
                            <i className="icon-nav-mon-plan-actions" aria-hidden="true"></i>
                            Configuration
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} /> 
                <Can perform="documentaryspace:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-documentaryspace" to="/documentaryspace" className="padding">
                            <i className="icon-nav-espace-documentaire" aria-hidden="true"></i>
                            Espace documentaire
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="hotline:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-hotline" to="/hotlines" className="padding">
                            <i className="icon-nav-ma-hotline" aria-hidden="true"></i>
                            Hotline
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="connectionhistory:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-connectionhistory" to="/connectionhistory" className="padding">
                            <i className="icon-nav-historique" aria-hidden="true"></i>
                            Historique des connexions
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />                
                <Can perform="legifrance:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-legifrance" to="/legifrance" className="padding">
                            <i className="icon-documentaire-jurisprudence" aria-hidden="true"></i>
                            Import Légifrance
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />                
                <Can perform="maillog:list" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-maillog" to="/maillogs" className="padding">
                            <i className="icon-mail" aria-hidden="true"></i>
                            Historique des emails
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
                <Can perform="conformityquizz:write" yes={() => (<div className="col-md-3">
                    <div className="contenu">
                        <NavLink id="menu-home-conformityquizz" to="/conformityquizz" className="padding">
                            <i className="icon-documentaire-jurisprudence" aria-hidden="true"></i>
                            Conformité par questionnaire
                            &nbsp;<i className="icon-documentaire-lien" aria-hidden="true"></i>
                        </NavLink>
                    </div>
                </div>)} />
            </div>
        </DefaultLayout>
    );
}
