import { React } from 'commons';
import WatchsiteUtils from 'shared/services/WatchsiteUtils';

export default function PerimeterSelectionWatchsite(props) {

    const isActive = WatchsiteUtils.isActive(props.watchsite);

    return <li className="watchsite">
        <input
            type="checkbox"
            id={`watchsite-${props.watchsite.id}`}
            checked={props.parentChecked || props.perimeter.includes("watchsite:" + props.watchsite.id)}
            onChange={(e) => props.updatePerimeter("watchsite:" + props.watchsite.id, e)}
        />
        <label htmlFor={`watchsite-${props.watchsite.id}`}>
            {props.watchsite.label}
            {!isActive && (<i className="icon-autres-temps-depasse color-rouge" title="Point de veille expiré ou désactivé"></i>)}
            {parseInt(props.watchsite.affectation_mode) === 1 ? " (Envoi différé)" : ""}
        </label>        
    </li>;
}