import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';

const PushApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("pushs/searches", query, from, size, sorting, direction);
    },

    excludeSheets: function(sheets) {
        return ApiRequest.post("pushs/exclude", {"sheets": sheets}).then(response => response.data);
    },

    addSheets: function(sheets) {
        return ApiRequest.post("pushs/add", {"sheets": sheets}).then(response => response.data);
    },

    affectations: function(sheet, perimeter = null) {
        if (perimeter) {
            return ApiRequest.get("pushs/" + sheet + "/affectations?perimeter=" + perimeter.join(",")).then(response => response.data);
        } else {
            return ApiRequest.get("pushs/" + sheet + "/affectations").then(response => response.data);
        }
    },

    absences: function(sheet) {
        return ApiRequest.get("pushs/" + sheet + "/absences").then(response => response.data);
    }
}
export default PushApi;