export const STATUS_TODO = 1; // after deferred assignment, duplication, and addition from E22
export const STATUS_TOSENT = 2; // manual status for consultant in E22
export const STATUS_SENT_BY_APAVE = 3; // affectation OK
export const STATUS_ADD_BY_CUSTOMER = 4; // affectation OK
export const STATUS_ARCHIVED = 5; // affectation NOK

export const AFFECTATION_STATUS = {
    [STATUS_TODO]: "A traiter",
    [STATUS_TOSENT]: "A envoyer",
    [STATUS_SENT_BY_APAVE]: "Affectée par Apave",
    [STATUS_ADD_BY_CUSTOMER]: "Ajoutée par client",
    [STATUS_ARCHIVED]: "Archiver",
}

export function getAffectationStatus() {
    let options = [];
    for (const [key, value] of Object.entries(AFFECTATION_STATUS)) {
        options.push({
            value: parseInt(key), 
            label: value,
        });
    }
    return options;
}

export function getAffectationStatusLabel(status) {
    return AFFECTATION_STATUS[status];
}
