import { React, useEffect, useState, toast, _ } from 'commons';
import axios from 'axios';
import { useSecurity } from 'shared/hooks';
import { AccountApi } from 'shared/api';
import PerimeterSelectionAccount from 'views/push/Perimeter/Form//PerimeterSelectionAccount';

export default function PerimeterSelectionForm(props) {

    const [user] = useSecurity();
    const [accounts, setAccounts] = useState([]);
    
    const watchsiteMapper = (w, a) => {
        return {
            "is_node": false,
            "is_watchsite": true,
            "label": w.label,
            "id": w.id,
            "consultants": w.consultants,
            "affectation_mode": w.affectation_mode,
            "subscription_begin": w.subscription_begin,
            "subscription_end": w.subscription_end,
            "active": w.active,
            "account": a,
        };
    };
    const nodeMapper = (n, a) => {
        let children = _.sortBy(n.childs, n => n.label.toLowerCase()).map(item => nodeMapper(item, a));
        children = children.concat(_.sortBy(n.watchsites, w => w.label.toLowerCase()).map(item => watchsiteMapper(item, a)));
        return {
            "is_node": true,
            "is_watchsite": false,
            "label": n.label,
            "id": n.id,
            "children": children,
            "account": a
        };
    };
    const cleanTree = (t) => {
        const cleanedTree = [];
        t.forEach(item => {
            if (item.is_node) {
                item.children = cleanTree(item.children);
                if (item.children.length > 0) {
                    cleanedTree.push(item);
                }
            } else if (item.is_watchsite && item.consultants.includes(user.id)) {
                cleanedTree.push(item);
            }
        });
        return cleanedTree;
    }

    function updateAllPerimeter(event) {
        const newPerimeter = [];
        const newAccountsSelection = [];

        if (event.target.checked) {
            accounts.forEach(item => {
                newPerimeter.push("account:" + item.account.id);
                newAccountsSelection.push(item.account);
            });
        }

        if (props.setAccountsSelection) {
            props.setAccountsSelection(newAccountsSelection);
        }
        props.setPerimeter(newPerimeter);
    }

    function updatePerimeter(value, event) {
        const newPerimeter = [];
        const newAccountsSelection = [];

        const addNodeToPerimeter = (n) => {
            newPerimeter.push("node:" + n.id);
            newAccountsSelection.push(n.account);
            n.children.forEach(treeItem => {
                if (treeItem.is_node) {
                    addNodeToPerimeter(treeItem);
                }
                if (treeItem.is_watchsite) {
                    addWatchsiteToPerimeter(treeItem);
                }
            });
        }

        const addWatchsiteToPerimeter = (w) => {
            newPerimeter.push("watchsite:" + w.id);
            newAccountsSelection.push(w.account);
        }

        const updateNodePerimeter = (n, parentChecked = false) => {
            const itemValue = "node:" + n.id;

            if (value === itemValue && event.target.checked) {
                // on le coche, pas besoin de traiter les enfants
                newPerimeter.push(itemValue);
                newAccountsSelection.push(n.account);
                return 1;
            }

            if (value === itemValue && !event.target.checked) {
                // on le décoche, pas besoin de traiter les enfants
                // mais on doit indiquer au parent qu'on force sa valeur décocher
                return -1;
            }

            if (props.perimeter.includes(itemValue) || parentChecked) {
                // déjà cocher, on doit traité les enfants pour si value fait parti des enfants
                let treeItemState = null;
                const uncheckedChilds = [];
                n.children.forEach(treeItem => {
                    if (treeItem.is_node) {                        
                        treeItemState = updateNodePerimeter(treeItem, props.perimeter.includes(itemValue) || parentChecked);
                        if (treeItemState === -1) {
                            uncheckedChilds.push("node:" + treeItem.id);
                        }
                    }
                    if (treeItem.is_watchsite) {
                        treeItemState = updateWatchsitePerimeter(treeItem);                        
                        if (treeItemState === -1) {
                            uncheckedChilds.push("watchsite:" + treeItem.id);
                        }
                    }
                });

                if (uncheckedChilds.length === 0) {
                    // aucun enfant décoché, le parent reste coché
                    if (props.perimeter.includes(itemValue)) {
                        newPerimeter.push(itemValue);
                        newAccountsSelection.push(n.account);
                    }
                    return 1;
                } else {
                    // un enfant a été décoché, on doit forcer ses freres qui doivent resté coché
                    n.children.forEach(treeItem => {
                        if (treeItem.is_node) {
                            if (!uncheckedChilds.includes("node:" + treeItem.id)) {
                                addNodeToPerimeter(treeItem);
                            }
                        }
                        if (treeItem.is_watchsite) {
                            if (!uncheckedChilds.includes("watchsite:" + treeItem.id)) {
                                addWatchsiteToPerimeter(treeItem);
                            }
                        }
                    });
                    return -1;
                }                
            }

            if (!props.perimeter.includes(itemValue)) {
                // autres cas, on fait rien, on parcours les enfants
                n.children.forEach(treeItem => {
                    if (treeItem.is_node) {
                        updateNodePerimeter(treeItem);
                    }
                    if (treeItem.is_watchsite) {
                        updateWatchsitePerimeter(treeItem);
                    }
                });
                return 2;
            }

            // ne devrait pas arrivé
            return 3;
        };

        const updateWatchsitePerimeter = (w) => {
            const itemValue = "watchsite:" + w.id;

            if (value === itemValue && event.target.checked) {
                // on le coche, pas besoin de traiter les enfants
                newPerimeter.push(itemValue);
                newAccountsSelection.push(w.account);
                return 1;
            }

            if (value === itemValue && !event.target.checked) {
                // on le décoche, pas besoin de traiter les enfants
                // mais on doit indiquer au parent qu'on force sa valeur décocher
                return -1;
            }

            if (props.perimeter.includes(itemValue)) {
                // il était coché et il le reste
                newPerimeter.push(itemValue);
                newAccountsSelection.push(w.account);
                return 1;
            }

            if (!props.perimeter.includes(itemValue)) {
                // il était pas coché, on fait rien
                return 2;
            }

            // ne devrait pas arrivé
            return 3;
        };

        accounts.forEach(item => {
            const itemValue = "account:" + item.account.id;

            if (value === itemValue && event.target.checked) {
                // on le coche, pas besoin de traiter les enfants
                newPerimeter.push(itemValue);
                newAccountsSelection.push(item.account);
            }

            if (value !== itemValue && props.perimeter.includes(itemValue)) {
                // déjà cocher, on doit traité les enfants pour si value fait parti des enfants

                const uncheckedChilds = [];
                item.tree.forEach(treeItem => {
                    if (treeItem.is_node) {
                        if (updateNodePerimeter(treeItem, true) === -1) {
                            uncheckedChilds.push("node:" + treeItem.id);
                        }
                    }
                    if (treeItem.is_watchsite) {
                        if (updateWatchsitePerimeter(treeItem) === -1) {
                            uncheckedChilds.push("watchsite:" + treeItem.id);
                        }
                    }
                });

                if (uncheckedChilds.length === 0) {
                    // aucun enfant décoché, le parent reste coché
                    newPerimeter.push(itemValue);
                    newAccountsSelection.push(item.account);
                } else {
                    // un enfant a été décoché, on doit forcer ses freres qui doivent resté coché
                    item.tree.forEach(treeItem => {
                        if (treeItem.is_node) {
                            if (!uncheckedChilds.includes("node:" + treeItem.id)) {
                                addNodeToPerimeter(treeItem);
                            }
                        }
                        if (treeItem.is_watchsite) {
                            if (!uncheckedChilds.includes("watchsite:" + treeItem.id)) {
                                addWatchsiteToPerimeter(treeItem);
                            }
                        }
                    });
                }
            }

            if (!props.perimeter.includes(itemValue)) {
                // autres cas, on fait rien, on parcours les enfants
                item.tree.forEach(treeItem => {
                    if (treeItem.is_node) {
                        updateNodePerimeter(treeItem);
                    }
                    if (treeItem.is_watchsite) {
                        updateWatchsitePerimeter(treeItem);
                    }
                });
            }  
        });

        if (props.setAccountsSelection) {
            props.setAccountsSelection(_.uniqBy(newAccountsSelection, a => a.id));
        }
        props.setPerimeter(newPerimeter);
    }

    function load() {
        const query = {"bool":{"should":[
            {"match":{"pilot_consultants": user.id}},
            {"match":{"associate_consultants": user.id}}
        ]}};

        if (props.restrictedAccount) {
            query.bool.should.push({"match":{"id": props.restrictedAccount}});
        }

        AccountApi
            .search(query, 0, 99999, "name", "ASC")
            .then(([resultsAccounts]) => {
                const treeRequests = [];
                const newTree = [];

                resultsAccounts.forEach(account => {
                    if (props.restrictedAccount === null || props.restrictedAccount === account.id) {
                        treeRequests.push(
                            AccountApi
                                .getConsultantTree(account.id)
                                .then(accountTree => {
                                    const a = {"id": account.id, "name": account.name};
                                    newTree.push({
                                        "account": a,
                                        "tree": cleanTree(
                                                _.sortBy(accountTree.nodes, n => n.label.toLowerCase()).map(item => nodeMapper(item, a))
                                            ).concat(
                                                _.sortBy(accountTree.watchsites, w => w.label.toLowerCase()).map(item => watchsiteMapper(item, a))
                                            )                                        
                                    });
                                })
                        );
                    }                    
                });
                
                axios.all(treeRequests)
                    .then(() => setAccounts(_.sortBy(newTree, a => a.account.name.toLowerCase())))
                    .catch(() => toast.error("Impossible de récupérer vos comptes."));
            })
            .catch(() => toast.error("Impossible de récupérer vos comptes."));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, [user.id, props.restrictedAccount]);

    return <ul className="arborescence accounttree no-arrow offset-50px">
        <li>
            <input id="select-all" type="checkbox" onChange={(e) => updateAllPerimeter(e)} /><label htmlFor="select-all">Tout sélectionner</label>
            <ul>
                {accounts.map(item => <React.Fragment key={item.account.id}>
                    <PerimeterSelectionAccount item={item} perimeter={props.perimeter} updatePerimeter={updatePerimeter} />
                </React.Fragment>)}
            </ul>
        </li>
    </ul>;
}

PerimeterSelectionForm.defaultProps = {
    restrictedAccount: null,
};
