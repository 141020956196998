import { React, Prompt, Link, DefaultLayout, useContext, useEffect, useState, toast, fileDownload, _ } from 'commons';
import { denyIfCantPerform, compileErrorsFromResponse, confirm } from 'shared/services';
import { ToggableBlocComponent, FieldComponent, LoadButton, PageLoadingComponent, PreviewFieldComponent } from "shared/components";
import { useToggler, useForm, usePosition } from "shared/hooks";
import { SheetApi } from "shared/api";
import { ReferentialContext } from 'shared/stores/Referential';
import { REFERENTIAL_DISPATCH_GROUP } from 'shared/data';
import DispatchApi from 'api/Dispatch';
import DispatchFormListSheets from 'views/dispatch/form/ListSheets';
import DispatchFormAddSheets from 'views/dispatch/form/AddSheets';
import axios from 'axios';

export default function DispatchForm(props) {
    
    denyIfCantPerform(props, "dispatch:write");

    localStorage.setItem("sheetFormReferer", document.location.pathname);

    const [initializePosition, setPosition] = usePosition("dispatch-form-" + (props.match.params.id ? props.match.params.id : "new"));
    const [referentialContext] = useContext(ReferentialContext);
    const [groupOrder, setGroupOrder] = useState([]);
    const [saving, setSaving] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [sheets, setSheets] = useState({});
    const [loading, setLoading] = useState(true);
    const [togglers, toggle] = useToggler({'configuration': true, 'group': true, 'list_sheets': true});
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications, setHasModifications] = useForm({id: null, number: "", introduction: ""});
    const defaultGroup = parseInt(process.env.REACT_APP_DEFAULT_DISPATCH_GROUP);

    function load() {
        if (props.match.params.id) {
            axios.all([
                DispatchApi.get(props.match.params.id),
                DispatchApi.listSheets(props.match.params.id)
            ])
            .then(axios.spread((newData, [sheetsResult]) => {
                let newSheets = {};
                sheetsResult.forEach(sheet => {
                    if (!newSheets[sheet.dispatch_group ? sheet.dispatch_group : 0]) {
                        newSheets[sheet.dispatch_group ? sheet.dispatch_group : 0] = [];
                    }
                    newSheets[sheet.dispatch_group ? sheet.dispatch_group : 0].push(sheet);
                });
                setSheets(newSheets);
                setData(newData);
                setGroupOrder(newData.groups_ordering);
                
                _.delay(initializePosition, 500);
            }))
            .finally(() => setLoading(false));

        } else {
            let indexOrder = 1;
            let newGroupOrder = [{id: 0, position: indexOrder}];            
            referentialContext[REFERENTIAL_DISPATCH_GROUP].forEach(group => {
                indexOrder++;
                newGroupOrder.push({id: group.id, position: indexOrder});                
            });
            setGroupOrder(newGroupOrder);
    
            DispatchApi
                .listAvailableSheets()
                .then(([results]) => {
                    let newSheets = {};
                    results.forEach(sheet => {
                        if (!newSheets[sheet.dispatch_group ? sheet.dispatch_group : 0]) {
                            newSheets[sheet.dispatch_group ? sheet.dispatch_group : 0] = [];
                        }
                        newSheets[sheet.dispatch_group ? sheet.dispatch_group : 0].push(sheet);
                    });
                    setSheets(newSheets);

                    _.delay(initializePosition, 500);
                })
                .finally(() => setLoading(false));
        }
    }

    function generate(event) {
        event.preventDefault();
        setGenerating(true);
        DispatchApi
            .generate(data.id)
            .then(file => {
                setValue("generated_at", (new Date()).toISOString());
                fileDownload(file, data.number + ".pdf");
            })
            .finally(() => setGenerating(false));
    }

    function download(event) {
        event.preventDefault();
        setDownloading(true);
        DispatchApi
            .download(data.id)
            .then(file => fileDownload(file, data.number + ".pdf"))
            .finally(() => setDownloading(false));
    }

    function save(event) { 
        event.preventDefault();
        setSaving(true);

        let sheetToSend = [];
        Object.keys(sheets).forEach(group => { sheetToSend = sheetToSend.concat(sheets[group]); });
        sheetToSend.forEach((s, i) => {
            sheetToSend[i] = {
                id: s.id,
                dispatch_position: s.dispatch_position,
                dispatch_group: s.dispatch_group,
                dispatch_pagebreak: s.dispatch_pagebreak,
                dispatch_image: s.dispatch_image && s.dispatch_image.hash ? s.dispatch_image.hash : s.dispatch_image,
            };
        });
       
        DispatchApi
            .save({
                id: data.id,
                introduction: data.introduction,
                number: data.number,
                groups_ordering: groupOrder,
                sheets: sheetToSend
            })
            .then(newData => {
                setData(newData);
                toast.success("Enregistrement effectué.");
                reloadOrClose(event, props, "dispatchs", newData.id);
            })
            .catch(error => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);                    
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            })
            .finally(() => setSaving(false));
        
    }

    function updateGroupOrder(value, groupId) {
        let newGroupOrder = [...groupOrder];
        newGroupOrder.forEach(group => {
            if (group.id === groupId) {
                group.position = value;
            }
        });

        setGroupOrder(_.orderBy(newGroupOrder, ['position'], ['asc']));
        setHasModifications(true);
    }

    function updateSheetPosition(sheet, position, updateSorting = false) {
        let newSheets = {...sheets};
        let groupId = sheet.dispatch_group ? sheet.dispatch_group : "0";

        position = parseInt(position);
        if (position < 0) {
            position = 0;
        }

        newSheets[groupId].forEach(s => {
            if (s.id === sheet.id) {
                s.dispatch_position = position;
            }
        });
        if (updateSorting) {
            newSheets[groupId].sort((a, b) => a.dispatch_position < b.dispatch_position ?  -1 : 1);
        }
        setSheets(newSheets);
        setHasModifications(true);
    }

    function updateSheetGroup(sheet, group) {
        let newSheets = {...sheets};
        let groupId = sheet.dispatch_group ? sheet.dispatch_group : "0";
        newSheets[groupId] = newSheets[groupId].filter(s => s.id !== sheet.id);
        if (!newSheets[group]) {
            newSheets[group] = [];
        }
        sheet.dispatch_group = group;
        sheet.dispatch_position = newSheets[group].length + 1;
        newSheets[group].push(sheet);
        setSheets(newSheets);
        setHasModifications(true);
    }

    function updateSheetPagebreak(sheet, value) {
        let newSheets = {...sheets};
        newSheets[sheet.dispatch_group ? sheet.dispatch_group : "0"].forEach(s => {
            if (s.id === sheet.id) {
                s.dispatch_pagebreak = value;
            }
        });
        setSheets(newSheets);
        setHasModifications(true);
    }

    function updateSheetImage(sheet, value) {
        let newSheets = {...sheets};
        newSheets[sheet.dispatch_group ? sheet.dispatch_group : "0"].forEach(s => {
            if (s.id === sheet.id) {
                s.dispatch_image = value;
            }
        });
        setSheets(newSheets);
        setHasModifications(true);
    }

    function getGroupLabel(group) {
        if (group === 0) {
            return "Sans regroupement";
        }

        let label = "";
        referentialContext[REFERENTIAL_DISPATCH_GROUP].forEach(item => {
            if (parseInt(item.id) === parseInt(group)) {
                label = item.value;
            }
        })

        return label;
    }

    function attachSheets(sheetsToAttached) {        
        const requests = [];
        const newSheets = [];
        let newPosition = sheets[0] ? sheets[0].length + 1 : 1;
        sheetsToAttached.forEach(s => {
            let attach = true;
            _.forEach(sheets, groupSheets => {
                _.forEach(groupSheets, currentSheet => {
                    if (currentSheet.id === s) {
                        attach = false;
                    }
                })
            });

            if (attach) {
                requests.push(
                    SheetApi
                        .get(s)
                        .then(sheet => {
                            newSheets.push({
                                id: sheet.id,
                                title: sheet.title,
                                dispatch_image: sheet.dispatch_image,
                                dispatch_group: defaultGroup,
                                dispatch_position: newPosition,
                                dispatch_pagebreak: false,
                            });
                            newPosition++;
                        })
                );
            } else {
                toast.warning("Fiche " + s + " déjà présente");
            }
            
        })

        axios
            .all(requests)
            .then(() => {
                let _sheets = {...sheets};
                if (!_sheets[defaultGroup]) {
                    _sheets[defaultGroup] = [];
                }
                newSheets.forEach(sheet => {
                    _sheets[defaultGroup].push(sheet);
                })
                setSheets(_sheets);
                setHasModifications(true);
            });    
    }

    function unattachSheets(sheetsToUnattached) {
        confirm("Confirmez l'exclusion de la fiche ?", function() {
            const newSheets = {};
            groupOrder.forEach(group => {
                newSheets[group.id] = [];
                if (sheets[group.id]) {
                    sheets[group.id].forEach((s) => {
                        if (!sheetsToUnattached.includes(s.id)) {
                            newSheets[group.id].push(s);
                        }
                    });
                }
            });
            setSheets(newSheets);
            setHasModifications(true);
            toast.info("Fiche exclue. Pensez a bien sauvegarder.");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    if (loading) {
        return <PageLoadingComponent label="Générer une dépêche" />
    }

    return <DefaultLayout onScroll={(position) => setPosition(position)} screen="E18" title="Générer une dépêche">
        <Prompt
            when={formHasModifications()}
            message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
        />
        <section>
            <h1>Générer une dépêche</h1>
            {props.match.params.id && data.generated_at && new Date(data.updated_at) > new Date(data.generated_at) && <>
                <div className="alert alert-warning" role="alert">Le PDF n'est pas à jour par rapport aux données de la dépêche. Merci de regénérer le PDF.</div>
            </>}
            <form onSubmit={save} id="form-account" className="form">
                <ToggableBlocComponent label="Configuration de la dépêche" id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                    <FieldComponent
                        name="number"
                        label="Numéro de la dépêche *"
                        error={errors.number}
                        value={data.number}
                        onChange={value => setValue("number", value)}
                    />
                    <FieldComponent
                        type="textarea"
                        rte
                        name="introduction"
                        label="Introduction"
                        error={errors.introduction}
                        value={data.introduction}
                        onChange={value => setValue("introduction", value)}
                    />
                    <PreviewFieldComponent label="Nombre de fiche(s) dans la dépêche">
                        {_.flatMap(sheets, s => s).length}
                    </PreviewFieldComponent>
                </ToggableBlocComponent>
                <ToggableBlocComponent label="Configuration des regroupements" id="group" toggled={togglers["group"]} toggle={toggle}>
                    {groupOrder.map(group => <>
                        <FieldComponent
                            className="field small"
                            name={`order_group_${group.id}`}
                            label={getGroupLabel(group.id)}
                            value={group.position}
                            onChange={value => updateGroupOrder(value, group.id)}
                        />
                    </>)}
                </ToggableBlocComponent>
                {props.match.params.id && <DispatchFormAddSheets attachSheets={attachSheets} currentSheets={sheets} /> }
                <DispatchFormListSheets 
                    sheets={sheets}
                    updateSheetPosition={updateSheetPosition}
                    updateSheetGroup={updateSheetGroup}
                    updateSheetPagebreak={updateSheetPagebreak}
                    updateSheetImage={updateSheetImage}
                    groupOrder={groupOrder}
                    getGroupLabel={getGroupLabel}
                    unattachSheets={unattachSheets}
                />
                <section className="row">
                    <div className="col">
                        <Link id="goto-dispatch-list" to="/dispatchs" className="btn btn-bleu-4">Consulter anciennes dépêches</Link>
                    </div>
                    <div className="col text-right">                    
                        <LoadButton 
                            loading={saving} 
                            label="Sauvegarder" 
                            name="save" 
                            id="save-dispatch"
                        />
                        {props.match.params.id && <>
                            <LoadButton 
                                loading={generating} 
                                type="button"
                                onClick={generate}
                                label="Génerer" 
                                name="generate" 
                                id="generate-dispatch"
                            />
                            {data.generated_at && <LoadButton 
                                loading={downloading} 
                                type="button"
                                onClick={download}
                                label="Visualiser" 
                                name="download" 
                                id="download-dispatch"
                            />}                            
                        </>}
                    </div>
                </section>
            </form>
        </section>
    </DefaultLayout>
}