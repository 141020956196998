import { React, useState, useEffect, toast } from 'commons';
import { denyIfCantPerform, compileErrorsFromResponse, formatDate } from 'shared/services';
import DefaultLayout from "layouts/DefaultLayout";
import { AccountApi } from 'shared/api';
import { PageLoadingComponent, FieldComponent, FileComponent, LoadButton, ToggableBlocComponent } from 'shared/components';
import { useToggler, useForm, useSecurity } from 'shared/hooks';
import { getConformityLabel } from 'data/SheetAffectedConformity';
import ConformityQuizzApi from 'api/ConformityQuizz';

export default function ConformityquizzForm(props) {

    denyIfCantPerform(props, "conformityquizz:write");

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [treeOptions, setTreeOptions] = useState([]);
    const [user,,,, isAdmin] = useSecurity();
    const [togglers, toggle] = useToggler({'form': true});
    const [data, errors, setErrors, setValue] = useForm();
    const [report, setReport] = useState("");
    const [success, setSuccess] = useState();

    function loadAccounts() {
        let querySearch = {};
        if (!isAdmin()) {
            querySearch = {
                "bool": {
                    "should": [
                        {"match": {"pilot_consultants": user.id}},
                        {"match": {"associate_consultants": user.id}}                        
                    ]
                }
            }
        }

        AccountApi
            .search(querySearch, 0, 10000, "name", "asc")
            .then(([results]) => setAccounts(results.map(r => {return {value: r.id, label: r.name};})))
            .finally(() => setLoading(false));
    }

    function loadTree() {
        if (data.account) {
            const watchsiteMapper = (watchsite) => {
                return {
                    "label": watchsite.label,
                    "value": watchsite.id,
                    "disabled": false,
                } 
            };
            const nodeMapper = (node) => {
                let children = node.childs.map(nodeMapper);
                children = children.concat(node.watchsites.map(watchsiteMapper));
                return {
                    "label": node.label,
                    "value": "node:" + node.id,
                    "children": children,
                    "disabled": true,
                }
            };
            const cleanTree = (t) => {
                const cleanedTree = [];
                t.forEach(item => {
                    if (item.children) {
                        // node
                        item.children = cleanTree(item.children);
                        if (item.children.length > 0) {
                            cleanedTree.push(item);
                        }    
                    } else {
                        // watchsite
                        cleanedTree.push(item);
                    }
                    
                });
                return cleanedTree;
            }

            if (isAdmin()) {
                AccountApi
                    .getTree(data.account)
                    .then(response => {
                        const cleanedOptions = cleanTree(response.nodes.map(nodeMapper))
                            .concat(response.watchsites.map(watchsiteMapper));

                        setTreeOptions(cleanedOptions)
                    })
                    .catch(() => toast.error("Impossible de récupérer l'arborescence."));
            } else {
                AccountApi
                    .getConsultantTree(data.account)
                    .then(response => {
                        const cleanedOptions = cleanTree(response.nodes.map(nodeMapper))
                            .concat(response.watchsites.map(watchsiteMapper));

                        setTreeOptions(cleanedOptions)
                    })
                    .catch(() => toast.error("Impossible de récupérer l'arborescence."));
            }

            
        } else {
            setTreeOptions([]);
        }
    }

    function submit(event) {
        event.preventDefault();
        setSaving(true);
        setReport("");

        ConformityQuizzApi
            .process({
                account: data.account,
                watchsite: data.watchsite,
                date: data.date,
                quizz: data.quizz ? data.quizz.hash : null,
            })
            .then((response) => {
                const newReport = [];
                if (response.report.success) {
                    toast.success("Données importées");
                    Object.values(response.report.updated_sheets).forEach(info => {
                        newReport.push("Fiche " + info.sheet + " mise à jour");
                        newReport.push("Date d'évaluation : " + formatDate(info.before_evaluation_date) + " vers " + formatDate(info.after_evaluation_date));
                        newReport.push("Conformité : " + getConformityLabel(info.before_conformity) + " vers " + getConformityLabel(info.after_conformity));
                    });
                    Object.values(response.report.actions).forEach(action => {
                        newReport.push("Nouvel action pour la fiche " + action.sheet + " : " + action.action);
                    });
                    Object.values(response.report.comments).forEach(comment => {
                        newReport.push("Nouveau commentaire pour la fiche " + comment.sheet + " : " + comment.comment);
                    });
                } else {
                    toast.warning("Des erreurs sont survenues");
                    Object.values(response.report.missing_sheets).forEach(sheet => {
                        newReport.push("Fiche " + sheet + " non affecté au point de veille");
                    });
                }

                setSuccess(response.report.success);
                setReport(newReport.join("\n"));
            })
            .catch(error => {
                setReport("");
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            })
            .finally(() => {
                setSaving(false);
                setValue("quizz", null);
            });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadAccounts, [user.id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadTree, [data.account, user.id]);

    if (loading) {
        return <PageLoadingComponent label="Conformité par questionnaire" />
    }

    return (
        <DefaultLayout screen="E48" title="Conformité par questionnaire">
            <h1>Conformité par questionnaire</h1>
            <section>
                <form className="form" id="form-configuration" onSubmit={submit}>
                    <ToggableBlocComponent label="Paramétrage" id="form" toggled={togglers["form"]} toggle={toggle}>
                        <FieldComponent
                            type="select"
                            name="account"
                            label="Compte *"
                            error={errors.account}
                            onChange={value => setValue("account", parseInt(value))}
                            value={data.account}
                            options={accounts}
                        />
                        {data.account && <FieldComponent
                            type="dropdown-tree-select"
                            name="watchsite"
                            label="Point de veille *"
                            error={errors.watchsite}
                            data={treeOptions}
                            value={data.watchsite}
                            onChange={(value) => setValue("watchsite", value)}                
                            mode="radioSelect"
                        />}
                        <FieldComponent
                            type="date"
                            name="date"
                            label="Date de l'audit"
                            value={data.date}
                            error={errors.date}
                            onChange={(value) => setValue("date", value)}
                        />
                        <FileComponent 
                            label="Fichier à importer *"
                            name="quizz"
                            type="file"
                            value={data.quizz}
                            error={errors.quizz}
                            onChange={value => setValue("quizz", value)}
                            extensions={[".csv"]}
                            upload={ConformityQuizzApi.upload}
                        />
                        {report && <FieldComponent
                            type="textarea"
                            name="report"
                            label="Rapport"
                            value={report}
                            disabled={true}
                            error={success ? null : " "}
                        />}
                    </ToggableBlocComponent>
                    <div className="row">
                        <div className="col text-right">
                            <LoadButton 
                                loading={saving}
                                label="Enregistrer" 
                                name="save" 
                                id="save-watchsite"
                            />
                        </div>
                    </div>
                </form>
            </section>
        </DefaultLayout>
    );
}
