import React, { useState, useEffect } from 'react';
import { Prompt } from "react-router-dom";
import { toast } from 'react-toastify';
import { denyIfCantPerform } from 'shared/services/Can';
import DefaultLayout from "layouts/DefaultLayout";
import FieldComponent from 'shared/components/FieldComponent';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import LoadButton from 'shared/components/LoadButton';
import ConfigurationApi from 'shared/api/Configuration';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";

export default function ConfigurationForm(props) {

    denyIfCantPerform(props, "configuration:write");

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [togglers, toggle] = useToggler({'configuration': true});
    const [data,,, setValue, setData,, formHasModifications, setHasModifications] = useForm();

    useEffect(() => {
        ConfigurationApi
            .list()
            .then(([results]) => {
                let d = {};
                results.forEach(c => {
                    d = Object.assign({}, d, {[c.code]: c.value});
                });
                setData(d);
                setLoading(false);
            })
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function submit(event) {
        event.preventDefault();
        setSaving(true);

        const configurations = [
            {"code": "import_legifrance", "value": data.import_legifrance},
            {"code": "message_503_bo", "value": data.message_503_bo},
            {"code": "message_503_fo", "value": data.message_503_fo},
            {"code": "message_home_bo", "value": data.message_home_bo},
            {"code": "message_home_fo", "value": data.message_home_fo},
        ];
        
        ConfigurationApi
            .bulkUpdate(configurations)
            .then(referential => {
                setSaving(false);
                setHasModifications(false);
                toast.success("Enregistrement effectué.");
            })
            .catch(error => {
                setSaving(false);
                toast.error(error.response.data.message);
            });
    }
    if (loading) {
        return <PageLoadingComponent label="Configuration" />
    }

    return (
        <DefaultLayout screen="E54" title="Configuration">
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>Configuration</h1>
            <section>
                <form className="form" id="form-configuration" onSubmit={submit}>
                    <ToggableBlocComponent label="Configuration" id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                        <FieldComponent 
                            label="Heure import Légifrance"
                            className="field small"
                            name="import_legifrance"
                            value={data.import_legifrance}
                            onChange={value => setValue("import_legifrance", value)}
                        >&nbsp;heures 00 minutes</FieldComponent>
                        <FieldComponent 
                            type="textarea"
                            rte={true}
                            label="Message de maintenance back-office"
                            name="message_503_bo"
                            value={data.message_503_bo}
                            onChange={value => setValue("message_503_bo", value)}
                        />
                        <FieldComponent 
                            type="textarea"
                            rte={true}
                            label="Message de maintenance front-office"
                            name="message_503_fo"
                            value={data.message_503_fo}
                            onChange={value => setValue("message_503_fo", value)}
                        />
                        <FieldComponent 
                            type="textarea"
                            rte={true}
                            label="Message d'accueil back-office"
                            name="message_home_bo"
                            value={data.message_home_bo}
                            onChange={value => setValue("message_home_bo", value)}
                        />
                        <FieldComponent 
                            type="textarea"
                            rte={true}
                            label="Message d'accueil front-office"
                            name="message_home_fo"
                            value={data.message_home_fo}
                            onChange={value => setValue("message_home_fo", value)}
                        />
                    </ToggableBlocComponent>
                    <div className="row">
                        <div className="col text-right">
                            <LoadButton 
                                loading={saving}
                                label="Enregistrer" 
                                name="save" 
                                id="save-watchsite"
                            />
                        </div>
                    </div>
                </form>
            </section>
        </DefaultLayout>
    );
}
