import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';

import DefaultLayout from "layouts/DefaultLayout";

import { useList } from 'shared/hooks/List';
import { usePosition } from 'shared/hooks/Position';

import UserApi from 'shared/api/User';
import InternalUserApi from 'shared/api/InternalUser';
import InvitationApi from 'shared/api/Invitation';

import { FileUtils, convertCriteriesToSearchQuery, preventDefault, confirm, Can, denyIfCantPerform, formatDate } from 'shared/services';

import ListComponent from 'shared/components/ListComponent';
import FilterComponent from 'shared/components/FilterComponent';
import LoadButton from 'shared/components/LoadButton';

import { getBoRoles, getRoleLabel } from 'shared/data/Roles';

export default function InternalUserList(props) {

    denyIfCantPerform(props, "internaluser:list");

    const [initializePosition, setPosition] = usePosition("internaluser-list");
    const [exporting, setExporting] = useState(false);
    const columns = [
        {id: 'email', title: 'Email'},
        {id: 'firstname', title: 'Prénom'},
        {id: 'lastname', title: 'Nom'},
        {id: 'city', title: 'Ville'},
        {id: 'roles', title: 'Profil', sortable: false, render: (row) => <>
            {row.roles && row.roles.map(role => 
                <React.Fragment key={role}>{role !== 'ROLE_USER' && <p key={role}>{getRoleLabel(role)}</p>}</React.Fragment>
            )}
        </>},
        {id: 'active', title: 'Actif', format: 'checksingle', className: 'text-center'},
        {id: 'last_connexion', title: 'Date de dernière connexion', format: 'datetime'},
        {id: 'invitation', title: 'Invitation', sortable: false, render: (row) => {            
            let validation = formatDate(row.validation_at, true);
            if (validation) {
                return <strong>Validé le {validation}</strong>;
            } else if (row.is_expired) {
                return <>Expiré le {formatDate(row.expire_at, true)}</>;
            } else if (Array.isArray(row.invitations) && row.invitations.length > 0) {
                let invitation = formatDate(row.invitations[row.invitations.length - 1], true);
                return (<>Invitation {row.invitations.length} / 4 du {invitation}</>);
            } else {
                let invitation = formatDate(row.invitation_at, true);
                return (<>Invité le {invitation}</>);
            }
        }},
        {id: 'action', title: '', sortable: false, render: (row) => {
            return (<ul className="actions">
                <Can perform="internaluser:read" data={row} yes={() => (<li><Link title="Modifier" to={`/internalusers/${row.id}`}><i className="icon-actions-modifier" aria-hidden="true"></i></Link></li>)} />
                <Can perform="internaluser:write" data={row} yes={() => (<li><Link title="Supprimer" onClick={(e) => deleteUser(e, row.id)} to={`/internalusers/delete/${row.id}`}><i className="icon-actions-supprimer" aria-hidden="true"></i></Link></li>)} />
                {row.active === 1 && (<Can perform="internaluser:write" data={row} yes={() => (<li><Link title="Désactiver" onClick={(e) => deactivateUser(e, row.id)} to={`/internalusers/deactivate/${row.id}`}><i className="icon-actions-desactiver-utilisateur" aria-hidden="true"></i></Link></li>)} />)}
                {_.isNil(row.validation_at) && <li><Link title="Relancer l'invitation" onClick={(e) => invite(e, row.id)} to={`/internalusers/invite/${row.id}`}>
                    <i className="icon-actions-relancer-utilisateur" aria-hidden="true"></i>
                </Link></li>}
            </ul>)
        }}
    ];

    const filterType = {
        keyword: {type: "wildcard", fields: ["email", "lastname", "firstname", "apave", "direction", "agency", "city"]},
        active: {type: "match", fields: ["active"]},
        roles: {type: "terms", fields: ["roles"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("internal-user-list", InternalUserApi, null, "lastname");

    useEffect(() => {
        submitSearch(filterType, () => initializePosition(), false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function deleteUser(event, user) {
        event.preventDefault();

        confirm("Confirmez vous la suppression de l'utilisateur ?", function() {
            setLoading(true);
            UserApi
                .delete(user)
                .then(response => {
                    setLoading(false);
                    toast.success("Utilisateur supprimé");
                    refresh();
                })
                .catch(error => console.log(error));
        });
    }

    function deactivateUser(event, user) {
        event.preventDefault();

        setLoading(true);

        UserApi
            .bulkUpdate([user], {"active": 0})
            .then(response => {
                setLoading(false);
                toast.success("Utilisateur désactivé");
                refresh();
            })
            .catch(error => console.log(error));
    }

    function exportUsers(event) {
        event.preventDefault();
        setExporting(true);

        InternalUserApi.export(convertCriteriesToSearchQuery(criterias, filterType))
            .then(data => {
                setExporting(false);
                fileDownload(data, FileUtils.getFilePrefix() + "-Utilisateurs-Apave.xlsx");
            })
            .catch(error => {
                setExporting(false);
                toast.error("Erreur durant la création de l'export.")
            });
    }

    function invite(event, user) {
        event.preventDefault();

        setLoading(true);

        InvitationApi
            .bulkCreate([user], "BO")
            .then(response => {
                toast.success(response.data.messages[0]);
                refresh();
            })
            .catch(error => { setLoading(false); toast.error(error.response.data.message) });
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E11" title="Liste des utilisateurs Apave">
            <h1>Liste des utilisateurs Apave</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    type="select"
                                    colClassName="col-md-4"
                                    label="Utilisateur actif"
                                    name="active"
                                    onChange={value => addCriteria("active", value)}
                                    clearable={true}
                                    value={criterias.active}
                                    options={[
                                        {value: "1", label: "Oui"},
                                        {value: "0", label: "Non"},
                                    ]}
                                />
                                <FilterComponent
                                    colClassName="col-md-4"
                                    label="Recherche libre"
                                    name="keyword"
                                    onChange={value => addCriteria("keyword", value)}
                                    value={criterias.keyword || ""}
                                />
                                <FilterComponent
                                    type="select"
                                    colClassName="col-md-4"
                                    label="Profil"
                                    name="roles"
                                    onChange={value => addCriteria("roles", value)}
                                    clearable={true}
                                    value={criterias.roles}
                                    options={getBoRoles()}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-internaluser" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-internaluser" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="internalusers"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                    >
                        <>
                            <Link id="new-internaluser" to="/internalusers/new" className="btn btn-primary h25 icon">
                                <i className="icon-boutons-creer-acces" aria-hidden="true"></i>&nbsp;Créer un utilisateur
                            </Link>
                            <LoadButton
                                loading={exporting}
                                onClick={exportUsers}
                                label="Exporter"
                                name="export-internalusers"
                                id="export-internalusers"
                                className="btn btn-primary h25"
                                iconClassName="icon-file-excel"
                            />
                        </>
                    </ListComponent>
                </form>
            </div>
        </DefaultLayout>
    );
}