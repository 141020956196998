import { React, useEffect, useState, Link, toast, fileDownload, DefaultLayout } from 'commons';
import { useList, usePosition, useSecurity } from 'shared/hooks';
import { AccountApi, ContractApi, WatchsiteApi, InternalUserApi } from 'shared/api';
import { FileUtils, Can, denyIfCantPerform, preventDefault, convertCriteriesToSearchQuery } from 'shared/services';
import { ListComponent, FilterComponent } from 'shared/components';

export default function AccountList(props) {

    denyIfCantPerform(props, "account:list");

    const [initializePosition, setPosition] = usePosition("account-list");
    const columns = [
        {
            id: 'logo', 
            title: 'Logo',
            sortable: false,
            className: 'text-center',
            format: 'image'
        },
        {id: 'name', title: 'Raison sociale'},
        {id: 'city', title: 'Ville'},
        {id: 'apave_pilot', title: 'Apave pilote'},
        {id: 'apave_feed', title: 'Apave qui alimente'},
        {
            id: 'consultants',
            title: 'Consultants',
            sortable: false,
            render: (row) => {
                const consultantFilter = (c) => {
                    const watchsites = c.watchsites && c.watchsites.length > 0 ? [...c.watchsites] : [];
                    return watchsites.filter(w => w.account === row.id).length > 0;
                };
                const pilotConsultants = row.pilot_consultants ? [...row.pilot_consultants].filter(consultantFilter) : [];
                const associateConsultants = row.associate_consultants ? [...row.associate_consultants].filter(consultantFilter) : [];
                
                return <>
                    {pilotConsultants.length > 0 && <>
                        <label>Consultant(s) pilote(s)</label>
                        <ul>
                            {pilotConsultants.map((consultant) => (
                                <li key={consultant.id}>{consultant.firstname} {consultant.lastname}</li>
                            ))}
                        </ul>
                    </>}
                    {associateConsultants.length > 0 && <>
                        <label>Consultant(s) associé(s)</label>                
                        <ul>
                            {associateConsultants.map((consultant) => (
                                <li key={consultant.id}>{consultant.firstname} {consultant.lastname}</li>
                            ))}
                        </ul>
                    </>}
                </>
            }
        },
        {id: 'next_expiration', title: 'Date de prochaine expiration', sortable: false, format: 'date'},
        {id: 'active', title: 'Actif', format: 'checksingle'},
        {id: 'is_start', title: 'Start', format: 'checksingle'},
        {
            id: 'action',
            title: '',
            sortable: false,
            render: (row) => { return (<ul className="actions">
                    <li><Link title="Modifier" to={`/accounts/${row.id}`}><i className="icon-actions-modifier" aria-hidden="true"></i></Link></li>
                    <li><Link title="Voir les utilisateurs" to={`/externalusers/?account=${row.id}`}><i className="icon-documentaire-module-prive-client" aria-hidden="true"></i></Link></li>
                </ul>)
            }
        }
    ];

    const filterType = {
        active: {type: "match", fields: ["active"]},
        keyword: {type: "wildcard", fields: ["name", "city", "zipcode", "address", "siren"]},
        pilot_consultants: {type: "match", fields: ["pilot_consultants", "associate_consultants"]},
        is_start: {type: "match", fields: ["is_start"]},
    };

    const [user] = useSecurity();
    const defaultCriterias = user.roles.includes("ROLE_CONSULTANT") ? {pilot_consultants: user.id} : {};
    const [consultants, setConsultants] = useState([{value: "", label: "Chargement"}]);
    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("account-list", AccountApi, null, "name", defaultCriterias);

    useEffect(() => {
        submitSearch(filterType, () => initializePosition(), false);

        InternalUserApi
            .search({"bool":{"must":[{"terms":{"roles":["ROLE_CONSULTANT"]}}]}})
            .then(([results]) => {
                let newconsultants = [];
                Object.entries(results).forEach(([index, user]) => {
                    newconsultants.push({"value": user.id, "label": user.lastname + " " + user.firstname});
                });
                setConsultants(newconsultants);
            })
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function exportAccounts(event) {
        setLoading(true);
        event.preventDefault();
        AccountApi.export(convertCriteriesToSearchQuery(criterias, filterType))
            .then(data => {
                setLoading(false);
                fileDownload(data, FileUtils.getFilePrefix() + "-Comptes.xlsx");
            })
            .catch(error => {
                setLoading(false);
                toast.error("Erreur durant la création de l'export.")
            });
    }

    function exportContracts(event) {
        setLoading(true);
        event.preventDefault();
        ContractApi.export(convertCriteriesToSearchQuery(criterias, filterType))
            .then(data => {
                setLoading(false);
                fileDownload(data, FileUtils.getFilePrefix() + "-Contrats.xlsx");
            })
            .catch(error => {
                setLoading(false);
                toast.error("Erreur durant la création de l'export.")
            });
    }

    function exportWatchsites(event) {
        setLoading(true);
        event.preventDefault();
        WatchsiteApi.export(convertCriteriesToSearchQuery(criterias, filterType))
            .then(data => {
                setLoading(false);
                fileDownload(data, FileUtils.getFilePrefix() + "-PointsDeVeille.xlsx");
            })
            .catch(error => {
                setLoading(false);
                toast.error("Erreur durant la création de l'export.")
            });
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E04" title="Liste des comptes">
            <h1>Liste des comptes</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    colClassName="col-md-4"
                                    type="select"
                                    label="Compte actif"
                                    name="active"
                                    onChange={value => addCriteria("active", value)}
                                    clearable={true}
                                    value={criterias.active}
                                    options={[
                                        {value: "1", label: "Oui"},
                                        {value: "0", label: "Non"},
                                    ]}
                                />
                                <FilterComponent
                                    colClassName="col-md-4"
                                    label="Recherche libre"
                                    name="keyword"
                                    onChange={value => addCriteria("keyword", value)}
                                    value={criterias.keyword || ""}
                                />
                                <FilterComponent
                                    colClassName="col-md-4"
                                    type="select"
                                    name="pilot_consultants"
                                    label="Consultants"
                                    clearable={true}
                                    onChange={value => addCriteria("pilot_consultants", value)}
                                    value={criterias.pilot_consultants}
                                    options={consultants}
                                />
                                <FilterComponent
                                    colClassName="col-md-4"
                                    type="select"
                                    name="is_start"
                                    label="Offre"
                                    onChange={value => addCriteria("is_start", value)}
                                    clearable={true}
                                    value={criterias.is_start}
                                    options={[
                                        {value: "1", label: "Start"},
                                        {value: "0", label: "Premium"},
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-account" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-account" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <ListComponent 
                        id="accounts"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                    >
                        <Link id="new-account" to="/accounts/new" className="btn btn-primary h25 icon"><i className="icon-boutons-creer-acces" aria-hidden="true"></i>&nbsp;Créer un compte</Link>
                        <Can perform="account:export" yes={() => (
                            <>
                                <button id="export-accounts" onClick={exportAccounts} type="button" className="btn btn-primary h25 icon"><i className="icon-file-excel" aria-hidden="true"></i>&nbsp;Export des comptes</button>
                                <button id="export-contracts" onClick={exportContracts} type="button" className="btn btn-primary h25 icon"><i className="icon-file-excel" aria-hidden="true"></i>&nbsp;Export des contrats</button>
                            </>
                        )} />
                        <Can perform="account:export-all-watchsites" yes={() => (
                            <button id="export-watchsites" onClick={exportWatchsites} type="button" className="btn btn-primary h25 icon"><i className="icon-file-excel" aria-hidden="true"></i>&nbsp;Export des points de veille</button>
                        )} />
                    </ListComponent>
                </form>
            </div>
        </DefaultLayout>
    );
}
