import React, { useEffect, useState } from 'react';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import BarLoader from "react-spinners/BarLoader";

import DefaultLayout from "layouts/DefaultLayout";

import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import { useSecurity } from 'shared/hooks/Security';

import { getActivityDomain } from 'shared/data/ActivityDomain';

import ExternalUserApi from 'shared/api/ExternalUser';
import AccountApi from 'shared/api/Account';
import UserApi from 'shared/api/User';

import { denyIfCantPerform } from 'shared/services/Can';
import { compileErrorsFromResponse, compileDataToSelectOptions } from 'shared/services/Utils';

import FieldComponent from 'shared/components/FieldComponent';
import SidebarComponent from 'shared/components/SidebarComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import DomainSelectionComponent from 'shared/components/DomainSelectionComponent';

import AccountTree from 'views/externaluser/form/AccountTree';


export default function ExternalUserForm(props) {

    const [togglers, toggle] = useToggler({
        'account': true,
        'configuration': true,
        'alerts': true,
        'accessright': true,
        'accesswatchsites': true,
    });

    const defaultValue = {
        id: null,
        user_id: null,
        account_id: null,
        email: "",
        lastname: "",
        firstname: "",
        active: 0,
        telephone: "",
        civility: "Mr",
        function: "",
        effectif: "",
        activity_domain: "",
        roles: [],
        access_user_configuration: 0,
        access_write_hotline: 0,
        access_write: 0,
        access_tree_management: 0,
        access_profil: 0,
        readable_watchsites: [],
        writable_watchsites: [],
        readable_nodes: [],
        writable_nodes: [],
        alert_activation: 0,
        alert_frequency: 0,
        alert_format: 0,
        alert_sheet_status: 0,
        alert_domains: [],
        alert_review_activation: 0,
        alert_deadline_activation: 0,
        alert_documentary_space_activation: 0,
        alert_restriction: 0,
        accesses: [],
    };

    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm(defaultValue);

    const history = useHistory();
    const [user,,,, isAdmin] = useSecurity();
    const [useraccounts, setUserAccounts] = useState([]);
    const [currentAccountsIds, setCurrentAccountsIds] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [accountsOptions, setAccountsOptions] = useState([]);
    const [saving, setSaving] = useState(false);
    const [is_load, setIsLoad] = useState((!props.match.params.id));
    const [emailchecked, setEmailchecked] = useState(false);
    const [account, setAccount] = useState({});
    const [alertFrequencyOptions, setAlertFrequencyOptions] = useState([]);
    const [tree, setTree] = useState({nodes: [], watchsites: []});
    const [loadingTree, setLoadingTree] = useState(true);

    const mode = props.match.params.id ? "update" : "create";

    useEffect(() => {
        if (props.match.params.id) {
            ExternalUserApi
                .get(props.match.params.id)
                .then(newData => {
                    setAccount(newData.account);
                    setData({
                        id: newData.id,
                        user_id: newData.user_id,
                        account_id: newData.account.id,
                        email: newData.email,
                        lastname: newData.lastname,
                        firstname: newData.firstname,
                        active: newData.active,
                        telephone: newData.telephone,
                        civility: newData.civility,
                        function: newData.function,
                        effectif: newData.effectif,
                        activity_domain: newData.activity_domain,
                        roles: newData.roles,
                        access_user_configuration: newData.access_user_configuration,
                        access_write_hotline: newData.access_write_hotline,
                        access_write: newData.access_write,
                        access_tree_management: newData.access_tree_management,
                        access_profil: newData.access_profil,
                        readable_watchsites: newData.readable_watchsites,
                        writable_watchsites: newData.writable_watchsites,
                        readable_nodes: newData.readable_nodes,
                        writable_nodes: newData.writable_nodes,
                        alert_activation: newData.alert_activation === true ? 1 : 0,
                        alert_frequency: newData.alert_frequency,
                        alert_format: newData.alert_format,
                        alert_sheet_status: newData.alert_sheet_status,
                        alert_domains: newData.alert_domains || [],
                        alert_review_activation: newData.alert_review_activation === true ? 1 : 0,
                        alert_deadline_activation: newData.alert_deadline_activation === true ? 1 : 0,
                        alert_documentary_space_activation: newData.alert_documentary_space_activation === true ? 1 : 0,
                        alert_restriction: newData.alert_restriction === true ? 1 : 0,
                        accesses: newData.accesses,
                    });
                    setIsLoad(true);

                    AccountApi.useraccounts(newData.email).then(([ua]) => {
                        let accountsIds = ua.map(item => item.id);
                        accountsIds.push(data.account_id);

                        setAccounts(ua);
                        setUserAccounts(ua)
                        setCurrentAccountsIds(accountsIds);
                    });
                })
                .catch(response => {
                    toast.error("Une erreur est survenue lors de la récupération de l'utilisateur");
                    history.goBack();
                });
        }        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mode === "create") {
            let querySearch = {};
            if (!isAdmin()) {
                querySearch = {
                    "bool": {
                        "should": [
                            {"match": {"pilot_consultants": user.id}},
                            {"match": {"associate_consultants": user.id}},
                            {"match": {"is_start": 1}}
                        ]
                    }
                }
            }

            AccountApi
                .search(querySearch, 0, 10000, "name", "asc")
                .then(([results]) => {
                    setAccounts(results);
                    setAccountsOptions(compileDataToSelectOptions(results));
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data.account_id) {
            AccountApi
                .get(data.account_id)
                .then(newData => {
                    setAccount(newData);
                    if (newData.is_start) {
                        setValue("access_write_hotline", 0);
                        setValue("access_write", 0);
                        setValue("access_profil", 0);
                    }

                    let accountsIds = useraccounts.map(item => item.id);
                    accountsIds.push(data.account_id);

                    setCurrentAccountsIds(accountsIds);
                })
                .catch(error => console.log(error));

            AccountApi
                .getTree(data.account_id)
                .then(newData => {
                    if (mode === "create") {
                        setValue("accesses", []);
                    }
                    setTree(newData);
                    setLoadingTree(false);
                })
                .catch(error => console.log(error));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.account_id]);

    useEffect(() => {
        let frequencies = [
            {value: 0, label: "Quotidienne"},
            {value: 1, label: "Quotidienne + Récapitulatif mensuel"},
            {value: 2, label: "Hebdomadaire"},
            {value: 3, label: "Hebdomadaire + Récapitulatif mensuel"},
            {value: 4, label: "Bimensuelle"},
            {value: 5, label: "Mensuelle"},
        ];
        accounts.forEach(a => {
            if (currentAccountsIds.includes(a.id)) {
                if (Array.isArray(a.available_alert_frequencies)) {
                    frequencies = frequencies.filter(v => a.available_alert_frequencies.includes(v.value));
                } else {
                    frequencies = [];
                }
            }             
        });
        setAlertFrequencyOptions(frequencies);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.account_id, useraccounts, accounts, currentAccountsIds]);

    useEffect(() => {
        if (data.roles.includes("ROLE_ADMIN_CUSTOMER")) {
            let newData = {...data};
            newData.access_user_configuration = 1;            
            if (!account.is_start) {
                newData.access_write_hotline = 1;
                newData.access_profil = 1;
                newData.access_write = 1;
            }
            setData(newData);
        }
        if (data.roles.includes("ROLE_USER_CUSTOMER")) {
            let newData = {...data};
            newData.access_tree_management = 0;
            setData(newData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.roles]);

    function checkIfEmailexist() {
        if (data.email) {
            AccountApi.useraccounts(data.email).then(([ua]) => setUserAccounts(ua));

            UserApi
                .exist(data.email)
                .then(newData => {
                    if (newData.exist) {
                        UserApi
                            .isExternal(data.email)
                            .then(isExternal => {
                                if(!isExternal) {
                                    toast.warning("Cet email correspond à un utilisateur interne. Vous ne pouvez pas l'utiliser.");
                                } else {
                                    setEmailchecked(true);
                                    setData({
                                        user_id: newData.user.id,
                                        email: newData.user.email,
                                        lastname: newData.user.lastname,
                                        firstname: newData.user.firstname,
                                        active: newData.user.active,
                                        telephone: newData.user.telephone,
                                        civility: newData.user.civility,
                                        function: newData.user.function,
                                        effectif: newData.effectif,
                                        activity_domain: newData.activity_domain,
                                        roles: newData.user.roles,
                                        access_user_configuration: newData.user.access_user_configuration,
                                        access_write: newData.user.access_write,
                                        access_write_hotline: newData.user.access_write_hotline,
                                        access_tree_management: newData.user.access_tree_management,
                                        access_profil: newData.user.access_profil,
                                        alert_activation: newData.alert_activation === true ? 1 : 0,
                                        alert_frequency: newData.alert_frequency,
                                        alert_format: newData.alert_format,
                                        alert_sheet_status: newData.alert_sheet_status,
                                        alert_domains: newData.alert_domains || [],
                                        alert_review_activation: newData.alert_review_activation === true ? 1 : 0,
                                        alert_deadline_activation: newData.alert_deadline_activation === true ? 1 : 0,
                                        alert_documentary_space_activation: newData.alert_documentary_space_activation === true ? 1 : 0,
                                        alert_restriction: newData.alert_restriction === true ? 1 : 0,
                                        readable_watchsites: [],
                                        writable_watchsites: [],
                                        readable_nodes: [],
                                        writable_nodes: [],
                                        accesses: [],
                                    });
                                    if (newData.nb_account === 0 || newData.deleted !== false) {
                                        toast.info("Cet utilisateur à été supprimé et va être réactivé.");
                                    } else {
                                        toast.info("L'utilisateur existe déjà (associé à " + newData.nb_account + " comptes). Des droits supplémentaires lui seront ajoutés.");
                                    }                                    
                                }
                            });
                    } else {
                        setEmailchecked(true);
                        let newvalue = defaultValue;
                        newvalue = Object.assign({}, newvalue, {email: data.email})
                        setData(newvalue);
                    }
                })
                .catch(error => toast.error(error.response.data.message));
        }
    }

    function accessRightChange(accessesitems, checked, value) {
        if (checked) {
            accessesitems.push(parseInt(value));
        } else {
            accessesitems = accessesitems.filter(id => id !== parseInt(value));
        }
        return accessesitems;
    }

    function getAccessesUpdated(checked, value, type, accessmode) {
        let newaccesses = [...data.accesses];
        let access = null;
        if (type === "node") {
            access = data.accesses.find((item => (item.node && item.node.id === parseInt(value))));
        } else {
            access = data.accesses.find((item => (item.watchsite && item.watchsite.id === parseInt(value))));
        }
        if (access) {
            if (accessmode === "write") {
                access.writeaccess = checked ? 1 : 0;
            } else {
                access.readaccess = checked ? 1 : 0;
                if (checked) {
                    access.writeaccess = 0;
                }
            }
        } else {
            newaccesses.push({
                [type]: {
                    id: parseInt(value)
                },
                writeaccess: (accessmode === "write" && checked) ? 1 : 0,
                readaccess: (accessmode === "read" && checked) ? 1 : 0,
            })
        }
        return newaccesses;
    }

    function watchsiteReadAccessChange(event) {
        setData({...data, ...{
            writable_watchsites: accessRightChange(data.writable_watchsites.slice(0), false, event.target.value),
            readable_watchsites: accessRightChange(data.readable_watchsites.slice(0), event.target.checked, event.target.value),
            accesses: getAccessesUpdated(event.target.checked, event.target.value, "watchsite", "read")
        }});
    }

    function watchsiteWriteAccessChange(event) {
        setData({...data, ...{
            writable_watchsites: accessRightChange(data.writable_watchsites.slice(0), event.target.checked, event.target.value),
            accesses: getAccessesUpdated(event.target.checked, event.target.value, "watchsite", "write"),
        }});
    }

    function nodeReadAccessChange(event) {
        setData({...data, ...{
            writable_nodes: accessRightChange(data.writable_nodes.slice(0), false, event.target.value),
            readable_nodes: accessRightChange(data.readable_nodes.slice(0), event.target.checked, event.target.value),
            accesses: getAccessesUpdated(event.target.checked, event.target.value, "node", "read"),
        }});
    }

    function nodeWriteAccessChange(event) {
        setData({...data, ...{
            writable_nodes: accessRightChange(data.writable_nodes.slice(0), event.target.checked, event.target.value),
            accesses: getAccessesUpdated(event.target.checked, event.target.value, "node", "write"),
        }});        
    }

    function submit(event) { 
        event.preventDefault();

        if (event.nativeEvent.submitter === null) {
            checkIfEmailexist();
        } else {
            setSaving(true);
            
            let newData = {...data};
            if(newData.activity_domain === null) newData.activity_domain = "";
            delete newData.accesses;

            const availableFrequency = alertFrequencyOptions.map(o => o.value);
            if (!availableFrequency.includes(newData.alert_frequency)) {
                newData.alert_frequency = null;
            }

            ExternalUserApi
                .save(newData)
                .then(freshData => {
                    setData({...data, ...{
                        readable_watchsites: freshData.readable_watchsites,
                        writable_watchsites: freshData.writable_watchsites,
                        readable_nodes: freshData.readable_nodes,
                        writable_nodes: freshData.writable_nodes,
                        accesses: freshData.accesses,
                    }});

                    setErrors({});
                    setSaving(false);

                    toast.success("Enregistrement effectué.");
                    reloadOrClose(event, props, "externalusers", freshData.id);            
                })
                .catch(error => {
                    setSaving(false);
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);        
                    } else {
                        setErrors(compileErrorsFromResponse(error.response));
                        toast.error("Des erreurs sont survenues");
                    }
                });
        }
    }

    if (is_load !== true) {
        return <PageLoadingComponent label="Edition d'un utilisateur" />
    } else {
        denyIfCantPerform(props, "externaluser:read", {account: account});
        return (
            <DefaultLayout screen="E10" title="Edition d'un utilisateur">
                <Prompt
                    when={formHasModifications()}
                    message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
                />
                <section className="sidebar">
                    <h1>Configuration d'utilisateur</h1>
                    <SidebarComponent
                        label="Configuration de l'utilisateur"
                        links={[
                            {anchor: "#configuration", label: "Configuration de l'utilisateur", active: true},
                            {anchor: "#accessright", label: "Configuration des droits", active: true},
                            {anchor: "#alerts", label: "Configuration des alertes", active: true},
                            {anchor: "#accesswatchsites", label: "Accès aux points de veille", active: (data.account_id > 0)},
                        ]}
                    />
                    <section>
                        <form onSubmit={submit} id="form-account" className="form">
                            {mode === "update" && account && 
                                <ToggableBlocComponent label="Information sur le compte" id="account" toggled={togglers["account"]} toggle={toggle} className="border-gris-25 border-lrb">
                                    <div className="bg-gris-20">
                                        <div className="flex-label">
                                            <label>Compte associé</label>
                                            {account.name}
                                        </div>
                                    </div>
                                </ToggableBlocComponent>
                            }
                            <ToggableBlocComponent label="Paramètres" id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                                <div className="bg-blanc">
                                    <FieldComponent 
                                        name="email"
                                        label="Email *"
                                        error={errors.email}
                                        value={data.email}
                                        disabled={mode === "update"}
                                        onChange={value => setValue("email", value)}                                                
                                        onFocus={e => setEmailchecked(false)}
                                    >
                                        <button type="button" className="btn btn-primary" id="check-email" onClick={checkIfEmailexist}>Vérifier</button>
                                    </FieldComponent>                                            
                                    {(mode === "update" || emailchecked) && <>
                                        {(mode === "create" && 
                                            <FieldComponent
                                                type="select"
                                                name="account_id"
                                                label="Compte *"
                                                error={errors.account_id}
                                                options={accountsOptions}
                                                value={data.account_id}
                                                onChange={value => setValue("account_id", value)}
                                            />
                                        )}
                                        <FieldComponent
                                            type="checksingle"
                                            name="active" 
                                            label="Actif"
                                            checksingleLabel="Oui" 
                                            onChange={value => setValue("active", (value ? 1 : 0))}
                                            checked={data.active}
                                        />
                                        {(mode === "update" && account.created_by) && (
                                            <div className="flex-label">
                                                <label>Auteur de l’invitation :</label>
                                                <span>{account.created_by.lastname} {account.created_by.firstname}</span>
                                            </div>
                                        )}
                                        <FieldComponent
                                            type="radio" 
                                            name="civility"
                                            label="Civilité *"
                                            options={[{value:"Mr", label: "Monsieur"}, {value:"Mrs", label: "Madame"}]}
                                            value={data.civility}
                                            error={errors.civility}
                                            onChange={value => setValue("civility", value)}
                                        />
                                        <FieldComponent
                                            name="lastname" 
                                            label="Nom *"
                                            className="field small"
                                            error={errors.lastname}
                                            value={data.lastname}
                                            onChange={value => setValue("lastname", value)}
                                        />
                                        <FieldComponent
                                            name="firstname"
                                            label="Prénom *"
                                            className="field small"
                                            error={errors.firstname}
                                            value={data.firstname}
                                            onChange={value => setValue("firstname", value)}
                                        />
                                        <FieldComponent
                                            name="telephone"
                                            label="Téléphone"
                                            className="field small"
                                            value={data.telephone}
                                            onChange={value => setValue("telephone", value)}
                                        />
                                        <FieldComponent
                                            name="function"
                                            label="Fonction *"
                                            error={errors.function}
                                            className="field small"
                                            value={data.function}
                                            onChange={value => setValue("function", value)}
                                        />
                                        <FieldComponent 
                                            name="effectif"
                                            label="Effectif"
                                            className="field small"
                                            value={data.effectif}
                                            onChange={value => setValue("effectif", value)}
                                        />
                                        <FieldComponent
                                            type="select"
                                            name="activity_domain"
                                            label="Domaine d'activité"
                                            className="field"
                                            clearable
                                            value={data.activity_domain}
                                            onChange={value => setValue("activity_domain", value)}
                                            options={getActivityDomain()}
                                        />
                                    </>}
                                </div>
                            </ToggableBlocComponent>
                            {(mode === "update" || emailchecked) && (
                                <ToggableBlocComponent label="Configuration des droits" id="accessright" toggled={togglers["accessright"]} toggle={toggle}>
                                    <div className="bg-blanc">
                                        <FieldComponent
                                            type="select"
                                            name="roles"
                                            label="Profils *"
                                            value={data.roles[0] || null}
                                            multiple={false}
                                            error={errors.roles}
                                            options={[
                                                {value: 'ROLE_ADMIN_CUSTOMER', label: 'Administrateur client'},
                                                {value: 'ROLE_USER_CUSTOMER', label: 'Utilisateur simple'}
                                            ]}
                                            onChange={value => setValue('roles', [value])}
                                        />
                                        <div className="offset-label">
                                            <div className="row">
                                                <FieldComponent
                                                    type="checksingle"
                                                    name="access_user_configuration"
                                                    label="Configuration des utilisateurs"
                                                    onChange={value => setValue("access_user_configuration", value ? 1 : 0)}
                                                    checked={data.access_user_configuration}
                                                    wrapper="fieldset-div"
                                                    className="col-md-6"
                                                    disabled={data.roles.includes("ROLE_ADMIN_CUSTOMER")}
                                                />
                                                <FieldComponent
                                                    type="checksingle"
                                                    name="access_tree_management"
                                                    label="Consultation de l'arborescence"
                                                    onChange={value => setValue("access_tree_management", value ? 1 : 0)}
                                                    checked={data.access_tree_management}
                                                    wrapper="fieldset-div"
                                                    className="col-md-6"
                                                    disabled={data.roles.includes("ROLE_USER_CUSTOMER")}
                                                />
                                                <FieldComponent
                                                    type="checksingle"
                                                    name="access_profil"
                                                    label="Accès au profil"
                                                    onChange={value => setValue("access_profil", value ? 1 : 0)}
                                                    checked={data.access_profil}
                                                    wrapper="fieldset-div"
                                                    className="col-md-6"
                                                    disabled={data.roles.includes("ROLE_ADMIN_CUSTOMER") || account.is_start}
                                                />
                                                <FieldComponent
                                                    type="checksingle"
                                                    name="access_write"
                                                    label="Accès en écriture"
                                                    onChange={value => setValue("access_write", value ? 1 : 0)}
                                                    checked={account.is_start === false && data.access_write}
                                                    wrapper="fieldset-div"
                                                    className="col-md-6"
                                                    disabled={data.roles.includes("ROLE_ADMIN_CUSTOMER") || account.is_start}
                                                />
                                                {account.access_hotline === 1 && (
                                                    <FieldComponent
                                                        type="checksingle"
                                                        name="access_write_hotline"
                                                        label="Hotline en écriture"
                                                        onChange={value => setValue("access_write_hotline", value ? 1 : 0)}
                                                        checked={data.access_write_hotline}
                                                        wrapper="fieldset-div"
                                                        className="col-md-6"
                                                        disabled={data.roles.includes("ROLE_ADMIN_CUSTOMER") || account.is_start}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </ToggableBlocComponent>
                            )}
                            {(mode === "update" || emailchecked) && (
                                <ToggableBlocComponent label="Alertes" id="alerts" toggled={togglers["alerts"]} toggle={toggle}>
                                    <div className="bg-blanc">
                                        <FieldComponent
                                            type="radio"
                                            name="alert_activation"
                                            label="Activer les alertes"
                                            options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                            value={data.alert_activation}
                                            onChange={value => setValue("alert_activation", parseInt(value))}
                                        />
                                        {data.alert_activation === 1 && <>
                                            <FieldComponent
                                                type="radio"
                                                name="alert_frequency"
                                                label="Fréquence des alertes"
                                                options={alertFrequencyOptions}
                                                value={data.alert_frequency}
                                                error={errors.alert_frequency}
                                                onChange={value => setValue("alert_frequency", parseInt(value))}
                                            />
                                            <FieldComponent
                                                type="radio"
                                                name="alert_format"
                                                label="Format des alertes"
                                                options={[
                                                    {value:0, label: "Séparé : Une fiche par mail"},
                                                    {value:1, label: "Groupé : Fiches groupées dans un mail"}
                                                ]}
                                                value={data.alert_format}
                                                onChange={value => setValue("alert_format", parseInt(value))}
                                            />     
                                            {account.access_notfinalized_sheetalert === 1 && <FieldComponent
                                                type="radio"
                                                name="alert_sheet_status"
                                                label="Etat d'avancement de la fiche"
                                                options={[
                                                    {value:0, label: "Alerte lorsque la fiche est finalisée"},
                                                    {value:1, label: "Alerte dès que la fiche est affectée"}
                                                ]}
                                                value={data.alert_sheet_status}
                                                onChange={value => setValue("alert_sheet_status", parseInt(value))}
                                            />}
                                            {account.access_alert_filters === 1 && <>
                                                <FieldComponent
                                                    type="radio"
                                                    name="alert_restriction"
                                                    label="Accès à tous les domaines, sous domaines, thèmes"
                                                    options={[{value:0, label: "Oui"}, {value:1, label: "Non"}]}
                                                    value={data.alert_restriction}
                                                    onChange={value => setValue("alert_restriction", parseInt(value))}
                                                />
                                                {data.alert_restriction === 1 && <>
                                                    <DomainSelectionComponent 
                                                        label="Restriction des alertes aux domaines/sous-domaines/thèmes"
                                                        accounts={currentAccountsIds}
                                                        name="alert_domains"
                                                        onChange={value => setValue("alert_domains", value)}
                                                        value={data.alert_domains}
                                                    />
                                                </>}
                                            </>}
                                        </>}
                                    </div>
                                    <hr />
                                    <div className="bg-blanc">
                                        <FieldComponent
                                            type="radio"
                                            name="alert_review_activation"
                                            label="Activation des alertes sur les dates de revue des fiches"
                                            options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                            value={data.alert_review_activation}
                                            onChange={value => setValue("alert_review_activation", parseInt(value))}
                                        />
                                        <FieldComponent
                                            type="radio"
                                            name="alert_deadline_activation"
                                            label="Activation des alertes sur les dates limite de réalisation des actions"
                                            options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                            value={data.alert_deadline_activation}
                                            onChange={value => setValue("alert_deadline_activation", parseInt(value))}
                                        />
                                    </div>
                                    <div className="bg-blanc">
                                        {account.access_newsletter === 1 
                                            && account.access_documentary_space === 1
                                            && <>                                 
                                            <hr />
                                            <FieldComponent
                                                type="radio"
                                                name="alert_documentary_space_activation"
                                                label="Réception de la newsletter de l'espace documentaire"
                                                options={[{value:1, label: "Oui"}, {value:0, label: "Non"}]}
                                                value={data.alert_documentary_space_activation}
                                                onChange={value => setValue("alert_documentary_space_activation", parseInt(value))}
                                            />
                                        </>}
                                    </div>                                    
                                </ToggableBlocComponent>
                            )}
                            {data.account_id > 0 && (
                                <ToggableBlocComponent label="Accès aux points de veille" id="accesswatchsites" toggled={togglers["accesswatchsites"]} toggle={toggle} wrapChildren={false}>
                                    <header className="bg-gris-45">
                                        <div className="row">
                                            <div className="col-md-10 offset-md-2">
                                                <div className="d-flex offset-135px">
                                                    <div className="w-90px text-center">Lecture seule</div>
                                                    <div className="w-120px text-center">Lecture + Écriture</div>
                                                </div>
                                            </div>
                                        </div>
                                    </header>
                                    <div className="border-gris-25 border-lrb bg-blanc">
                                        {errors.accesswatchsites && <span id="accesswatchsites-error" className="help-block color-danger">
                                            {errors.accesswatchsites}
                                        </span>}
                                        {account.is_start === false && data.access_write === 1 && <>L'utilisateur possède un accès total en écriture.</>}
                                        <div className={account.is_start === false && data.access_write === 1 ? "d-none" : ""}>
                                            <BarLoader loading={loadingTree} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                                            <AccountTree
                                                tree={tree}
                                                accesses={data.accesses}
                                                onWatchsiteReadAccessChange={watchsiteReadAccessChange}
                                                onWatchsiteWriteAccessChange={watchsiteWriteAccessChange}
                                                onNodeReadAccessChange={nodeReadAccessChange}
                                                onNodeWriteAccessChange={nodeWriteAccessChange}
                                                isStart={account.is_start}
                                            />
                                        </div>
                                    </div>
                                </ToggableBlocComponent>
                            )}
                            <section  className="bloc"></section>
                            <section className="row">
                                <div className="col">
                                    <Link id="back-externaluser-list" to="/externalusers" className="btn btn-bleu-4">Retour à la liste</Link>
                                </div>
                                {(mode === "update" || emailchecked) && 
                                    <div className="col text-right">
                                        <LoadButton 
                                            loading={saving} 
                                            label="Enregistrer et fermer"
                                            name="save-and-close"
                                            id="save-and-close-externaluser"
                                        />
                                        <LoadButton 
                                            loading={saving} 
                                            label="Enregistrer" 
                                            name="save" 
                                            id="save-externaluser"
                                        />
                                    </div>
                                }
                            </section>
                        </form>
                    </section>
                </section>
            </DefaultLayout>        
        );
    }
}
