export const APPLICABILITY_STATE_TOCOMEUP = 0;
export const APPLICABILITY_STATE_CURRENT = 1;
export const APPLICABILITY_STATE_LAPSED = 2;

export const APPLICABILITIES_STATE = {
    [APPLICABILITY_STATE_TOCOMEUP]: "A venir",
    [APPLICABILITY_STATE_CURRENT]: "En cours",
    [APPLICABILITY_STATE_LAPSED]: "Caduc",
}

export const APPLICABILITIES_COLOR = {
    [APPLICABILITY_STATE_TOCOMEUP]: "warning",
    [APPLICABILITY_STATE_CURRENT]: "valid",
    [APPLICABILITY_STATE_LAPSED]: "error",
}

export const APPLICABILITIES_COLOR_FADED = {
    [APPLICABILITY_STATE_TOCOMEUP]: "warning-faded",
    [APPLICABILITY_STATE_CURRENT]: "valid-faded",
    [APPLICABILITY_STATE_LAPSED]: "error-faded",
}

export function getApplicabilities() {    
    let status = [];
    for (const [key, value] of Object.entries(APPLICABILITIES_STATE)) {
        status.push({
            value: parseInt(key), 
            label: value,
        });
    }
    return status;
}

export function getApplicabilityLabel(applicability) {
    return APPLICABILITIES_STATE[applicability];
}

export function getApplicabilityColor(applicability, faded = false) {
    if (faded) {
        return APPLICABILITIES_COLOR_FADED[applicability];
    }
    return APPLICABILITIES_COLOR[applicability];
}
