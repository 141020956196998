import { React, toast, DefaultLayout, useState, useEffect } from 'commons';
import ConnectionHistoryApi from 'api/ConnectionHistory';
import { useList, usePosition } from 'shared/hooks';
import { ListComponent, FilterComponent, LoadButton } from 'shared/components';
import { getRoles, getRoleLabel } from 'shared/data';
import { FileUtils, convertCriteriesToSearchQuery, denyIfCantPerform, AccountAutocomplete, preventDefault } from 'shared/services';

export default function ConnectionHistoryList(props) {
    
    denyIfCantPerform(props, "connectionhistory:list");

    const [initializePosition, setPosition] = usePosition("connectionhistory-list");
    const [exporting, setExporting] = useState(false);
    const columns = [
        {id: 'datetime', title: 'Date de connexion', format: 'datetime'},
        {id: 'user.firstname', title: 'Prénom'},
        {id: 'user.lastname', title: 'Nom'},
        {id: 'user.email', title: 'Email'},
        {id: 'roles', title: 'Profil', render: (row) => <>
            {row.roles && row.roles.map(role => 
                <React.Fragment key={role}>{role !== 'ROLE_USER' && <p key={role}>{getRoleLabel(role)}</p>}</React.Fragment>
            )}
        </>},
        {id: 'account.name', title: 'Compte'},
        {id: 'ip', title: 'IP'},
    ];

    const filterType = {
        name: {type: "wildcard", fields: ["user.email", "user.lastname", "user.firstname"]},
        roles: {type: "terms", fields: ["roles"]},
        context: {type: "match", fields: ["context"]},
        account: {type: "match", fields: ["account.id"]},
        datetime: {type: "range", fields: ["datetime"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("connection-history-list", ConnectionHistoryApi, null, "datetime", null, "desc");

    useEffect(() => {
        submitSearch(filterType, () => initializePosition());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function exportHistory(event) {
        event.preventDefault();
        setExporting(true);
        const exportQuery = convertCriteriesToSearchQuery(criterias, filterType);
        const fileName = FileUtils.getFilePrefix() + "-HistoriqueConnexion.xlsx";
        ConnectionHistoryApi
            .export(exportQuery, fileName)
            .then(data => {
                setExporting(false);
                toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible.");
            })
            .catch(error => {
                setExporting(false);
                toast.error("Erreur durant la création de l'export.")
            });
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E03" title="Historique des connexions">
            <h1>Historique des connexions</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    label="Nom"
                                    name="name"
                                    onChange={value => addCriteria("name", value)}
                                    value={criterias.name || ""}
                                />
                                <FilterComponent
                                    label="Profil"
                                    name="roles"
                                    onChange={value => addCriteria("roles", value)}
                                    value={criterias.roles}
                                    type="select"
                                    clearable={true}
                                    options={getRoles()}
                                />
                                <FilterComponent
                                    label="Instance"
                                    name="context"
                                    onChange={value => addCriteria("context", value)}
                                    value={criterias.context}
                                    type="select"
                                    clearable={true}
                                    options={[
                                        {value: 'frontoffice', label: 'frontoffice'},
                                        {value: 'backoffice', label: 'backoffice'},
                                    ]}
                                />
                                <FilterComponent
                                    label="Compte"
                                    type="async-select"
                                    name="account"
                                    clearable={true}
                                    value={criterias.account}
                                    loadOptions={(value, callback) => AccountAutocomplete({keyword: value}, callback)}
                                    onChange={value => addCriteria("account", value)}
                                />
                                <FilterComponent
                                    label="Date"
                                    name="datetime"
                                    type="daterange"
                                    value={criterias.datetime}
                                    onChange={value => addCriteria("datetime", value)}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-connectionhistory" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-connectionhistory" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="connectionhistory"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        globalActions={(                 
                            <LoadButton 
                                loading={exporting} 
                                label="Exporter"
                                name="export"
                                id="export-connectionhistory"
                                className="btn btn-primary h25 icon"
                                onClick={exportHistory}
                            />
                        )}
                    />
                </form>
            </div>
        </DefaultLayout>
    );
}