import React, { useState, useEffect } from 'react';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import DefaultLayout from "layouts/DefaultLayout";

import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";

import { getApave } from 'data/Apave';

import InternalUserApi from 'shared/api/InternalUser';

import { compileErrorsFromResponse } from 'shared/services/Utils';
import Can, { canPerform, denyIfCantPerform } from 'shared/services/Can';

import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import PageLoadingComponent from 'shared/components/PageLoadingComponent';

export default function InternalUserForm(props) {

    const history = useHistory();
    const [saving, setSaving] = useState("");
    const [togglers, toggle] = useToggler({'configuration': true});
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        email: "",
        active: 0,
        civility: "Mr",
        firstname: "",
        lastname: "",
        telephone: "",
        mobile: "",
        function: "",
        roles: [],
        apave: "",
        direction: "",
        agency: "",
        city: "",
        uses_secured_push: 0
    });
    const [is_load, setIsLoad] = useState((!props.match.params.id));

    useEffect(() => {
        if (props.match.params.id) {
            InternalUserApi
                .get(props.match.params.id)
                .then(data => {
                    setIsLoad(true);
                    setData(d => Object.assign({}, d, data));
                })
                .catch(response => {
                    toast.error("Une erreur est survenue lors de la récupération de l'utilisateur");
                    history.goBack();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function notifiyRoleChange(newValue) {
        if (data.roles.includes("ROLE_CONSULTANT") && !newValue.includes("ROLE_CONSULTANT")) {
            toast.warning("Supprimer le rôle de consultant peut avoir des répercussions sur d'autres données (comptes, points de veille)");
        }
    }

    function submit(event) { 
        event.preventDefault();

        setSaving(event.nativeEvent.submitter.name);

        InternalUserApi
            .save(data)
            .then(newdata => {
                setData(Object.assign({}, data, {"id": newdata.id}));
                setSaving("");
                setErrors({});
                toast.success("Enregistrement effectué.");
                reloadOrClose(event, props, "internalusers", newdata.id);            
            })
            .catch(error => {
                setSaving("");
                if (error.response.data.message) {
                    toast.error(error.response.data.message);                    
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    if (is_load !== true) {
        return <PageLoadingComponent label="Edition d'un utilisateur" />
    } else {
        denyIfCantPerform(props, "internaluser:read", data);
        return (
            <DefaultLayout screen="E12" title="Configuration de l'utilisateur">
                <Prompt
                    when={formHasModifications()}
                    message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
                />
                <h1>Configuration de l'utilisateur</h1>
                <section>
                    <form onSubmit={submit} id="form-account" className="form">
                        <ToggableBlocComponent label="Configuration" id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                            <FieldComponent name="email" label="Email *" error={errors.email} value={data.email} onChange={value => setValue("email", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent type="checksingle" name="active" label="Actif" checksingleLabel="Oui" onChange={value => setValue("active", (value ? 1 : 0))} checked={data.active} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent type="radio" name="civility" label="Civilité" options={[{value:"Mr", label: "Monsieur"}, {value:"Mrs", label: "Madame"}]} value={data.civility} onChange={value => setValue("civility", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="lastname" label="Nom *" className="field small" error={errors.lastname} value={data.lastname} onChange={value => setValue("lastname", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="firstname" label="Prénom *" className="field small" error={errors.firstname} value={data.firstname} onChange={value => setValue("firstname", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="telephone" label="Téléphone fixe" className="field small" value={data.telephone} onChange={value => setValue("telephone", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="mobile" label="Téléphone mobile" className="field small" value={data.mobile} onChange={value => setValue("mobile", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="function" label="Fonction *" className="field small" error={errors.function} value={data.function} onChange={value => setValue("function", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent
                                type="select"
                                name="roles"
                                label="Profils *"
                                value={data.roles}
                                multiple={true}
                                error={errors.roles}
                                options={[
                                    {value: 'ROLE_CONSULTANT', label: 'Consultant'},
                                    {value: 'ROLE_LIBRARIAN', label: 'Documentaliste'},
                                    {value: 'ROLE_EXPERT', label: 'Expert'},
                                    {value: 'ROLE_ADMIN', label: 'Administrateur'},
                                    {value: 'ROLE_SUPER_ADMIN', label: 'Administrateur technique'},
                                ]}
                                onChange={values => { notifiyRoleChange(values); setValue("roles", values)} }
                                disabled={!canPerform("internaluser:write", data)}
                            />
                            <FieldComponent type="select" name="apave" label="Apave de rattachement *" className="field small" error={errors.apave} value={data.apave} onChange={value => setValue("apave", value)} options={getApave()} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="direction" label="Direction *" className="field small" error={errors.direction} value={data.direction} onChange={value => setValue("direction", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="agency" label="Agence *" className="field small" error={errors.agency} value={data.agency} onChange={value => setValue("agency", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent name="city" label="Ville *" className="field small" error={errors.city} value={data.city} onChange={value => setValue("city", value)} disabled={!canPerform("internaluser:write", data)} />
                            <FieldComponent type="checksingle" name="uses_secured_push" label="Push sécurisé ?" checksingleLabel="Bloquer l'ajout automatique de fiche volontairement supprimée du push ?" className="field small" error={errors.uses_secured_push} value={data.uses_secured_push} onChange={value => setValue("uses_secured_push", (value ? 1 : 0))} checked={data.uses_secured_push} disabled={!canPerform("internaluser:write", data)} />
                        </ToggableBlocComponent>
                        <section className="row">
                            <div className="col">
                                <Link id="back-internal-user-list" to="/internalusers" className="btn btn-bleu-4">Retour à la liste</Link>
                            </div>
                            <Can
                                perform="internaluser:write"
                                data={data}
                                yes={() => (
                                    <div className="col text-right">
                                        <LoadButton 
                                            loading={saving === "save-and-close"} 
                                            label="Enregistrer et fermer"
                                            name="save-and-close"
                                            id="save-and-close-internaluser"
                                        />
                                        <LoadButton 
                                            loading={saving === "save"} 
                                            label="Enregistrer" 
                                            name="save" 
                                            id="save-internaluser"
                                        />
                                    </div>
                                )}
                            />
                        </section>
                    </form>
                </section>
            </DefaultLayout>
        )
    }
}
