import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useToggler } from "shared/hooks/Toggler";
import { SHEET_DONE, SHEET_TO_DO } from 'data/SheetStatus';
import PushApi from 'api/Push';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import FieldComponent from "shared/components/FieldComponent";
import LoadButton from "shared/components/LoadButton";
import SheetAutocomplete from 'shared/services/autocomplete/Sheet';
import _ from 'lodash';
import axios from 'axios';

export default function PushAddForm(props) {

    const [adding, setAdding] = useState(false);
    const [togglers, toggle] = useToggler({'add-sheet': true});
    const [sheets, setSheets] = useState([]);

    function submit(event) {
        event.preventDefault();

        setAdding(true);

        const requests = [];
        sheets.forEach(sheet => {
            requests.push(
                PushApi
                    .addSheets([sheet.value])
                    .then(() => {
                        props.refresh();
                        toast.success("Fiche " + sheet.value + " ajoutée au push");
                    })
                    .catch(error => toast.error(error.response.data.message))
            );
        });

        axios.all(requests).then(() => {
            setAdding(false)
            setSheets([]);
        });
    }

    function add(value, sheet) {
        if (!_.find(sheets, sheet)) {
            const newSheets = [...sheets];
            newSheets.push(sheet)
            setSheets(newSheets);
        }
    }

    function remove(sheet) {
        setSheets(sheets.filter(s => s.value !== sheet.value));
    }

    return <section>
        <form onSubmit={submit} id="form-add-sheet-to-push" className="form">
            <ToggableBlocComponent label="Ajouter des fiches au push" id="add-sheet" toggled={togglers["add-sheet"]} toggle={toggle}>
                <FieldComponent
                    label="Fiche"
                    type="async-select"
                    cacheOptions={false}
                    name="linkedsheet"
                    loadOptions={(inputValue, callback) => SheetAutocomplete({keyword: inputValue, status: [SHEET_DONE, SHEET_TO_DO]}, callback, "title", 'ASC', 1, false)}
                    onChange={add}
                />
                <div className="flex-label">
                    <ul className="filelist arborescence no-arrow squared">
                        {sheets.map(sheet => <li key={sheet.value}>
                            {sheet.label}
                            <button data-info="Enlever la fiche" type="button" onClick={() => remove(sheet)} id="remove-sheet-from-push-queue">
                                <i className="icon-filtres-poubelle" aria-hidden="true"></i>
                            </button>
                        </li>)}
                    </ul>
                </div>
                <section>
                    <div className="text-right" style={{marginBottom: "15px"}}>
                        <LoadButton 
                            loading={adding} 
                            label="Ajouter au push" 
                            name="add" 
                            id="add-to-push"
                        />
                    </div>
                </section>
            </ToggableBlocComponent>
        </form>
    </section>;
}