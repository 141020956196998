import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import Can from "shared/services/Can";

export default function Menu(props) {
    const [exportedFiles, setExportedFiles] = useState(localStorage.getItem("exportReady") ? localStorage.getItem("exportReady") : 0);

    setInterval(() => setExportedFiles(localStorage.getItem("exportReady") ? localStorage.getItem("exportReady") : 0), 1000);

    function toggleDocumentarySpace() {
        document.getElementById("menu-documentaryspace-li").classList.toggle("act");
    }

    function toggleDashboardMenu() {
        document.getElementById("menu-dashboard-li").classList.toggle("act");
    }

    function toggleObligationGenerator() {
        document.getElementById("menu-obligationgenerator-li").classList.toggle("act");
    }

    return (
        <nav className="main-nav">
            <ul>
                {!props.isLogged && <>
                    <li>
                        <a id="menu-login" href="/" title="Retour à Pilot-Veille">
                            <i className="icon-nav-accueil" aria-hidden="true"></i>&nbsp;Retour à Pilot-Veille
                        </a>
                    </li>
                </>}
                {props.isLogged && <>
                    <li><NavLink id="menu-homepage" to="/" title="Accueil"><i className="icon-nav-accueil" aria-hidden="true"></i>&nbsp;Accueil</NavLink></li>
                    <Can perform="sheet:dashboard" yes={() => (
                        <li id="menu-dashboard-li" className="hassub">
                            <NavLink id="menu-dashboard" to="/dashboard" title="Tableau de bord des fiches"><i className="icon-tasks" aria-hidden="true"></i>&nbsp;Tableau de bord</NavLink>
                            <button className="submenu-toggler" onClick={toggleDashboardMenu}><i className="icon-right-open" aria-hidden="true"></i></button>
                            <ul>
                                <Can perform="dashboard:search-need-decision" yes={() => (<li><NavLink id="menu-dashboard-search-need-decision" to="/dashboard?trigger=need-decision" title="Tableau de bord > Fiche à statuer">Fiche à statuer</NavLink></li>)} />
                                <Can perform="dashboard:search-need-synthesis" yes={() => (<li><NavLink id="menu-dashboard-search-need-synthesis" to="/dashboard?trigger=need-synthesis" title="Tableau de bord > Fiche à synthétiser">Fiche à synthétiser</NavLink></li>)} />
                                <Can perform="dashboard:search-need-technical-affectation" yes={() => (<li><NavLink id="menu-dashboard-search-need-technical-affectation" to="/dashboard?trigger=need-technical-affectation" title="Tableau de bord > Fiche à affecter niveau technique">Fiche à affecter niveau technique</NavLink></li>)} />
                                <Can perform="dashboard:search-need-technical" yes={() => (<li><NavLink id="menu-dashboard-search-need-technical" to="/dashboard?trigger=need-technical" title="Tableau de bord > Fiche à commenter niveau technique">Fiche à commenter niveau technique</NavLink></li>)} />
                                <Can perform="dashboard:search-need-consolidation" yes={() => (<li><NavLink id="menu-dashboard-search-need-consolidation" to="/dashboard?trigger=need-consolidation" title="Tableau de bord > Fiche à consolider">Fiche à consolider</NavLink></li>)} />
                            </ul>
                        </li>)} />
                    <Can perform="sheet:list" yes={() => (<li><NavLink id="menu-sheet" to="/sheets" title="Recherche avancée"><i className="icon-nav-recherche-texte" aria-hidden="true"></i>&nbsp;Recherche avancée</NavLink></li>)} />
                    <Can perform="push:list" yes={() => (<li><NavLink id="menu-push" to="/push" title="Push consultant"><i className="icon-accueil-dernieres-fiches" aria-hidden="true"></i>&nbsp;Push consultant</NavLink></li>)} />
                    <Can perform="sheetaffected:list" yes={() => (<li><NavLink id="menu-sheet-affected" to="/sheetsaffected" title="Fonds documentaire"><i className="icon-th-list" aria-hidden="true"></i>&nbsp;Fonds documentaire</NavLink></li>)} />
                    <Can perform="account:list" yes={() => (<li><NavLink id="menu-accounts" to="/accounts" title="Comptes"><i className="icon-nav-arbo-point-veille" aria-hidden="true"></i>&nbsp;Comptes</NavLink></li>)} />
                    <Can perform="externaluser:list" yes={() => (<li><NavLink id="menu-externalusers" to="/externalusers" title="Utilisateurs externes"><i className="icon-nav-utilisateur-externe" aria-hidden="true"></i>&nbsp;Utilisateurs externes</NavLink></li>)} />
                    <Can perform="internaluser:list" yes={() => (<li><NavLink id="menu-internalusers" to="/internalusers" title="Utilisateurs internes"><i className="icon-nav-utilisateur-interne" aria-hidden="true"></i>&nbsp;Utilisateurs internes</NavLink></li>)} />
                    <Can perform="dispatch:write" yes={() => (<li><NavLink id="menu-dispatch-new" to="/dispatchs/new" title="Générer une dépêche"><i className="icon-nav-ma-veille" aria-hidden="true"></i>&nbsp;Générer une dépêche</NavLink></li>)} />
                    <Can perform="hotline:list"><li><NavLink id="menu-hotlines" to="/hotlines" title="Hotline"><i className="icon-nav-ma-hotline" aria-hidden="true"></i>&nbsp;Hotline</NavLink></li></Can>
                    <Can perform="referential:list" yes={() => (<li><NavLink id="menu-referentials" to="/referentials" title="Référentiels"><i className="icon-nav-gestion-categories" aria-hidden="true"></i>&nbsp;Référentiels</NavLink></li>)} />
                    <Can perform="configuration:write" yes={() => (<li><NavLink id="menu-configurations" to="/configurations" title="Configuration"><i className="icon-nav-mon-plan-actions" aria-hidden="true"></i>&nbsp;Configuration</NavLink></li>)} />
                    <Can perform="connectionhistory:list" yes={() => (<li><NavLink id="menu-connectionhistory" to="/connectionhistory" title="Historique des connexions"><i className="icon-nav-historique" aria-hidden="true"></i>&nbsp;Historique des connexions</NavLink></li>)} />
                    <Can perform="documentaryspace:list" yes={() => (
                        <li id="menu-documentaryspace-li" className="hassub">
                            <NavLink id="menu-documentaryspace" to="/documentaryspace" title="Espace documentaire"><i className="icon-nav-espace-documentaire" aria-hidden="true"></i>&nbsp;Espace documentaire</NavLink>
                            <button className="submenu-toggler" onClick={toggleDocumentarySpace}><i className="icon-right-open" aria-hidden="true"></i></button>
                            <ul>
                                <li><NavLink id="menu-documentaryspace-news" to="/documentaryspace/news" title="Espace documentaire > Actualité documentaire">Actualité documentaire</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-form" to="/documentaryspace/form" title="Espace documentaire > Formulaires et déclaration">Formulaires et déclaration</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-caselaw" to="/documentaryspace/caselaw" title="Espace documentaire > Jurisprudences">Jurisprudences</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-thematicfile" to="/documentaryspace/thematicfile" title="Espace documentaire > Dossiers et fiches thématiques">Dossiers et fiches thématiques</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-regulatorycontrol" to="/documentaryspace/regulatorycontrol" title="Espace documentaire > Contrôles réglementaires">Contrôles réglementaires</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-drafttext" to="/documentaryspace/drafttext" title="Espace documentaire > Projet de texte">Projet de texte</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-newsflash" to="/documentaryspace/newsflash" title="Espace documentaire > Flash d'information">Flash d'information</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-regulatoryflowchart" to="/documentaryspace/regulatoryflowchart" title="Espace documentaire > Organigrammes réglementaires">Organigrammes réglementaires</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-sanction" to="/documentaryspace/sanction" title="Espace documentaire > Sanctions">Sanctions</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-newsletter" to="/documentaryspace/newsletter" title="Espace documentaire > Newsletter">Newsletter</NavLink></li>
                                <li><NavLink id="menu-documentaryspace-clientspecificmodule" to="/documentaryspace/clientspecificmodule" title="Espace documentaire > Document privé">Document privé</NavLink></li>
                            </ul>
                        </li>
                    )} />
                    <Can perform="maillog:list" yes={() => (<li><NavLink id="menu-maillog" to="/maillogs" title="Historique des emails"><i className="icon-mail" aria-hidden="true"></i>&nbsp;Historique des emails</NavLink></li>)} />
                    <Can perform="conformityquizz:write"><li><NavLink id="menu-conformityquizz" to="/conformityquizz" title="Conformité par questionnaire"><i className="icon-documentaire-jurisprudence" aria-hidden="true"></i>&nbsp;Questionnaire</NavLink></li></Can>
                    <li>
                        <NavLink id="exports-list" to="/export" title="Mes exports">
                            <i className="icon-accueil-dernieres-fiches" aria-hidden="true">
                                {exportedFiles > 0 && <span class="badge badge-pill badge-danger">{exportedFiles}</span>}
                            </i>&nbsp;Mes exports
                        </NavLink>
                    </li>
                    <Can perform="obligationgenerator:write">
                        <li id="menu-obligationgenerator-li" className="hassub">
                            <NavLink id="menu-obligationgenerator" to="/obligationgenerator" title="Générateur d'obligations"><i className="icon-documentaire-jurisprudence" aria-hidden="true"></i>&nbsp;Générateur d'obligations</NavLink>
                            <button className="submenu-toggler" onClick={toggleObligationGenerator}><i className="icon-right-open" aria-hidden="true"></i></button>
                            <ul>
                                <li><NavLink id="menu-obligationgenerator-export" to="/obligationgeneratorform" title="Générateur d'obligations > Générateur">Générateur</NavLink></li>
                                <li><NavLink id="menu-obligationgenerator-import" to="/obligationgeneratorimport" title="Générateur d'obligations > Import">Import</NavLink></li>
                            </ul>
                        </li>
                    </Can>
                </>}
            </ul>
        </nav>
    )
}
