import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { denyIfCantPerform } from 'shared/services/Can';
import DefaultLayout from "layouts/DefaultLayout";
import NodeForm from 'views/node/Form';
import AccountApi from 'shared/api/Account';
import TreeUtils from 'shared/services/TreeUtils';
import PageLoadingComponent from 'shared/components/PageLoadingComponent';

export default function NodeFormWrapper(props) {
    denyIfCantPerform(props, "account:read");

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [tree, setTree] = useState({nodes: [], watchsites: []});
    const [account, setAccount] = useState({});
    const [node, setNode] = useState({});

    useEffect(() => {
        axios
            .all([
                AccountApi.getTree(props.match.params.account),
                AccountApi.get(props.match.params.account)
            ])
            .then(axios.spread((treeData, accountData) => {
                const nodeMapper = (n) => {
                    n.childs.map(nodeMapper);
                    if (parseInt(n.id) === parseInt(props.match.params.id)) {
                        setNode(n);
                    }
                    return n;
                };

                treeData = TreeUtils.populateReadablePaths(treeData);
                treeData.nodes.map(nodeMapper);

                setTree(treeData);

                setTree(TreeUtils.populateReadablePaths(treeData));

                accountData.pilot_consultants = accountData.pilot_consultants.map(c => c.id);
                accountData.associate_consultants = accountData.associate_consultants.map(c => c.id);
                accountData.domains = accountData.domains.map(d => d.id);
                setAccount(accountData);

                setLoading(false);
            }))
            .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <PageLoadingComponent label="Configuration du noeud" />
    } else {
        return (
            <DefaultLayout title="Configuration du noeud">
                <NodeForm
                    node={node}
                    account={account}
                    tree={tree}
                    onRequestClose={() => history.push("/accounts/" + props.match.params.account)}
                />
            </DefaultLayout>
        );
    }
}
