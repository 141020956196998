import React from 'react';
import _ from 'lodash';
import Select from 'react-select';

export default function Contracts(props) {

    const yearsOptions = _.range(new Date().getFullYear(), 2000).map(year => {
        return {"value": year, "label": year}
    });

    function getSelectedConsultant(contract) {
        let values = [];
        if (props.consultants) {
            Object.entries(props.consultants).forEach(([index, option]) => {
                if (option.value === contract.consultant) {
                    values.push(option);
                }
            });
        };
        return values;
    }

    function getSelectedYear(contract) {
        let values = [];
        Object.entries(yearsOptions).forEach(([index, option]) => {
            if (option.value === contract.year) {
                values.push(option);
            }
        });
        return values;
    }

    function updateYear(event, indexToUpdate) {
        let newContracts = [];
        Object.entries(props.contracts).forEach(([index, contract]) => {
            if (indexToUpdate === parseInt(index)) {
                contract.year = event.value;
            }
            newContracts.push(contract);
        });
        props.onChange(event, newContracts);
    }

    function updateAmount(event, indexToUpdate) {
        let newContracts = [];
        Object.entries(props.contracts).forEach(([index, contract]) => {
            if (indexToUpdate === parseInt(index)) {
                contract.amount = event.target.value;
            }
            newContracts.push(contract);
        });
        props.onChange(event, newContracts);
    }

    function updateConsultant(event, indexToUpdate) {
        let newContracts = [];
        Object.entries(props.contracts).forEach(([index, contract]) => {
            if (indexToUpdate === parseInt(index)) {
                contract.consultant = event.value;
            }
            newContracts.push(contract);
        });
        props.onChange(event, newContracts);
    }

    function add(event) {
        props.contracts.push({year: 0, amount: 0});
        props.onChange(event, props.contracts);
    }

    function remove(event, indexToRemove) {
        props.contracts.splice(indexToRemove, 1)
        props.onChange(event, props.contracts);
    }

    return (
        <React.Fragment>
            <div className="offset-label row">
                <div className="col-md-4"><label>Exercice</label></div>
                <div className="col-md-4"><label>Consultant</label></div>
                <div className="col-md-3"><label>Montant</label></div>
                <div className="col-md-1"></div>
            </div>            
            {props.contracts && props.contracts.map((contract, index) => 
                <div key={index} className="offset-label row">
                    <div className="col-md-3">
                        <Select
                            className="react-select-container field"
                            classNamePrefix="react-select"
                            placeholder=""
                            value={getSelectedYear(contract)}
                            name="year"
                            onChange={e => {updateYear(e, index);}}
                            options={yearsOptions}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={props.disabled}
                        />
                    </div>
                    <div className="col-md-4">
                        <Select
                            className="react-select-container field"
                            classNamePrefix="react-select"
                            placeholder=""
                            value={getSelectedConsultant(contract)}
                            name="consultant"
                            onChange={e => {updateConsultant(e, index);}}
                            options={props.consultants}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={props.disabled}
                        />
                    </div>
                    <div className="col-md-4">
                        <input className="field auto" type="number" onChange={e => {updateAmount(e, index);}} value={contract.amount} disabled={props.disabled} /> €
                    </div>
                    {!props.disabled && <div className="col-md-1 d-flex">
                        <button id="remove-contract" className="at-bottom btn btn-primary icon icon-only" type="button" onClick={e => {remove(e, index);}}>
                            <i className="icon-boutons-moins"></i>
                        </button>
                    </div>}
                </div>
            )}
            {!props.disabled && <div className="offset-label row">
                <div className="offset-md-11 col-md-1">
                    <button id="add-contract" type="button" className="btn btn-primary icon icon-only" onClick={add}><i className="icon-boutons-plus" aria-hidden="true"></i></button>
                </div>
            </div>}
        </React.Fragment>
    );
}
