import { React, useState, useEffect, toast } from 'commons';
import { useToggler, useForm } from "shared/hooks";
import { ToggableBlocComponent, LoadButton, FieldComponent } from "shared/components";
import { AccountApi, SheetAffectedApi } from 'shared/api';
import { compileDataToSelectOptions, compileErrorsFromResponse } from 'shared/services';

export default function SheetAffectedListFormBaseDuplication(props) {

    const [togglers, toggle] = useToggler({'configuration': true});
    const [duplicating, setDuplicating] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [treeOptions, setTreeOptions] = useState([]);
    const [data, errors, setErrors, setValue] = useForm({
        accountSource: null,
        source: null,
        target: props.perimeter.id,
        include_consultant_comment: 0,
        include_comment: 0,
        include_conformity: 0,
    });

    function load() {        
        AccountApi
            .search({}, 0, 99999, "name", "ASC")
            .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
            .catch(() => toast.error("Impossible de récupérer vos comptes."));        
    }

    function loadTree() {
        if (data.accountSource) {
            const watchsiteMapper = (watchsite) => {
                return {
                    "label": watchsite.label,
                    "value": watchsite.id,
                    "disabled": false,
                } 
            };
            const nodeMapper = (node) => {
                let children = node.childs.map(nodeMapper);
                children = children.concat(node.watchsites.map(watchsiteMapper));
                return {
                    "label": node.label,
                    "value": "node:" + node.id,
                    "children": children,
                    "disabled": true
                }
            };
            const cleanTree = (t) => {
                const cleanedTree = [];
                t.forEach(item => {
                    if (item.children) {
                        // node
                        item.children = cleanTree(item.children);
                        if (item.children.length > 0) {
                            cleanedTree.push(item);
                        }    
                    } else {
                        // watchsite
                        cleanedTree.push(item);
                    }
                    
                });
                return cleanedTree;
            }

            AccountApi
                .getTree(data.accountSource)
                .then(response => {
                    const cleanedOptions = cleanTree(response.nodes.map(nodeMapper))
                        .concat(response.watchsites.map(watchsiteMapper));

                    setTreeOptions(cleanedOptions);
                    setValue("source", null);
                })
                .catch(() => toast.error("Impossible de récupérer l'arborescence."));
        } else {
            setTreeOptions([]);
            setValue("source", null);
        }
    }

    function execute(event) {
        event.preventDefault();
        setDuplicating(true);

        SheetAffectedApi
            .duplicate(data)
            .then(() => {
                toast.success("La base est en cours de duplication. Vous serez prévénu par mail une fois le processus finalisé.");
                setDuplicating(false);
                props.onClose();
            })
            .catch(error => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Erreur lors de la duplication de la base.");
                }
                setDuplicating(false);
            });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(loadTree, [data.accountSource]);

    return <section>
        <h1>Sélectionner la base source à dupliquer vers la base {props.perimeter.label}</h1>
        <form className="form" id="base-duplication-form" onSubmit={execute}>
            <ToggableBlocComponent label="Configuration de la duplication" id="configuration" toggled={togglers["configuration"]} toggle={toggle}>
                <FieldComponent
                    name="include_consultant_comment"
                    label="Inclure le commentaire consultant"
                    type="radio"
                    value={data.include_consultant_comment}
                    options={[
                        {value: 1, label: "Oui"},
                        {value: 0, label: "Non"}
                    ]}
                    onChange={value => setValue("include_consultant_comment", parseInt(value))}
                />
                <FieldComponent
                    name="include_comment"
                    label="Inclure les commentaires clients"
                    type="radio"
                    value={data.include_comment}
                    options={[
                        {value: 1, label: "Oui"},
                        {value: 0, label: "Non"}
                    ]}
                    onChange={value => setValue("include_comment", parseInt(value))}
                />
                <FieldComponent
                    name="include_conformity"
                    label="Inclure la conformité"
                    title="Au global et en exigences : mode de conformité, conformité, date d'évaluation, date de revue, priorité"
                    type="radio"
                    value={data.include_conformity}
                    options={[
                        {value: 1, label: "Oui"},
                        {value: 0, label: "Non"}
                    ]}
                    onChange={value => setValue("include_conformity", parseInt(value))}
                />
                <FieldComponent
                    type="select"
                    label="Compte *"
                    value={data.accountSource}
                    name="accountSource"
                    onChange={value => setValue("accountSource", value)}
                    options={accounts}
                    clearable
                />
                <FieldComponent
                    type="dropdown-tree-select"
                    name="watchsite_source"
                    label="Point de veille *"
                    data={treeOptions}
                    value={data.source}
                    error={errors.source}
                    onChange={(value) => setValue("source", value)}
                    mode="radioSelect"
                />
            </ToggableBlocComponent>
            <section className="row">
                <div className="col">
                    <button type="button" id="back-list" onClick={() => props.onClose()} className="btn btn-bleu-4">
                        Retour à la liste
                    </button>
                </div>
                <div className="col text-right">
                    <LoadButton loading={duplicating} id="confirm-duplication" className="btn btn-primary" label="Dupliquer" />
                </div>
            </section>
        </form>
    </section>;
}