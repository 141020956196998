import { React, useEffect, useState, Modal, toast, fileDownload, Link } from 'commons';
import Affectations from 'views/sheet/detail/Affectations';
import { SheetApi } from 'shared/api';
import { PageLoadingComponent, LoadButton } from "shared/components";
import { SheetCommonDetail } from 'shared/partials';
import { mediumModalCustomStyles, Can, FileUtils } from 'shared/services';

export default function SheetDetail(props) {

    const [exporting, setExporting] = useState("");
    const [sheet, setSheet] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [affectationsOpened, openingAffectations] = useState(false);
            
    useEffect(() => {
        const sheetId = props.match.params.id;
        if (sheetId) {
            SheetApi
                .get(sheetId)
                .then(freshSheet => {
                    setSheet(freshSheet);
                    setIsLoad(false);
                });
        }
    }, [props]);

    function exportPdf(event) {
        event.preventDefault();
        setExporting("export-sheet");
        SheetApi
            .exportPdf(sheet.id)
            .then(binaries => {
                setExporting("");
                fileDownload(binaries, FileUtils.getFilePrefix() + "-Fiche" + sheet.id + '.pdf');
            })
            .catch(error => {
                setExporting("");
                toast.error(error.response.data.message);
            });
    }

    function exportRequirements() {
        setExporting("export-requirements");
        SheetApi
            .exportRequirements(sheet.id)
            .then(binaries => {
                setExporting("");
                fileDownload(binaries, FileUtils.getFilePrefix() + "-Fiche" + sheet.id + "-Exigences.xlsx");
            })
            .catch(error => {
                setExporting("");
                toast.error(error.response.data.message);
            });
    }

    if (isLoad === true) {
        return <PageLoadingComponent label="Détail fiche publique" />
    }

    return (
        <SheetCommonDetail displayTechnicalLevel sheet={sheet} backoffice title={sheet.is_private ? "Détail fiche privée" : "Détail fiche publique"} screen="E23">
            <section className="row">
                <div className="col-md-3">
                    <Can perform="sheet:write" yes={() => <>
                        {sheet.is_private && <Link id="goto-sheet-form" className="btn btn-primary" to={`/sheets/private/${sheet.id}`}>Modifier la fiche</Link>}
                        {!sheet.is_private && <Link id="goto-sheet-form" className="btn btn-primary" to={`/sheets/${sheet.id}`}>Modifier la fiche</Link>}
                    </>} />
                </div>
                <div className="col-md-9 text-right">
                    <Can perform="affectations:list" yes={() => <>
                        <button id="detail-affectations" onClick={() => openingAffectations(true)} className="btn btn-primary">Voir les affectations</button>
                    </>} />                 
                    <LoadButton 
                        loading={exporting === "export-sheet"} 
                        label={"Exporter en PDF"}
                        name="export-sheet"
                        id="export-sheet"
                        onClick={exportPdf}
                    />
                    {sheet.cut_into_requirements === true && (
                        <LoadButton 
                            loading={exporting === "export-requirements"} 
                            label={"Exporter les exigences"}
                            name="export-requirements"
                            id="export-requirements"
                            onClick={exportRequirements}
                        />
                    )}
                </div>
            </section>
            <Can perform="affectations:list" yes={() => <>
                <Modal 
                    isOpen={affectationsOpened}
                    onRequestClose={() => openingAffectations(false)}
                    style={mediumModalCustomStyles}>
                    
                    <Affectations sheet={sheet} onRequestClose={() => openingAffectations(false)} />
                    
                </Modal>
            </>} />
        </SheetCommonDetail>
    );
}
