import React from 'react';
import { Link} from 'react-router-dom';
import WatchsiteUtils from 'shared/services/WatchsiteUtils';
import { prefixLinkTo } from 'shared/services';

export default function TreeWatchsiteComponent(props) {

    const isActive = WatchsiteUtils.isActive(props.item);
    const className = isActive ? "" : "color-rouge";

    return (
        <li id={`watchsite-${props.item.id}`} data-type="watchsite" data-id={props.item.id} className={`draggable ${className}`} draggable="true" onDragStart={props.onDragStart}>
            {!props.disabled && <input 
                type="checkbox"
                id={`watchsite-${props.item.id}-selection`}
                value={props.item.id}
                onChange={props.onSelect}
                checked={props.selected.includes(props.item.id)}
            />}
            <label htmlFor={`watchsite-${props.item.id}-selection`}>
                <Link 
                    id={`watchsite-${props.item.id}-link`}
                    className={className}
                    onClick={e => props.onWatchsiteClick(e, props.item)}
                    to={prefixLinkTo() + `/accounts/${props.account.id}/watchsites/${props.item.id}`}
                >
                    {props.item.label}
                    {!isActive && (<i className="icon-autres-temps-depasse" title="Point de veille expiré ou désactivé"></i>)}
                </Link>
            </label>            
        </li>
    )
}