import { React, DatePicker, BarLoader, Link, toast, _, fileDownload, DefaultLayout, useState, useEffect } from 'commons';
import { SheetApi, RequirementApi } from 'shared/api';
import { useList, usePosition } from 'shared/hooks';
import { ListComponent, FilterComponent, StatusComponent, HtmlComponent, PageLoadingComponent, LoadButton } from 'shared/components';
import { FileUtils, Can, denyIfCantPerform, formatDate, stripTagsAndCrop, preventDefault } from 'shared/services';
import { getStatus, getStatusLabel, getStatusColor } from 'data/RequirementStatus';
import { getConformityLabel, getConformityColor } from 'data/RequirementConformity';
import RequirementImportForm from 'views/requirement/import/Form';
import fr from "date-fns/locale/fr";
import { format } from 'date-fns';
import { getApplicabilities, getApplicabilityColor } from 'data/RequirementApplicability';

export default function RequirementList(props) {

    denyIfCantPerform(props, "requirement:list");

    const [initializePosition, setPosition] = usePosition("requirement-list");
    const columns = [
        {id: 'position', title: 'Position', className: "w-147px", render: (row) => <>
            {updatingPosition === row.id && <BarLoader loading={true} width={20} color="#5bad27" css="display:inline-block;margin-left: 10px;" />}
            {updatingPosition !== row.id && <>
                <input 
                    className="field w-57px"
                    type="text"
                    id={`position-${row.id}`}
                    value={row.position}
                    onChange={(e) => {
                        row.position = e.target.value;
                        forceRefresh((refreshing + 1));
                    }}
                    disabled={updatingPosition !== null}
                />
                {updatingPosition === null && <>
                    <button 
                        type="button"
                        className="no-style"
                        onClick={(e) => updatePosition(row.id, document.getElementById("position-" + row.id).value)}
                    >
                        <i className={"icon-boutons-enregistrer" + (row.iconClassName || "")} />
                    </button>
                    {row.position > 0 && <button
                        className="no-style"
                        type="button"
                        title="Monter"
                        onClick={(e) => updatePosition(row.id, (row.position - 1))}>
                            <i className={"icon-up-open" + (row.iconClassName || "")} aria-hidden="true" />
                    </button>}
                    <button
                        className="no-style"
                        type="button"
                        title="Descendre"
                        onClick={(e) => updatePosition(row.id, (row.position + 1))}>
                            <i className={"icon-down-open" + (row.iconClassName || "")} aria-hidden="true" />
                    </button>
                </>}
            </>}
        </>},
        {id: 'id', title: 'Numéro'},
        {id: 'section.title', title: 'Section', sortable: false, render: (row) => <>{getRequirementPath(row).join(" / ")}</>},
        {id: 'article', title: 'Article'},
        {id: 'description', title: 'Exigence', render: (row) => <>            
            <div className="d-none">
                <HtmlComponent>{stripTagsAndCrop(row.description, 100)}</HtmlComponent>
            </div>
            <div>
                <HtmlComponent>{row.description}</HtmlComponent>
            </div>
            <button
                style={{float: "right"}}
                onClick={(e) => {
                    e.target.previousSibling.classList.toggle("d-none"); 
                    e.target.previousSibling.previousSibling.classList.toggle("d-none");
                    e.target.classList.toggle("actif"); 
                }}
                className={"toggler " + (row.iconClassName || "color-vert-apave")} type="button">
            </button>

        </>},
        {id: 'applicability', title: 'Plage d’applicabilité', sortable: false, render: (row) => {
            let start = "";
            let end = "";
            if (row.applicability_start_at) {
                start = "Du " + formatDate(row.applicability_start_at)
            }
            if (row.applicability_end_at) {
                end = "Au " + formatDate(row.applicability_end_at)
            }
            return <>{start}{start && <br />}{end}</>;
        }},
        {id: 'default_conformity', title: 'Conformité par défaut', className:'text-center', render: (row) => 
            <StatusComponent 
                value={getConformityLabel(row.default_conformity)} 
                color={getConformityColor(row.default_conformity)} 
            />
        },
        {id: 'status', title: 'Statut', className:'text-center', render: (row) => 
            <StatusComponent 
                value={getStatusLabel(row.status)} 
                color={getStatusColor(row.status)} 
            />
        },
        {id: 'action', title: 'Actions', sortable: false, render: (row) =>
            <ul className="actions">
                <Can perform="requirement:read" data={row} yes={() =>
                    <li>
                        <Link title="Modifier" to={`/sheets/${sheet.id}/requirements/${row.id}`} id="edit-requirement">
                            <i className={"icon-actions-modifier" + (row.iconClassName || "")} aria-hidden="true"></i>
                        </Link>
                    </li>
                } />
            </ul>
        }
    ];

    const filterType = {
        status: {type: "terms", fields: ["status"]},
        sheet: {type: "match", fields: ["sheet"]},
        applicability_state: {type: "terms", fields: ["applicability_state"]}
    };

    const [batch_new_value, setBatchNewValue] = useState();
    const [current_batch, setCurrentBatch] = useState();
    const [exporting, setExporting] = useState(false);
    const [batch_processing, setBatchProcessing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [updatingPosition, setUpdatingPosition] = useState(null);
    const [sheet, setSheet] = useState();
    const [selection, setSelection] = useState([]);
    const [refreshing, forceRefresh] = useState(1)
    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        listloading,
        setListLoading,
        refresh,
    ] = useList(
        "requirement-list-" + props.match.params.sheet,
        RequirementApi,
        null,
        "position",
        {
            "sheet": parseInt(props.match.params.sheet),
            "status": [0, 1],
            "applicability_state": [0, 1, 2]
        },
        "asc",
        "999999999",
        null,
        null,
        modifyRows
    );

    useEffect(() => {
        addCriteria("sheet", parseInt(props.match.params.sheet));
        let newcriterias = {...criterias};
        newcriterias.sheet = parseInt(props.match.params.sheet);

        SheetApi
            .get(props.match.params.sheet)
            .then(data => {
                setSheet(data);
                setLoading(false);

                submitSearch(filterType, () => initializePosition());
            })
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getRequirementPath(requirement) {
        let path = [];
        const iter = (section) => {            
            if (section.parent) {
                iter(section.parent);
            }
            path.push(section.title);
        };
        if (requirement.section) {
            iter(requirement.section);
        }
        return path;
    }

    function modifyRows(rows) {
        rows.forEach(row => {
            row.className = "bg-" + getApplicabilityColor(row.applicability_state, true);
        });
        return rows;
    }

    function refreshAfterSucceed() {
        setListLoading(true);
        refresh();
    }

    function updateSelection(newSelection) {
        setSelection(newSelection);
    }

    function executeBatch() {
        if (!batch_new_value && current_batch === "update_status") {
            toast.error("Veuillez indiquer une valeur");
        } else {
            setBatchProcessing(true);
            let field = "";
            let value = batch_new_value;
            switch (current_batch) {
                case "update_applicability_start_at": 
                    field = "applicability_start_at";
                    value = batch_new_value ? format(batch_new_value, 'yyyy-MM-dd') : null;
                    break;
                case "update_applicability_end_at":
                    field = "applicability_end_at";
                    value = batch_new_value ? format(batch_new_value, 'yyyy-MM-dd') : null;
                    break;
                case "update_status":
                    field = "status";
                    break;
                default: return false;
            }
    
            RequirementApi
                .bulkUpdate(selection, {[field]: value})
                .then(response => {
                    toast.success("Exigences modifiées");
                    setBatchProcessing(false);
                    setSelection([]);
                    setListLoading(true);

                    _.delay(refreshAfterSucceed, 1000); // solr indexation
                })
                .catch(error => { 
                    setBatchProcessing(false);
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);                    
                    } else {
                        Object.entries(error.response.data).forEach(([index, dataerror]) => {
                            toast.error(dataerror.message);
                        });
                    }
                });
        }
        
    }

    function updatePosition(id, position) {
        setListLoading(true);
        setUpdatingPosition(id);
        RequirementApi
            .updatePosition(id, position)
            .then(d => { 
                toast.success("Position modifiée");
                _.delay(() => setUpdatingPosition(null)); // solr indexation
                _.delay(refreshAfterSucceed, 1000); // solr indexation
            })
            .catch(error => {
                setListLoading(false); 
                setUpdatingPosition(null);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                }
                if (error.response.data[0].message) {
                    toast.error(error.response.data[0].message);
                }                
            });
    }


    function exportRequirements() {
        setExporting(true);
        SheetApi
            .exportRequirements(sheet.id)
            .then(binaries => {
                setExporting(false);
                fileDownload(binaries, FileUtils.getFilePrefix() + "-Fiche" + sheet.id + "-Exigences.xlsx");
            })
            .catch(error => {
                setExporting(false);
                toast.error(error.response.data.message);
            });
    }  

    if (loading) {
        return (
            <PageLoadingComponent label="Exigences de la fiche" />
        );
    } else {
        const batchActions = (
            <>
                <select id="batch-requirements" onChange={(e) => {setBatchNewValue(null); setCurrentBatch(e.target.value)} }>
                    <option></option>
                    <option value="update_applicability_start_at">Modifier la date de début d'applicabilité</option>
                    <option value="update_applicability_end_at">Modifier la date de fin d'applicabilité</option>
                    <option value="update_status">Modifier le statut des exigences</option>
                </select>
                {current_batch === "update_applicability_start_at" && 
                    <>
                        <DatePicker 
                            id="batch-requirements-start"
                            locale={fr}
                            onChange={v => setBatchNewValue(v)}
                            selected={batch_new_value}
                            dateFormat={"dd/MM/yyyy"}
                            placeholderText="dd/mm/yyyy"
                        />
                    </>}
                {current_batch === "update_applicability_end_at" && 
                    <>
                        <DatePicker 
                            id="batch-requirements-end"
                            locale={fr}
                            onChange={v => setBatchNewValue(v)}
                            selected={batch_new_value}
                            dateFormat={"dd/MM/yyyy"}
                            placeholderText="dd/mm/yyyy"
                        />
                    </>}
                {current_batch === "update_status" && 
                    <>
                        <select id="batch-requirements-status" value={batch_new_value || ""} onChange={(e) => setBatchNewValue(e.target.value)}>
                            <option value=""></option>
                            {getStatus().map(status => <option key={status.value} value={status.value}>{status.label}</option>)}
                        </select>
                    </>}
                {current_batch &&
                    <LoadButton
                        loading={batch_processing}
                        onClick={executeBatch}
                        label="Configurer"
                        name="execute-requirements-batch"
                        id="execute-requirements-batch"
                        className="btn btn-primary h25"
                        iconClassName=""
                        displayIcon={false}
                />}
            </>
        )

        return (
            <DefaultLayout onScroll={(position) => setPosition(position)} screen="E16" title={`Gestion des exigences de la fiche ${sheet.id}`}>
                <h1>Gestion des exigences de la fiche {sheet.id}</h1>
                <div className="">
                    <HtmlComponent>{sheet.title}</HtmlComponent>
                </div>
                <div className="bloc">
                    <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                        <section className="filters">
                            <header>
                                <h2>Recherche</h2>
                            </header>
                            <div className="bg-gris-25">
                                <div className="row">
                                    <FilterComponent
                                        type="select"
                                        multiple={true}
                                        colClassName="offset-4 col-md-4"
                                        label="Statut"
                                        name="status"
                                        onChange={value => addCriteria("status", value)}
                                        value={criterias.status}
                                        clearable
                                        options={getStatus()}
                                    />
                                    <FilterComponent
                                        type="select"
                                        multiple={true}
                                        colClassName="offset-4 col-md-4"
                                        label="Etat d'applicatibilité"
                                        name="applicability_state"
                                        onChange={value => addCriteria("applicability_state", value)}
                                        value={criterias.applicability_state}
                                        clearable
                                        options={getApplicabilities()}
                                    />
                                </div>
                            </div>
                            <div className="bg-gris-25 border-b border-gris-60">
                                <div className="row">
                                    <div className="col-md-9">
                                        <button id="clearfilter-requirement" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button id="search-internaluser" type="submit" className="btn btn-primary">Rechercher</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ListComponent 
                            id="requirements"
                            loading={listloading}
                            selectable={true}
                            selection={selection}
                            onSelect={updateSelection}
                            rows={rows}
                            columns={columns}
                            sorting={sorting}
                            direction={direction}
                            onSortingChange={updateSorting}
                            sortOptions={[
                                {value: "applicability_start_at", label: "Date de début d'applicabilité"},
                                {value: "applicability_end_at", label: "Date de fin d'applicabilité"}
                            ]}
                            perpage={limit}
                            onPerpageChange={updateLimit}
                            paginable={false}
                            page={page}
                            onPageChange={updatePage}
                            totalrows={totalrows}
                            batchActions={batchActions}
                        >
                            <Link
                                id="new-requirement"
                                to={`/sheets/${sheet.id}/requirements/new`}
                                className="btn btn-primary h25 icon"
                            >
                                <i className="icon-boutons-creer-acces" aria-hidden="true"></i>&nbsp;Créer une exigence
                            </Link>
                            <LoadButton
                                loading={exporting}
                                onClick={exportRequirements}
                                label="Exporter"
                                name="export-requirements"
                                id="export-requirements"
                                className="btn btn-primary h25"
                                iconClassName="icon-file-excel"
                            />
                        </ListComponent>

                        <RequirementImportForm sheet={sheet} onSuccess={() => _.delay(refreshAfterSucceed, 1000)} />
                    </form>
                </div>
                <section className="row">
                    <div className="col">
                        {sheet.is_private && <Link id="back-to-sheet" to={`/sheets/private/${sheet.id}`} className="btn btn-bleu-4">Retour à la fiche</Link>}
                        {!sheet.is_private && <Link id="back-to-sheet" to={`/sheets/${sheet.id}`} className="btn btn-bleu-4">Retour à la fiche</Link>}
                    </div>
                </section>
            </DefaultLayout>
        );
    }
}