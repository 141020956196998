import AccountForm from "views/account/Form";
import AccountList from "views/account/List";
import Cgu from "shared/views/Cgu";
import ConfigurationForm from "views/configuration/Form";
import ConnectionHistoryList from "views/connectionhistory/List";
import Denied from "shared/views/Denied";
import ExternalUserForm from "views/externaluser/Form";
import ExternalUserList from "views/externaluser/List";
import HistoryList from "shared/views/history/List";
import Home from "views/Home";
import HotlineList from 'views/hotline/List';
import InternalUserForm from "views/internaluser/Form";
import InternalUserList from "views/internaluser/List";
import Logout from "views/Logout";
import MailLogList from "views/maillog/List";
import NodeFormWrapper from "views/node/FormWrapper";
import Push from "views/push/Push";
import ReferentialForm from "views/referential/Form";
import ReferentialList from "views/referential/List";
import RequirementForm from "views/requirement/Form";
import RequirementList from "views/requirement/List";
import SheetDetail from "views/sheet/Detail";
import SheetForm from "views/sheet/Form";
import SheetList from "views/sheet/List";
import SheetPrivateForm from "views/sheet/PrivateForm";
import SheetDashboard from "views/sheet/Dashboard";
import SheetAffectedList from "views/sheetaffected/List";
import Status from "views/Status";
import WatchsiteFormWrapper from "views/watchsite/FormWrapper";
import HotlineForm from 'views/hotline/Form';
import LegifranceList from 'views/legifrance/List';
import DocumentarySpaceMenu from "views/documentaryspace/Menu";
import DocumentarySpaceList from "views/documentaryspace/List";
import DocumentarySpaceForm from "views/documentaryspace/Form";
import DispatchForm from "views/dispatch/Form";
import DispatchList from "views/dispatch/List";
import ConformityquizzForm from "views/conformityquizz/Form";
import ExportList from "shared/views/export/List";
import ObligationGeneratorMenu from "views/obligationgenerator/Menu";
import ObligationGeneratorFormWrapper from "views/obligationgenerator/FormWrapper";
import ObligationGeneratorImport from "views/obligationgenerator/Import";

import Login from "views/Login";
import AdslLogin from "views/AdslLogin";
import Loader from "views/Loader";
import UserValidation from "views/UserValidation";
import Maintenance from "views/Maintenance";

const routes = [
    {path: "/", breadcrumb: "Accueil", component: Home},
    {path: "/status", breadcrumb: "Statut", component: Status},
    {path: "/logout", breadcrumb: "Déconnexion", component: Logout},
    {path: "/accounts", breadcrumb: "Comptes", component: AccountList},
    {path: "/accounts/new", breadcrumb: "Nouveau compte", component: AccountForm},
    {path: "/accounts/:id", breadcrumb: "Modification du compte", component: AccountForm},
    {path: "/accounts/:account/nodes/:id", breadcrumb: "Modification d'un noeud", component: NodeFormWrapper},
    {path: "/accounts/:account/watchsites/:id", breadcrumb: "Modification d'un point de veille", component: WatchsiteFormWrapper},
    {path: "/externalusers", breadcrumb: "Utilisateurs externes", component: ExternalUserList},
    {path: "/externalusers/new", breadcrumb: "Nouvel utilisateur externe", component: ExternalUserForm},
    {path: "/externalusers/:id", breadcrumb: "Modification utilisateur externe", component: ExternalUserForm},
    {path: "/internalusers", breadcrumb: "Utilisateurs internes", component: InternalUserList},
    {path: "/internalusers/new", breadcrumb: "Nouvel utilisateur interne", component: InternalUserForm},
    {path: "/internalusers/:id", breadcrumb: "Modification utilisateur interne", component: InternalUserForm},
    {path: "/referentials", breadcrumb: "Référentiels", component: ReferentialList},
    {path: "/referentials/new-:type", breadcrumb: "Ajout d'une valeur", component: ReferentialForm},
    {path: "/referentials/:id", breadcrumb: "Modification d'une valeur", component: ReferentialForm},
    {path: "/configurations", breadcrumb: "Configuration", component: ConfigurationForm},
    {path: "/dashboard", breadcrumb: "Tableau de bord", component: SheetDashboard},
    {path: "/sheets", breadcrumb: "Recherche avancée", component: SheetList},
    {path: "/sheets/new", breadcrumb: "Nouvelle fiche", component: SheetForm},
    {path: "/sheets/:id", breadcrumb: "Modification d'une fiche", component: SheetForm},
    {path: "/sheets/:id/preview", breadcrumb: "Consultation d'une fiche", component: SheetDetail},
    {path: "/sheets/:sheet/requirements", breadcrumb: "Exigences", component: RequirementList},
    {path: "/sheets/:sheet/requirements/new", breadcrumb: "Nouvelle exigence", component: RequirementForm},
    {path: "/sheets/:sheet/requirements/:id", breadcrumb: "Modifier une exigence", component: RequirementForm},
    {path: "/sheets/private/new", breadcrumb: "Nouvelle fiche privée", component: SheetPrivateForm},
    {path: "/sheets/private/:id", breadcrumb: "Modification fiche privée", component: SheetPrivateForm},
    {path: "/sheetsaffected", breadcrumb: "Fonds documentaire", component: SheetAffectedList},    
    {path: "/configurations", breadcrumb: "Configuration", component: ConfigurationForm},
    {path: "/connectionhistory", breadcrumb: "Historique des connexions", component: ConnectionHistoryList},
    {path: "/maillogs", breadcrumb: "Historique des emails", component: MailLogList},
    {path: "/history/:type/:id", breadcrumb: "Historique", component: HistoryList},
    {path: "/push", breadcrumb: "Push consultant", component: Push},
    {path: "/cgu", breadcrumb: "Conditions générales d'utilisation", component: Cgu},
    {path: "/denied", breadcrumb: "Accès refusé", component: Denied},
    {path: "/hotlines", breadcrumb: "Liste des hotlines", component: HotlineList},
    {path: "/hotlines/new", breadcrumb: "Création d'une hotline", component: HotlineForm},
    {path: "/hotlines/:id", breadcrumb: "Edition d'une hotline", component: HotlineForm},
    {path: "/legifrance", breadcrumb: "Import Légifrance", component: LegifranceList},
    {path: "/documentaryspace", breadcrumb: "Espace documentaire", component:DocumentarySpaceMenu},
    {path: "/documentaryspace/thematicfile", breadcrumb: "Dossiers et fiches thématiques", component: DocumentarySpaceList},    
    {path: "/documentaryspace/thematicfile/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/thematicfile/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/caselaw", breadcrumb: "Jurisprudence", component: DocumentarySpaceList},
    {path: "/documentaryspace/caselaw/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/caselaw/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/clientspecificmodule", breadcrumb: "Module spécifique client", component: DocumentarySpaceList},
    {path: "/documentaryspace/clientspecificmodule/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/clientspecificmodule/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/drafttext", breadcrumb: "Projet de texte", component: DocumentarySpaceList},
    {path: "/documentaryspace/drafttext/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/drafttext/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/form", breadcrumb: "Formulaire / déclaration (CERFA et notice)", component: DocumentarySpaceList},
    {path: "/documentaryspace/form/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/form/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/news", breadcrumb: "Actualité documentaire", component: DocumentarySpaceList},
    {path: "/documentaryspace/news/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/news/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/newsflash", breadcrumb: "Flash d'information", component: DocumentarySpaceList},
    {path: "/documentaryspace/newsflash/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/newsflash/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/newsletter", breadcrumb: "Newsletter", component: DocumentarySpaceList},
    {path: "/documentaryspace/newsletter/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/newsletter/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/regulatorycontrol", breadcrumb: "Contrôle règlementaire", component: DocumentarySpaceList},
    {path: "/documentaryspace/regulatorycontrol/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/regulatorycontrol/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/regulatoryflowchart", breadcrumb: "Organigramme règlementaire", component: DocumentarySpaceList},
    {path: "/documentaryspace/regulatoryflowchart/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/regulatoryflowchart/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/sanction", breadcrumb: "Sanction", component: DocumentarySpaceList},
    {path: "/documentaryspace/sanction/new", breadcrumb: "Nouveau document", component: DocumentarySpaceForm},
    {path: "/documentaryspace/sanction/:id", breadcrumb: "Modifier un document", component: DocumentarySpaceForm},
    {path: "/export", breadcrumb: "Mes exports", component: ExportList},
    {path: "/obligationgenerator", breadcrumb: "Générateur d'obligations", component: ObligationGeneratorMenu},
    {path: "/obligationgeneratorform", breadcrumb: "Générateur d'obligations > Générateur", component: ObligationGeneratorFormWrapper},
    {path: "/obligationgeneratorimport", breadcrumb: "Générateur d'obligations > Import", component: ObligationGeneratorImport},

    {path: "/dispatchs", breadcrumb: "Dépêches", component: DispatchList},
    {path: "/dispatchs/new", breadcrumb: "Générer d'une dépêche", component: DispatchForm},
    {path: "/dispatchs/:id", breadcrumb: "Modification d'une dépêche", component: DispatchForm},
    {path: "/conformityquizz", breadcrumb: "Conformité par questionnaire", component: ConformityquizzForm},    

    {path: "/login", breadcrumb: "Connexion", component: Login},
    {path: "/adsllogin", breadcrumb: "Connexion", component: AdslLogin},
    {path: "/loader", breadcrumb: "Chargement", component: Loader},
    {path: "/users/validation/:hash", breadcrumb: "Validation d'un utilisateur", component: UserValidation},
    {path: "/maintenance", breadcrumb: "Maintenance", component: Maintenance},
];

export default routes;