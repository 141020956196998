import axios from 'axios';

const StatusApi = {

    status: function() {
        return axios
            .get(
                process.env.REACT_APP_BACKEND_URL + "status",
                {
                    withCredentials: true,
                    headers: {'Content-Type': 'application/json'}
                }
            )
            .then(response => response.data);
    }
}
export default StatusApi;
