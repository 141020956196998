import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Prompt } from "react-router-dom";
import { toast } from 'react-toastify';

import { useForm } from 'shared/hooks/Form';
import { useSecurity } from 'shared/hooks/Security';
import { useToggler } from "shared/hooks/Toggler";

import { getCodeNaf } from 'data/CodeNaf';

import LoadButton from 'shared/components/LoadButton';
import FileComponent from 'shared/components/FileComponent';
import FieldComponent from 'shared/components/FieldComponent';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";

import AccountApi from 'shared/api/Account';
import WatchsiteApi from 'shared/api/Watchsite';

import Can, { canPerform } from 'shared/services/Can';
import { compileErrorsFromResponse } from 'shared/services/Utils';
import AccountAutocomplete from 'shared/services/autocomplete/Account';

import WatchsiteUsersList from 'views/watchsite/form/WatchsiteUsersList';

export default function WatchsiteForm(props) {
    
    const [user,,,, isAdmin] = useSecurity();
    const [togglers, toggle] = useToggler({'watchsite-configuration': true, 'watchsite-transfer': true, 'watchsite-users': true});    
    const [saving, setSaving] = useState("");
    const [loadingTransfer, setLoadingTransfer] = useState(false);
    const [transfertAccount, setTransfertAccount] = useState();
    const [canActivateWatchsite, setCanActivateWatchsite] = useState(false);

    const consultants = props.consultants;
    consultants.sort((a, b)  => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1);

    const watchsite = Object.assign(
        {},
        {
            "label": "",
            "active": 0,
            "logo": null,
            "node": null,
            "siret": "",
            "subscription_begin": null,
            "subscription_end": null,
            "naf": "",
            "description": "",
            "address": "",
            "zipcode": "",
            "city": "",
            "affectation_mode": 0,
            "consultants": _.isNil(props.watchsite.id) && user.roles.includes("ROLE_CONSULTANT") ? [user.id] : [],
            "attachments": []
        },
        props.watchsite
    );

    if (watchsite.subscription_begin) watchsite.subscription_begin = new Date(watchsite.subscription_begin);
    if (watchsite.subscription_end) watchsite.subscription_end = new Date(watchsite.subscription_end);

    const [data, errors, setErrors, setValue, setData,, formHasModifications, setHasModifications] = useForm(watchsite);

    const mapper = (node) => {
        return {
            "label": node.label,
            "value": node.id,
            "children": node.childs.map(mapper),
            "checked": (data.node === node.id)
        }
    };
    const tree = props.tree.nodes.map(mapper);

    useEffect(() => {
        if (props.account.is_start) {
            checkActivationRestriction();
        } else {
            setCanActivateWatchsite(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function checkActivationRestriction() {
        AccountApi.getTree(props.account.id)
            .then(data => {
                let otherActiveWatchsites = data.watchsites.filter(w => (w.id !== watchsite.id && w.active === 1));
                if (otherActiveWatchsites.length === 0) {
                    setCanActivateWatchsite(true);
                }
            })
            .catch(error => console.log(error));
    }

    function submit(event) { 
        event.preventDefault();

        setSaving(event.nativeEvent.submitter.name);
        
        let apiData = {...data};
        apiData.logo = data.logo ? data.logo.hash : null;
        apiData.attachments = [];
        apiData.account = props.account.id;
        data.attachments.forEach(a => { apiData.attachments.push(a.hash); });

        WatchsiteApi
            .save(apiData)
            .then(newData => {
                setErrors({});
                setSaving("");
                setHasModifications(false);
                setData(Object.assign({}, data, {"id": newData.id}));
                toast.success("Enregistrement effectué.");
                
                if (event.nativeEvent.submitter.name === "save-and-close") {
                    props.onRequestClose();
                }
            })
            .catch(error => {
                setSaving("");
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    function transfer() {
        if (transfertAccount) {
            setLoadingTransfer(true);
            WatchsiteApi
                .transfer(data.id, transfertAccount)
                .then(() => {
                    setLoadingTransfer(false);
                    toast.success("Transfert effectué");
                    props.onRequestClose();
                })
                .catch(error => {
                    setLoadingTransfer(false);
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        Object.entries(error.response.data).forEach(([, error]) => {
                            toast.error(error.message);
                        });
                    }
                });
        }
    }

    function promptChecker() {
        if (props.promptChecker) {
            return props.promptChecker() || formHasModifications();
        } else {
            return formHasModifications();
        }
    }

    function subscriptionBeginChange(value) {
        if (props.account.is_start && value !== null && data.subscription_end === null) {
            let dateEnd = new Date(value);
            dateEnd.setFullYear(dateEnd.getFullYear() + 1);
            data.subscription_end = dateEnd;
        }
        setValue("subscription_begin", value);
    }

    function getAvailableAffectationMode() {
        if (props.account.is_start) {
            return [
                {value: 0, label: "Immédiat"}
            ];
        } else {
            return [
                {value: 0, label: "Immédiat"},
                {value: 1, label: "Différé"}
            ];
        }
        
    }

    return (
        <section>
            <Prompt
                when={promptChecker()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>Configuration du point de veille</h1>
            <form className="form" id="form-watchsite" onSubmit={submit}>
                <ToggableBlocComponent label="Configuration" id="watchsite-configuration" toggled={togglers["watchsite-configuration"]} toggle={toggle} wrapChildren={false}>
                    <div className="border-gris-25 border-lrb bg-gris-20">
                        <div className="flex-label">
                            <label>Compte associé</label>
                            {props.account.name}
                        </div>
                        {data.id && data.readablePaths &&
                            <div className="flex-label">
                                <label>Chemin</label>
                                {data.readablePaths.join(" / ")}
                            </div>}
                    </div>
                    <div className="border-gris-25 border-lrb bg-blanc">                
                        <FieldComponent 
                            prefixId="watchsite-"
                            label="Raison sociale *"
                            className="field small"
                            name="label"
                            error={errors.label}
                            value={data.label}
                            onChange={value => setValue("label", value)}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            type="checksingle"
                            name="active"
                            label="Activé"
                            error={errors.active}
                            checksingleLabel="Oui"
                            onChange={value => setValue("active", (value ? 1 : 0))}
                            checked={data.active}
                            disabled={canActivateWatchsite !== true || !canPerform("account:write", props.account)}
                        >
                            {(props.account.is_start && canActivateWatchsite !== true) &&
                                <span class="color-rouge">Les comptes start ne peuvent avoir qu'un seul point de veille actif à la fois. Veuillez désactiver vos autres points de veille pour pouvoir activer celui-ci.</span>
                            }
                        </FieldComponent>
                        <FileComponent
                            label="Logo"
                            extensions={[".png", ".jpg", ".jpeg", ".gif"]}
                            value={data.logo}
                            name="logo"
                            onChange={value => setValue("logo", value)}
                            upload={WatchsiteApi.uploadLogo}   
                            disabled={!canPerform("account:write", props.account)}                                         
                        />
                        <FieldComponent 
                            prefixId="watchsite-"
                            type="dropdown-tree-select"
                            label="Noeud"
                            className="field small"
                            name="node"
                            data={tree}
                            mode="radioSelect"
                            onChange={value => setValue("node", value)}
                            value={data.node}
                            disabled={!canPerform("account:write", props.account) || props.account.is_start}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            label="Siret"
                            className="field small"
                            name="siret"
                            value={data.siret}
                            onChange={value => setValue("siret", value)}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            type="date"
                            name="subscription_begin"
                            className="field small"
                            label="Date de début d'abonnement *"
                            error={errors.subscription_begin}
                            onChange={value => subscriptionBeginChange(value)}
                            value={data.subscription_begin}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            type="date"
                            name="subscription_end"
                            className="field small"
                            label="Date de fin d'abonnement *"
                            error={errors.subscription_end}
                            onChange={value => setValue("subscription_end", value)}
                            value={data.subscription_end}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            type="select"
                            name="naf"
                            label="Code NAF"
                            className="field small"
                            onChange={value => setValue("naf", value)}
                            value={data.naf}
                            options={getCodeNaf().map(({code, label}) => { return {"label": label, "value": code}})}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            type="textarea"
                            name="address"
                            className="field h-100px"
                            label="Adresse *"
                            error={errors.address}
                            onChange={value => setValue("address", value)}
                            value={data.address}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            name="zipcode"
                            label="Code postal *"
                            className="field small"
                            error={errors.zipcode}
                            onChange={value => setValue("zipcode", value)}
                            value={data.zipcode}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            name="city"
                            label="Ville *"
                            className="field small"
                            error={errors.city}
                            onChange={value => setValue("city", value)}
                            value={data.city}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            type="select"
                            name="affectation_mode"
                            className="field small"
                            label="Mode d'affectation"
                            onChange={value => setValue("affectation_mode", parseInt(value))}
                            value={data.affectation_mode}
                            options={getAvailableAffectationMode()}
                            disabled={!canPerform("account:write", props.account) || props.account.is_start}
                        />
                        <FileComponent
                            label="Pièces jointes"
                            extensions={[".png", ".jpg", ".jpeg", ".gif", ".doc", ".docx", ".pdf", ".xls", ".xlsx", ".ppt", ".pptx"]}
                            value={data.attachments}
                            name="attachments"
                            multiple
                            onChange={value => setValue("attachments", value)}
                            upload={WatchsiteApi.uploadAttachments} 
                            disabled={!canPerform("account:write", props.account)}                                 
                        />
                        <FieldComponent
                            prefixId="watchsite-"
                            type="textarea"
                            name="description"
                            className="field h-100px"
                            label="Description"
                            onChange={value => setValue("description", value)}
                            value={data.description}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        {props.account.is_start && <div class="flex-label">
                            <strong class="label">Consultants&nbsp;:</strong>
                            <span>
                                <input class="field" type="text" disabled value="Pilot Veille Start" />
                            </span>
                        </div>}
                        {!props.account.is_start && <>
                            <FieldComponent
                                type="select"
                                multiple={true}
                                name="consultants"
                                label="Consultants *"
                                onChange={values => setValue("consultants", values)}
                                value={data.consultants}
                                options={consultants}
                                error={errors.consultants}
                                disabled={!canPerform("account:write", props.account)}
                            />
                        </>}
                    </div>
                </ToggableBlocComponent>
                <div className="row">
                    <div className="col">
                        <button id="close-watchsite" onClick={props.onRequestClose} type="button" className="btn btn-bleu-4">Fermer</button>
                    </div>
                    <Can
                        perform="account:write"
                        data={props.account}
                        yes={() => (<div className="col text-right">
                            <LoadButton 
                                loading={saving === "save-and-close"} 
                                label="Enregistrer et fermer"
                                name="save-and-close"
                                id="save-and-close-watchsite"
                            />
                            <LoadButton 
                                loading={saving === "save"} 
                                label="Enregistrer" 
                                name="save" 
                                id="save-watchsite"
                            />
                        </div>)}
                    />
                </div>
                {data.id && <Can
                    perform="account:write"
                    data={props.account}
                    yes={() => (
                        <ToggableBlocComponent label="Transfert de compte" id="watchsite-transfer" toggled={togglers["watchsite-transfer"]} toggle={toggle}>
                            <div className="row">
                                <div className="col-md-8">
                                    <FieldComponent
                                        prefixId="watchsite-"
                                        type="async-select"
                                        name="target"
                                        label="Compte cible"
                                        loadOptions={(value, callback) => AccountAutocomplete({keyword: value, consultant: (isAdmin() ? null : user.id)}, callback)}
                                        onChange={value => setTransfertAccount(value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <LoadButton 
                                        loading={loadingTransfer} 
                                        label="Transférer"
                                        iconclassName="icon-boutons-transferer"
                                        onClick={transfer}
                                        id="transfer-watchsite"
                                    />
                                </div>
                            </div>
                        </ToggableBlocComponent>
                    )}
                />}
            </form>

            {data.id && (<Can
                perform="account:write"
                data={props.account}
                yes={() => (
                    <>
                        <ToggableBlocComponent label="Configuration des utilisateurs" id="watchsite-users" toggled={togglers["watchsite-users"]} toggle={toggle} wrapChildren={false}>
                            <WatchsiteUsersList
                                watchsite={data.id}
                                parent={data.node}
                                account={props.account.id}
                            />
                        </ToggableBlocComponent>
                        <div className="row">
                            <div className="col">
                                <button id="close-watchsite" onClick={props.onRequestClose} type="button" className="btn btn-bleu-4">Fermer</button>
                            </div>
                        </div>
                    </>
                )}
            />)}
        </section>
    )
}