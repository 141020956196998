import FieldComponent from 'shared/components/FieldComponent';
import { useEffect, useState } from 'react';
import HtmlComponent from 'shared/components/HtmlComponent';
import { formatDate } from 'shared/services/Utils';

export default function SheetDeadlineItem(props) {

    const { value, onChange, remove, error, readOnly } = props;

    const MODE_SHOW = 1;
    const MODE_EDIT = 2;

    const [mode, setMode] = useState(value.id.toString().includes('new_') ? MODE_EDIT : MODE_SHOW);

    function edit(event) {
        event.preventDefault();
        setMode(MODE_EDIT);
    }

    useEffect(() => {
        if (value.updated_at) {
            setMode(MODE_SHOW);
        }
    }, [value.updated_at])

    function preRemove(event) {
        event.preventDefault();
        event.stopPropagation();
        remove(value.id);
    }

    return (
        <tr>
            <td>
                {(mode === MODE_SHOW && (
                    <strong>{formatDate(value.date)}</strong>
                )) || (mode === MODE_EDIT && (
                    <FieldComponent
                        type="date"
                        name={"deadline_date[" + value.id + "]"}
                        value={value.date}
                        onChange={v => onChange(value.id, "date", v)}
                        wrap={false}
                        error={error.date}
                    />
                ))}
            </td>
            <td>
                {(mode === MODE_SHOW && (
                    <strong>{value.article}</strong>
                )) || (mode === MODE_EDIT && (
                    <FieldComponent
                        name={"deadline_article[" + value.id + "]"}
                        value={value.article}
                        onChange={v => onChange(value.id, "article", v)}
                        wrap={false}
                        error={error.article}
                    />
                ))}
            </td>
            <td>
                {(mode === MODE_SHOW && (
                    <HtmlComponent>{value.text}</HtmlComponent>
                )) || (mode === MODE_EDIT && (
                    <FieldComponent
                        type="textarea"
                        className="field h-100px"
                        name={"deadline_text[" + value.id + "]"}
                        value={value.text}
                        onChange={v => onChange(value.id, "text", v)}
                        wrap={false}
                        rte={true}
                        error={error.text}
                    />
                ))}
            </td>
            {readOnly === false && (
                <td>
                    <ul className="actions">
                        {mode === MODE_SHOW && 
                            <li>
                                <button onClick={edit} className="infobulle" data-info="Modifier l'échéance">
                                    <i className="icon-actions-modifier" aria-hidden="true"></i>
                                </button>
                            </li>
                        }
                        <li>
                            <button onClick={preRemove} className="infobulle" data-info="Supprimer l'échéance">
                                <i className="icon-boutons-poubelle" aria-hidden="true"></i>
                            </button>
                        </li>
                    </ul>
                </td>
            )}
        </tr>
    )
}