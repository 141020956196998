import { React, Link, useEffect, useState, toast } from 'commons';
import {  } from "react-router-dom";
import Modal from 'react-modal';
import DefaultLayout from "layouts/DefaultLayout";
import { usePosition, useList, useSecurity } from 'shared/hooks';
import MailLogApi from 'api/MailLog';
import { AccountApi } from 'shared/api';
import { ListComponent, FilterComponent } from 'shared/components';
import MailLogDetail from 'views/maillog/Detail';
import { denyIfCantPerform, mediumModalCustomStyles, preventDefault, compileDataToSelectOptions } from 'shared/services';
import { getMailTypes, getMailTypeLabel } from 'data/MailType';

export default function MailLogList(props) {

    denyIfCantPerform(props, "maillog:list");

    const [user] = useSecurity();
    const [showing, setShowing] = useState(false);
    const [message, setMessage] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [initializePosition, setPosition] = usePosition("maillog-list");
    
    const columns = [
        {id: 'subject', title: 'Objet'},
        {id: 'account.name', title: 'Compte'},        
        {id: 'send_at', title: 'Date et heure', format: 'datetime'},
        {id: 'recipient', title: 'Destinataire'},
        {id: 'type', title: 'Type', render: (row) => <>{getMailTypeLabel(row.type)}</>},
        {id: 'action', title: '', sortable: false, render: (row) => <ul className="actions">
            <li><Link title="Voir" to={`/maillogs/${row.id}`} onClick={(e) => show(e, row)}>
                <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
            </Link></li>
        </ul>}
    ];

    const filterType = {
        send_at: {type: "range", fields: ["send_at"]},
        type: {type: "match", fields: ["type"]},
        recipient: {type: "wildcard", fields: ["recipient"]},
        account: {type: "match", fields: ["account.id"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("maillog-list", MailLogApi, null, "recipient");

    useEffect(() => {
        if (user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) {
            AccountApi
                .search({}, 0, 99999, "name", "ASC")
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        } else if (user.roles.includes("ROLE_CONSULTANT")) {
            AccountApi
                .search(
                    {"bool": {"should": [{"match":{"pilot_consultants": user.id}}, {"match":{"associate_consultants": user.id}}]}},
                    0,
                    99999,
                    "name",
                    "ASC"
                )
                .then(([results]) => setAccounts(compileDataToSelectOptions(results)))
                .catch(() => toast.error("Impossible de récupérer vos comptes."));
        }

        submitSearch(filterType, () => initializePosition());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function show(event, row) {
        event.preventDefault();

        setMessage(row);
        setShowing(true);
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E46" title="Historique des emails">
            <h1>Historique des emails</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    label="Date"
                                    name="send_at"
                                    type="daterange"
                                    value={criterias.send_at}
                                    onChange={value => addCriteria("send_at", value)}
                                />
                                <FilterComponent
                                    label="Destinataire"
                                    name="recipient"
                                    onChange={value => addCriteria("recipient", value)}
                                    value={criterias.recipient}
                                />
                                <FilterComponent
                                    label="Contexte"
                                    name="type"
                                    type="select"
                                    onChange={value => addCriteria("type", value)}
                                    value={criterias.type}
                                    options={getMailTypes()}
                                    clearable
                                />
                                <FilterComponent
                                    label="Compte"
                                    type="select"
                                    name="account"
                                    clearable={true}
                                    value={criterias.account}
                                    options={accounts}
                                    onChange={value => addCriteria("account", value)}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-maillog" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-maillog" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent
                        id="maillogs"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                    />
                </form>
            </div>

            <Modal isOpen={showing} onRequestClose={() => setShowing(false)} style={mediumModalCustomStyles}>
                <MailLogDetail message={message} onClose={() => setShowing(false)} />
            </Modal>
        </DefaultLayout>
    );
}
