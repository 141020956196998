import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";

import { useForm } from 'shared/hooks/Form';
import { useSecurity } from 'shared/hooks/Security';

import SecurityApi from 'shared/api/Security';

import { compileErrorsFromResponse } from 'shared/services/Utils';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';

export default function Login(props) {

    const [logging, setLogging] = useState(false);
    const [user,, setUser] = useSecurity();
    const [data, errors, setErrors, setValue] = useForm({
        email: "",
        password: "",
    });

    if (sessionStorage.getItem("message")) {
        toast.error(sessionStorage.getItem("message"));
        sessionStorage.removeItem("message");
    }

    useEffect(() => {
        if (_.isEmpty(user)) {
            setUser({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function login(event) {
        event.preventDefault();

        setLogging(true);

        SecurityApi.login(data)
            .then(() => {
                setErrors({});
                setLogging(false);
                props.history.push("/loader");
            })
            .catch(error => {
                setLogging(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    return (
        <section className="login">
            <form className="form" onSubmit={login}>
                <h1>
                    <div className="main-logo">
                        <img src={logoapave} width="96" alt="Logo Apave" />
                    </div>
                    <div className="main-name">
                        <img src={logopilotveille} alt="Logo Pilot Veille" />
                    </div>
                </h1>
                <div className="bloc">
                    <section>
                        <header>
                            <h2>Connexion</h2>
                        </header>
                        <div className="bg-blanc border-rlb border-gris-40">
                            <FieldComponent name="email" label="Identifiant *" error={errors.email} value={data.email} onChange={value => setValue("email", value)} />
                            <FieldComponent type="password" name="password" label="Mot de passe *" error={errors.password} value={data.password} onChange={value => setValue("password", value)} />
                            <div className="row">
                                <div className="col-md-3 text-right order-2">
                                    <LoadButton loading={logging} loaderWidth={25} label="Connexion" name="login" id="login" displayIcon={false} />
                                </div>
                                <div className="col-md-9 offset-label order-1">
                                    <a id="forgot-password" href={process.env.REACT_APP_ADSL_URL} className="btn btn-alert">Mot de passe oublié ?</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </form>
        </section>    
    )
}