import { React, Link, useContext } from 'commons';
import { stripTags } from 'shared/services/Utils';
import { ReferentialContext } from 'shared/stores/Referential';
import { REFERENTIAL_DISPATCH_GROUP } from 'shared/data';
import { ToggableBlocComponent, FieldComponent, FileComponent } from "shared/components";
import { SheetApi } from "shared/api";
import { Can } from "shared/services";
import { useToggler } from "shared/hooks";

export default function DispatchFormListSheets(props) {

    const [referentialContext] = useContext(ReferentialContext);

    let groups = {"0": "Sans regroupement"};
    let toggleConfiguration = {"group-0": true};
    let groupOptions = [{value: "0", label: "Sans regroupement"}];
    referentialContext[REFERENTIAL_DISPATCH_GROUP].forEach(group => {
        groups[group.id] = group.value;
        toggleConfiguration["group-" + group.id] = true;
        groupOptions.push({value: group.id, label: group.value});
    });
    const [togglers, toggle] = useToggler(toggleConfiguration);

    function unattach(event, sheet) {
        event.preventDefault();

        props.unattachSheets([sheet]);
    }

    return <>
        {props.groupOrder.map(group => <ToggableBlocComponent label={props.getGroupLabel(group.id)} id={`group-${group.id}`} toggled={togglers["group-" + group.id]} toggle={toggle}>
            <table className="table table-bordered table-striped dispatch-sheets">
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Saut de page</th>
                        <th>Numéro</th>
                        <th>Titre</th>
                        <th>Regroupement</th>
                        <th>Image</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {props.sheets[group.id] && props.sheets[group.id].map(sheet => <tr>
                        <td>
                            <FieldComponent 
                                wrapper={([, input]) => <>{input}</>}
                                className="field xxsmall"
                                name={`sheet-${sheet.id}-position`}
                                value={sheet.dispatch_position}
                                onChange={value => props.updateSheetPosition(sheet, value)}
                                onBlur={() => {
                                    if (sheet.dispatch_position) {
                                        props.updateSheetPosition(sheet, sheet.dispatch_position, true);
                                    }
                                }}
                            />
                        </td>
                        <td className="w-50px">
                            <FieldComponent 
                                wrapper={([, input]) => <>{input}<label htmlFor={`sheet-${sheet.id}-pagebreak`}></label></>}
                                type="checksingle"
                                name={`sheet-${sheet.id}-pagebreak`}
                                checked={sheet.dispatch_pagebreak}
                                onChange={value => props.updateSheetPagebreak(sheet, value)}
                            />
                        </td>
                        <td>{sheet.id}</td>
                        <td className="w-800px">{stripTags(sheet.title)}</td>
                        <td>
                            <FieldComponent
                                wrapper={([, input,, children]) => <>{input}{children}</>}
                                type="select"
                                options={groupOptions}
                                name={`sheet-${sheet.id}-group`}
                                value={sheet.dispatch_group ? sheet.dispatch_group : "0"}
                                onChange={value => props.updateSheetGroup(sheet, value)}
                            />
                        </td>
                        <td className="dispatch-image">
                            <FileComponent
                                wrapper={([, input]) => <>{input}</>}
                                extensions={[".png", ".jpg", ".jpeg", ".gif"]}
                                value={sheet.dispatch_image}
                                name="logo"
                                onChange={value => props.updateSheetImage(sheet, value)}
                                upload={SheetApi.uploadDispatchImage}
                                inputClassName="field small"
                            />
                        </td>
                        <td>
                            <ul className="actions">
                                <Can perform="sheet:read" yes={() => (<>
                                    <li>
                                        <Link title="Consulter la fiche" to={"/sheets/" + sheet.id + "/preview"} id={`preview-sheet-${sheet.id}`}>
                                            <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                </>)} />
                                <Can perform="sheet:write" yes={() => (<>                            
                                    <li>
                                        <Link title="Modifier la fiche" to={"/sheets/" + sheet.id} id={`edit-sheet-${sheet.id}`}>
                                            <i className="icon-actions-modifier" aria-hidden="true"></i>
                                        </Link>
                                    </li>
                                </>)} />
                                <li>
                                    <Link title="Exclure la fiche" to="/dispatch/unattachsheets" onClick={(e) => unattach(e, sheet.id)} id={`unattach-sheet-${sheet.id}`}>
                                        <i className="icon-actions-exclure-fiche-push" aria-hidden="true"></i>
                                    </Link>
                                </li>
                            </ul>
                        </td>                      
                    </tr>)}
                </tbody>
            </table>
        </ToggableBlocComponent>)}
    </>
}