import { DefaultLayout, toast, useEffect, useState, Link, Prompt, useHistory } from "commons";
import { FieldComponent, FileComponent, LoadButton, PageLoadingComponent, PreviewFieldComponent, StatusComponent } from "shared/components";
import { AccountApi, HotlineApi, ReferentialApi, ExternalUserApi } from 'shared/api';
import { HOTLINE_STATUS_DRAFT, HOTLINE_STATUS_ANSWERED, getHotlineStatusColor, getHotlineStatusLabel } from 'shared/data';
import { useForm, useSecurity } from "shared/hooks";
import { compileErrorsFromResponse, formatDate, formatUser, denyIfCantPerform } from "shared/services";

export default function HotlineForm(props) {

    denyIfCantPerform(props, 'hotline:write');
    
    const editMode = props.match.params.id ? true : false;
    const history = useHistory();
    const [user,,,, isAdmin] = useSecurity();
    const [users, setUsers] = useState([]);
    const [domainTree, setDomainTree] = useState([]);
    const [hotline, setHotline] = useState({});
    const [isLoad, setIsLoad] = useState(editMode);
    const [saving, setSaving] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        created_by: null,
        account: null,
        title: '',
        question: '',
        attachments: [],
        confidential: false,
        complement: '',
        status: HOTLINE_STATUS_DRAFT,
    });

    useEffect(() => {
        if (data.account) {
            ReferentialApi
                .accountsDomainTree([data.account])
                .then(t => setDomainTree(t.map(d => Object.assign({}, d, {disabled: true}))));

            ExternalUserApi
                .search({"bool":{"must":[{"match":{"account_id": data.account}}]}})
                .then(([results]) => setUsers(results.map(userItem => { 
                        return {"value": userItem.user_id, "label": userItem.lastname + " " + userItem.firstname};
                    }))
                );                
        }
    }, [data.account]);

    function loadAccounts() {
        let querySearch = {};
        if (!isAdmin()) {
            querySearch = {
                "bool": {
                    "should": [
                        {"match": {"pilot_consultants": user.id}},
                        {"match": {"associate_consultants": user.id}}                        
                    ]
                }
            }
        }

        AccountApi
            .search(querySearch, 0, 10000, "name", "asc")
            .then(([results]) => 
                setAccounts(
                    results.map(r => {return {value: r.id, label: r.name};
                })
            ));
    }

    useEffect(() => {
        loadAccounts();
        
        if (editMode) {
            HotlineApi
                .get(props.match.params.id)
                .then(newData => {
                    setData(formatData(newData));
                    setHotline(newData);
                    setIsLoad(false);
                })
                .catch(() => {
                    toast.error("Une erreur est survenue lors de la récupération de la question");
                    history.goBack();
                });
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function formatData(newData) {
        let hotlineData = {...newData};

        if (hotlineData.account)  hotlineData.account = hotlineData.account.id;
        if (hotlineData.created_by)  hotlineData.created_by = hotlineData.created_by.id;
        if (hotlineData.categories) hotlineData.categories = hotlineData.categories.map(elem => elem.id);
       
        return hotlineData;
    }

    function submit(event) {
        event.preventDefault();
        setSaving(true);

        const submitButton = event.nativeEvent.submitter.name;

        let requestData = {};
        requestData = {...data};
        requestData.attachments = data.attachments.map(file => file.hash) || [];
        requestData.send = (submitButton === "save-and-send");

        HotlineApi
            .save(requestData)
            .then(newData => {
                setErrors({});
                setSaving(false);
                setData(formatData(newData));
                toast.success("Enregistrement effectué.");
                reloadOrClose(event, props, "hotlines", newData.id);
            })
            .catch(error => {
                setSaving(false);
                if (error.response.data.message) {
                    error.response.data.message.split('\n').forEach(e => toast.error(e));
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            }); 
    }

    function categoriesChange(values) {
        let newValues = [];

        const mapper = (item) => {
            if (item.children) {
                item.children = item.children.map(mapper);
                item.children.forEach(child => {
                    if (!newValues.includes(item.value)
                        && (values.includes(child.value) || newValues.includes(child.value))
                    ) {
                        newValues.push(item.value);
                    }
                });
            }
            if (values.includes(item.value) && !newValues.includes(item.value)) {
                newValues.push(item.value);  
            }

            return item;
        }; 

        domainTree.map(mapper);

        setValue("categories", newValues);
    }

    const pageName = editMode ? 'Edition d\'une hotline' : 'Création d\'une hotline';

    if (isLoad === true) {
        return <PageLoadingComponent label={pageName} />
    }

    return (
        <DefaultLayout screen="E45" title={pageName}>
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>Hotline</h1>
            <section className="bloc">
                <form onSubmit={submit} id="form-hotline" className="form">
                    <section id="configuration" className="bloc">
                        <header className="uppercase">{pageName}</header>
                        <section className="border-gris-25 border-lrb bg-blanc">
                            <div className="bg-gris-10">
                                <PreviewFieldComponent label="Statut">
                                    <StatusComponent 
                                        value={getHotlineStatusLabel(data.status)}
                                        color={getHotlineStatusColor(data.status)}
                                    />
                                </PreviewFieldComponent>
                            </div>
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Titre"
                                    required
                                    name="title"
                                    value={data.title}
                                    onChange={value => setValue("title", value)}
                                    error={errors.title}
                                />
                                <FieldComponent
                                    label="Confidentiel"
                                    type="checksingle"
                                    name="confidential"
                                    checksingleLabel="Oui"
                                    checked={data.confidential}
                                    onChange={value => setValue("confidential", value)}
                                />
                            </div>
                            <div className="bg-blanc">
                                {editMode && <PreviewFieldComponent label="Compte">
                                    {hotline.account.name}
                                </PreviewFieldComponent>}
                                {!editMode && <FieldComponent
                                    label="Compte"
                                    type="select"
                                    name="account"
                                    className="field medium"
                                    value={data.account}
                                    error={errors.account}
                                    options={accounts}
                                    onChange={value => setValue("account", value)}
                                />}
                                {data.account && <FieldComponent
                                    label="Utilisateur"
                                    type="select"
                                    name="created_by"
                                    className="field medium"
                                    value={data.created_by}
                                    error={errors.created_by}
                                    options={users}
                                    onChange={value => setValue("created_by", value)}
                                />}
                            </div>
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Question"
                                    required
                                    type="textarea"
                                    rte
                                    name="question"
                                    value={data.question}
                                    onChange={value => setValue("question", value)}
                                    error={errors.question}
                                />
                            </div>
                            <div className="bg-gris-10">                                   
                                <PreviewFieldComponent label="Auteur de la question">
                                    {formatUser(hotline.created_by)}
                                </PreviewFieldComponent>

                                <PreviewFieldComponent label="Date et heure de la question">
                                    {formatDate(hotline.created_at, true)}
                                </PreviewFieldComponent>
                            </div>
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Réponse"
                                    required
                                    type="textarea"
                                    rte
                                    name="answer"
                                    value={data.answer}
                                    onChange={value => setValue("answer", value)}
                                    error={errors.answer}
                                />
                            </div>
                            {data.answered_at && 
                                <div className="bg-gris-10">                                   
                                    <PreviewFieldComponent label="Auteur de la réponse">
                                        {formatUser(data.answered_by)}
                                    </PreviewFieldComponent>

                                    <PreviewFieldComponent label="Date et heure de la réponse">
                                        {formatDate(data.answered_at, true)}
                                    </PreviewFieldComponent>
                                </div>
                            }
                            <div className="bg-blanc">
                                <FieldComponent
                                    label="Domaines / Sous domaines *"
                                    type="dropdown-tree-select"
                                    name="categories"
                                    error={errors.categories}
                                    onChange={categoriesChange}
                                    value={data.categories}
                                    data={domainTree}
                                    mode="hierarchical"
                                />
                            </div>
                            <div className="bg-blanc">
                                <FileComponent
                                    label="Pièces-jointes"
                                    value={data.attachments}
                                    name="attachments"
                                    multiple
                                    onChange={value => setValue('attachments', value)}
                                    upload={HotlineApi.uploadAttachments}
                                    error={errors.attachments}
                                    extensions={['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.jpg', '.jpeg', '.png']}
                                    showSize
                                    preview={editMode}
                                />
                            </div>
                        </section>
                    </section>

                    <section className="row">
                        <div className="col-md-3">
                            <Link id="back-hotlines" to="/hotlines" className="btn btn-bleu-4">Retour à la liste</Link>
                        </div>

                        <div className="col-md-9 text-right">
                            <LoadButton 
                                loading={saving} 
                                label="Enregistrer"
                                name="save"
                                id="save"
                            />
                            {data.status !== HOTLINE_STATUS_ANSWERED &&
                                <LoadButton 
                                    loading={saving} 
                                    label="Enregistrer et envoyer"
                                    name="save-and-send"
                                    id="save-and-send"
                                />
                            }
                        </div>
                    </section>
                </form>
            </section>
        </DefaultLayout>
    )
}
