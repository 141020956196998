import { React, DefaultLayout, Link, useEffect, fileDownload } from 'commons';
import { denyIfCantPerform, preventDefault } from 'shared/services';
import { useList, usePosition } from 'shared/hooks';
import { ListComponent, FilterComponent } from 'shared/components';
import DispatchApi from 'api/Dispatch';

export default function DispatchList(props) {
    
    denyIfCantPerform(props, "dispatch:list");
    const [initializePosition, setPosition] = usePosition("dispatch-list");
    const columns = [
        {id: 'number', title: 'Numéro'},
        {id: 'created_at', title: 'Date', format: "date"},
        {
            id: 'action',
            title: '',
            sortable: false,
            className: "w-80px text-center",
            render: (row) => { return (<ul className="actions">
                    <li><Link title="Modifier" to={`/dispatchs/${row.id}`}><i className="icon-actions-modifier" aria-hidden="true"></i></Link></li>
                    {row.generated_at && <li><Link title="Voir le PDF" onClick={(e) => { download(row, e)} } to={`/dispatchs/${row.id}/pdf`}><i className="icon-actions-consulter-pdf" aria-hidden="true"></i></Link></li>}
                </ul>)
            }
        }
    ];

    const filterType = {
        number: {type: "match", fields: ["number"]},
        created_at: {type: "range", fields: ["created_at"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("account-list", DispatchApi, null, "number");

    function load() {
        submitSearch(filterType, () => initializePosition(), false);
    }

    function download(row, event) {
        event.preventDefault();
        setLoading(true);
        DispatchApi
            .download(row.id)
            .then(file => fileDownload(file, row.number + ".pdf"))
            .finally(() => setLoading(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(load, []);

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E19" title="Dépêches">
            <h1>Consultation des anciennes dépêches</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent label="Numéro" name="numéro" onChange={value => addCriteria("number", value)} value={criterias.number} />                                
                                <FilterComponent label="Date" name="created_at" type="daterange" value={criterias.created_at} onChange={value => addCriteria("created_at", value)} />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-dispatch" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-dispatch" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <ListComponent 
                        id="dispatchs"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                    />
                </form>
            </div>
        </DefaultLayout>
    );
}