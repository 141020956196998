import React, { useEffect, useState, useContext } from 'react';
import { Switch, Redirect, Route, BrowserRouter as Router } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import BarLoader from "react-spinners/BarLoader";

import routes from "routes";
import Page404 from "shared/views/Page404";

import { useSecurity } from 'shared/hooks/Security';
import { useAutomaticRedirect } from 'shared/hooks/AutomaticRedirect';
import { ReferentialContext } from 'shared/stores/Referential';

import SecurityApi from 'shared/api/Security';
import ReferentialApi from 'shared/api/Referential';
import ExportApi from 'shared/api/Export';

import { REFERENTIALS } from 'shared/data/ReferentialType';

import logoapave from "images/logoapave.svg";
import logopilotveille from "images/logopilotveille.png";

export default function Dispatcher() {

    const [updateLastVisit, getLastVisitPath, shouldRedirect] = useAutomaticRedirect();
    const [user,, setUser] = useSecurity();
    const [loading, setLoading] = useState(true);
    const [islogged, setIslogged] = useState(user.id ? true : null);
    const [referentialContext, referentialDispatcher] = useContext(ReferentialContext);
    const loader = <>
        <section className="login">                
            <h1>
                <div className="main-logo">
                    <img src={logoapave} width="96" alt="Logo apave" />
                </div>
                <div className="main-name">
                    <img src={logopilotveille} alt="Logo Pilot Veille" />
                </div>
            </h1>
        </section>
        <section className="login" style={{marginTop:"50px"}}>
            <BarLoader loading={true} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
        </section>                
    </>;

    useEffect(() => {
        if (document.location.pathname !== "/adsllogin") {
            SecurityApi
                .getCurrentUser()
                .then(response => {
                    setUser(response.data);
                    console.log("set is logged true");
                    setIslogged(true);
                    setLoading(false);
                })
                .catch(() => {
                    console.log("set is logged false error catch");
                    setIslogged(false);
                    setLoading(false);
                });
        } else {
            console.log("set is logged false document.location.pathname === /adsllogin");
            setIslogged(false);
            setLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function checkExport() {
        ExportApi
            .checkForNew()
            .then(result => {
                if (result.length > 0) {
                    let message = 'Un export est disponible pour être téléchargé (';
                    if (result.length > 1) {
                        message = 'Des exports sont disponibles pour être téléchargés (';
                    }
                    let files = [];
                    result.forEach(element => {
                        files.push(element.filename);
                    });
                    message += files.join(', ');
                    message += ')';
                    toast.info(message);
                }
            })
            .catch(() => {});

        ExportApi
            .getReadyExportAmount()
            .then(response => localStorage.setItem("exportReady", response.amount))
            .catch(() => {});
    }

    function loadReferential() {
        if (referentialContext.status === "empty") {
            referentialDispatcher({action: "status", data: "loading"});
            axios
                .all([
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_TYPE),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_IMPACT),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_SOURCE),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_TRANSMITTER),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_DESCRIPTORS),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_TEXT_CODIFICATION),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_DOMAIN),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_DISPATCH_GROUP),
                    ReferentialApi.list(REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE),
                    ReferentialApi.tree(),
                ])
                .then(axios.spread((
                    [type],
                    [impact],
                    [source],
                    [transmitter],
                    [involvedActor],
                    [involvedFunction],
                    [descriptor],
                    [codification],
                    [domains],
                    [dispatchGroups],
                    [documentType],
                    tree
                ) => {
                    referentialDispatcher({action: "populate", data: type, type: REFERENTIALS.REFERENTIAL_TEXT_TYPE});
                    referentialDispatcher({action: "populate", data: impact, type: REFERENTIALS.REFERENTIAL_TEXT_IMPACT});
                    referentialDispatcher({action: "populate", data: source, type: REFERENTIALS.REFERENTIAL_SOURCE});
                    referentialDispatcher({action: "populate", data: transmitter, type: REFERENTIALS.REFERENTIAL_TRANSMITTER});
                    referentialDispatcher({action: "populate", data: involvedActor, type: REFERENTIALS.REFERENTIAL_INVOLVED_ACTOR});
                    referentialDispatcher({action: "populate", data: involvedFunction, type: REFERENTIALS.REFERENTIAL_INVOLVED_FUNCTION});
                    referentialDispatcher({action: "populate", data: descriptor, type: REFERENTIALS.REFERENTIAL_DESCRIPTORS});
                    referentialDispatcher({action: "populate", data: codification, type: REFERENTIALS.REFERENTIAL_TEXT_CODIFICATION});
                    referentialDispatcher({action: "populate", data: domains, type: REFERENTIALS.REFERENTIAL_DOMAIN});
                    referentialDispatcher({action: "populate", data: dispatchGroups, type: REFERENTIALS.REFERENTIAL_DISPATCH_GROUP});
                    referentialDispatcher({action: "populate", data: documentType, type: REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE});
                    referentialDispatcher({action: "populate", data: tree, type: "tree"});

                    referentialDispatcher({action: "status", data: "load"});
                }));
        }
    }

    if ((loading || referentialContext.status === "loading") && document.location.pathname !== "/loader") {
        return <>{loader}</>;
    }

    let allowNoLogged = (
        /users\/validation\/[a-z0-9]*/gi.test(document.location.pathname)
        || /maintenance.*/gi.test(document.location.pathname)
        || /cgu/gi.test(document.location.pathname)
        || /adsllogin/gi.test(document.location.pathname)
        || /denied/gi.test(document.location.pathname)
    );

    if (!user.id && !islogged && !allowNoLogged && document.location.pathname !== "/login" && document.location.pathname !== "/loader") {
        updateLastVisit();
        window.history.pushState("Login", "Login", "/login");

    } else if (islogged && shouldRedirect()) {
        return <Redirect to={getLastVisitPath()} />
    }

    if (referentialContext.status === "empty" && islogged) {
        // if referential not logged
        loadReferential();
        return <>{loader}</>;
    }

    setInterval(checkExport, process.env.REACT_APP_INTERVAL_TO_CHECK_FOR_READY_EXPORTS);

    return (
        <Router>
            <Switch>
                {routes.map(route => (
                    <Route exact path={route.path} key={route.path} component={route.component} />
                ))}
                <Route component={Page404} />
            </Switch>
        </Router>
    );
}