import { React, DefaultLayout, useState } from 'commons';
import { toast } from 'react-toastify';
import { FieldComponent, LoadButton, ToggableBlocComponent } from 'shared/components';
import { useToggler } from 'shared/hooks';
import { preventDefault } from 'shared/services';
import ObligationGeneratorApi from 'shared/api/ObligationGenerator';

export default function ObligationGeneratorImport() {

    const [questionsFile, setQuestionsFile] = useState();
    const [obligationsFile, setObligationsFile] = useState();
    const [questionsImporting, setQuestionsImporting] = useState(false);
    const [obligationsImporting, setObligationsImporting] = useState(false);
    const [togglers, toggle] = useToggler({'questions': true, 'obligations': true});

    function importObligationGeneratorQuestions() {
        setQuestionsImporting(true);
        ObligationGeneratorApi
            .importQuestions(questionsFile)
            .then(response => {
                toast.success("Import des questions effectué");
            })
            .catch(error => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setQuestionsFile(null);
                setQuestionsImporting(false);
            });
    }

    function importObligationGeneratorObligations() {
        setObligationsImporting(true);
        ObligationGeneratorApi
            .importObligations(obligationsFile)
            .then(response => {
                toast.success("Import des obligations effectué");
            })
            .catch(error => {
                toast.error(error.response.data.message);
            })
            .finally(() => {
                setObligationsFile(null);
                setObligationsImporting(false);
            });
    }

    return (
        <DefaultLayout title={`Générateur d'obligations`}>
            <h1>Générateur d'obligations</h1>
            <section className="bloc">
                <form className="form" id="form-questions" onSubmit={(e) => preventDefault(e, importObligationGeneratorQuestions())}>
                    <ToggableBlocComponent label="Import des questions" id="form" toggled={togglers["questions"]} toggle={toggle}>
                        <FieldComponent
                            type="file"
                            label="Fichier à importer"
                            name="questions"
                            accept=".xlsx"
                            extensions={[".xlsx"]}
                            onChange={value => setQuestionsFile(value)}
                            multiple={false}
                            showRequirements={true}
                        />
                    </ToggableBlocComponent>
                    <div className="row">
                        <div className="col text-right">
                            <LoadButton
                                loading={questionsImporting}
                                label="Importer"
                                name="import-obligation-generator-questions"
                                id="import-obligation-generator-questions"
                            />
                        </div>
                    </div>
                </form>
            </section>
            <section className="bloc">
                <form className="form" id="form-obligations" onSubmit={(e) => preventDefault(e, importObligationGeneratorObligations())}>
                    <ToggableBlocComponent label="Import des obligations" id="form" toggled={togglers["obligations"]} toggle={toggle}>
                        <FieldComponent
                            type="file"
                            label="Fichier à importer"
                            name="obligations"
                            accept=".xlsx"
                            extensions={[".xlsx"]}
                            onChange={value => setObligationsFile(value)}
                            multiple={false}
                            showRequirements={true}
                        />
                    </ToggableBlocComponent>
                    <div className="row">
                        <div className="col text-right">
                        <LoadButton
                            loading={obligationsImporting}
                            label="Importer"
                            name="import-obligation-generator-questions"
                            id="import-obligation-generator-questions"
                        />
                        </div>
                    </div>
                </form>
            </section>
        </DefaultLayout>
    );
}