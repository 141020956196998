import { React, Modal, useEffect, useState, useContext, Link, _, toast } from 'commons';
import { useList, usePosition, useSecurity } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { SheetApi, SheetAffectedApi } from 'shared/api';
import { getSheetStatus, getSheetStatusColor, getSheetStatusLabel, SHEET_ARCHIVED } from 'data/SheetStatus';
import { getSheetStateColor, getSheetStateLabel, REFERENTIALS } from 'shared/data';
import { TreeReferentialComponent, ListComponent, StatusComponent, FilterComponent, HtmlComponent } from 'shared/components';
import { Can, denyIfCantPerform, preventDefault, toggleArrayValue, compileDataToSelectOptions, FileUtils, modalCustomStyles, mediumModalCustomStyles } from 'shared/services';
import Affectations from 'views/push/List/Assignements/Affectations';
import AffectationForm from 'views/push/List/Assignements/AffectationForm';

export default function SheetListAffected(props) {

    denyIfCantPerform(props, "sheet:list");

    const [initializePosition] = usePosition("sheet-list");
   
    const [referentialContext] = useContext(ReferentialContext);
    const sources = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_SOURCE], 'id', 'value');
    const transmitters = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TRANSMITTER], 'id', 'value');
    const text_types = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], 'id', 'value');
    const tree = _.cloneDeep(referentialContext["tree"]);
    const [user] = useSecurity();
    const [checkingAffectations, setCheckingAffectations]= useState(false);
    const [affecting, setAffecting]= useState(false);
    const [currentSheet, setCurrentSheet] = useState(null);

    const columns = [
        {id: 'sheet.id', title: 'Numéro'},
        {id: 'title', title: 'Titre', render: (row) => <HtmlComponent>{row.title}</HtmlComponent>},
        {id: 'sheet.categories', title: 'Domaines', sortable: false, render: (row) => 
            <TreeReferentialComponent className="arborescence" items={tree} value={row.sheet.categories.map(e => e.id)} />
        },
        {id: 'sheet.text_date', title: 'Date du texte', format: 'date'},
        {id: 'sheet.publication_date', title: 'Date de publication', format: 'date'},
        {id: 'sheet.add_to_dispatch', title: 'Dépêche', format: 'checksingle'},
        {id: 'sheet.status', title: 'Statut', render: (row) => 
            <StatusComponent 
                value={getSheetStatusLabel(row.sheet.status)} 
                color={getSheetStatusColor(row.sheet.status)} 
            />
        },
        {id: 'sheet.state_synthesis', title: 'Niveau synthèse', render: (row) => 
            <StatusComponent 
                value={getSheetStateLabel(row.sheet.state_synthesis)} 
                color={getSheetStateColor(row.sheet.state_synthesis)} 
            />
        },
        {id: 'sheet.state_technical', title: 'Niveau technique', render: (row) => 
            <StatusComponent 
                value={getSheetStateLabel(row.sheet.state_technical)} 
                color={getSheetStateColor(row.sheet.state_technical)} 
            />
        },
        {id: 'sheet.nor', title: 'NOR'},
        {id: 'sheet.text_number', title: 'Numéro du texte'},
        {id: 'action', title: '', sortable: false, render: (row) => 
            <ul className="actions">
                <Can perform="sheet:read" yes={() => (<>
                    <li>
                        <Link title="Consulter la fiche" to={"/sheets/" + row.sheet.id + "/preview"} id="preview-sheet">
                            <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                        </Link>
                    </li>
                    <li>
                        <a onClick={(e) => downloadText(e, row.sheet)} title="Consulter le texte reglementaire" href={"/sheets/" + row.sheet.id} id={`download-text-${row.sheet.id}`}>
                            <i className="icon-actions-consulter-pdf" aria-hidden="true"></i>
                        </a>
                    </li>
                </>)} />
                <Can perform="sheet:write" yes={() => (<>
                    <li>
                        <Link data-info="Modifier la fiche" className="infobulle" to={"/sheets/" + (row.sheet.is_private ? "private/" : "") + row.sheet.id} id="edit-sheet">
                            <i className="icon-actions-modifier" aria-hidden="true"></i>
                        </Link>
                    </li>
                    <li>
                        <a onClick={(e) => archiveSheet(e, row.sheet)} title="Archiver la fiche" href={"/sheets/" + row.sheet.id} id={`archive-sheet-${row.sheet.id}`}>
                            <i className="icon-actions-archiver-fiche" aria-hidden="true"></i>
                        </a>
                    </li>
                </>)} />
                <li>
                    <a title="Points de veille déjà affectés" onClick={(e) => openAffectations(e, row.sheet)} href={"/sheets/" + row.sheet.id + "/affections"} id={`affectations-sheet-${row.sheet.id}`}>
                        <i className="icon-actions-points-veille-affectes" aria-hidden="true"></i>
                    </a>
                </li>
                {(user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_SUPER_ADMIN")) && <li>
                    <a onClick={(e) => toggleDispatch(e, row)} title="Ajouter/Retirer de la dépêche" href={"/sheets/" + row.id} id={`toggle-dispatch-${row.id}`}>
                        <i className="icon-actions-ajouter-retirer-depeche" aria-hidden="true"></i>
                    </a>
                </li>}
                <li>
                    <a title="Affecter la fiche aux éléments sélectionnés" onClick={(e) => openAffectationForm(e, row.sheet)} href={"/sheets/" + row.sheet.id + "/affect"} id={`affect-sheet-${row.sheet.id}`}>
                        <i className="icon-actions-affecter" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        }
    ];

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("sheet-list", SheetAffectedApi, preFetch, "sheet.title", {
        id: '',
        account: 0,
        is_private: '0'
    });

    let filterType = {
        keyword: {type: "wildcard", fields: getKeywordFields()},
        id: {type: "match", fields: ["sheet.id"]},
        text_type: {type: "terms", fields: ["sheet.text_types"]},
        text_date: {type: "range", fields: ["sheet.text_date"]},
        publication_date: {type: "range", fields: ["sheet.publication_date"]},
        major_text: {type: "match", fields: ["sheet.major_text"], cast: "int"},
        repealed_text: {type: "match", fields: ["sheet.repealed_text"], cast: "int"},
        cut_into_requirements: {type: "match", fields: ["sheet.cut_into_requirements"], cast: "int"},
        text_number: {type: "match", fields: ["sheet.text_number"]},
        nor: {type: "match", fields: ["sheet.nor"]},        
        source: {type: "terms", fields: ["sheet.source"]},
        transmitter: {type: "terms", fields: ["sheet.transmitter"]},
        categories: {type: "terms", fields: ["sheet.categories"]},
        status: {type: "terms", fields: ["sheet.status"]},
        consultant_comment: {type: "wildcard", fields: ["consultant_comment"]},
        comments: {type: "wildcard", fields: ["comments"]},
        hidden: {type: "match", fields: ["hidden"]},
        account: {type: "match", fields: ["watchsite.account"]},
        is_private: {type: "match", fields: ["sheet.is_private"], cast: "int"},
    };

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        newCriterias.account = props.account;
        return [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters];
    }

    function load() {
        submitSearch(filterType, () => initializePosition(), false);
    }

    function openAffectations(event, sheet) {
        event.preventDefault();
        setCurrentSheet(sheet);
        setCheckingAffectations(true);
    }

    function openAffectationForm(event, sheet) {
        event.preventDefault();
        setCurrentSheet(sheet);
        setAffecting(true);
    }

    function archiveSheet(event, row) {
        event.preventDefault();
        SheetApi
            .bulkUpdate([row.id], {"status": SHEET_ARCHIVED})
            .then(() => {
                toast.success("Mise à jour effectuée.")
                refresh();
            });
    }

    function downloadText(event, row) {
        event.preventDefault();
        FileUtils.download(row.reglementary_text);
    }

    function toggleDispatch(event, row) {
        event.preventDefault();
        SheetApi
            .bulkUpdate([row.id], {"add_to_dispatch": row.add_to_dispatch ? 0 : 1})
            .then(() => toast.success("Mise à jour effectuée."));
    }

    function getKeywordFields() {
        return Array.isArray(criterias.keyword_fields) ? criterias.keyword_fields : ["sheet.title", "sheet.reglementary_text", "sheet.synthesis", "sheet.technical_comment"];
    }

    // eslint-disable-next-line
    useEffect(load, []);
    // eslint-disable-next-line
    useEffect(load, [props.account]);

    return (
        <div className="bloc">
            <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                <section className="filters">
                    <header>
                        <h2>Recherche</h2>
                    </header>
                    <div className="bg-gris-25">
                        <div className="row">
                            <FilterComponent type="text" name="id" onChange={value => addCriteria("id", value)} value={criterias.id} label="Numéro de la fiche" />
                            <FilterComponent 
                                type="keyword"
                                name="keyword"
                                label="Recherche libre" 
                                fields={[
                                    {value: 'sheet.title', label: 'Titre'},
                                    {value: 'sheet.reglementary_text', label: 'Texte'},
                                    {value: 'sheet.synthesis', label: 'Synthèse'},
                                    {value: 'sheet.technical_comment', label: 'Commentaire technique'},
                                ]}
                                selectedFields={getKeywordFields()}
                                onChange={value => addCriteria("keyword", value)}
                                onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(getKeywordFields(), value))}
                                value={criterias.keyword || ""} 
                            />
                            <FilterComponent type="select" name="sheet_source" onChange={value => addCriteria("sheet_source", value)} value={criterias.sheet_source} label="Source" options={sources} clearable />
                            <FilterComponent type="select" name="sheet_transmitter" onChange={value => addCriteria("sheet_transmitter", value)} value={criterias.sheet_transmitter} label="Emetteur" multiple options={transmitters} />
                            <FilterComponent type="select" name="sheet_text_type" onChange={value => addCriteria("sheet_text_type", value)} value={criterias.sheet_text_type} label="Type de texte" multiple options={text_types} />
                            <FilterComponent type="select" name="sheet_status" onChange={value => addCriteria("sheet_status", value)} value={criterias.sheet_status} label="Statut" options={getSheetStatus()} clearable />
                            <FilterComponent type="daterange" name="sheet_text_date" onChange={value => addCriteria("sheet_text_date", value)} value={criterias.sheet_text_date} label="Date du texte" />
                            <FilterComponent type="daterange" name="sheet_publication_date" onChange={value => addCriteria("sheet_publication_date", value)} value={criterias.sheet_publication_date} label="Date de publication" />
                            <FilterComponent type="text" name="sheet_text_number" onChange={value => addCriteria("sheet_text_number", value)} value={criterias.sheet_text_number} label="Numéro de texte" />
                            <FilterComponent type="text" name="sheet_nor" onChange={value => addCriteria("sheet_nor", value)} value={criterias.sheet_nor} label="Numéro NOR" />
                            <FilterComponent type="radio" name="sheet_repealed_text" onChange={value => addCriteria("sheet_repealed_text", value)} value={criterias.sheet_repealed_text} label="Texte abrogé" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                            <FilterComponent type="radio" name="sheet_cut_into_requirements" onChange={value => addCriteria("sheet_cut_into_requirements", value)} value={criterias.sheet_cut_into_requirements} label="Découpé en exigence" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                            <FilterComponent type="radio" name="sheet_major_text" onChange={value => addCriteria("sheet_major_text", value)} value={criterias.sheet_major_text} label="Texte majeur" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                            <FilterComponent type="dropdown-tree-select" name="sheet_categories" onChange={value => addCriteria("sheet_categories", value)} value={criterias.sheet_categories} label="Domaines / Sous domaines / Thèmes" data={tree} mode="hierarchical" />
                            <FilterComponent type="text" name="consultant_comment" onChange={value => addCriteria("consultant_comment", value)} value={criterias.consultant_comment} label="Mots clés commentaire consultant" />
                            <FilterComponent type="text" name="comments" onChange={value => addCriteria("comments", value)} value={criterias.comments} label="Mots clés commentaires clients" />
                            <FilterComponent type="radio" name="hidden" onChange={value => addCriteria("hidden", value)} value={criterias.hidden} label="Fiche masquée" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                            <FilterComponent
                                type="radio"
                                name="is_private"
                                onChange={value => addCriteria("is_private", value)}
                                value={criterias.is_private}
                                label="Fiche privée"
                                blankLabel="Tous"
                                options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]}
                            />
                        </div>
                    </div>
                    <div className="bg-gris-25 border-b border-gris-60">
                        <div className="row">
                            <div className="col-md-9">
                                <button id="clearfilter-sheet" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                            </div>
                            <div className="col-md-3 text-right">
                                <button id="search-sheet" type="submit" className="btn btn-primary">Rechercher</button>
                            </div>
                        </div>
                    </div>
                </section>

                <ListComponent 
                    id="referentials"
                    loading={loading}
                    selectable={false}
                    rows={rows}
                    columns={columns}
                    sorting={sorting}
                    direction={direction}
                    onSortingChange={updateSorting}
                    perpage={limit}
                    onPerpageChange={updateLimit}
                    page={page}
                    onPageChange={updatePage}
                    totalrows={totalrows}
                    globalActions={(
                        <>
                            <Link id="new-sheet" to="/sheets/new" className="btn btn-primary h25 icon">
                                <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>&nbsp;Créer une fiche
                            </Link>
                        </>
                    )}
                />
            </form>
            <Modal isOpen={checkingAffectations} onRequestClose={() => setCheckingAffectations(false)} style={mediumModalCustomStyles}>
                <Affectations
                    sheet={currentSheet}
                    perimeter={["account:" + props.account]}
                    onClose={() => setCheckingAffectations(false)}
                />
            </Modal>
            <Modal isOpen={affecting} onRequestClose={() => setAffecting(false)} style={modalCustomStyles}>
                <AffectationForm
                    restrictedAccount={props.account}
                    sheet={currentSheet}
                    perimeter={["account:" + props.account]}
                    onClose={() => setAffecting(false)}
                    refresh={refresh}
                />
            </Modal>
        </div>
    )
}