import { uniqid } from "shared/services/Utils";
import SheetDeadlineItem from "shared/views/sheet/form/SheetDeadlineItem";

export default function SheetDeadline(props) {

    const { value, error, onChange, readOnly } = props;

    function addDeadline(event) {
        event.preventDefault();
        onChange([...value, {
            id: uniqid('new_'),
            date: null,
            article: '',
            text: 'Entrée en vigueur du texte'
        }]);
    }

    function updateDeadline(id, field, v) {
        let newDeadlines = [...value];
        const index = newDeadlines.findIndex(deadline => deadline.id === id);
        newDeadlines[index][field] = v;
        onChange(newDeadlines);
    }

    function removeDeadline(id) {
        const index = value.findIndex(deadline => deadline.id === id);
        onChange(value.filter((item, cIndex) => cIndex !== index));
    }

    return (<>
        <section className="border-t border-gris-40">
            <header className="bg-gris-45">
                <h3>Échéances</h3>
            </header>
            <table className="bg-blanc table table-bordered table-striped">
                <thead>
                    <tr>
                        <th className="w-100px">Date</th>
                        <th>Article</th>
                        <th>Texte</th>
                        {readOnly === false && <th className="w-34px"></th>}
                    </tr>
                </thead>
                <tbody>
                    {value.length > 0 && value.map(deadline => 
                        <SheetDeadlineItem
                            key={deadline.id}
                            value={deadline}
                            error={error ? error[deadline.id] : []}
                            onChange={updateDeadline}
                            remove={removeDeadline}
                            readOnly={readOnly}
                        />
                    )}
                    {value.length === 0 && <tr><td colSpan="3">Aucune échéance.</td></tr>}
                </tbody>
            </table>
        </section>

        {readOnly === false && (
            <div className="bg-gris-10 border-t border-gris-40">
                <div className="text-right">
                    <button onClick={addDeadline} className="btn btn-primary icon uppercase">
                        <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>
                        &nbsp;Ajouter une échéance
                    </button>
                </div>
            </div>
        )}
    </>);
}

SheetDeadline.defaultProps = {
    readOnly: false
};