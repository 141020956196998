import AbstractApi from 'shared/api/Abstract';

const ConnectionHistoryApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("connectionlogs/searches", query, from, size, sorting, direction);
    },

    export: function(query, fileName) {
        return AbstractApi.export("connectionlogs", query, "", fileName);
    }
}
export default ConnectionHistoryApi;
