export const MAIL_TYPES = {
    'action.closed': 'Fermeture action',
    'action.updated': 'Mise à jour action',
    'alert.deadline': 'Alerte sur expiration action',
    'alert.grouped': 'Alerte groupé',
    'alert.review': 'Alerte sur date de revue arrivant à expiration',
    'alert.separate': 'Alerte séparé',
    'alert.summary': 'Récapitulatif',
    'error_flux': 'Erreur flux',
    'invitation.expired': 'Invitation expirée',
    'invitation.externaluser': 'Invitation utilisateur externe',
    'invitation.externaluser.reminder': 'Rappel invitation utilisateur externe',
    'invitation.internaluser': 'Invitation utilisateur interne',
    'invitation.internaluser.reminder': 'Rappel invitation utilisateur interne',
    'newsletter': 'Newsletter espace documentaire',
}

export function getMailTypes() {
    let types = [];
    for (const [key, value] of Object.entries(MAIL_TYPES)) {
        types.push({
            value: key, 
            label: value,
        });
    }
    return types;
}

export function getMailTypeLabel(type) {
    return MAIL_TYPES[type];
}
