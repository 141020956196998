import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import BarLoader from "react-spinners/BarLoader";

import InternalUserApi from 'shared/api/InternalUser';
import WatchsiteApi from 'shared/api/Watchsite';

export default function WatchsiteBatch(props) {

    const [consultants, setConsultants] = useState([{value: "", label: "Chargement"}]);
    const [batchLoading, setBatchLoading] = useState(false);
    const [current_batch, setCurrentBatch] = useState();
    const [allselected, setAllselected] = useState(false);
    const [affectation_mode, setAffectationMode] = useState(0);
    const [subscription_begin, setSubscriptionBegin] = useState();
    const [subscription_end, setSubscriptionEnd] = useState();
    const [consultant, setConsultant] = useState();

    useEffect(() => {
        InternalUserApi
            .search({"bool":{"must":[{"terms":{"roles":["ROLE_CONSULTANT"]}}]}})
            .then(([results]) => {
                let newconsultants = [];
                Object.entries(results).forEach(([index, user]) => {
                    newconsultants.push({"value": user.id, "label": user.lastname + " " + user.firstname});
                });
                setConsultants(newconsultants);
            })
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function updateAffectationMode() {
        setBatchLoading(true);
        WatchsiteApi
            .bulkUpdate(props.selected, {"affectation_mode": affectation_mode})
            .then(response => {
                setBatchLoading(false);
                toast.success("Modification effectuée");
                props.onBatchSuccess();
            })
            .catch(error => {
                setBatchLoading(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    Object.entries(error.response.data).forEach(([index, error]) => {
                        toast.error(error.message);
                    });
                }
            });
    }

    function updateSubscriptionDate() {
        setBatchLoading(true);
        WatchsiteApi
            .bulkUpdate(props.selected, {"subscription_begin": subscription_begin, "subscription_end": subscription_end})
            .then(response => {
                setBatchLoading(false);
                toast.success("Modification effectuée");
                props.onBatchSuccess();
            })
            .catch(error => {
                setBatchLoading(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    Object.entries(error.response.data).forEach(([index, error]) => {
                        toast.error(error.message);
                    });
                }
            });
    }

    function assignConsultant() {
        setBatchLoading(true);
        InternalUserApi
            .assignWatchsite(consultant, props.selected)
            .then(response => {
                setBatchLoading(false);
                toast.success("Consultant affecté.");
                props.onBatchSuccess();
            })
            .catch(error => {
                setBatchLoading(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    Object.entries(error.response.data).forEach(([index, error]) => {
                        toast.error(error.message);
                    });
                }
            });
    }

    function unassignConsultant() {        
        InternalUserApi
            .unassignWatchsite(consultant, props.selected)
            .then(response => {
                setBatchLoading(false);
                toast.success("Affectation du consultant retiré.");
                props.onBatchSuccess();
            })
            .catch(error => {
                setBatchLoading(false);
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    Object.entries(error.response.data).forEach(([index, error]) => {
                        toast.error(error.message);
                    });
                }
            });
    }

    return (
        <div className="row">
            <div className="col-md-8">
                <input
                    type="checkbox"
                    name="all-items"
                    id="all-items"
                    value={allselected}
                    onChange={e => {setAllselected(e.target.checked); props.selectAll(e.target.checked)} } 
                />
                <label htmlFor="all-items">&nbsp;</label>
                <select onChange={e => setCurrentBatch(e.target.value)}>
                    <option></option>
                    <option value="update_subscription_date">Modifier la date d'abonnement</option>
                    <option value="update_affectation_mode">Modifier le mode d'affectation</option>
                    <option value="assign_consultant">Affecter un consultant</option>
                    <option value="unassign_consultant">Retirer l'affectation d'un consultant</option>
                </select>

                {current_batch === "update_affectation_mode" && (
                    <React.Fragment>
                        <select onChange={e => setAffectationMode(e.target.value)} value={affectation_mode}>
                            <option value="0">Immédiat</option>
                            <option value="1">Différé</option>
                        </select>
                        <button id="run-batch-watchsite-update-affectation" onClick={updateAffectationMode} type="button" className="btn secondary h25 btn-primary">Valider</button>
                    </React.Fragment>
                )}
                {current_batch === "update_subscription_date" && (
                    <React.Fragment>
                        <label htmlFor="batch-watchsite-subscription-begin" style={{display:"inline-block"}}>Du</label>
                        <input 
                            id="batch-watchsite-subscription-begin" 
                            type="date"
                            value={subscription_begin}
                            onChange={e => setSubscriptionBegin(e.target.value)}
                        />
                        <label htmlFor="batch-watchsite-subscription-end" style={{display:"inline-block"}}>au</label>
                        <input
                            id="batch-watchsite-subscription-end"
                            type="date"
                            value={subscription_end}
                            onChange={e => setSubscriptionEnd(e.target.value)}
                        />
                        <button id="run-batch-watchsite-update-subscription" onClick={updateSubscriptionDate} type="button" className="btn secondary h25 btn-primary">Valider</button>
                    </React.Fragment>
                )}
                {current_batch === "assign_consultant" && (
                    <React.Fragment>
                        <select onChange={e => setConsultant(e.target.value)} value={consultant}>
                            <option></option>
                            {consultants.map((o) => (<option key={o.value} value={o.value}>{o.label}</option>))}
                        </select>
                        <button id="run-batch-watchsite-add-consultant" onClick={assignConsultant} type="button" className="btn secondary h25 btn-primary">Valider</button>
                    </React.Fragment>
                )}
                {current_batch === "unassign_consultant" && (
                    <React.Fragment>
                        <select onChange={e => setConsultant(e.target.value)} value={consultant}>
                            <option></option>
                            {consultants.map((o) => (<option key={o.value} value={o.value}>{o.label}</option>))}
                        </select>
                        <button id="run-batch-watchsite-unassign-consultant" onClick={unassignConsultant} type="button" className="btn secondary h25 btn-primary">Valider</button>
                    </React.Fragment>
                )}
            </div>
            <div className="col-md-4">
                <BarLoader loading={batchLoading} width={100} color="#5bad27" css="display:block;margin:12px 0px 0px auto;" />
            </div>
        </div>
    );
}