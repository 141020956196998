import { React, useState, toast } from 'commons';
import { FieldComponent, LoadButton } from 'shared/components';
import LegifranceApi from 'api/Legifrance';

export default function LegifranceManual(props) {
    const [file, setFile] = useState();
    const [saving, setSaving] = useState(false);

    function createImport(event) {
        event.preventDefault();
        setSaving(true);
        LegifranceApi
            .create(file)
            .then(() => {
                setSaving(false);
                props.onSuccess();
                toast.success("Import correctement paramétré. Le journal sera disponible une fois celui-ci terminé.")
            });
    }

    return <section className="bloc">
        <header>
            <h2 className="uppercase">Import manuel</h2>
        </header>
        <div className="border-rlb border-gris-40">
            <div className="row">
                <div className="col-md-8">
                    <FieldComponent
                        type="file"
                        label="Fichiers à importer"
                        name="requirements"
                        accept=".tgz,.gz"
                        onChange={value => setFile(value)}
                    />
                </div>
                <div className="col-md-4">
                    <LoadButton
                        loading={saving}
                        onClick={createImport}
                        label="Importer"
                        name="import-requirements"
                        id="import-requirements"
                    />
                </div>
            </div>
        </div>
    </section>;
}