import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import BarLoader from "react-spinners/BarLoader";

import TreeComponent from 'shared/components/TreeComponent';
import LoadButton from 'shared/components/LoadButton';

import { modalCustomStyles } from 'shared/services/ModalStyle';
import TreeUtils from 'shared/services/TreeUtils';

import AccountApi from 'shared/api/Account';
import WatchsiteApi from 'shared/api/Watchsite';
import NodeApi from 'shared/api/Node';
import Can, { canPerform } from 'shared/services/Can';
import FileUtils from 'shared/services/FileUtils';

import WatchsiteBatch from 'views/account/form/WatchsiteBatch';
import NodeForm from 'views/node/Form';
import WatchsiteForm from 'views/watchsite/Form';

Modal.setAppElement('#root');

export default function AccountTree(props) {

    const [tree, setTree] = useState({nodes: [], watchsites: []});
    const [loading, setLoading] = useState(true);

    const [exporting, setExporting] = useState(false);

    const [modal_node_opened, setModalNodeOpened] = useState(false);
    const [modal_watchsite_opened, setModalWatchsiteOpened] = useState(false);       
    
    const [selected_watchsites, setSelectedWatchsites] = useState([]);
    const [current_node, setCurrentNode] = useState({
        account: (props.account.id ? props.account.id : null)
    });
    const [current_watchsite, setCurrentWatchsite] = useState({
        account: (props.account.id ? props.account.id : null)
    });

    useEffect(() => { 
        loadTree();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function loadTree() {
        setLoading(true);
        AccountApi.getTree(props.account.id)
            .then(data => {
                setTree(TreeUtils.populateReadablePaths(data));
                setLoading(false);
            })
            .catch(error => console.log(error));
    }

    function selectWatchsite(event) {
        let newSelection = selected_watchsites.slice(0);
        if (event.target.checked) {
            newSelection.push(parseInt(event.target.value));
        } else {
            newSelection = newSelection.filter(id => id !== parseInt(event.target.value));
        }
        setSelectedWatchsites(newSelection);
    }
    
    function selectNode(node, newSelection) {
        Object.entries(node.childs).forEach(([index, node]) => {
            selectNode(node, newSelection);
        });
        Object.entries(node.watchsites).forEach(([index, watchsite]) => {
            newSelection.push(parseInt(watchsite.id));
        });
    }
    
    function moveItem(event) {
        const target = event.target.tagName === "LI" ? event.target : event.target.closest("li");
        if (target && target.getAttribute("data-type") === "node") {
            const item = document.getElementById(event.dataTransfer.getData("text"));

            const parent = target.getAttribute("data-id");
            const id = item.getAttribute("data-id");
            const type = item.getAttribute("data-type");

            
            if (type === "watchsite") {
                setLoading(true);
                WatchsiteApi
                    .bulkUpdate([id], {"node": parent})
                    .then(response => loadTree())
                    .catch(error => {
                        setLoading(false);
                        if (error.response.data.message) {
                            toast.error(error.response.data.message);
                        } else {
                            Object.entries(error.response.data).forEach(([index, error]) => {
                                toast.error(error.message);
                            });
                        }
                    });
            }
            if (type === "node") {
                if (id !== parent) {
                    setLoading(true);
                    NodeApi
                        .bulkUpdate([id], {"parent": parent})
                        .then(response => loadTree())
                        .catch(error => {
                            setLoading(false);
                            if (error.response.data.message) {
                                toast.error(error.response.data.message);
                            } else {
                                Object.entries(error.response.data).forEach(([index, error]) => {
                                    toast.error(error.message);
                                });
                            }
                        });
                } else {
                    toast.error("Impossible de déplacer un noeud vers lui même");
                }
            }

            target.classList.remove("droppable");
        }
    }

    function selectAll(isSelected) {
        if (isSelected) {
            let newSelection = [];
            Object.entries(tree.nodes).forEach(([index, node]) => {
                selectNode(node, newSelection);
            });
            Object.entries(tree.watchsites).forEach(([index, watchsite]) => {
                newSelection.push(parseInt(watchsite.id));
            });
            setSelectedWatchsites(newSelection);
        } else {
            setSelectedWatchsites([]);
        }
    }

    function openNodeModal(node = null) {
        if (node === null) {
            node = {
                id: null,
                label: "",
                parent: null,
                account: (props.account ? props.account.id : null)
            };
        }
        setCurrentNode(node);
        setModalNodeOpened(true);
    }

    function openWatchsiteModal(watchsite = null) {
        if (watchsite === null) {
            watchsite = {
                id: null,
                label: "",
                node: null,
                account: (props.account ? props.account.id : null)
            };
        }
        setCurrentWatchsite(watchsite);
        setModalWatchsiteOpened(true);
    }

    function exportWatchsites() {
        setExporting(true);
        WatchsiteApi
            .export({"bool":{"must":[{"match":{"id": props.account.id}}]}})
            .then(data => {
                setExporting(false);
                fileDownload(data, FileUtils.getFilePrefix() + "-" + props.account.name + "-PointsDeVeille.xlsx");
            })
            .catch(error => {
                setExporting(false);
                toast.error("Erreur durant la création de l'export.");
            });
    }

    return (
        <React.Fragment>       
            <Can
                perform="account:write"
                data={props.account}
                yes={() => (<header className="bg-gris-45">
                    <WatchsiteBatch
                        selected={selected_watchsites}
                        selectAll={selectAll}
                        onBatchSuccess={loadTree}
                    />
                </header>)}
            />
            <div className="border-gris-25 border-lrb bg-blanc">
                <div className="row">
                    <div className="col-md-10 offset-md-2">
                        <Can
                            perform="account:write"
                            data={props.account}
                            yes={() => (<div className="btn-group" role="group" aria-label="Ajout d'élément à l'arborescence">
                                <button
                                    id="add-node"
                                    onClick={e => openNodeModal()}
                                    type="button"
                                    className="btn secondary h40 btn-primary"
                                    disabled={props.account.is_start}>
                                        Ajouter un noeud
                                </button>
                                <button
                                    id="add-watchsite"
                                    onClick={e => openWatchsiteModal()}
                                    type="button"
                                    className="btn secondary h40 btn-primary">
                                        Ajouter un point de veille
                                </button>
                                <LoadButton 
                                    loading={exporting}
                                    label="Export des points de veille"
                                    iconclassName="icon-file-excel"
                                    className="btn secondary h40 btn-primary icon"
                                    onClick={exportWatchsites}
                                    id="export-watchsites"
                                />
                            </div>)}
                        />
                        <TreeComponent
                            tree={tree}
                            account={props.account}
                            onWatchsiteClick={(e, w) => {e.preventDefault(); openWatchsiteModal(w)}}
                            onNodeClick={(e, n) => {e.preventDefault(); openNodeModal(n)}}
                            onNodeRemove={loadTree}
                            onDragEnd={moveItem}
                            onSelect={selectWatchsite}
                            selected={selected_watchsites}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <BarLoader loading={loading} width={100} color="#5bad27" css="display:block;margin:10px auto;" />
                    </div>
                </div>
            </div>           

            <Modal 
                isOpen={modal_node_opened}
                onRequestClose={() => {loadTree(); setModalNodeOpened(false)}}
                style={modalCustomStyles}>
                <NodeForm
                    node={current_node}
                    account={props.account}
                    promptChecker={props.promptChecker}
                    tree={tree}
                    onRequestClose={() => {loadTree(); setModalNodeOpened(false)}}
                />
            </Modal>
            <Modal 
                isOpen={modal_watchsite_opened}
                onRequestClose={() => {loadTree(); setModalWatchsiteOpened(false)}}
                style={modalCustomStyles}>
                <WatchsiteForm
                    watchsite={current_watchsite}
                    consultants={props.consultants}
                    account={props.account}
                    promptChecker={props.promptChecker}
                    tree={tree}
                    onRequestClose={() => {loadTree(); setModalWatchsiteOpened(false)}}
                />
            </Modal>
        </React.Fragment>
    );
}