export const SHEET_TO_RULE = 1;
export const SHEET_TO_DO = 2;
export const SHEET_DONE = 3;
export const SHEET_ARCHIVED = 4;

export const SHEET_STATUS = {
    [SHEET_TO_RULE]: "A statuer",
    [SHEET_TO_DO]: "A traiter",
    [SHEET_DONE]: "Finalisée",
    [SHEET_ARCHIVED]: "Archivée",
}

export const SHEET_STATUS_SMALL = {
    [SHEET_TO_RULE]: "S",
    [SHEET_TO_DO]: "T",
    [SHEET_DONE]: "F",
    [SHEET_ARCHIVED]: "A",
}

export const SHEET_STATUS_COLOR = {
    [SHEET_TO_RULE]: "error",
    [SHEET_TO_DO]: "alert",
    [SHEET_DONE]: "valid",
    [SHEET_ARCHIVED]: "new",
}

export function getSheetStatus() {
    let sheetStatus = [];
    for (const [key, value] of Object.entries(SHEET_STATUS)) {
        sheetStatus.push({
            value: parseInt(key), 
            label: value,
            color: SHEET_STATUS_COLOR[key],
        });
    }
    return sheetStatus;
}


export function getSheetStatusSmall() {
    let sheetStatus = [];
    for (const [key, value] of Object.entries(SHEET_STATUS_SMALL)) {
        sheetStatus.push({
            value: parseInt(key), 
            label: value,
            title: SHEET_STATUS[key],
            color: SHEET_STATUS_COLOR[key],
        });
    }
    return sheetStatus;
}

export function getSheetStatusLabel(status) {
    return SHEET_STATUS[status];
}

export function getSheetStatusColor(status) {
    return SHEET_STATUS_COLOR[status];
}