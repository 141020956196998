import { React, Modal, useEffect, useState, useContext, _ } from 'commons';
import { useList } from 'shared/hooks/List';
import { ReferentialContext } from 'shared/stores/Referential';
import { SHEET_STATE_VALIDATED, SHEET_STATE_UNNECESSARY, SHEET_STATE_TODECIDE, SHEET_STATE_TODO } from 'shared/data';
import { modalCustomStyles, mediumModalCustomStyles, preventDefault } from 'shared/services';
import { ListComponent, FilterComponent } from 'shared/components';
import { SHEET_DONE } from 'data/SheetStatus';
import PushApi from 'api/Push';
import BatchActions from 'views/push/List/BatchActions';
import RowPush from 'views/push/List/RowPush';
import PushAddForm from 'views/push/PushAddForm';
import Affectations from 'views/push/List/Assignements/Affectations';
import Absences from 'views/push/List/Assignements/Absences';
import AffectationForm from 'views/push/List/Assignements/AffectationForm';

export default function PushList(props) {

    const [batchSelection, setBatchSelection] = useState([]);
    const [checkingAffectations, setCheckingAffectations]= useState(false);
    const [checkingAbscences, setCheckingAbscences]= useState(false);
    const [affecting, setAffecting] = useState(false);
    const [currentSheet, setCurrentSheet] = useState(null);
    const [referentialContext] = useContext(ReferentialContext);
    const domainTree = _.cloneDeep(referentialContext["tree"]);

    const columns = [
        {id: 'sheet.id', title: 'Numéro de la fiche', selectable: false},
        {id: 'sheet.title', title: 'Titre', selectable: false},
        {id: 'sheet.text_date', title: 'Date du texte'},
        {id: 'sheet.publication_date', title: 'Date de publication'},
        {id: 'sheet.affectation_deadline', title: 'Délai d\'affectation', sortable: false},
        {id: 'sheet.categories', title: 'Domaines', sortable: false},
        {id: 'sheet.state_synthesis', title: 'Etat niveau synthèse'},
        {id: 'sheet.state_technical', title: 'Etat niveau technique'},
        {id: 'sheet.status', title: 'Statut de la fiche'},
        {id: 'sheet.text_number', title: 'Numéro de texte'},
    ];

    const filterType = {
        domains: {type: "terms", fields: ["sheet.categories"]},
        status: {type: "match", fields: ["sheet.status"]},
        state_synthesis: {type: "terms", fields: ["sheet.state_synthesis"]},
        state_technical: {type: "terms", fields: ["sheet.state_technical"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("push-list", PushApi, preFetch, "sheet.id", {status: SHEET_DONE});

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        if (newCriterias.finalize_state === "synthesis_only") {
            newCriterias.state_synthesis = [SHEET_STATE_VALIDATED];
            newCriterias.state_technical = [SHEET_STATE_TODECIDE, SHEET_STATE_TODO];
        }
        if (newCriterias.finalize_state === "synthesis_technical") {
            newCriterias.state_synthesis = [SHEET_STATE_VALIDATED];
            newCriterias.state_technical = [SHEET_STATE_VALIDATED, SHEET_STATE_UNNECESSARY];
        }
        return [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters];
    }

    function openAffectations(sheet) {
        setCurrentSheet(sheet);
        setCheckingAffectations(true);
    }

    function openAbsences(sheet) {
        setCurrentSheet(sheet);
        setCheckingAbscences(true);
    }

    function openAffectationForm(sheet) {
        setCurrentSheet(sheet);
        setAffecting(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => submitSearch(filterType), []);

    return <>
        <div className="bloc">
            <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                <section className="filters">
                    <header>
                        <h2><button className="toggler" type="button">Recherche</button></h2>
                    </header>
                    <div className="bg-gris-25">
                        <div className="row">
                            <FilterComponent
                                type="dropdown-tree-select"
                                label="Domaines / Sous domaines / Thèmes"
                                name="domains"
                                onChange={value => addCriteria("domains", value)}
                                value={criterias.domains}
                                data={domainTree}
                                mode="hierarchical"
                                colClassName="col-md-6"
                            />
                            <FilterComponent
                                type="select"
                                label="Fiche complétée"
                                name="finalize_state"
                                onChange={value => addCriteria("finalize_state", value)}
                                value={criterias.finalize_state}                            
                                clearable={true}
                                options={[
                                    {value: "synthesis_only", label: "Fiche avec synthèse uniquement"},
                                    {value: "synthesis_technical", label: "Fiche avec synthèse et commentaire"}
                                ]}
                                colClassName="col-md-3"
                            />
                            <FilterComponent
                                type="checksingle"
                                label="Etat synthèse et commentaire"
                                checksingleLabel="Synthèse et commentaire finalisés"
                                name="status"
                                onChange={value => addCriteria("status", value ? SHEET_DONE : null)}
                                value={SHEET_DONE}
                                checked={criterias.status}
                                colClassName="col-md-3"
                            />
                        </div>
                    </div>
                    <div className="bg-gris-25 border-b border-gris-60">
                        <div className="row">
                            <div className="col-md-9">
                                <button id="clearfilter-push" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                            </div>
                            <div className="col-md-3 text-right">
                                <button id="search-push" type="submit" className="btn btn-primary">Filtrer</button>
                            </div>
                        </div>
                    </div>
                </section>

                <ListComponent 
                    id="push"
                    className="table table-bordered"
                    displayHeader={false}
                    loading={loading}
                    selectable={true}
                    selection={batchSelection}
                    onSelect={(s) => setBatchSelection(s)}
                    selectField="sheet.id"
                    rows={rows}
                    columns={columns}
                    sorting={sorting}
                    direction={direction}
                    onSortingChange={updateSorting}
                    perpage={limit}
                    onPerpageChange={updateLimit}
                    page={page}
                    onPageChange={updatePage}
                    totalrows={totalrows}
                    batchActions={<BatchActions cleanSelection={() => setBatchSelection([])}  selection={batchSelection} refresh={refresh} setLoading={setLoading} />}
                    renderRow={(row, cols, index, drawRow, drawSelectable) =>
                        <RowPush 
                            key={row.id}
                            perimeter={props.perimeter}
                            row={row}
                            columns={cols}
                            index={index}
                            drawSelectable={drawSelectable}
                            refresh={refresh}
                            setLoading={setLoading}
                            openAffectations={openAffectations}
                            openAbsences={openAbsences}
                            openAffectationForm={openAffectationForm}
                        />
                    }                
                />
            </form>
        </div>

        <PushAddForm refresh={refresh} />

        <Modal isOpen={checkingAffectations} onRequestClose={() => setCheckingAffectations(false)} style={mediumModalCustomStyles}>
            <Affectations sheet={currentSheet} perimeter={props.perimeter} onClose={() => setCheckingAffectations(false)} />
        </Modal>

        <Modal isOpen={checkingAbscences} onRequestClose={() => setCheckingAbscences(false)} style={mediumModalCustomStyles}>
            <Absences sheet={currentSheet} onClose={() => setCheckingAbscences(false)} />
        </Modal>
        
        <Modal isOpen={affecting} onRequestClose={() => setAffecting(false)} style={modalCustomStyles}>
            <AffectationForm sheet={currentSheet} perimeter={props.perimeter} onClose={() => setAffecting(false)} refresh={refresh} />
        </Modal>
    </>
}