import { React, Link, toast, useEffect } from 'commons';
import { useList } from 'shared/hooks';
import { AccessApi } from 'shared/api';
import { preventDefault} from 'shared/services'; 
import { ListComponent, FilterComponent } from 'shared/components';
import NodeUserAssignment from 'views/node/form/NodeUserAssignment';

export default function NodeUsersList(props) {

    const columns = [
        {
            id: 'user.email', 
            title: 'Email'
        },
        {
            id: 'user.firstname', 
            title: 'Prénom / Nom',
            render: (row) => (<>{row.user.civility} {row.user.firstname} <strong>{row.user.lastname}</strong></>)
        },
        {
            id: 'inherit_access', 
            title: 'Droit d’accès sur le noeud',
            render: (row) => {
                if (row.inherit_writeaccess === 1) {
                    return (<>Ecriture</>);
                } else if (row.inherit_readaccess === 1) {
                    return (<>Lecture</>);
                }                
            }
        },
        {
            id: 'access', 
            title: 'Paramétrage de l’accès',
            sortable: false,
            render: (row) => <>
                {row.user.access_write === 0 && 
                    <span className="field-wrap">
                        <select
                            id={`accessright-${row.id}`}
                            className="field"
                            onChange={e => updateAccessright(row, e.target.value)}
                            value={row.writeaccess === 1 ? "write" : (row.readaccess === 1 ? "read" : "")}
                        >
                            <option value="">{props.parent ? "Récupérer droit parent" : "Supprimer l'accès"}</option>
                            <option value="write">Ecriture</option>
                            {row.writeaccess === 0 && row.inherit_writeaccess === 0 && <option value="read">Lecture</option>}
                        </select>
                        <label htmlFor={`accessright-${row.id}`}><i className="icon-general-carat"></i></label>
                    </span>}
                {row.user.access_write === 1 && <span>Accès total en écriture</span>}
            </>
        },
        {
            id: 'user', 
            title: 'Accès à la fiche utilisateur',
            sortable: false,
            render: (row) => (<Link title="Voir l'utilisateur" to={`/externalusers/${row.user.id}00${row.account.id}`}><i className="icon-actions-modifier" aria-hidden="true"></i></Link>)
        }
    ];

    const filterType = {
        node: {type: "match", fields: ["node"]},
        keyword: {type: "wildcard", fields: ["user.email", "user.lastname", "user.firstname"]},
        accessright: {type: "match", fields: ["accessright"]},
        inherit_readaccess: {type: "match", fields: ["inherit_readaccess"]},
        inherit_writeaccess: {type: "match", fields: ["inherit_writeaccess"]}
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("node-user-list", AccessApi, preFetch, "user.email");

    useEffect(() => {
        submitSearch(filterType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {        
        let completeCriterias = {...newCriterias};
        completeCriterias.node = props.node;
        if (completeCriterias.accessright === "write") {
            delete completeCriterias.accessright;
            completeCriterias.inherit_writeaccess = 1;
        }
        if (completeCriterias.accessright === "read") {
            delete completeCriterias.accessright;
            completeCriterias.inherit_readaccess = 1;
            completeCriterias.inherit_writeaccess = 0;
        }
        return [newSorting, newDirection, completeCriterias, newLimit, newPage, newFilters];
    }

    function updateAccessright(access, value) {
        setLoading(true);
        switch (value) {
            case "read": 
                access.writeaccess = 0;
                access.readaccess = 1;
                break;
            case "write": 
                access.writeaccess = 1;
                access.readaccess = 1;
                break;
            case "":
            default:
                access.writeaccess = 0;
                access.readaccess = 0;
                break;
        }
        AccessApi
            .update(
                access.id,
                {
                    account: access.account.id,
                    user: access.user.id,
                    node: access.node.id,
                    readaccess: access.readaccess,
                    writeaccess: access.writeaccess,
                }
            )
            .then(newData => {            
                refresh();
                toast.success("Droit d'accès mise à jour");
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                <section className="filters">
                    <header className="uppercase bg-gris-45">
                        Recherche
                    </header>
                    <div className="bg-gris-25">
                        <div className="row">
                            <FilterComponent
                                colClassName="col-md-6"
                                label="Recherche libre"
                                name="keyword"
                                onChange={value => addCriteria("keyword", value)}
                                value={criterias.keyword || ""}
                            />
                            <FilterComponent
                                colClassName="col-md-6"
                                type="select"
                                label="Droit d'accès"
                                name="accessright"
                                onChange={value => addCriteria("accessright", value)}
                                value={criterias.accessright || ""}
                                clearable={true}
                                options={[
                                    {"value": "write", "label": "Ecriture"},
                                    {"value": "read", "label": "Lecture"}
                                ]}
                            />
                        </div>
                    </div>
                    <div className="bg-gris-25 border-b border-gris-60">
                        <div className="row">
                            <div className="col-md-9">
                            </div>
                            <div className="col-md-3 text-right">
                                <button id="search-node-user" type="submit" className="btn btn-primary">Rechercher</button>
                            </div>
                        </div>
                    </div>
                </section>

                <ListComponent 
                    id="nodeusers"
                    loading={loading}
                    selectable={false}
                    rows={rows}
                    columns={columns}
                    sorting={sorting}
                    direction={direction}
                    onSortingChange={updateSorting}
                    perpage={limit}
                    onPerpageChange={updateLimit}
                    page={page}
                    onPageChange={updatePage}
                    totalrows={totalrows}
                    border=""
                >
                    <Link id="new-externaluser" to="/externalusers/new" className="btn btn-primary h25 icon"><i className="icon-boutons-creer-acces" aria-hidden="true"></i>&nbsp;Créer un utilisateur</Link>
                </ListComponent>
            </form>

            <NodeUserAssignment
                node={props.node}
                account={props.account}
                onAssigment={refresh}
            />
        </>            
    );
}