import ApiRequest from 'shared/services/ApiRequest';
import FileApi from 'shared/api/File';

const ConformityQuizzApi = {

    process: function(data) {
        return ApiRequest.post("conformityquizz", data).then(response => response.data);
    },

    upload: function(files) {
        return FileApi.upload("conformityquizz/upload", files);
    }
}

export default ConformityQuizzApi;