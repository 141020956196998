import { React } from 'commons';
import { denyIfCantPerform } from 'shared/services';
import ObligationGeneratorForm from 'shared/views/obligationgenerator/Form';

export default function ObligationGeneratorFormWrapper(props) {

    denyIfCantPerform(props, "obligationgenerator:read");

    return <ObligationGeneratorForm />
}
