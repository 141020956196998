import { React, useState } from 'commons';
import { useToggler } from "shared/hooks";
import { ToggableBlocComponent, FieldComponent } from "shared/components";

export default function UpdateComments(props) {

    const [togglers, toggle] = useToggler({'comment-configuration': true});
    const [comment, setComment] = useState(props.globalComment);

    return <section>
        <form className="form">
            <ToggableBlocComponent 
                label="Configurer le commentaire pour le(s) point(s) de veille sélectionné(s)"
                id="comment-configuration"
                toggled={togglers["comment-configuration"]}
                toggle={toggle}
            >
                <div className="bg-blanc">
                    <FieldComponent
                        wrapper={([, input]) => <>{input}</>}
                        type="textarea"
                        rte={true}
                        name="mass-comment"
                        value={comment}
                        onChange={value => setComment(value)}
                    />
                </div>
            </ToggableBlocComponent>
            <section className="row">
                <div className="col">
                    <button type="button" id="back-push" onClick={() => props.onClose()} className="btn btn-bleu-4">
                        Retour au push
                    </button>
                </div>
                <div className="col text-right">
                    <button
                        type="button"
                        id="valid-perimeter"
                        onClick={() => {
                            props.onSubmit(comment);
                            props.onClose();
                        }}
                        className="btn btn-primary"
                    >
                        Valider le commentaire
                    </button>
                </div>
            </section>
        </form>
    </section>;
}