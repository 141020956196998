import { React, Modal, useEffect, useContext, useState, Link, toast, _ } from 'commons';
import { useList, usePosition, useToggler } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { SheetAffectedApi } from 'shared/api';
import { getAffectationStatusLabel, getAffectationStatus, STATUS_TODO, STATUS_TOSENT, STATUS_SENT_BY_APAVE, STATUS_ADD_BY_CUSTOMER, STATUS_ARCHIVED } from 'data/AffectationStatus';
import { ToggableBlocComponent, TreeReferentialComponent, ListComponent, StatusComponent, FilterComponent, HtmlComponent } from 'shared/components';
import { convertCriteriesToSearchQuery, mediumModalCustomStyles, preventDefault, FileUtils, formatDate } from 'shared/services';
import SheetAffectedListBatchActions from 'views/sheetaffected/list/BatchActions';
import SheetAffectedListFormUpdateComment from 'views/sheetaffected/list/form/UpdateComment';
import SheetAffectedListFormAddSheet from 'views/sheetaffected/list/form/AddSheet';

export default function SheetAffectedListSheetList(props) {

    const [initializePosition] = usePosition("sheet-affected-list");
    const [togglers, toggle] = useToggler({'database-search': true});
    const [referentialContext] = useContext(ReferentialContext);
    const [batchSelection, setBatchSelection] = useState([]);
    const [updatingComment, setUpdatingComment] = useState(false);
    const [currentSheetaffected, setCurrentSheetaffected] = useState(null);
    const tree = _.cloneDeep(referentialContext["tree"]);

    const columns = [
        {id: 'sheet.id', title: 'Numéro'},
        {id: 'status', title: 'Statut d\'affectation', render: (row) => 
            <StatusComponent value={getAffectationStatusLabel(row.status)} size="extra-large" />
        },
        {id: 'sheet.title', title: 'Titre', render: (row) => <HtmlComponent>{row.sheet.title}</HtmlComponent>},
        {id: 'sheet.categories', title: 'Domaines', sortable: false, render: (row) => 
            <TreeReferentialComponent className="arborescence" items={tree} value={row.sheet.categories.map(e => e.id)} />
        },
        {id: 'sheet.text_date', title: 'Date du texte', render: (row) => <>{row.sheet.text_date ? formatDate(new Date(row.sheet.text_date)) : ''}</>},
        {id: 'sheet.publication_date', title: 'Date de publication', render: (row) => <>{row.sheet.text_date ? formatDate(new Date(row.sheet.publication_date)) : ''}</>},
        {id: 'affectation_date', title: 'Date d\'affectation', format: 'date'},
        {id: 'sheet.major_text', title: 'Texte majeur', render: (row) => <>{row.sheet.major_text && <i class="icon-autres-texte-majeur color-vert-apave" aria-hidden="true"></i>}</>},
        {id: 'hidden', title: 'Fiche masquée', format: 'checksingle'},
        {id: 'action', title: '', sortable: false, render: (row) => 
            <ul className="actions">
                <li>
                    <Link target="_blank" title="Consulter la fiche" to={"/sheets/" + row.sheet.id + "/preview"} id="preview-sheet">
                        <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                    </Link>
                </li>
                <li>
                    <Link onClick={(e) => downloadText(e, row.sheet)} title="Consulter le texte reglementaire" to={"/sheets/" + row.sheet.id} id={`download-text-${row.sheet.id}`}>
                        <i className="icon-actions-consulter-pdf" aria-hidden="true"></i>
                    </Link>
                </li>
                <li>
                    <Link title="Modifier le commentaire" onClick={(e) => updateComment(e, row)} to={"/sheetsaffected/" + row.id + "/updatecomment"} id="updatecomment-sheet">
                        <i className="icon-actions-modifier-commentaire" aria-hidden="true"></i>
                    </Link>
                </li>
                {row.status !== STATUS_ARCHIVED && <li>
                    <Link title="Exclure la fiche" onClick={(e) => updateStatus(e, [row.id], STATUS_ARCHIVED)} to={"/sheetsaffected/" + row.id + "/exclude"} id="updatecomment-sheet">
                        <i className="icon-actions-archiver-fiche" aria-hidden="true"></i>
                    </Link>
                </li>}
            </ul>
        }
    ];

    let filterType = {
        watchsite: {type: "match", fields: ["watchsite"]},
        id: {type: "match", fields: ["sheet.id"]},
        sheet_categories: {type: "terms", fields: ["sheet.categories"]},        
        affectation_date: {type: "range", fields: ["affectation_date"]},
        status: {type: "terms", fields: ["status"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh
    ] = useList("sheet-affected-list", SheetAffectedApi, preFetch, "affectation_date", {
        id: '',
        watchsite: props.watchsite,
        status: [STATUS_TODO, STATUS_TOSENT, STATUS_SENT_BY_APAVE, STATUS_ADD_BY_CUSTOMER]
    });

    function preFetch(newSorting, newDirection, newCriterias, newLimit, newPage, newFilters) {
        newCriterias.watchsite = props.watchsite;
        return [newSorting, newDirection, newCriterias, newLimit, newPage, newFilters];
    }

    function load() {
        submitSearch(filterType, () => initializePosition(), false);
        setBatchSelection([]);
    }

    function updateComment(event, sheetaffected) {
        event.preventDefault();
        setUpdatingComment(true);
        setCurrentSheetaffected(sheetaffected);
    }

    function updateStatus(event, sheetsaffected, status, cleanSelection = false) {
        event.preventDefault();
        setLoading(true);

        SheetAffectedApi
            .bulkUpdate(sheetsaffected, {status: status})
            .then(() => {
                toast.success("Mise à jour du statut effectué");
                if (cleanSelection) {
                    setBatchSelection([]);
                }
            })
            .catch(() => toast.error("Une erreur est survenue"))
            .finally(() => refresh());
    }

    function downloadText(event, sheet) {
        event.preventDefault();
        FileUtils.download(sheet.reglementary_text);
    }

    function exportResearch(event) {
        event.preventDefault();
        setLoading(true);

        const fileName = FileUtils.getFilePrefix() + "-FondDocumentaire-" + props.watchsite + ".xlsx";
        SheetAffectedApi
            .export(convertCriteriesToSearchQuery(criterias, filterType), "backoffice", fileName)
            .then(() => toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible."))
            .catch(() => toast.error("Erreur durant la création de l'export."))
            .finally(() => setLoading(false));
    }

    function openSheetAffecationFormForWatchsite() {
        SheetAffectedApi
            .search(
                {"bool":{"must":[
                    {"match":{"watchsite": props.watchsite}},
                    {"match":{"status": STATUS_TOSENT}}
                ]}},
                0,
                99999,
                "sheet.id",
                "ASC"
            )
            .then(([results]) => props.openSheetAffecationForm(results));
    }

    function openSheetAffecationFormForAccount() {
        SheetAffectedApi
            .search(
                {"bool":{"must":[
                    {"match":{"watchsite.account": props.account}},
                    {"match":{"status": STATUS_TOSENT}}
                ]}},
                0,
                99999,
                "sheet.id",
                "ASC"
            )
            .then(([results]) => props.openSheetAffecationForm(results));
    }

    function openBaseDuplicationForm() {
        let watchsiteData = {};
        const mapper = item => {
            if (parseInt(props.watchsite) === parseInt(item.value)) {
                watchsiteData = {id: item.value, label: item.label};
            }
            if (item.children) {
                item.children.map(mapper);
            }
            return item;
        };

        props.treeOptions.map(mapper);

        props.openBaseDuplicationForm(watchsiteData);
    }

    // eslint-disable-next-line
    useEffect(load, []);

    // eslint-disable-next-line
    useEffect(load, [props.reload]);

    return (
        <div className="bloc">
            <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                <ToggableBlocComponent wrapChildren={false} label="Recherche" id="database-search" toggled={togglers["database-search"]} toggle={toggle}>
                    <section className="filters">
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent type="text" name="id" onChange={value => addCriteria("id", value)} value={criterias.id} label="Numéro de la fiche" />                            
                                <FilterComponent type="dropdown-tree-select" name="sheet_categories" onChange={value => addCriteria("sheet_categories", value)} value={criterias.sheet_categories} label="Domaines / Sous domaines / Thèmes" data={tree} mode="hierarchical" />
                                <FilterComponent type="daterange" name="affectation_date" onChange={value => addCriteria("affectation_date", value)} value={criterias.affectation_date} label="Date d'affectation" />                   
                                <FilterComponent type="select" name="status" onChange={value => addCriteria("status", value)} value={criterias.status} label="Statut d'affectation" options={getAffectationStatus()} multiple />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-sheet-affected" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">
                                    <button id="search-sheet-affected" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="sheets-affected"
                        loading={loading}
                        selectable={true}
                        selection={batchSelection}
                        onSelect={(s) => setBatchSelection(s)}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        batchActions={<SheetAffectedListBatchActions rows={rows} openSheetAffecationForm={props.openSheetAffecationForm} selection={batchSelection} updateStatus={updateStatus} setLoading={setLoading} refresh={refresh} />}
                        toolbarClassName="bg-gris-25"
                        className="table table-bordered table-striped-contrast table-striped"
                    >
                        <div className="row">
                            <div className="col">
                                <button onClick={openSheetAffecationFormForAccount} id="send-prepare-account-sheet-affected" className="btn h25 btn-primary" type="button">Envoyer les fiches "A envoyer" sur le compte</button>
                                <button onClick={openSheetAffecationFormForWatchsite} id="send-prepare-watchsite-sheet-affected" className="btn h25 btn-primary" type="button">Envoyer les fiches "A envoyer" sur le point de veille</button>
                            </div>
                            <div className="col text-right">
                                <button onClick={openBaseDuplicationForm} id="duplicate-database" className="btn h25 btn-primary" type="button">Dupliquer la base</button>
                                <button id="export-sheets-affected" onClick={exportResearch} type="button" className="btn btn-primary h25 icon"><i className="icon-file-excel" aria-hidden="true"></i>&nbsp;Exporter</button>
                                <Link to="/sheets" target="_blank" className="btn btn-primary h25 icon"><i className="icon-documentaire-lien" aria-hidden="true"></i>&nbsp;Ajouter des fiches</Link>
                            </div>
                        </div>
                    </ListComponent>
                </ToggableBlocComponent>
            </form>
            <SheetAffectedListFormAddSheet watchsite={props.watchsite} onSuccess={refresh} />

            <Modal isOpen={updatingComment} onRequestClose={() => setUpdatingComment(false)} style={mediumModalCustomStyles}>
                <SheetAffectedListFormUpdateComment sheetaffected={currentSheetaffected} loading={loading} setLoading={setLoading} onClose={() => setUpdatingComment(false)} />
            </Modal>
        </div>
    )
}