import { React, useState } from 'commons';
import ConfigurationWatchsite from 'views/push/List/Assignements/AffectationForm/ConfigurationWatchsite';
import ConfigurationNode from 'views/push/List/Assignements/AffectationForm/ConfigurationNode';

export default function ConfigurationAccount(props) {

    const [folded, setFold] = useState(false);
    const isIncluded = props.includedPerimeters.includes("account:" + props.item.account.id);
    const isForced = props.forcedPerimeters.includes("account:" + props.item.account.id);
    const isIncluedInUpdateBatch = props.batchCommentUpdatePerimeters.includes("account:" + props.item.account.id);

    if (!props.accountsSelection.includes(props.item.account.id)) {
        return <></>;
    }

    let hasWatchsiteAffected = false;
    const hasWatchsiteAffectedChecker = (i) => {
        if (i.is_watchsite) {
            if (props.alreadyAffected.includes(i.id)) {
                hasWatchsiteAffected = true;
            }
        }
        if (i.is_node && !hasWatchsiteAffected) {
            i.children.map(hasWatchsiteAffectedChecker);
        }
        return i;
    };

    if (Array.isArray(props.alreadyAffected)) {
        props.item.tree.map(hasWatchsiteAffectedChecker);
    }

    return <li>
        <div className="highlight">
            <button type="button" className="btn btn-primary icon icon-only" onClick={() => setFold(!folded)}>
                <i className={folded ? "icon-boutons-plus" : "icon-boutons-moins"}></i>
            </button>
            <fieldset className="at-left">
                <legend className="sr-only">Paramétrage du compte&nbsp;:</legend>
                <div>
                    <input
                        checked={isIncluded}
                        type="checkbox"
                        id={`account-${props.item.account.id}-affect`}
                        onChange={e => props.updateParameters("include-perimeter", "account:" + props.item.account.id, e.target.checked)}
                    />
                    <label id={`account-${props.item.account.id}-affect-label`} htmlFor={`account-${props.item.account.id}-affect`} className="w-120px text-center">
                        <span className="sr-only">Affecter</span>
                    </label>
                    {isIncluded && <>
                        <input
                            checked={isIncluedInUpdateBatch}
                            type="checkbox"
                            id={`account-${props.item.account.id}-updatecomment`}
                            onChange={e => props.updateParameters("addto-batch-update-comment", "account:" + props.item.account.id, e.target.checked)}
                        />
                        <label id={`account-${props.item.account.id}-updatecomment-label`} htmlFor={`account-${props.item.account.id}-updatecomment`} className="w-120px text-center">
                            <span className="sr-only">Modifier le commentaire</span>
                        </label>
                        {hasWatchsiteAffectedChecker && <>
                        <input
                            checked={isForced}
                            type="checkbox"
                            id={`account-${props.item.account.id}-forceaffectation`}
                            onChange={e => props.updateParameters("force-perimeter", "account:" + props.item.account.id, e.target.checked)}
                        />
                        <label id={`account-${props.item.account.id}-forceaffectation-label`} htmlFor={`account-${props.item.account.id}-forceaffectation`} className="w-120px text-center">
                            <span className="sr-only">Forcer l'affectation</span>
                        </label>
                        </>}
                    </>}
                </div>
            </fieldset>
            <label htmlFor={`account-${props.item.account.id}`}>{props.item.account.name}</label>
        </div>
        <ul className={folded ? "d-none" : ""}>
            {props.item.tree.map(treeItem => {
                if (treeItem.is_watchsite) {
                    return <ConfigurationWatchsite 
                        key={treeItem.id}
                        watchsite={treeItem}
                        includedPerimeters={props.includedPerimeters}
                        forcedPerimeters={props.forcedPerimeters}
                        batchCommentUpdatePerimeters={props.batchCommentUpdatePerimeters}
                        specificComments={props.specificComments}
                        parentIncluded={isIncluded}
                        parentForced={isForced}
                        parentIncluedInUpdateBatch={isIncluedInUpdateBatch}
                        updateParameters={props.updateParameters}
                        updateComment={props.updateComment}
                        alreadyAffected={props.alreadyAffected}
                    />;
                }
                if (treeItem.is_node) {
                    return <ConfigurationNode
                        key={treeItem.id}
                        node={treeItem}
                        includedPerimeters={props.includedPerimeters}
                        forcedPerimeters={props.forcedPerimeters}
                        batchCommentUpdatePerimeters={props.batchCommentUpdatePerimeters}
                        specificComments={props.specificComments}
                        parentIncluded={isIncluded}
                        parentForced={isForced}
                        parentIncluedInUpdateBatch={isIncluedInUpdateBatch}
                        updateParameters={props.updateParameters}
                        updateComment={props.updateComment}
                        alreadyAffected={props.alreadyAffected}
                    />;
                }
                return <React.Fragment key={treeItem.id}></React.Fragment>;
            })}
        </ul>
    </li>;
}