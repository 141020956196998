import { React, toast, DefaultLayout, Link, useEffect, useState } from 'commons';
import { useList, usePosition } from 'shared/hooks';
import { ListComponent, FilterComponent } from 'shared/components';
import { ReferentialApi } from 'shared/api';
import { denyIfCantPerform, preventDefault, confirm } from 'shared/services';
import { REFERENTIAL_SUB_DOMAIN, REFERENTIAL_THEME, getReferentialType } from 'shared/data';

export default function ReferentialList(props) {
    
    denyIfCantPerform(props, "referential:list");

    const [columns, setColumns] = useState([]);
    const [sortOptions, setSortOptions] = useState([]);
    const [initializePosition, setPosition] = usePosition("referential-list");

    function defineColumns() {
        let newColumns = [];
        let newOptions = [];
        
        newOptions.push({value: 'value', label: 'Valeur'});
        newColumns.push({id: 'value', title: 'Valeur'});

        if (criterias.type === REFERENTIAL_SUB_DOMAIN || criterias.type === REFERENTIAL_THEME) {
            const label = (criterias.type === REFERENTIAL_SUB_DOMAIN) ? 'Domaines' : 'Sous-domaines';
            newOptions.push({value: 'parent', label: label});
            newColumns.push({
                id: 'parent',
                title: label,
                render: (row) => {
                    if (row.parent) {
                        return row.parent.value;
                    }
                    return null;
                }
            });
        }

        if (criterias.type === REFERENTIAL_THEME) {
            newOptions.push({value: 'parent-bis', label: 'Domaines'});
            newColumns.push({
                id: 'parent-bis',
                title: 'Domaines',
                render: (row) => {
                    if (row.parent && row.parent.parent) {
                        return row.parent.parent.value;
                    }
                    return null;
                }
            });
        }

        newColumns.push({
            id: 'action',
            title: '',
            sortable: false,
            className: 'w-80px',
            render: (row) => {           
                return (
                    <ul className="actions">
                        <li><Link title="Modifier" to={`/referentials/${row.id}`} id="edit-referential"><i className="icon-actions-modifier" aria-hidden="true"></i></Link></li>
                        <li><Link title="Supprimer" onClick={(e) => deleteReferential(e, row)} to={`/referentials/delete/${row.id}`} id="delete-referential"><i className="icon-actions-supprimer" aria-hidden="true"></i></Link></li>
                    </ul>
                )
            }
        });

        setColumns(newColumns);
        setSortOptions(newOptions);
    }

    const filterType = {
        type: {type: "match", fields: ["type"]},
    };

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,
        setLoading,
        refresh,
    ] = useList("referential-list", ReferentialApi, null, "value");

    useEffect(() => {
        submitSearch(filterType, () => initializePosition(), false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        defineColumns();
        // eslint-disable-next-line
    }, [rows]);

    function deleteReferential(event, referential) {
        event.preventDefault();

        if (referential.used) {
            toast.warning("Impossible de supprimer cette valeur, elle est utilisée.")
        } else {

            confirm('Etes-vous sur de vouloir supprimer la valeur "' + referential.value + '" ?', function() {
                setLoading(true);

                ReferentialApi
                    .delete(referential.id)
                    .then(response => {
                        setLoading(false);
                        toast.success("Valeur supprimée");
                        refresh();
                    })
                    .catch((error => {
                        console.error(error);
                        toast.error("Une erreur est survenue lors de la suppression de la valeur")
                    }));
            });
        }
    }

    return (
        <DefaultLayout onScroll={(position) => setPosition(position)} screen="E39" title="Gestion des référentiels">
            <h1>Gestion des référentiels</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                    <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Selection du référentiel</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    colClassName="col-md-4"
                                    type="select"
                                    label="Type de référentiel"
                                    name="type"
                                    onChange={value => addCriteria("type", value)}
                                    value={criterias.type}
                                    options={getReferentialType()}
                                />
                                <div className="col-md-4" style={{'paddingTop': 23}}>
                                    <button id="search-referential" type="submit" className="btn btn-primary">Selectionner</button>
                                </div>
                            </div>
                        </div>
                    </section>

                    <ListComponent 
                        id="referentials"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sortOptions={sortOptions}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                    >
                        <Link id="new-referential" to={`/referentials/new-${criterias.type}`} className="btn btn-primary h25 icon"><i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>&nbsp;Ajouter une valeur</Link>
                    </ListComponent>
                </form>
            </div>
        </DefaultLayout>
    );
}