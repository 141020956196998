import React from 'react';
import _ from 'lodash';

export default function Domains(props) {

    const parentIdCategories = [];
    _.forEach(props.categories, (c) => {
        if (!_.isNil(c.parent)) {
            parentIdCategories.push(c.parent.id)
        }
    });
    const lastCategories = props.categories.filter(category => !parentIdCategories.includes(category.id));
    const categories = [];
    _.forEach(lastCategories, (c) => {
        if (_.isNil(c.parent)) {
            categories.push({d: c});
        } else if (_.isNil(c.parent.parent)) {
            categories.push({
                d: c.parent,
                sd: c
            });
        } else {
            categories.push({
                d: c.parent.parent,
                sd: c.parent,
                t: c
            });
        }
    });

    return <>
        {categories.map((category, index) => <div key={index}>
            <span className="color-vert-apave"><strong>D :</strong></span> {category.d.value}
            {category.sd && <> / <span className="color-vert-apave"><strong>SD :</strong></span> {category.sd.value}</>}
            {category.t && <> / <span className="color-vert-apave"><strong>T :</strong></span> {category.t.value}</>}
        </div>)}
    </>
}