import { React, Link, Prompt, useHistory, toast, DefaultLayout, useEffect, useState, useContext, _ } from 'commons';
import { denyIfCantPerform, SheetAutocomplete, compileErrorsFromResponse, compileDataToSelectOptions, stripTags } from 'shared/services';
import { useToggler, useForm } from 'shared/hooks';
import { LoadButton, FieldComponent, FileComponent, ToggableBlocComponent, PageLoadingComponent } from 'shared/components';
import { ReferentialContext } from 'shared/stores';
import { DocumentarySpaceApi, InternalUserApi, AccountApi } from 'shared/api';
import { typeLabels, REFERENTIALS } from 'shared/data';

import { useSecurity } from 'shared/hooks/Security';

export default function DocumentarySpaceForm(props) {

    denyIfCantPerform(props, "documentaryspace:write");

    const validEntityTypes = [
        'caselaw',
        'clientspecificmodule',
        'drafttext',
        'form',
        'news',
        'newsflash',
        'newsletter',
        'regulatorycontrol',
        'regulatoryflowchart',
        'sanction',
        'thematicfile',
    ];

    let type = props.match.params.type;
    if (!type) {
        let typeMatches = document.location.href.match(/\/documentaryspace\/(((?!\/).)*)\/{0,1}/);
        if (typeMatches.length > 1) {
            type = typeMatches[1];
        }
    }
    const [user] = useSecurity();

    if (!validEntityTypes.includes(type)) {
        props.history.push('/denied');
    }

    const [togglers, toggle] = useToggler({'type': true, 'data': true});
    const currentDate = new Date();
    const nextMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        type: type,
        id: null,
        date: currentDate,
        title: '',
        category: null,
        date_end_novelty: nextMonthDate,
        date_end_update_notification: null,
        hidden: 0,
        author: user.id,
        jurisdiction: null,
        decision: null,
        decision_date: null,
        appeal_number: null,
        synthesis: null,
        teaching: null,
        pdf: null,
        account: null,
        sheet: null,
        sheets: [],
        number: 0,
        regulatory_reference: null,
        form: null,
        interactive_form: null,
        online_declaration: null,
        technical_manual: [],
        comment: '', 
        description: '', 
        image: null,
        url: '', 
        date_last_sending: null,
        document_type: null,
    });
    const [referentialContext] = useContext(ReferentialContext);
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(!!props.match.params.id);
    const domainTree = _.cloneDeep(referentialContext["tree"]);
    const [accounts, setAccounts] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [defaultSheet, setDefaultSheet] = useState([]);
    const [defaultSheets, setDefaultSheets] = useState([]);
    const documentTypes = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_DOCUMENT_TYPE] ?? [], 'id', 'value');

    function getEntityFromType(typeToConvert) {
        let entityName = '';
        switch (typeToConvert) {
            case 'caselaw': entityName = 'DocumentCaseLaw'; break;
            case 'clientspecificmodule': entityName = 'DocumentClientSpecificModule'; break;
            case 'drafttext': entityName = 'DocumentDraftText'; break;
            case 'form': entityName = 'DocumentForm'; break;
            case 'news': entityName = 'DocumentNews'; break;
            case 'newsflash': entityName = 'DocumentNewsFlash'; break;
            case 'newsletter': entityName = 'DocumentNewsletter'; break;
            case 'regulatorycontrol': entityName = 'DocumentRegulatoryControl'; break;
            case 'regulatoryflowchart': entityName = 'DocumentRegulatoryFlowchart'; break;
            case 'sanction': entityName = 'DocumentSanction'; break;
            case 'thematicfile': entityName = 'DocumentThematicFile'; break;
            default:
                // nothing todo; 
                break;
        };
        return entityName;
    }

    function populateData(newData) {
        let category = null;
        if (newData.theme) {
            category = newData.theme.id;
        } else if (newData.subdomain) {
            category = newData.subdomain.id;
        } else if (newData.domain) {
            category = newData.domain.id;
        }

        setData({
            ...{type: type},
            ...newData,
            ...{
                hidden: newData.hidden ? 1 : 0,
                domain: newData.domain ? newData.domain.id: null,
                subdomain: newData.subdomain ? newData.subdomain.id: null,
                theme: newData.theme ? newData.theme.id: null,
                category: category,
                author: newData.author ? newData.author.id : user.id,
                date: newData.date ? new Date(newData.date) : null,
                date_end_novelty: newData.date_end_novelty ? new Date(newData.date_end_novelty) : nextMonthDate,
                date_end_update_notification: newData.date_end_update_notification ? new Date(newData.date_end_update_notification) : null,
                decision_date: newData.decision_date ? new Date(newData.decision_date) : null,
                sheet: newData.sheet ? newData.sheet.id : null,
                sheets: newData.sheets ? newData.sheets.map(s => s.id) : null,
                date_last_sending: newData.date_last_sending ? new Date(newData.date_last_sending) : null,
                document_type: newData.document_type ? newData.document_type.id : null,
            }
        });
    }

    function load() {
        if (props.match.params.id) {
            DocumentarySpaceApi
                .get(type, props.match.params.id)
                .then(newData => {
                    populateData(newData);
                    if (newData.sheet) {
                        setDefaultSheet([{value: newData.sheet.id, label: newData.sheet.id + " " + stripTags(newData.sheet.title).replaceAll('&nbsp;', ' ')}]);
                    }
                    if (newData.sheets) {
                        setDefaultSheets(
                            newData.sheets.map(s => { 
                                return {value: s.id, label: s.id + " " + stripTags(s.title).replaceAll('&nbsp;', ' ')}
                            })
                        );
                    }

                    setLoading(false);
                })
                .catch((e) => {
                    toast.error("Une erreur est survenue lors de la récupération du document");
                    history.goBack();
                });
        }
        
        if (type === 'clientspecificmodule') {
            AccountApi
                .search(
                    {"bool": {
                        "must": [
                            {"match": {"access_private_documentary_space": 1}},
                            {"match": {"access_documentary_space": 1}}
                        ]
                    }},
                    0,
                    10000,
                    "name",
                    "asc"
                )
                .then(([results]) => {
                    setAccounts(results.map(r => { return {value: r.id, label: r.name}}));
                });
        }

        InternalUserApi
            .search({}, 0, 10000, "lastname", "asc")
            .then(([results]) => {
                setAuthors(results.map(r => { return {value: r.id, label: r.lastname + " " + r.firstname}}));
            });
    }

    function submit(event) {
        event.preventDefault();
        setSaving(true);

        let json = {...data};
        json.hidden = !!data.hidden;
        json.pdf = data.pdf ? data.pdf.hash : null;
        json.decision= data.decision ? data.decision.hash : null;
        json.image = data.image ? data.image.hash : null;
        json.form = data.form ? data.form.hash : null;
        json.interactive_form = data.interactive_form ? data.interactive_form.hash : null;
        json.technical_manual = data.technical_manual ? data.technical_manual.map(f => f.hash) : null;

        DocumentarySpaceApi
            .save(type, json)
            .then((newData) => {
                populateData(newData);
                setErrors({});
                toast.success("Enregistrement effectué.");
                reloadOrClose(event, props, "documentaryspace/" + type, newData.id);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);
                    } else {
                        setErrors(compileErrorsFromResponse(error.response));
                        toast.error("Des erreurs sont survenues");
                    }
                }
            })
            .finally(() => setSaving(false));
    }

    function sendNewsletter() {
        setSending(true);
        DocumentarySpaceApi
            .sendNewsletter(data.id)
            .then((newData) => {
                setValue("date_last_sending", new Date(newData.date_last_sending));
                toast.success("Envoi en cours.");
            })
            .catch((error) => toast.error(error.response.data.message))
            .finally(() => setSending(false));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => load(), []);

    if (loading) {
        return <PageLoadingComponent label={`Edition d'un document ${typeLabels[type]}`} />
    } else {

        return (
            <DefaultLayout screen="E52" title={`Edition d'un document ${typeLabels[type]}`}>
                <Prompt
                    when={formHasModifications()}
                    message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
                />
                <section>
                    <h1>Edition d'un document « {typeLabels[type]} »</h1>
                    <section>
                        <form onSubmit={submit} id="form-type" className="form">
                            <ToggableBlocComponent label="Type de document" id="type" toggled={togglers["type"]} toggle={toggle} className="border-gris-25 border-lrb">
                                <div className="bg-gris-20">
                                    <div className="flex-label">
                                        <label>Type de document</label>
                                        {typeLabels[type]}
                                    </div>
                                </div>
                            </ToggableBlocComponent>
                            <ToggableBlocComponent label={typeLabels[type]} id="data" toggled={togglers["data"]} toggle={toggle}>
                                <div className="bg-blanc">
                                    <FieldComponent
                                        type="date"
                                        name="date"
                                        label="Date"
                                        error={errors.date}
                                        value={data.date}
                                        preview={true}
                                        />
                                    <FieldComponent
                                        name="title"
                                        label="Titre *"
                                        error={errors.title}
                                        value={data.title}
                                        onChange={value => setValue("title", value)}
                                        />
                                    <FieldComponent
                                        type="dropdown-tree-select"
                                        name="category"
                                        label="Domaine / Sous domaine / Thème *"
                                        error={errors.category}
                                        value={data.category}
                                        onChange={value => setValue("category", value)}
                                        data={domainTree}
                                        enableFromLevel={2}
                                        mode="radioSelect"
                                        />
                                    {type === 'caselaw' && (
                                        <>
                                            <FieldComponent
                                                name="jurisdiction"
                                                label="Juridiction *"
                                                error={errors.jurisdiction}
                                                value={data.jurisdiction}
                                                onChange={value => setValue("jurisdiction", value)}
                                                />
                                             <FileComponent
                                                name="decision"
                                                label="Décision"
                                                upload={DocumentarySpaceApi.uploadPdf}
                                                uploadAdditionalParams={{
                                                    entity: getEntityFromType(type),
                                                    field: 'decision'
                                                }}
                                                error={errors.decision}
                                                value={data.decision}
                                                extensions={['.pdf']}
                                                showDate
                                                showSize
                                                onChange={value => setValue("decision", value)}
                                                maxSize="10MO"
                                                />
                                            <FieldComponent
                                                type="date"
                                                name="decision_date"
                                                label="Date de la décision"
                                                error={errors.decision_date}
                                                value={data.decision_date}
                                                onChange={value => setValue("decision_date", value)}
                                                />
                                            <FieldComponent
                                                name="appeal_number"
                                                label="Numéro de pourvoi"
                                                error={errors.appeal_number}
                                                value={data.appeal_number}
                                                onChange={value => setValue("appeal_number", value)}
                                                />
                                            <FieldComponent
                                                type="textarea"
                                                rte={true}
                                                name="synthesis"
                                                label="Synthèse"
                                                error={errors.synthesis}
                                                value={data.synthesis}
                                                onChange={value => setValue("synthesis", value)}
                                                />
                                            <FieldComponent
                                                type="textarea"
                                                rte={true}
                                                name="teaching"
                                                label="Enseignement"
                                                error={errors.teaching}
                                                value={data.teaching}
                                                onChange={value => setValue("teaching", value)}
                                                />
                                        </>
                                    )}
                                    {type === 'clientspecificmodule' && (
                                        <>
                                            <FieldComponent
                                                type="select"
                                                name="account"
                                                label="Compte client *"
                                                options={accounts}
                                                error={errors.account}
                                                value={data.account}
                                                onChange={value => setValue("account", value)}
                                                />
                                            <FieldComponent
                                                type="select"
                                                name="type"
                                                label="Type de document"
                                                options={documentTypes}
                                                error={errors.document_type}
                                                value={data.document_type}
                                                onChange={value => setValue("document_type", value)}
                                                />
                                        </>
                                    )}
                                    {type === 'form' && (
                                        <>
                                            <FieldComponent
                                                type="async-select"
                                                name="sheets"
                                                label="Fiches associées"
                                                defaultOptions={defaultSheets}
                                                loadOptions={(inputValue, callback) => SheetAutocomplete({keyword: inputValue}, callback)}
                                                value={data.sheets}
                                                onChange={(value, options) => {
                                                    setValue("sheets", value);
                                                    setDefaultSheets(options)
                                                }}
                                                multiple
                                                />
                                            <FieldComponent
                                                type="text"
                                                name="number"
                                                label="Numéro *"
                                                error={errors.number}
                                                value={data.number}
                                                onChange={value => setValue("number", value)}
                                                />
                                            <FieldComponent
                                                type="textarea"
                                                name="regulatory_reference"
                                                label="Référence règlementaire"
                                                error={errors.regulatory_reference}
                                                value={data.regulatory_reference}
                                                onChange={value => setValue("regulatory_reference", value)}
                                                />
                                            <FileComponent
                                                name="form"
                                                label="Formulaire *"
                                                upload={DocumentarySpaceApi.uploadPdf}
                                                uploadAdditionalParams={{
                                                    entity: getEntityFromType(type),
                                                    field: 'form'
                                                }}
                                                error={errors.form}
                                                value={data.form}
                                                extensions={['.pdf']}
                                                showDate
                                                showSize
                                                onChange={value => setValue("form", value)}
                                                maxSize="10MO"
                                                />
                                            <FileComponent
                                                name="interactive_form"
                                                label="Formulaire interactif"
                                                upload={DocumentarySpaceApi.uploadPdf}
                                                uploadAdditionalParams={{
                                                    entity: getEntityFromType(type),
                                                    field: 'interactive_form'
                                                }}
                                                error={errors.interactive_form}
                                                value={data.interactive_form}
                                                extensions={['.pdf']}
                                                showDate
                                                showSize
                                                onChange={value => setValue("interactive_form", value)}
                                                maxSize="10MO"
                                                />
                                            <FieldComponent
                                                type="text"
                                                name="online_declaration"
                                                label="Déclaration en ligne"
                                                error={errors.online_declaration}
                                                value={data.online_declaration}
                                                onChange={value => setValue("online_declaration", value)}
                                                />
                                            <FileComponent
                                                name="technical_manual"
                                                label="Notice technique"
                                                upload={DocumentarySpaceApi.uploadPdf}
                                                uploadAdditionalParams={{
                                                    entity: getEntityFromType(type),
                                                    field: 'technical_manual'
                                                }}
                                                error={errors.technical_manual}
                                                value={data.technical_manual}
                                                extensions={['.pdf']}
                                                showDate
                                                showSize
                                                multiple
                                                onChange={value => setValue("technical_manual", value)}
                                                maxSize="10MO"
                                                />
                                            <FieldComponent
                                                type="textarea"
                                                rte={true}
                                                name="comment"
                                                label="Commentaire"
                                                error={errors.comment}
                                                value={data.comment}
                                                onChange={value => setValue("comment", value)}
                                                />
                                        </>
                                    )}
                                    {type === 'news' && (
                                        <>
                                            <FieldComponent
                                                type="textarea"
                                                name="description"
                                                label="Description"
                                                error={errors.description}
                                                value={data.description}
                                                onChange={value => setValue("description", value)}
                                                />
                                            <FileComponent
                                                name="image"
                                                label="Image liée"
                                                upload={DocumentarySpaceApi.uploadImage}
                                                uploadAdditionalParams={{
                                                    entity: getEntityFromType(type),
                                                    field: 'image'
                                                }}
                                                error={errors.image}
                                                value={data.image}
                                                extensions={['.jpg', '.jpeg', '.png', '.gif']}
                                                showDate
                                                showSize
                                                onChange={value => setValue("image", value)}
                                                />
                                            <FieldComponent
                                                type="async-select"
                                                name="sheet"
                                                label="Fiche associée"
                                                defaultOptions={defaultSheet}
                                                loadOptions={(inputValue, callback) => SheetAutocomplete({keyword: inputValue}, callback)}
                                                value={data.sheet}
                                                onChange={(value, sheet) => {
                                                    setDefaultSheet([sheet]);
                                                    setValue("sheet", value)
                                                }}
                                                />
                                            <div className="flex-label">
                                                <ul className="filelist arborescence no-arrow squared">
                                                    {defaultSheet.map(sheet => <li key={sheet.value}>
                                                        {sheet.label}
                                                        <button
                                                            data-info="Enlever la fiche"
                                                            type="button"
                                                            onClick={() => {
                                                                setDefaultSheet([]);
                                                                setValue("sheet", null);
                                                            }}
                                                            id="remove-sheet-from-dispatch-queue"
                                                        >
                                                            <i className="icon-filtres-poubelle" aria-hidden="true"></i>
                                                        </button>
                                                    </li>)}
                                                </ul>
                                            </div>
                                            <FieldComponent
                                                type="text"
                                                name="url"
                                                label="Lien internet"
                                                error={errors.url}
                                                value={data.url}
                                                onChange={value => setValue("url", value)}
                                                />
                                        </>
                                    )}
                                    {type === 'newsletter' && (
                                        <>
                                            <FieldComponent
                                                type="date"
                                                name="date_last_sending"
                                                label="Date de dernier envoi"
                                                value={data.date_last_sending}
                                                disabled={true}
                                                />
                                        </>
                                    )}
                                    {
                                        [
                                            'clientspecificmodule',
                                            'newsletter',
                                            'drafttext',
                                            'newsflash',
                                            'regulatorycontrol',
                                            'regulatoryflowchart',
                                            'sanction',
                                            'thematicfile'
                                        ].includes(type) && (
                                            <>
                                                <FileComponent
                                                    name="pdf"
                                                    label="Fichier pdf lié *"
                                                    upload={DocumentarySpaceApi.uploadPdf}
                                                    uploadAdditionalParams={{
                                                        entity: getEntityFromType(type),
                                                        field: 'pdf'
                                                    }}
                                                    error={errors.pdf}
                                                    value={data.pdf}
                                                    extensions={['.pdf']}
                                                    showDate
                                                    showSize
                                                    onChange={value => setValue("pdf", value)}
                                                    maxSize="20MO"
                                                    />
                                            </>
                                        )
                                    }
                                    <FieldComponent
                                        type="date"
                                        name="date_end_novelty"
                                        label="Date de fin de nouveauté *"
                                        error={errors.date_end_novelty}
                                        value={data.date_end_novelty}
                                        onChange={value => setValue("date_end_novelty", value)}
                                        />
                                    <FieldComponent
                                        type="date"
                                        name="date_end_update_notification"
                                        label="Date de fin de notification de mise à jour"
                                        error={errors.date_end_update_notification}
                                        value={data.date_end_update_notification}
                                        onChange={value => setValue("date_end_update_notification", value)}
                                        />
                                    <FieldComponent
                                        type="radio"
                                        name="hidden"
                                        label="Actif"
                                        error={errors.hidden}
                                        value={data.hidden}
                                        onChange={value => setValue("hidden", parseInt(value))}
                                        options={[{"value": 0, "label": "Oui"}, {"value": 1, "label": "Non"}]}
                                        />
                                    <FieldComponent
                                        type="select"
                                        name="author"
                                        label="Auteur *"
                                        options={authors}
                                        error={errors.author}
                                        value={data.author}
                                        onChange={value => setValue("author", value)}
                                        />
                                </div>
                            </ToggableBlocComponent>                            
                            <section className="row">
                                <div className="col">
                                    <Link id="back-documentaryspaceentity-list" to={`/documentaryspace/${type}`} className="btn btn-bleu-4">Retour à la liste</Link>
                                </div>
                                <div className="col text-right">
                                    <LoadButton 
                                        loading={saving} 
                                        label="Enregistrer et fermer"
                                        name="save-and-close"
                                        id={`save-and-close-documentaryspace-${type}`}
                                    />
                                    <LoadButton 
                                        loading={saving} 
                                        label="Enregistrer" 
                                        name="save" 
                                        id={`save-documentaryspace-${type}`}
                                    />
                                    {type === "newsletter" && data.id && <LoadButton 
                                        loading={sending} 
                                        label="Envoyer la newsletter" 
                                        name="send-newsletter" 
                                        id="send-newsletter"
                                        type="button"
                                        onClick={sendNewsletter}
                                    />}
                                </div>
                            </section>
                        </form>
                    </section>
                </section>
            </DefaultLayout>
        );
    }
}