import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';

const LegifranceApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("imports/searches", query, from, size, sorting, direction);
    },

    log: function(id) {
        return ApiRequest.get("imports/" + id + "/log").then(response => response.data);
    },

    create: function(file) {
        const formData = new FormData();
        formData.append('file', file);
        return ApiRequest
            .post(
                "imports",
                formData,
                {
                    headers: {'Content-Type': 'multipart/form-data'}
                }
            )
            .then(response => response.data);
    }
}
export default LegifranceApi;
