import React, { useState, useEffect, useCallback, useContext } from 'react';
import _ from "lodash";
import { Link, Redirect, Prompt } from 'react-router-dom';
import { toast } from 'react-toastify';
import DefaultLayout from "layouts/DefaultLayout";
import { useForm } from 'shared/hooks/Form';
import { ReferentialContext } from 'shared/stores/Referential';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import PageLoadingComponent from 'shared/components/PageLoadingComponent';
import { REFERENTIAL_TYPE, REFERENTIAL_PARENT } from 'shared/data/ReferentialType';
import ReferentialApi from 'shared/api/Referential';
import { compileDataToSelectOptions, compileErrorsFromResponse } from 'shared/services/Utils';
import { canPerform, denyIfCantPerform } from 'shared/services/Can';

export default function ReferentialForm(props) {

    denyIfCantPerform(props, "referential:write");

    const [saving, setSaving] = useState("");
    const [parents, setParents] = useState([{value: "", label: "Chargement"}]);
    const [isLoad, setIsLoad] = useState(!!props.match.params.id);
    const [referential, setReferential] = useState({});
    const [, referentialDispatcher] = useContext(ReferentialContext);

    const [data, errors, setErrors, setValue, setData, reloadOrClose, formHasModifications] = useForm({
        id: null,
        value: "",
        type: parseInt(props.match.params.type) || null,
        parent: null,
    });

    const loadParents = useCallback(() => {
        if(data.type !== null && REFERENTIAL_PARENT[data.type]) {
            ReferentialApi
                .list(REFERENTIAL_PARENT[data.type])
                .then(([referentials]) => {
                    let freshParents = compileDataToSelectOptions(referentials, 'id', ['value']);
                    setParents(
                        _.sortBy(freshParents, ['label'])
                    );
                });
        }
    }, [data.type]);

    useEffect(() => {
        const referentialId = props.match.params.id;
        if (referentialId) {
            ReferentialApi
                .get(referentialId)
                .then(freshReferential => {
                    setReferential(freshReferential);
                    setIsLoad(false);
                    let newReferential = {...freshReferential};
                    if (newReferential.parent) {
                        newReferential.parent = freshReferential.parent.id;
                    }
                    setData(d => Object.assign({}, d, newReferential));
                })
                .catch(error => console.log(error));
        }
    }, [props, setIsLoad, setData]);

    useEffect(() => {
        loadParents();
    }, [loadParents])

    function submit(event) { 
        event.preventDefault();
        if (event.target.id === "form-referential") {
            setSaving(event.nativeEvent.submitter.name);

            ReferentialApi
                .save(data)
                .then(freshReferential => {
                    ReferentialApi
                        .list(freshReferential.type)
                        .then(([r]) => {
                            referentialDispatcher({action: "populate", data: r, type:freshReferential.type});

                            setData(Object.assign({}, data, {"id": freshReferential.id}));
                            setErrors({});
                            setSaving("");
                            toast.success("Enregistrement effectué.");
                            reloadOrClose(event, props, "referentials", freshReferential.id);
                        });
                })
                .catch(error => {
                    setSaving("");
                    if (error.response.data.message) {
                        toast.error(error.response.data.message);                    
                    } else {
                        setErrors(compileErrorsFromResponse(error.response));
                        toast.error("Des erreurs sont survenues");
                    }
                });
        }
    }

    if (canPerform("referential:list", referential) === false) {
        return <Redirect to="/denied" />;
    }

    const pageName = props.match.params.id ? "Modification d'une valeur" : "Ajout d'une valeur";

    if (isLoad === true) {
        return <PageLoadingComponent label={pageName} />
    }

    return (
        <DefaultLayout screen="E39 - bis" title="Configuration référentiel">
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>{pageName}</h1>
            <section className="bloc">
                <form onSubmit={submit} id="form-referential" className="form">
                    <section id="configuration" className="bloc">
                        <header className="bg-gris-45 uppercase">
                            Configuration "{REFERENTIAL_TYPE[data.type]}"
                        </header>
                        <div className="border-gris-25 border-lrb bg-blanc">
                            <FieldComponent 
                                name="value" 
                                label="Libellé *" 
                                error={errors.value} 
                                value={data.value} 
                                onChange={value => setValue("value", value)} 
                            />

                            {(REFERENTIAL_PARENT[data.type]) && (
                                <FieldComponent
                                    type="select"
                                    name="parent"
                                    label="Parent"
                                    className="field large"
                                    onChange={value => setValue("parent", value)}
                                    value={data.parent}
                                    clearable={true}
                                    options={parents}
                                />
                            )}

                            <FieldComponent 
                                type="hidden" 
                                name="type" 
                                value={data.type} 
                            />
                        </div>
                    </section>
                    <section className="row">
                        <div className="col">
                            <Link id="back-referential-list" to="/referentials" className="btn btn-bleu-4">Retour à la liste</Link>
                        </div>
                        <div className="col text-right">       
                            <LoadButton 
                                loading={saving === "save-and-close"} 
                                label="Enregistrer et fermer"
                                name="save-and-close"
                                id="save-and-close-referential"
                            />
                            <LoadButton 
                                loading={saving === "save"} 
                                label="Enregistrer" 
                                name="save" 
                                id="save-referential"
                            />
                        </div>
                    </section>
                </form>
            </section>
        </DefaultLayout>
    );
}
