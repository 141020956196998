import React from 'react';
import { formatDate } from 'shared/services/Utils';
import { getMailTypeLabel } from 'data/MailType';

export default function MailLogDetail(props) {

    const { message } = props;

    return <form id="detail-maillog" className="form">
        <section className="bloc">
            <header className="border-rlb border-gris-40">
                <h2 className="uppercase">Détail du message</h2>
            </header>
            <section>
                <div className="flex-label"><label>Object : </label> {message.subject}</div>
                <div className="flex-label"><label>Destinataire : </label> {message.recipient}</div>
                <div className="flex-label"><label>Date et heure : </label> {formatDate(message.send_at, true)}</div>
                <div className="flex-label"><label>Contexte : </label> {getMailTypeLabel(message.type)}</div>
                {message.account && <div className="flex-label"><label>Compte : </label> {message.account.name}</div>}
                <div className="flex-label">
                    <label className="at-top">Message : </label>
                    <div style={{whiteSpace: "pre-line"}}>{message.message}</div>
                </div>
                <section className="row">
                    <div className="col">
                        <button
                            type="button"
                            id="back-maillog-list"
                            onClick={(e) => { e.preventDefault(); props.onClose()}}
                            className="btn btn-bleu-4"
                        >
                            Retour à la liste
                        </button>
                    </div>
                </section>   
            </section>
        </section>
    </form>;
}