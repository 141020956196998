import { Link, toast, DefaultLayout, React, useContext, useEffect, useState, _ } from 'commons';
import { getHotlineStatus, getHotlineStatusColor, getHotlineStatusLabel, HOTLINE_STATUS_OPEN, HOTLINE_STATUS_ANSWERED } from 'shared/data/Hotline';
import { FileUtils, denyIfCantPerform, toggleArrayValue, convertCriteriesToSearchQuery, preventDefault } from 'shared/services';
import { FilterComponent, ListComponent, LoadButton, StatusComponent, TreeReferentialComponent } from 'shared/components';
import { HotlineApi, AccountApi } from 'shared/api';
import { ReferentialContext } from 'shared/stores';
import { useList, useSecurity } from 'shared/hooks';

export default function HotlineList(props) {

    denyIfCantPerform(props, "hotline:list");

    const columns = [
        {id: 'title', title: 'Titre'},
        {id: 'account', title: 'Compte', render: (row) => row.account.name},
        {id: 'created_at', title: 'Date question', format: "date"},
        {id: 'created_by', title: 'Auteur question', format: "user"},
        {id: 'answered_at', title: 'Date réponse', format: "date"},
        {id: 'answered_by', title: 'Auteur réponse', format: "user"},
        {id: 'categories', title: 'Domaines / sous domaines', render: (row, params) =>
            <TreeReferentialComponent className="arborescence" items={params.tree} value={row.categories.map(e => e.id)} />
        },
        {id: 'status', title: "Statut", className: 'text-center', render: (row) => 
            <StatusComponent value={getHotlineStatusLabel(row.status)} color={getHotlineStatusColor(row.status)} />
        },
        {id: 'actions', title: '', sortable: false, render: (row) => 
            <ul className="actions">
                <li>
                    <Link title="Répondre" to={`/hotlines/${row.id}`}>
                        <i className="icon-actions-modifier" aria-hidden="true"></i>
                    </Link>
                </li>
            </ul>
        }
    ];

    let availableKeywordFields = [
        {value: 'title', label: 'Titre'},
        {value: 'question', label: 'Question'},
        {value: 'answer', label: 'Réponse'},
    ];

    const [user,,,, isAdmin] = useSecurity();
    const [referentialContext] = useContext(ReferentialContext);
    const domainTree = _.cloneDeep(referentialContext["tree"]);
    const [exporting, setExporting] = useState(false);
    const [accounts, setAccounts] = useState([]);


    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList(
        "hotline-list",
        HotlineApi,
        null,
        "created_at",
        {
            status: [HOTLINE_STATUS_OPEN, HOTLINE_STATUS_ANSWERED],
            keyword_fields: ['title', 'question', 'answer']
        },
        'DESC'
    );

    let filterType = {
        categories: {type: "terms", fields: ["categories"]},
        keyword: {type: "wildcard", fields: criterias.keyword_fields},
        status: {type: "terms", fields: ["status"]},
        account: {type: "match", fields: ["account"]}
    };

    function loadAccounts() {
        let querySearch = {};
        if (!isAdmin()) {
            querySearch = {
                "bool": {
                    "should": [
                        {"match": {"pilot_consultants": user.id}},
                        {"match": {"associate_consultants": user.id}}                        
                    ]
                }
            }
        }

        AccountApi
            .search(querySearch, 0, 10000, "name", "asc")
            .then(([results]) => 
                setAccounts(
                    results.map(r => {return {value: r.id, label: r.name};
                })
            ));
    }
    
    function exportHotlines() {
        setExporting(true);
        const exportQuery = convertCriteriesToSearchQuery(criterias, filterType);
        const fileName = FileUtils.getFilePrefix() + "-Hotlines.xlsx";
        HotlineApi
            .export(exportQuery, fileName)
            .then(responseData => {
                setExporting(false);
                toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible.");
            })
            .catch(() => {
                setExporting(false);
                toast.error("Erreur durant la création de l'export.")
            });
    }

    useEffect(() => {
        submitSearch(filterType);
        loadAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <DefaultLayout screen="E44" title="Liste hotline">
            <h1>Liste hotline</h1>
            <div className="bloc">
                <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))} >
                <section className="filters">
                        <header>
                            <h2><button className="toggler" type="button">Recherche</button></h2>
                        </header>
                        <div className="bg-gris-25">
                            <div className="row">
                                <FilterComponent
                                    label="Domaines / Sous domaines"
                                    type="dropdown-tree-select"
                                    name="categories"
                                    onChange={value => addCriteria("categories", value)} 
                                    value={criterias.categories}
                                    data={domainTree}
                                    mode="hierarchical"
                                />
                                <FilterComponent 
                                    type="keyword"
                                    name="keyword"
                                    label="Recherche libre"
                                    fields={availableKeywordFields}
                                    selectedFields={criterias.keyword_fields}
                                    onChange={value => addCriteria("keyword", value)}
                                    onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(criterias.keyword_fields, value))}
                                    value={criterias.keyword || ""} 
                                />
                                <FilterComponent
                                    type="buttons"
                                    name="status"
                                    onChange={v => addCriteria("status", v)}
                                    value={criterias.status}
                                    label="Statut"
                                    options={getHotlineStatus()}
                                    multiple
                                />
                                <FilterComponent
                                    type="select"
                                    label="Compte"
                                    name="account"
                                    options={accounts}
                                    clearable={true}
                                    onChange={value => addCriteria("account", value)}
                                    value={criterias.account}
                                />
                            </div>
                        </div>
                        <div className="bg-gris-25 border-b border-gris-60">
                            <div className="row">
                                <div className="col-md-9">
                                    <button id="clearfilter-user" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                                </div>
                                <div className="col-md-3 text-right">                                    
                                    <button id="search-user" type="submit" className="btn btn-primary">Rechercher</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>

                <form className="form">
                    <ListComponent
                        id="sheet"
                        loading={loading}
                        selectable={false}
                        rows={rows}
                        columns={columns}
                        sorting={sorting}
                        direction={direction}
                        onSortingChange={updateSorting}
                        perpage={limit}
                        onPerpageChange={updateLimit}
                        page={page}
                        onPageChange={updatePage}
                        totalrows={totalrows}
                        columnsRenderParams={{
                            tree: domainTree
                        }}
                        globalActions={(
                            <>
                                <Link id="new-user" to="/hotlines/new" className="btn btn-primary h25 icon">
                                    <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>&nbsp;Nouvelle question
                                </Link>
                                <LoadButton
                                    onClick={exportHotlines}
                                    loading={exporting}
                                    iconClass="icon-file-excel"
                                    className="btn btn-primary h25 icon"
                                    label="Exporter"
                                    id="export-hotline"
                                />
                            </>
                        )}
                    />
                </form>
            </div>
        </DefaultLayout>
    );
}