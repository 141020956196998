import { React, useState } from 'commons';
import PerimeterSelectionWatchsite from 'views/push/Perimeter/Form/PerimeterSelectionWatchsite';

export default function PerimeterSelectionNode(props) {

    const [folded, setFold] = useState(false);

    return <li>
        <button type="button" className="btn btn-primary icon icon-only" onClick={() => setFold(!folded)}>
            <i className={folded ? "icon-boutons-plus" : "icon-boutons-moins"}></i>
        </button>
        <input
            type="checkbox"
            id={`node-${props.node.id}`}
            checked={props.parentChecked || props.perimeter.includes("node:" + props.node.id)}
            onChange={(e) => props.updatePerimeter("node:" + props.node.id, e)}
        />
        <label htmlFor={`node-${props.node.id}`}>{props.node.label}</label>
        <ul className={folded ? "d-none" : ""}>
            {props.node.children.map(treeItem => {
                if (treeItem.is_watchsite) {
                    return <PerimeterSelectionWatchsite
                        key={treeItem.id}
                        parentChecked={props.parentChecked || props.perimeter.includes("node:" + props.node.id)}
                        perimeter={props.perimeter}
                        updatePerimeter={props.updatePerimeter}
                        watchsite={treeItem}
                    />;
                }
                if (treeItem.is_node) {
                    return <PerimeterSelectionNode
                        key={treeItem.id}
                        parentChecked={props.parentChecked || props.perimeter.includes("node:" + props.node.id)}
                        perimeter={props.perimeter}
                        updatePerimeter={props.updatePerimeter}
                        node={treeItem}
                    />;
                }
                return <React.Fragment key={treeItem.id}></React.Fragment>;
            })}
        </ul>
    </li>;
}