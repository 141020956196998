import { React, useEffect, useState, Link, toast, _, BarLoader } from 'commons';
import { formatDate } from "shared/services/Utils";
import { getSheetStateSmall, SHEET_STATE_TYPE_SYNTHESIS, SHEET_STATE_TYPE_TECHNICAL } from 'shared/data/SheetState';
import { getSheetStatusSmall } from 'data/SheetStatus';
import confirm from 'shared/services/Confirmation';
import PushApi from 'api/Push';
import StatusComponent from 'shared/components/StatusComponent';
import HtmlComponent from "shared/components/HtmlComponent";
import Domains from 'views/push/List/Domains';

export default function RowPush(props) {

    const { index, row, columns } = props;
    const className = index % 2 === 0 ? "" : "bg-gris-25";
    const [affected, setAffected] = useState("loading")
    const affectationDeadline = new Date(row.sheet.publication_date);
    affectationDeadline.setDate(affectationDeadline.getDate() + 21);
    var affectationDeadlineDays = (affectationDeadline.getTime() - (new Date()).getTime()) / (1000 * 3600 * 24);
    affectationDeadlineDays = affectationDeadlineDays > 0 ? Math.ceil(affectationDeadlineDays) : Math.floor(affectationDeadlineDays);

    let colSpan = 1;
    let rowSpan = 1;

    columns.forEach((col) => {
        if (col.selectable && col.is_active) {
            rowSpan = 2;
            colSpan = colSpan + 1;
        }        
    });

    function checkIfAffected() {
        setAffected("loading");

        PushApi
            .affectations(row.sheet.id)
            .then(results => setAffected(results.length > 0))
            .catch(error => toast.error(error.response.data.message));
    }

    function exclude(sheet, event) {
        event.preventDefault();
        confirm("Confirmez vous l'exclusion de la fiche de votre push ?", () => {
            props.setLoading(true);
            PushApi
                .excludeSheets([sheet])
                .then(() => {
                    toast.success("Fiche exclue");
                    props.refresh();
                })
                .catch(error => {
                    props.setLoading(false);
                    toast.error(error.response.data.message)
                });
        });
    }

    function affect(sheet, event) {
        event.preventDefault();
        props.openAffectationForm(sheet);
    }

    function affectations(sheet, event) {
        event.preventDefault();
        props.openAffectations(sheet);
    }

    function absences(sheet, event) {
        event.preventDefault();
        props.openAbsences(sheet);
    }

    useEffect(checkIfAffected, [row]);

    return <>
        <tr className={className}>
            {props.drawSelectable(props.row, {}, false, rowSpan)}
            <td colSpan={colSpan}><Link className="color-gris-texte" title="Consulter la fiche" to={`/sheets/${row.sheet.id}/preview`}>{row.sheet.id}: <strong><HtmlComponent>{row.sheet.title}</HtmlComponent></strong></Link></td>
            <td rowSpan={rowSpan} className="w-34px no-padding valign-top">
                <ul className='actions'>
                    <li><Link target="_blank" title="Consulter la fiche" to={`/sheets/${row.sheet.id}/preview`}><i className="icon-actions-consulter-fiche" aria-hidden="true"></i></Link></li>
                    <li><a title="Exclure la fiche du push" href={`/sheets/${row.sheet.id}/exlude`} onClick={(e) => exclude(row.sheet.id, e)}>
                        <i className="icon-actions-exclure-fiche-push" aria-hidden="true"></i>
                        <span className="d-none">Exclure la fiche du push</span>
                    </a></li>
                    <li><a title="Affecter la fiche aux éléments sélectionnés" href={`/sheets/${row.sheet.id}/affect`} onClick={(e) => affect(row.sheet, e)}>
                        <i style={{color:"#333"}} className="icon-actions-affecter" aria-hidden="true"></i>
                        <span className="d-none">Affecter la fiche aux éléments sélectionnés</span>
                    </a></li>
                    <li><a title="Point(s) de veille affecté(s)" href={`/sheets/${row.sheet.id}/affectations`} onClick={(e) => affectations(row.sheet, e)}>
                        <i className="icon-check" aria-hidden="true"></i>
                        <span className="d-none">Point(s) de veille affecté(s)</span>
                    </a></li>
                    <li><a title="Point(s) de veille non affecté(s)" href={`/sheets/${row.sheet.id}/absences`} onClick={(e) => absences(row.sheet, e)}>
                        <i className="icon-check-empty" aria-hidden="true"></i>
                        <span className="d-none">Point(s) de veille non affecté(s)</span>
                    </a></li>
                </ul>
            </td>
        </tr>
        <tr className={className}>
            {_.find(columns, {id: "sheet.text_date"}).is_active && <td><div title="Date du texte"><span className="color-vert-apave"><strong>DT :</strong></span> <strong>{formatDate(row.sheet.text_date)}</strong></div></td>}
            {_.find(columns, {id: "sheet.publication_date"}).is_active && <td><div title="Date de publication"><span className="color-vert-apave"><strong>DP :</strong></span><strong>{formatDate(row.sheet.publication_date)}</strong></div></td>}
            {_.find(columns, {id: "sheet.affectation_deadline"}).is_active && <td><div title="Délai d'affectation"><span className="color-vert-apave"><strong>DA :</strong></span> <strong><span className={affectationDeadlineDays > 0 ? "" : "color-rouge"}>{affectationDeadlineDays} jours</span></strong></div></td>}
            {_.find(columns, {id: "sheet.categories"}).is_active && <td><strong><Domains categories={row.sheet.categories} /></strong></td>}
            {_.find(columns, {id: "sheet.state_synthesis"}).is_active && <td><div title="Etat niveau synthèse"><strong><StatusComponent size="small" options={getSheetStateSmall(SHEET_STATE_TYPE_SYNTHESIS)} value={row.sheet.state_synthesis} /></strong></div></td>}
            {_.find(columns, {id: "sheet.state_technical"}).is_active && <td><div title="Etat niveau technique"><strong><StatusComponent size="small" options={getSheetStateSmall(SHEET_STATE_TYPE_TECHNICAL)} value={row.sheet.state_technical} /></strong></div></td>}
            {_.find(columns, {id: "sheet.status"}).is_active && <td><div title="Statut de la fiche"><strong><StatusComponent size="small" options={getSheetStatusSmall()} value={row.sheet.status} /></strong></div></td>}
            {_.find(columns, {id: "sheet.text_number"}).is_active && <td><div title="Numéro de texte">
                <span className="color-vert-apave"><strong>NT :</strong></span> <strong>{row.sheet.text_number}</strong>
                <br/>
                {affected === "loading" && <><BarLoader loading={true} width={20} color="#5bad27" css="display:block;margin:10px auto;" /></>}
                {affected === true && <>Fiche déjà affecté</>}
            </div></td>}
        </tr>
    </>
}