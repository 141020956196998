
export const ROLE_CONSULTANT = 'ROLE_CONSULTANT';
export const ROLE_LIBRARIAN = 'ROLE_LIBRARIAN';
export const ROLE_EXPERT = 'ROLE_EXPERT';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_ADMIN_CUSTOMER = 'ROLE_ADMIN_CUSTOMER';
export const ROLE_USER_CUSTOMER = 'ROLE_USER_CUSTOMER';

export const ROLES = {
    [ROLE_CONSULTANT]: "Consultant",
    [ROLE_LIBRARIAN]: "Documentaliste",
    [ROLE_EXPERT]: "Expert",
    [ROLE_ADMIN]: "Administrateur",
    [ROLE_SUPER_ADMIN]: "Administrateur technique",
    [ROLE_ADMIN_CUSTOMER]: "Administrateur client",
    [ROLE_USER_CUSTOMER]: "Utilisateur simple",
}

export const ROLES_BO = {
    [ROLE_CONSULTANT]: "Consultant",
    [ROLE_LIBRARIAN]: "Documentaliste",
    [ROLE_EXPERT]: "Expert",
    [ROLE_ADMIN]: "Administrateur",
    [ROLE_SUPER_ADMIN]: "Administrateur technique",
}

export const ROLES_FO = {
    [ROLE_ADMIN_CUSTOMER]: "Administrateur client",
    [ROLE_USER_CUSTOMER]: "Utilisateur simple",
}

export function getRoles() {    
    let roles = [];
    for (const [key, value] of Object.entries(ROLES)) {
        roles.push({
            value: key, 
            label: value,
        });
    }
    return roles;
}

export function getBoRoles() {    
    let roles = [];
    for (const [key, value] of Object.entries(ROLES_BO)) {
        roles.push({
            value: key, 
            label: value,
        });
    }
    return roles;
}

export function getFoRoles() {    
    let roles = [];
    for (const [key, value] of Object.entries(ROLES_FO)) {
        roles.push({
            value: key, 
            label: value,
        });
    }
    return roles;
}

export function getRoleLabel(role) {
    return ROLES[role];
}