import React, { useState } from 'react';
import { Prompt } from "react-router-dom";
import { toast } from 'react-toastify';
import LoadButton from 'shared/components/LoadButton';

import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";

import FieldComponent from 'shared/components/FieldComponent';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";

import NodeApi from 'shared/api/Node';
import Can, { canPerform } from 'shared/services/Can';
import { compileErrorsFromResponse } from 'shared/services/Utils';

import NodeUsersList from 'views/node/form/NodeUsersList';

export default function NodeForm(props) {

    const [togglers, toggle] = useToggler({'node-configuration': true, 'node-users': true});
    const node = Object.assign(
        {},
        {
            "label": "",
            "parent": null,
        },
        props.node
    );

    const [saving, setSaving] = useState("");
    const [data, errors, setErrors, setValue, setData,, formHasModifications, setHasModifications] = useForm(node);

    const mapper = (nodeItem) => {
        return {
            "label": nodeItem.label,
            "value": nodeItem.id,
            "children": nodeItem.childs.map(mapper),
            "checked": (data.parent === nodeItem.id),
            "disabled": data.id === nodeItem.id
        }
    };
    const tree = props.tree.nodes.map(mapper);

    function submit(event) { 
        event.preventDefault();

        setSaving(event.nativeEvent.submitter.name);

        NodeApi
            .save(data)
            .then(newData => {
                setErrors({});
                setData(Object.assign({}, data, {"id": newData.id}));
                setSaving("");
                setHasModifications(false);
                toast.success("Enregistrement effectué.");
                if (event.nativeEvent.submitter.name === "save-and-close") {
                    props.onRequestClose();
                }
            })
            .catch(error => {
                setSaving("");
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    function promptChecker() {
        if (props.promptChecker) {
            return props.promptChecker() || formHasModifications();
        } else {
            return formHasModifications();
        }
    }

    return (
        <section>
            <Prompt
                when={promptChecker()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            <h1>Configuration du noeud</h1>
            <form className="form" onSubmit={submit}>
                <ToggableBlocComponent label="Configuration du noeud" id="node-configuration" toggled={togglers["node-configuration"]} toggle={toggle} wrapChildren={false}>
                    <div className="border-gris-25 border-lrb bg-gris-20">
                        <div className="flex-label">
                            <label>Compte associé</label>
                            {props.account.name}
                        </div>
                        {data.id && data.readablePaths &&
                            <div className="flex-label">
                                <label>Chemin</label>
                                {data.readablePaths.join(" / ")}
                            </div>}
                    </div>
                    <div className="border-gris-25 border-lrb bg-blanc">
                        <FieldComponent 
                            prefixId="node-"
                            label="Libellé *"
                            className="field small"
                            name="label"
                            id="label"
                            error={errors.label}
                            value={data.label}
                            onChange={value => setValue("label", value)}
                            disabled={!canPerform("account:write", props.account)}
                        />
                        <FieldComponent 
                            prefixId="node-"
                            type="dropdown-tree-select"
                            label="Noeud parent"
                            className="field"
                            name="parent"
                            id="parent"
                            data={tree}
                            mode="radioSelect"
                            value={data.parent}
                            onChange={value => setValue("parent", value)}
                            disabled={!canPerform("account:write", props.account)}
                        />
                    </div>
                </ToggableBlocComponent>
                <div className="row">
                    <div className="col">
                        <button id="close-node" onClick={props.onRequestClose} type="button" className="btn btn-bleu-4">Fermer</button>
                    </div>
                    <Can
                        perform="account:write"
                        data={props.account}
                        yes={() => (
                            <div className="col text-right">
                                <LoadButton 
                                    loading={saving === "save-and-close"} 
                                    label="Enregistrer et fermer"
                                    name="save-and-close"
                                    id="save-and-close-node"
                                />
                                <LoadButton 
                                    loading={saving === "save"} 
                                    label="Enregistrer" 
                                    name="save" 
                                    id="save-node"
                                />
                            </div>
                        )}
                    />
                </div>
            </form>  
            {data.id && (<Can
                perform="account:write"
                data={props.account}
                yes={() => (<>
                    <ToggableBlocComponent label="Utilisateurs" id="node-users" toggled={togglers["node-users"]} toggle={toggle} wrapChildren={false}>
                        <NodeUsersList node={data.id} parent={data.parent} account={props.account.id} />
                    </ToggableBlocComponent>
                    <div className="row">
                        <div className="col">
                            <button id="close-node" onClick={props.onRequestClose} type="button" className="btn btn-bleu-4">Fermer</button>
                        </div>
                    </div>
                </>)}
            />)}
        </section>
    )
}