import React from 'react';
import { toast } from 'react-toastify';
import confirm from 'shared/services/Confirmation';
import PushApi from 'api/Push';

export default function BatchActions(props) {
    function exclude() {
        confirm("Confirmez vous l'exclusion des fiches de votre push ?", () => {
            props.setLoading(true);
            PushApi
                .excludeSheets(props.selection)
                .then(() => {
                    toast.success("Fiche(s) exclue(s)");
                    props.cleanSelection();
                    props.refresh();
                })
                .catch(error => {
                    props.setLoading(false);
                    toast.error(error.response.data.message)
                });
        });
    }
    return <button type="button" onClick={exclude} className="btn btn-primary h25">Exclure du push les fiches sélectionnées</button>
}