import { React, useState, useEffect, toast, _ } from 'commons';
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { SHEET_STATE_TODO, SHEET_STATE_UNNECESSARY } from 'shared/data';
import { InternalUserApi, SheetApi } from 'shared/api'
import { compileDataToSelectOptions } from 'shared/services';

registerLocale("fr", fr);

export default function SheetDashboardBatchAction(props) {

    const [batch, setBatch] = useState(null);
    const [technicalState, setTechnicalState] = useState(SHEET_STATE_TODO);
    const [expert, setExpert] = useState(null);
    const [experts, setExperts] = useState([]);
    const [librarian, setLibrarian] = useState(null);
    const [librarians, setLibrarians] = useState([]);
    const [date, setDate] = useState();

    function load() {        
        InternalUserApi
            .search({"bool":{"must":[{"terms":{"roles":["ROLE_EXPERT"]}}]}})
            .then(([u]) => setExperts(compileDataToSelectOptions(u, 'id', ['lastname', 'firstname'])));
        InternalUserApi
            .search({"bool":{"must":[{"terms":{"roles":["ROLE_LIBRARIAN"]}}]}})
            .then(([u]) => setLibrarians(compileDataToSelectOptions(u, 'id', ['lastname', 'firstname'])));            
    }

    function execute() {
        if (props.selection.length === 0) {
            toast.warning("Selection vide");
        } else {
            props.setLoading(true);
            switch (batch) {
                case "update_technical_state": 
                    if (expert || technicalState === SHEET_STATE_UNNECESSARY) {
                        SheetApi
                            .bulkUpdateTechnicalState(props.selection, technicalState, expert)
                            .then(() => {
                                setBatch("");
                                props.cleanSelection();
                                toast.success("Fiche(s) modifiées(s).")
                            })
                            .finally(() => _.delay(props.refresh, 2000));
                    } else {
                        toast.error("Veuillez sélectionner un expert.");
                        props.setLoading(false);
                    }
                    break;
                case "assign_librarian": 
                    if (librarian) {
                        SheetApi
                            .bulkUpdateSynthesisState(props.selection, SHEET_STATE_TODO, librarian)
                            .then(() => {
                                setBatch("");
                                props.cleanSelection();
                                toast.success("Fiche(s) modifiées(s).")
                            })
                            .finally(() => _.delay(props.refresh, 2000));
                    } else {
                        toast.error("Veuillez sélectionner un documentaliste.");
                        props.setLoading(false);
                    }
                    break;
                case "archived":
                    SheetApi
                        .bulkArchive(props.selection)
                        .then(() => {
                            setBatch("");
                            props.cleanSelection();
                            toast.success("Fiche(s) archivée(s).")
                        })
                        .finally(() => _.delay(props.refresh, 2000));
                    break;
                case "update_date_end_librarian_process": 
                case "update_date_end_expert_process":
                    if (date) {
                        let field = batch === "update_date_end_librarian_process" ? "date_end_librarian_process" : "date_end_expert_process";
                        SheetApi
                            .bulkUpdate(props.selection, {[field]: date})
                            .then(() => {
                                setBatch("");
                                props.cleanSelection();
                                toast.success("Date(s) de fin de traitement modifiée(s).")
                            })
                            .finally(() => _.delay(props.refresh, 2000));
                    } else {
                        toast.error("Veuillez sélectionner une date.");
                        props.setLoading(false);
                    }
                    break;
                default:
                    toast.error("Veuillez indiquer une tâche");
                    break;
            }
        }
    }

    // eslint-disable-next-line
    useEffect(load, []);

    return (
        <>
            <select id="batch-dashboard" value={batch} onChange={(e) => setBatch(e.target.value)}>
                <option value=""></option>
                <option value="update_technical_state">Statuer sur le niveau technique</option>
                <option value="assign_librarian">Affecter le documentaliste</option>
                <option value="archived">Archiver</option>
                <option value="update_date_end_librarian_process">Modifier date de fin de traitement documentaliste</option>
                <option value="update_date_end_expert_process">Modifier date de fin de traitement expert</option>
            </select>
            {batch === "update_technical_state" && <>
                <select id="technical-state" value={technicalState} onChange={(e) => setTechnicalState(parseInt(e.target.value))}>
                    <option value={SHEET_STATE_TODO}>Affecter</option>
                    <option value={SHEET_STATE_UNNECESSARY}>Non nécessaire</option>
                </select>
                {technicalState === SHEET_STATE_TODO && <>
                    <select id="batch-expert" value={expert} onChange={e => setExpert(e.target.value)}>
                        <option></option>
                        {experts.map((item) => <option key={item.value} value={item.value}>{item.label}</option>)}
                    </select>
                </>}
            </>}
            {batch === "assign_librarian" && <>
                <select id="batch-librarian" value={librarian} onChange={e => setLibrarian(e.target.value)}>
                    <option></option>
                    {librarians.map((item) => <option key={item.value} value={item.value}>{item.label}</option>)}
                </select>
            </>}
            {(batch === "update_date_end_librarian_process" || batch === "update_date_end_expert_process") && <>
                <DatePicker
                    id="batch-date"
                    locale="fr"
                    selected={date}
                    onChange={v => setDate(v)}
                    placeholderText={"jj/mm/aaaa"}
                    dateFormat={"dd/MM/yyyy"}                        
                />
            </>}
            {batch && <button onClick={execute} className="btn btn-primary h25" type="button">Executer</button>}
        </>
    )
}