import AbstractApi from 'shared/api/Abstract';
import ApiRequest from 'shared/services/ApiRequest';

const DispatchApi = {

    search: function(query, from, size, sorting, direction) {
        return AbstractApi.search("dispatchs/searches", query, from, size, sorting, direction);
    },

    get: function(id) {
        return AbstractApi.get("dispatchs", id);
    },

    save: function(data) {
        return AbstractApi.save("dispatchs", data);
    },

    generate: function(id) {
        return ApiRequest.post("dispatchs/" + id + "/pdf", {}, {responseType: 'blob'}).then(response => response.data);
    },

    download: function(id) {
        return ApiRequest.get("dispatchs/" + id + "/pdf", {responseType: 'blob'}).then(response => response.data);
    },

    listSheets: function(id) {
        return AbstractApi.list("dispatchs/" + id + "/sheets");
    },

    listAvailableSheets: function() {
        return AbstractApi.list("dispatchs/available/sheets");
    }
}

export default DispatchApi;