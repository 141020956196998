import React, { useState } from 'react';
import { Prompt } from "react-router-dom";
import { toast } from 'react-toastify';
import { useForm } from 'shared/hooks/Form';
import { useToggler } from "shared/hooks/Toggler";
import RequirementApi from 'shared/api/Requirement';
import FieldComponent from 'shared/components/FieldComponent';
import LoadButton from 'shared/components/LoadButton';
import ToggableBlocComponent from "shared/components/ToggableBlocComponent";
import { compileErrorsFromResponse } from 'shared/services/Utils';

export default function SectionForm(props) {
    
    const { section, sections, onUpdated, onRequestClose } = props;

    const [togglers, toggle] = useToggler({'section-configuration': true});
    const [saving, setSaving] = useState("");
    const [data, errors, setErrors, setValue,,, formHasModifications, setHasModifications] = useForm(section);

    function getSections() {
        const mapper = (s) => {
            return {
                "label": s.title,
                "value": s.id,
                "children": s.children ? s.children.map(mapper) : [],
                "checked": (data.parent === s.id),
                "disabled": (data.id === s.id),
            }
        };
        return sections.map(mapper);
    }

    function submit(event) {
        event.preventDefault();
        setSaving(event.nativeEvent.submitter.name);

        RequirementApi
            .saveSection(data)
            .then(newdata => {
                setValue("id", newdata.id);
                setSaving("");
                setHasModifications(false);
                setErrors({});
                onUpdated(newdata.id);
                toast.success("Enregistrement effectué.");
                if (event.nativeEvent.submitter.name === "save-and-close") {
                    onRequestClose();
                }
            })
            .catch(error => {
                setSaving("");
                if (error.response.data.message) {
                    toast.error(error.response.data.message);                    
                } else {
                    setErrors(compileErrorsFromResponse(error.response));
                    toast.error("Des erreurs sont survenues");
                }
            });
    }

    return (
        <section>
            <Prompt
                when={formHasModifications()}
                message="Vous avez des modifications non enregistrées, voulez-vous vraiment quitter ?"
            />
            {data.id && <h1>Modifier la section</h1>}
            {!data.id && <h1>Ajouter une nouvelle section</h1>}
            <form onSubmit={submit} id="form-requirement-section" className="form">
                <ToggableBlocComponent label="Configuration" id="section-configuration" toggled={togglers["section-configuration"]} toggle={toggle}>
                    <FieldComponent
                        className="field medium"
                        name="title"
                        label="Titre *"
                        value={data.title}
                        error={errors.title}
                        onChange={value => setValue("title", value)}
                    />
                    <FieldComponent
                        className="field medium"
                        type="dropdown-tree-select"
                        name="parent"
                        label="Parent"
                        onChange={value => setValue("parent", value)}
                        data={getSections()}
                        mode="radioSelect"
                    />
                </ToggableBlocComponent>
                <section className="row">
                    <div className="col">
                        <button id="close-requirement-section" onClick={props.onRequestClose} type="button" className="btn btn-bleu-4">Fermer</button>
                    </div>                            
                    <div className="col text-right">
                        <LoadButton 
                            loading={saving === "save-and-close"}
                            label="Enregistrer et fermer"
                            name="save-and-close"
                            id="save-and-close-requirement-section"
                        />
                        <LoadButton 
                            loading={saving === "save"} 
                            label="Enregistrer" 
                            name="save" 
                            id="save-requirement-section"
                        />
                    </div>
                </section>
            </form>
        </section>
    )
}