import { React, useState } from 'commons';
import ConfigurationWatchsite from 'views/push/List/Assignements/AffectationForm/ConfigurationWatchsite';

export default function ConfigurationNode(props) {

    const [folded, setFold] = useState(false);
    const isIncluded = props.parentIncluded || props.includedPerimeters.includes("node:" + props.node.id);
    const isForced = props.parentForced || props.forcedPerimeters.includes("node:" + props.node.id);
    const isIncluedInUpdateBatch = props.parentIncluedInUpdateBatch || props.batchCommentUpdatePerimeters.includes("node:" + props.node.id);

    let hasWatchsiteAffected = false;
    const hasWatchsiteAffectedChecker = (i) => {
        if (i.is_watchsite) {
            if (props.alreadyAffected.includes(i.id)) {
                hasWatchsiteAffected = true;
            }
        }
        if (i.is_node && !hasWatchsiteAffected) {
            i.children.map(hasWatchsiteAffectedChecker);
        }
        return i;
    };

    if (Array.isArray(props.alreadyAffected)) {
        props.node.children.map(hasWatchsiteAffectedChecker);
    }

    if (props.node.is_selected) {
        return <li>
            <div className="highlight">
                <button type="button" className="btn btn-primary icon icon-only" onClick={() => setFold(!folded)}>
                    <i className={folded ? "icon-boutons-plus" : "icon-boutons-moins"}></i>
                </button>
                <fieldset className="at-left">
                    <legend className="sr-only">Paramétrage du noeud&nbsp;:</legend>
                    <div>
                        <input
                            checked={isIncluded}
                            type="checkbox"
                            id={`node-${props.node.id}-affect`}
                            disabled={props.parentIncluded === true}
                            onChange={e => props.updateParameters("include-perimeter", "node:" + props.node.id, e.target.checked)}
                        />
                        <label id={`node-${props.node.id}-affect-label`} htmlFor={`node-${props.node.id}-affect`} className="w-120px text-center">
                            <span className="sr-only">Affecter</span>
                        </label>
                        {isIncluded && <>
                            <input
                                checked={isIncluedInUpdateBatch}
                                type="checkbox"
                                id={`node-${props.node.id}-updatecomment`}
                                disabled={props.parentIncluedInUpdateBatch === true}
                                onChange={e => props.updateParameters("addto-batch-update-comment", "node:" + props.node.id, e.target.checked)}
                            />
                            <label id={`node-${props.node.id}-updatecomment-label`} htmlFor={`node-${props.node.id}-updatecomment`} className="w-120px text-center">
                                <span className="sr-only">Modifier le commentaire</span>
                            </label>
                            {hasWatchsiteAffected && <>
                            <input
                                checked={isForced}
                                type="checkbox"
                                id={`node-${props.node.id}-forceaffectation`}
                                disabled={props.parentForced === true}
                                onChange={e => props.updateParameters("force-perimeter", "node:" + props.node.id, e.target.checked)}
                            />
                            <label id={`node-${props.node.id}-forceaffectation-label`} htmlFor={`node-${props.node.id}-forceaffectation`} className="w-120px text-center">
                                <span className="sr-only">Forcer l'affectation</span>
                            </label>
                            </>}
                        </>}
                    </div>
                </fieldset>
                <label htmlFor={`node-${props.node.id}`}>{props.node.label}</label>
            </div>
            <ul className={folded ? "d-none" : ""}>
                {props.node.children.map(treeItem => {
                    if (treeItem.is_watchsite) {
                        return <ConfigurationWatchsite
                            key={treeItem.id}
                            watchsite={treeItem}
                            includedPerimeters={props.includedPerimeters}
                            forcedPerimeters={props.forcedPerimeters}
                            batchCommentUpdatePerimeters={props.batchCommentUpdatePerimeters}
                            specificComments={props.specificComments}
                            parentIncluded={isIncluded}
                            parentForced={isForced}
                            parentIncluedInUpdateBatch={isIncluedInUpdateBatch}
                            updateParameters={props.updateParameters}
                            updateComment={props.updateComment}
                            alreadyAffected={props.alreadyAffected}
                        />;
                    }
                    if (treeItem.is_node) {
                        return <ConfigurationNode
                            key={treeItem.id}
                            node={treeItem}
                            includedPerimeters={props.includedPerimeters}
                            forcedPerimeters={props.forcedPerimeters}
                            batchCommentUpdatePerimeters={props.batchCommentUpdatePerimeters}
                            specificComments={props.specificComments}
                            parentIncluded={isIncluded}
                            parentForced={isForced}
                            parentIncluedInUpdateBatch={isIncluedInUpdateBatch}
                            updateParameters={props.updateParameters}
                            updateComment={props.updateComment}
                            alreadyAffected={props.alreadyAffected}
                        />;
                    }
                    return <React.Fragment key={treeItem.id}></React.Fragment>;
                })}
            </ul>
        </li>;
    } else {
        return <>
            {props.node.children.map(treeItem => {
                if (treeItem.is_watchsite) {
                    return <ConfigurationWatchsite
                        key={treeItem.id}
                        watchsite={treeItem}
                        includedPerimeters={props.includedPerimeters}
                        forcedPerimeters={props.forcedPerimeters}
                        batchCommentUpdatePerimeters={props.batchCommentUpdatePerimeters}
                        specificComments={props.specificComments}
                        parentIncluded={isIncluded}
                        parentForced={isForced}
                        parentIncluedInUpdateBatch={isIncluedInUpdateBatch}
                        updateParameters={props.updateParameters}
                        updateComment={props.updateComment}
                        alreadyAffected={props.alreadyAffected}
                    />;
                }
                if (treeItem.is_node) {
                    return <ConfigurationNode
                        key={treeItem.id}
                        node={treeItem}
                        includedPerimeters={props.includedPerimeters}
                        forcedPerimeters={props.forcedPerimeters}
                        batchCommentUpdatePerimeters={props.batchCommentUpdatePerimeters}
                        specificComments={props.specificComments}
                        parentIncluded={isIncluded}
                        parentForced={isForced}
                        parentIncluedInUpdateBatch={isIncluedInUpdateBatch}
                        updateParameters={props.updateParameters}
                        updateComment={props.updateComment}
                        alreadyAffected={props.alreadyAffected}
                    />;
                }
                return <React.Fragment key={treeItem.id}></React.Fragment>;
            })}
        </>
    }
}