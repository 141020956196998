import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { denyIfCantPerform } from 'shared/services/Can';
import DefaultLayout from "layouts/DefaultLayout";
import WatchsiteForm from 'views/watchsite/Form';
import AccountApi from 'shared/api/Account';
import TreeUtils from 'shared/services/TreeUtils';
import PageLoadingComponent from 'shared/components/PageLoadingComponent';

export default function WatchsiteFormWrapper(props) {
    denyIfCantPerform(props, "account:read");

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [tree, setTree] = useState({nodes: [], watchsites: []});
    const [account, setAccount] = useState({});
    const [watchsite, setWatchsite] = useState({});
    const [consultants, setConsultants] = useState({});

    useEffect(() => {
        axios
            .all([
                AccountApi.getTree(props.match.params.account),
                AccountApi.get(props.match.params.account),
                AccountApi.getConsultants(props.match.params.account)
            ])
            .then(axios.spread((treeData, accountData, [consultantsData]) => {
                const watchsiteMapper = (w) => {
                    if (parseInt(w.id) === parseInt(props.match.params.id)) {
                        setWatchsite(w);
                    }
                    return w;
                };
                const nodeMapper = (n) => {
                    n.watchsites.map(watchsiteMapper);
                    n.childs.map(nodeMapper);
                    return n;
                };

                treeData = TreeUtils.populateReadablePaths(treeData);
                treeData.nodes.map(nodeMapper);
                treeData.watchsites.map(watchsiteMapper);

                setTree(treeData);

                accountData.pilot_consultants = accountData.pilot_consultants.map(c => c.id);
                accountData.associate_consultants = accountData.associate_consultants.map(c => c.id);
                accountData.domains = accountData.domains.map(d => d.id);
                setAccount(accountData);

                let newconsultants = [];
                Object.entries(consultantsData).forEach(([index, user]) => {
                    newconsultants.push({"value": user.id, "label": user.lastname + " " + user.firstname});
                });
                setConsultants(newconsultants);

                setLoading(false);
            }))
            .catch(error => console.log(error));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <PageLoadingComponent label="Configuration du point de veille" />
    } else {
        return (
            <DefaultLayout title="Configuration du point de veille">
                <WatchsiteForm
                    watchsite={watchsite}
                    consultants={consultants}
                    account={account}
                    tree={tree}
                    onRequestClose={() => history.push("/accounts/" + props.match.params.account)}
                />
            </DefaultLayout>
        );
    }
}
