import { React, useState, Link, _ } from "commons";
import FieldComponent from "shared/components/FieldComponent";
import SheetAutocomplete from 'shared/services/autocomplete/Sheet';

export default function LinkedSheetPicker(props) {

    const { values, label, moveArrow, reverseValues, onChange } = props;
    const [currentValue, setCurrentValue] = useState(null);

    const loadSheets = _.debounce(_loadSheets, 300);
    function _loadSheets(inputValue, callback) {
        if (inputValue === "" || inputValue.length < 3) {
            callback([]);
        }
 
        SheetAutocomplete(
            {
                keyword: inputValue,
                excludeIds: [
                    props.sourceSheet,
                    ...values.map(item => item.value),
                    ...reverseValues.map(item => item.value)
                ]
            },
            callback
        );
    }

    function addItem(itemAdded) {
        let newItems = [...values];
        const itemIndex = newItems.findIndex(item => item.value === itemAdded.value);
        const itemIndexExclude = reverseValues.findIndex(item => item.value === itemAdded.value);
        if(itemIndex === -1 && itemIndexExclude === -1) {
            newItems.push(itemAdded);

            onChange(
                newItems,
                reverseValues
            );
        }
    }

    function moveItem(event, itemMoved) {
        event.preventDefault();
        let newItems = [...reverseValues];
        newItems.push(itemMoved);
        onChange(
            values.filter(item => item.value !== itemMoved.value),
            newItems
        )
    }

    function removeItem(event, itemRemoved) {
        event.preventDefault();
        onChange(
            values.filter(item => item.value !== itemRemoved.value),
            reverseValues
        );
    }

    return (
        <div className="flex-label">
            <span className="label">{label}</span>
            <FieldComponent
                wrap={false}
                type="async-select"
                cacheOptions={false}
                name="linkedsheet"
                className="field medium"
                loadOptions={loadSheets}
                value={currentValue}
                onChange={(value, item) => {
                    addItem(item);
                    setCurrentValue(null);
                }}
            />

            <ul className="filelist arborescence no-arrow squared">
                {_.orderBy(values, ["value"], "desc").map(item => {
                    return(
                        <li key={item.value}>
                            <span>{item.value} - {item.label} - </span>
                            <Link data-info="Editer la fiche dans un nouvel onglet" className="infobulle" target="_blank" to={"/sheets/" + item.value} id="edit-sheet-from-preview">
                                <i className="icon-actions-modifier" aria-hidden="true"></i>
                            </Link>
                            <button data-info="Déplacer la fiche dans l'autre liste" className="infobulle" type="button" onClick={e => moveItem(e, item)} id="move-sheet-to-other-list">
                                <i className={"icon-" + (moveArrow || "up")+ "-open"} aria-hidden="true"></i>
                            </button>
                            <button data-info="Supprimer la fiche de la liste" className="infobulle" type="button" onClick={e => removeItem(e, item)} id="remove-sheet-from-list">
                                <i className="icon-filtres-poubelle" aria-hidden="true"></i>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    )
}